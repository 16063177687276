import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Logger, MessageBox } from 'tmslib/src/context/MessageContext';
import {
  ValidData,
  callAxios as callAxios_,
  CallAxiosArgs,
  callAxiosGet as callAxiosGet_,
  downloadFile as downloadFile_,
} from 'tmslib/src/util/axiosutils';
import UrlGrid_, {
  UrlGridArgs as UrlGridArgs_,
} from 'tmslib/src/table/UrlGrid';
import { IId } from 'tmslib/src/table/DataGrid';
import { SimpleVhcl } from './shared/VhclSelector';
import { VhGrpTy } from './Tms/Tms';

export const isKAst = (prodId: string) =>
  prodId.length && prodId[0].isIn('A', '1');
export const isKStk = (prodId: string) => prodId.length && prodId[0] === 'A';
export const isKFut = (prodId: string) => prodId.length && prodId[0] === '1';
export const isKSFut = (prodId: string) =>
  prodId.length > 1 &&
  prodId[0] === '1' &&
  '12345BCDEFGH'.indexOf(prodId[1]) >= 0;

export const isUAst = (prodId: string) =>
  prodId.length && prodId[0].isIn('U', 'V');
export const isUStk = (prodId: string) => prodId.length && prodId[0] === 'U';
export const isUFut = (prodId: string) => prodId.length && prodId[0] === 'V';

export const isK2Fut = (prodId: string) => prodId.startsWith('101');
export const isK2FutSpr = (prodId: string) => prodId.startsWith('401');
export const isK2Mini = (prodId: string) => prodId.startsWith('105');
export const isK2MiniSpr = (prodId: string) => prodId.startsWith('405');
export const isKQFut = (prodId: string) => prodId.startsWith('106');
export const isKQFutSpr = (prodId: string) => prodId.startsWith('406');

export const isKMainIdxFutOrSpr = (prodId: string) =>
  isK2Fut(prodId) ||
  isK2Mini(prodId) ||
  isKQFut(prodId) ||
  isK2FutSpr(prodId) ||
  isK2MiniSpr(prodId) ||
  isKQFutSpr(prodId);

export const isSingleFund = (vhOrGrp: string) => {
  const grpIndex: number = Object.keys(VhGrpTy).indexOf(vhOrGrp);
  if (grpIndex >= 0 || vhOrGrp === 'ALL_FUNDS') {
    return false;
  }
  return true;
};

let mainAxios: AxiosInstance | null = null;

export const getMainAxios = () => {
  if (!mainAxios) mainAxios = axios.create();
  return mainAxios;
};

export const emptyGridArgs = {
  url: '',
  title: '',
  columns: [],
  headers: [],
  height: 100,
  hide: true,
};

export type UrlGridArgs<T extends IId> = Omit<UrlGridArgs_<T>, 'axiosInstance'>;

export const UrlGrid = <T extends IId>({
  args,
  params,
  refreshNeeded,
}: {
  args: UrlGridArgs<T>;
  params: object;
  refreshNeeded?: number;
}) =>
  UrlGrid_({
    args: { ...args, axiosInstance: getMainAxios() },
    params,
    refreshNeeded,
  });

export type { ValidData };

export const callAxios = (args: Omit<CallAxiosArgs, 'axiosInstance'>) =>
  callAxios_({ ...args, axiosInstance: getMainAxios() });

export const callAxiosGet = (
  args: Omit<CallAxiosArgs, 'isGet' | 'axiosInstance'>,
) => callAxiosGet_({ ...args, axiosInstance: getMainAxios() });

export const downloadFile = (
  args: Omit<CallAxiosArgs, 'responseType' | 'onSuccess' | 'axiosInstance'>,
) => downloadFile_({ ...args, axiosInstance: getMainAxios() });

export const utilGet = (
  m: MessageBox,
  logger: Logger,
  func: string,
  params: unknown,
  onSuccess: (data: ValidData, res: AxiosResponse) => void,
) =>
  callAxios({
    m,
    logger,
    url: `/Util/${func}`,
    params,
    onSuccess,
    isGet: true,
  });

export const utilPost = (
  m: MessageBox,
  logger: Logger,
  func: string,
  params: unknown,
  onSuccess: (data: ValidData, res: AxiosResponse) => void,
) =>
  callAxios({
    m,
    logger,
    url: `/Util/${func}`,
    params,
    onSuccess,
  });

export type FuncCall = (
  func: string,
  params: unknown,
  args?: {
    whenInvalidMsg?: string;
    additionalOnSuccess?: () => void; // 성공 후 refresh 전에 행동
    isGet?: boolean;
    title?: string;
  },
) => Promise<void>;

export function genFuncCall(
  m: MessageBox,
  logger: Logger,
  page: string,
  onSuccessDefault?: (data: ValidData, res: AxiosResponse) => void,
): FuncCall {
  return (func, params, args) =>
    callAxios({
      m,
      logger,
      url: `${page}/${func}`,
      params,
      onSuccess: (data, res) => {
        args?.additionalOnSuccess?.();
        onSuccessDefault?.(data, res);
      },
      whenInvalidMsg: args?.whenInvalidMsg,
      isGet: args?.isGet,
      title: args?.title,
    });
}

export enum UserCfgTy {
  None = 'None',
  TgtShowStdDev = 'TgtShowStdDev',
  TgtShowEarnRev = 'TgtShowEarnRev',
  TgtShowMktWei = 'TgtShowMktWei',
  TgtShowIdxWei = 'TgtShowIdxWei',
  TgtShowErSc = 'TgtShowErSc',
  TgtShowDropSc = 'TgtShowDropSc',
  TgtShowCurrPrc = 'TgtShowCurrPrc',
  TgtShowBatchOrd = 'TgtShowBatchOrd',
  TgtShowBasketOrd = 'TgtShowBasketOrd',
  TgtShowPeriodOrd = 'TgtShowPeriodOrd',
  SmrMineOnly = 'SmrMineOnly',
  SmrShowAll = 'SmrShowAll',
  AdvisorMode = 'AdvisorMode',
}

export const selectedUserCfgs = (
  m: MessageBox,
  logger: Logger,
  cfgs: UserCfgTy[],
  callback: (selected: UserCfgTy[]) => void,
) => utilGet(m, logger, 'SelectedUserCfgs', { cfgs }, (data) => callback(data));

export const updateUserCfg = (
  m: MessageBox,
  logger: Logger,
  cfg: UserCfgTy,
  val: boolean,
  callback?: (v: boolean) => void,
) => utilPost(m, logger, 'UpdateUserCfg', { cfg, val }, () => callback?.(val));

export const saveMemo = async ({
  m,
  logger,
  isDelete,
  memoParams,
  callback,
  oldval,
}: {
  m: MessageBox;
  logger: Logger;
  isDelete: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  memoParams: any;
  callback?: () => void;
  oldval?: string | null;
}) => {
  let memo = null;
  if (!isDelete) {
    memo = await m.prompt('Memo', oldval ?? '');
  }
  if (!isDelete && !memo) {
    m.alert('내용 없음');
    return;
  }
  const params = { ...memoParams, memo };
  utilPost(m, logger, 'SaveMemo', params, () => callback?.());
};

export const checkItems = <T>(items: T[], m: MessageBox, single = false) => {
  if (!items.length) {
    m.alert('선택 항목 없음');
    return false;
  }
  if (single && items.length > 1) {
    m.alert('한 항목만 선택하세요.');
    return false;
  }
  return true;
};

export const saveProdGrp = async (
  m: MessageBox,
  logger: Logger,
  st: string,
  prod: string,
  oldGrp: string | null,
  onSuccess: () => void
) => {
  const newGrp = await m.prompt('그룹명', oldGrp ?? undefined);
  if (newGrp == null) return;
  if (newGrp === oldGrp) return;
  const par = { st, prod, grp: newGrp };
  utilPost(m, logger, 'SaveProdGrp', par, () => onSuccess());
};

export const renameProdGrp = async (
  m: MessageBox,
  logger: Logger,
  st: string,
  oldGrp: string,
  onSuccess: () => void
) => {
  const newGrp = await m.prompt('그룹명', oldGrp ?? undefined);
  if (newGrp == null) return;
  if (newGrp === oldGrp) return;
  const par = { st, oldGrp, newGrp };
  utilPost(m, logger, 'RenameProdGrp', par, () => onSuccess());
};

export function GetDefaultUnit(vhcl: SimpleVhcl | null) {
  return vhcl?.crncy === 'USD' ? 1e-3 : 1e-8;
}
export function GetDefaultUnitDesc(vhcl: SimpleVhcl | null) {
  return vhcl?.crncy === 'USD' ? '(1K)' : '(억)';
}

export function getSplitFileNm(fnm_: string) {
  const fnm0 = fnm_.split('_').slice(1).join('_'); // 파일명 앞의 id_ 부분 생략
  let fnm = fnm0;
  if (fnm.length > 15) {
    fnm = fnm.slice(0, 15).concat('..');
  }
  return fnm;
}
