// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.ql-container {
    height: calc(100% - 50px) /* 툴바만큼 부모 높이 초과 방지 */
}`, "",{"version":3,"sources":["webpack://./../tmslib/src/ui/editor.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,qBAAqB;AACnD","sourcesContent":["div.ql-container {\n    height: calc(100% - 50px) /* 툴바만큼 부모 높이 초과 방지 */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
