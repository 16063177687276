// https://dev.to/mr_mornin_star/create-a-react-virtualizationwindowing-component-from-scratch-54lj

import React from 'react';

export interface WindowProps {
  containerHeight: number;
  rowHeight: number;
  // children: Array<JSX.Element>;
  getChildren: (startIndex: number, endIndex: number) => JSX.Element[];
  length: number;
  gap?: number;
  topPadding: number;
  scrollPosition: number;
}
const bufferedItems = 2;

function Window({
  containerHeight,
  rowHeight,
  getChildren,
  length,
  gap = 1,
  topPadding,
  scrollPosition,
}: WindowProps) {
  // get the children to be renderd
  const visibleChildren = React.useMemo(() => {
    const startIndex = Math.max(
      Math.floor(scrollPosition / rowHeight) - bufferedItems,
      0,
    );
    const endIndex = Math.min(
      Math.ceil((scrollPosition + containerHeight) / rowHeight - 1) +
        bufferedItems,
      length - 1,
    );
    return getChildren(startIndex, endIndex + 1).map(
      (child: JSX.Element, index: number) =>
        React.cloneElement(child, {
          style: {
            position: 'absolute',
            top: topPadding + (startIndex + index) * rowHeight + index * gap,
            height: rowHeight,
            left: 0,
            right: 0,
          },
        }),
    );
  }, [
    getChildren,
    length,
    containerHeight,
    rowHeight,
    topPadding,
    scrollPosition,
    gap,
  ]);

  return <>{visibleChildren.map((v) => v)}</>;
}

export default Window;
