import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import RadioGroup from 'tmslib/src/ui/RadioGroup';
import { SimpleTableDataType, arrayTable, simpleTable } from 'tmslib/src/table/tables';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { UrlGrid, UrlGridArgs , callAxiosGet } from '../../tmsutil'; 
import {
  AiComp,
  AiInv,
  AiPnL,
  AiProd,
  AiTgt,
  PeriodPerf,
  aiFillSchema,
} from '../../Tms/AI';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import { DftBtnStyle } from '../../AppTypes';

const currMenu = '/AI/BookView';

// prettier-ignore
const aiCompDft: UrlGridArgs<AiComp> = {
  url: `${currMenu}/AiComp`,
  title: '1. 기업 개요',
  columns: ['Id', 'nm', 'loc', 'cmpNo', 'regNo', 'ceo', 
    'exchStr', 'mc', 'vent_d1',
    'mainBiz', 'ipoComp', 'ipoState', 'sec0', 'sec1', 'sec2'],
  headers: ['기업코드', '기업명', '법인구분', '법인번호', '사업자등록번호', '대표이사',
    '상장시장', '시총(억)', '벤처해제일',
    '주요사업', '상장주관사', '상장진행현황', '대분류', '중분류', '소분류'],
  widths: {nm: 120, mainBiz: 200 },
  height: 250,
};

// prettier-ignore
const aiInvDft: UrlGridArgs<AiInv> = {
  url: `${currMenu}/AiInv`,
  title: '2. 투자 자산',
  columns: ['Id', 'prodId', 'prodNm', 'invTy', 'underNm', 'oldNew',
    'holdTy', 'oia', 'prin', 'amt', 'rt', 'cltdRt', 'avgPrc', 'currPrc', 'prcDiffRto',
    'ourGuy', 'backupGuy', 'mktExitD', 'emerExitD', 'emerExitWay', 'normExitD', 'normExitWay',
    'invD', 'convD', 'putD0', 'putDN', 'putD1', 'callD0', 'callD1', 'unlockD', 'ltd',
    'ytm', 'ytp', 'ytc', 'callRto', 'ord'],
  headers: ['Id', '자산코드', '자산명', '투자형태', '기초자산명', '구/신',
    '보유형태', '최초투자원금', '현재원금액', '현재평가액', '평가수익률', '회수수익률', '투자단가', '현재주가', '괴리율',
    '담당자(운용)', '담당자(지원)', 'Exit예상(M)', 'Exit예상(급)', 'Exit방안(급)', 'Exit예상(평)', 'Exit방안(평)',
    '투자일', '전환가능일', 'PUT최초일', 'PUT다음일', 'PUT최종일', 'CALL최초일', 'CALL종료일', '보호예수종료일', '만기일',
    'YTM', 'YTP', 'YTC', 'CALL가능비율', '투자번호'],
  height: 300,
  meta: { dftColWidth: 80 }
};

const setInvProdDetail = (prod: AiProd, inv: AiInv) => (
  <div className="d-flex children-me-4">
    <div>
      <b>자산 정보</b>
      {arrayTable(
        // prettier-ignore
        [prod.issueD,prod.ltd,prod.round,prod.couponRt,prod.ytm,prod.ytp,prod.ytc,prod.rfxPrcLmt,prod.rfxBase,prod.rfxUnitPrd,prod.intrTy,prod.spAgrr,inv.unlockD,inv.deposit,inv.dealComp,inv.dealGuy,inv.ourGuy,inv.otherInvs,
        ].map((v) => [v as SimpleTableDataType]),
        {
          headers: [''],
          // prettier-ignore
          rowHeaders: ['발행일','만기일','회차(종)','표면이자율','YTM','YTP','YTC','리픽싱','리픽싱기준','리픽싱단위','이자타입','특약유무','보호예수종료일','보관','딜소싱. 회사명','딜소싱. 담당자','당사담당자','공동투자자',],
          styler: () => ({ maxWidth: '200px' }),
        },
      )}
    </div>
    <div>
      <b>전환 단가</b>
      {simpleTable(prod.rfxs, ['d', 'prc', 'lb'], {
        headers: ['일자', '단가', '하단'],
      })}
    </div>
    <div>
      <b>조기상환청구권 (Put)</b>
      {simpleTable(prod.puts, ['reqD0', 'reqD1', 'exerD', 'xp', 'rto'], {
        headers: ['시작일', '종료일', '행사일', '조기상환율', '가능비율'],
      })}
    </div>
    <div>
      <b>매도청구권 (Call)</b>
      {simpleTable(prod.calls, ['reqD0', 'reqD1', 'exerD', 'xp', 'rto'], {
        headers: ['시작일', '종료일', '행사일', '원금상환율', '가능비율'],
      })}
    </div>
    <div>
      <b>표면이자</b>
      {simpleTable(prod.divs, ['d'], {
        headers: ['일자'],
      })}
    </div>
  </div>
);

type CompDetailTy = 'compGuy' | 'compStk';
type InvDetailTy = 'invProd' | 'invHold';

type CmprPos = {
  Id: number;
  prodId: string;
  prodNm: string | null;
  vhId: string;
  book: number;
  pos: number;
  diff: number;
}

export default function BookView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const tgt: AiTgt = (searchParams.get('tgt') as AiTgt) || AiTgt.AI;
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [vhOrGrp, setVhOrGrp] = useState('') || 'ALL_FUNDS';
  const [asKrw, setAsKrw] = useState(false);
  const [currComp, setCurrComp] = useState<AiComp | null>(null);
  const [refreshAiComp, setRefreshAiComp] = useState(0);
  const [refreshAiCompRefs, setRefreshAiCompRefs] = useState(0);
  const [compDetailTy, setCompDetailTy] = useState<CompDetailTy>('compGuy');
  const [invs, setInvs] = useState<AiInv[]>([]);
  const [currInv, setCurrInv] = useState<AiInv | null>(null);
  const [invDetailTy, setInvDetailTy] = useState<InvDetailTy>('invProd');
  const [currPnL, setCurrPnL] = useState<AiPnL | null>(null);
  const [prdD0, setPrdD0] = useState(info?.currBizDay || '');
  const [prdD1, setPrdD1] = useState(info?.currBizDay || '');
  const [periodPerf, setPeriodPerf] = useState<PeriodPerf[]>([]);
  const [cmprPos, setCmprPos] = useState<CmprPos[]>([]);

  // 필터 등 그리드 내부 상태 유지하려고 메모에.
  const aiComp: UrlGridArgs<AiComp> = useMemo(
    () => ({
      ...aiCompDft,
      meta: {
        dftColWidth: 100,
        onRowClick: (r) => setCurrComp(r.original),
      },
    }),
    [],
  );

  const aiInv: UrlGridArgs<AiInv> = useMemo(
    () => ({
      ...aiInvDft,
      meta: {
        dftColWidth: 80,
        onRowClick: (r) => setCurrInv(r.original),
      },
      onDataChange: (data) => setInvs(data),
    }),
    [],
  );

  useEffect(() => {
    setRefreshAiCompRefs((p) => p + 1);
    setCurrInv(null);
  }, [currComp]);

  useEffect(() => {
    setCurrPnL(null);
  }, [currInv]);

  const prod = currInv?.prod;

  const refreshPage = () => {
    setRefreshAiComp((p) => p + 1);
    setRefreshAiCompRefs((p) => p + 1);
    setCurrComp(null);
    setCurrInv(null);
  };

  const getPeriodPerf = () => {
    callAxiosGet({
      m,
      logger,
      url: `${currMenu}/Period`,
      params: { tgt, d: prdD1, d0: prdD0, comp: currComp?.Id, vhOrGrp, asKrw },
      onSuccess: (data) => setPeriodPerf(data),
    });
  };

  const getCmprPos = () => {
    callAxiosGet({
      m,
      logger,
      url: `${currMenu}/CmprPos`,
      params: { d },
      onSuccess: (data) => setCmprPos(data),
    });
  };

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <select
        value={tgt}
        onChange={(e) => setSearchParams({ d, tgt: e.target.value })}
      >
        <option value={AiTgt.AI}>대체본부</option>
        <option value={AiTgt.PE}>PE본부</option>
        <option value={AiTgt.PI}>고유자산</option>
      </select>
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      <VhclSelector
        d="2020-01-01" // 아주 과거자로 줘서 만료된 펀드들도 다 나오게.
        meta
        all
        tfim
        onChange={(vhcl) => vhcl && vhcl.Id !== vhOrGrp && setVhOrGrp(vhcl.Id)}
        value={vhOrGrp}
      />
      <label htmlFor="cbAsKrw">
        <input
          type="checkbox"
          id="cbAsKrw"
          checked={asKrw}
          onChange={(e) => setAsKrw(e.target.checked)}
        />
        외화자산 원화환산
      </label>
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => refreshPage()}
      >
        조회
      </button>
      <hr className="narrow light" />
      <UrlGrid args={aiComp} params={{ d }} refreshNeeded={refreshAiComp} />
      <div className="children-me-3">
        <RadioGroup
          name="compDetailTy"
          value={compDetailTy}
          options={['compGuy', 'compStk']}
          labels={['담당자', '지분']}
          onChange={(v) => setCompDetailTy(v as CompDetailTy)}
        />
      </div>
      {compDetailTy === 'compGuy' &&
        currComp &&
        arrayTable(
          [
            [currComp.guy0, currComp.guy1],
            [currComp.tel0, currComp.tel1],
            [currComp.mail0, currComp.mail1],
          ],
          {
            headers: ['', '담당자1', '담당자2'],
            rowHeaders: ['이름', '연락처', '메일주소'],
          },
        )}
      {compDetailTy === 'compStk' &&
        currComp &&
        arrayTable(
          [[currComp.commStks], [currComp.pfrdStks], [currComp.stkOpts]],
          {
            headers: ['', '발행주식수'],
            rowHeaders: ['보통주', '우선주,CB,BW', '스톡옵션'],
          },
        )}

      <hr className="narrow light" />
      {vhOrGrp && (
        <UrlGrid
          args={aiInv}
          params={{ tgt, d, comp: currComp?.Id, vhOrGrp, asKrw }}
          refreshNeeded={refreshAiCompRefs}
        />
      )}
      <div className="children-me-3">
        <RadioGroup
          name="invDetailTy"
          value={invDetailTy}
          options={['invProd', 'invHold']}
          labels={['자산 정보', '보유 / 거래']}
          onChange={(v) => setInvDetailTy(v as InvDetailTy)}
        />
      </div>
      <hr className="narrow light" />
      {invDetailTy === 'invProd' &&
        currInv &&
        prod &&
        setInvProdDetail(prod, currInv)}

      {invDetailTy === 'invHold' && currInv && (
        <>
          <SimpleGrid
            data={currInv.pnls}
            // prettier-ignore
            columns={['vhId','oiq','oip','oia', // 투자원금
            'ocq','ocp','oca', // 현재원금(투자자산)
            'ucq','ucp','uca', // 현재원금(기초자산)
            'op','oa', // 현재평가금(투자자산)
            'up','ua', // 현재평가금(기초자산)
            'cltdAmt','cltdPrin','cltdPrft','cltdRt','cltdRto',
          ]}
            // prettier-ignore
            headers={['코드','수량','단가','금액','수량','단가','금액','수량','단가','금액','현재가','금액','현재가','금액','회수금','원금','이익','수익률','회수율',
          ]}
            args={{
              headerGroups: [
                ['펀드', 1],
                ['투자원금', 3],
                ['현재원금(투자자산)', 3],
                ['현재원금(기초자산)', 3],
                ['현재평가금(투자자산)', 2],
                ['현재평가금(기초자산)', 2],
                ['회수금', 5],
              ],
              meta: {
                onRowClick: (r) => setCurrPnL(r.original),
              },
            }}
          />
          <SimpleGrid
            data={currPnL?.fills ?? []}
            // prettier-ignore
            columns={['d', 'stlD', 'prodId', 'prodNm', 'isBuy', 'qty', 'prc', 'fee', 'tax', 'div', 'fx', 'fillTy', 'prinTy', 'prin']}
            // prettier-ignore
            headers={['거래일', '결제일', '종목코드', '종목명', '매수', '수량', '단가', '수수료', '세금', '배당', '환율', '거래방법', '원금구분', '원금']}
            args={{
              meta: { dftColWidth: 80, maxHeight: 300 },
              widths: { qty: 100, prin: 100 },
              schema: aiFillSchema,
            }}
          />
        </>
      )}

      <hr className="narrow light" />
      <b>보유 일괄</b>
      <SimpleGrid
        data={currInv ? currInv.pnls : invs.map((v) => v.pnls).flat()}
        // prettier-ignore
        columns={['invId', 'prodId', 'prodNm', 'vhId', 'firstBuyD', 'oia',
          'lastSellD', 'cltdAmt', 'cltdPrin', 'lastSellFillTy',
          'oca', 'uca', 'oa', 'ua',
          'op', 'up', 'conv',
          'ourGuy', 'otherInvs']}
        // prettier-ignore
        headers={['투자ID', '자산코드', '자산명', '펀드', '최초매수일', '투자원금액',
            '최종매도일', '회수금', '회수원금', '최종매도방법',
            '현재원금(투자)', '현재원금(기초)', '현재평가금(투자)', '현재평가금(기초)',
            '현재가(투자)', '현재가(기초)', '전환',
            '당사담당자', '공동투자자']}
        args={{
          meta: {
            dftColWidth: 100,
            maxHeight: 300,
            useGlobalFilter: true,
            useFilterBox: true,
          },
          widths: { invId: 50, conv: 50, ourGuy: 70, otherInvs: 150 },
        }}
      />

      <hr className="narrow light" />
      <b>거래 일괄</b>
      <SimpleGrid
        data={currInv ? currInv.fills : invs.map((v) => v.fills).flat()}
        // prettier-ignore
        columns={['invId', 'vhId', 'd', 'stlD', 'prodId', 'prodNm',
            'isBuy', 'qty', 'prc', 'fee', 'tax', 'div', 'fx', 'fillTy', 'prinTy', 'prin']}
        // prettier-ignore
        headers={['투자ID', '펀드', '거래일', '결제일', '종목코드', '종목명',
            '매수', '수량', '단가', '수수료', '세금', '배당', '환율', '거래방법', '원금구분', '원금']}
        args={{
          meta: {
            dftColWidth: 100,
            maxHeight: 300,
            useGlobalFilter: true,
            useFilterBox: true,
          },
          widths: { invId: 50 },
          schema: aiFillSchema,
        }}
      />

      <br />
      <hr className="narrow light" />
      <div className="d-flex children-me-4">
        <b>기간 성과</b>
        <DateSelector
          value={prdD1}
          initDate0={prdD0}
          onChange={(date) => setPrdD1(date)}
          onChangeD0={(date0) => setPrdD0(date0)}
        />
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => getPeriodPerf()}
        >
          조회
        </button>
      </div>
      {arrayTable(
        [
          [
            _.sum(periodPerf.map((v) => v.oia)),
            _.sum(periodPerf.map((v) => v.cltdAmt)),
            _.sum(periodPerf.map((v) => v.cltdPrin)),
            _.sum(periodPerf.map((v) => v.cltdPrft)),
          ].map((v) => v.toFixedWithComma(0)),
        ],
        {
          headers: ['투자원금액', '회수금', '회수원금', '회수이익'],
        },
      )}
      <SimpleGrid
        data={periodPerf}
        // prettier-ignore
        columns={['invId', 'prodId', 'prodNm', 'vhId', 'oia', 'cltdAmt', 'cltdPrin', 'cltdPrft', 'ourGuy']}
        // prettier-ignore
        headers={['투자ID', '자산코드', '자산명', '펀드', '투자원금액', '회수금', '회수원금', '회수이익', '담당자(운용)']}
        args={{
          meta: {
            dftColWidth: 100,
            maxHeight: 300,
            useGlobalFilter: true,
            useFilterBox: true,
          },
          widths: { invId: 50 },
          infoMsg:
            '기준일자 조회시, 거래일자 기준으로 합산(단, 풋행사 경우 행사일(결제일) 기준)',
        }}
      />
      <hr className="narrow light" />
      <div className="d-flex children-me-4">
        <b>보통주 잔고대사</b>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => getCmprPos()}
        >
          조회
        </button>
      </div>
      <SimpleGrid
        data={cmprPos}
        // prettier-ignore
        columns={['prodId', 'prodNm', 'vhId', 'book', 'pos', 'diff']}
        // prettier-ignore
        headers={['자산코드', '자산명', '펀드', '원장수량(A)', '자산내역수량(B)', '오차수량(A-B)']}
        args={{
          meta: {
            dftColWidth: 100,
            maxHeight: 300,
            useGlobalFilter: true,
            useFilterBox: true,
          }
        }}
      />
    </div>
  );
}
