import React from 'react';
import { ConfirmInfo } from '../../../Tms/Loan';
import { FuncCall } from '../../../tmsutil';

export interface WarnMsg {
  Id: number;
  level: string;
  msg: string;
}

interface Props {
  tgtCall: FuncCall;
  d: string;
  warns: WarnMsg[];
  cnfrmNeeded: ConfirmInfo[];
}

export default function WarningBar({ tgtCall, d, warns, cnfrmNeeded }: Props) {
  const confirmLoan = (v: ConfirmInfo, acpt: boolean) => {
    const par = {
      d,
      st: v.stId,
      prod: v.prodId,
      acpt,
      fee: v.fee,
    };
    tgtCall('ConfirmLoan', par);
  };
  return (
    <>
      {warns.map((v) => (
        <div key={v.Id} className={`alert alert-slim alert-${v.level}`}>
          {v.msg}
        </div>
      ))}
      <ul
        style={{
          fontWeight: 'bold',
          fontSize: 'large',
          color: 'blue',
          verticalAlign: 'middle',
        }}
      >
        {cnfrmNeeded.map((v) => (
          <li key={v.Id} style={{ paddingBottom: '5px' }}>
            <span style={{ color: 'blue', fontSize: '11px' }}>{`${
              v.prodNm
            } ${v.fee.toFixed(1)}% 대차 진행? ${
              v.oppShortStId ? '(타매니저 롱청산물량, 취소시 마감상계)' : ''
            }`}</span>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => confirmLoan(v, true)}
            >
              진행
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => confirmLoan(v, false)}
            >
              취소
            </button>
            &nbsp;&nbsp;
          </li>
        ))}
      </ul>
    </>
  );
}
