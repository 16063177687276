// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog.msgBox {
  border: solid 1px gray;
  min-width: 200px;
  max-width: 600px;
  padding: 0.8em;
  top: 5%;
  left: 50%;
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%);
  z-index: 1000;
}

div.logBox-scroller {
  max-height: 170px;
  width: 85%;
  margin-left: 30px;
  /* for scroll down */
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
  border: solid 1px lightgray;
}

div.logBox {
  background-color: rgba(250, 250, 250, 0.9);
}

div.log {
  font-size: 12px;
  padding: 1px;
}

div.log-info {
  color: #555;
}

div.log-warning {
  color: #c90;
}

div.log-error {
  color: #d34;
}

.fade-out-box {
  animation: fadeout 5s;
  animation-fill-mode: forwards;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./../tmslib/src/context/messagecontext.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,OAAO;EACP,SAAS;EACT,aAAa;EACb,cAAc;EACd,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,2BAA2B;AAC7B;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["dialog.msgBox {\n  border: solid 1px gray;\n  min-width: 200px;\n  max-width: 600px;\n  padding: 0.8em;\n  top: 5%;\n  left: 50%;\n  margin-top: 0;\n  margin-left: 0;\n  transform: translate(-50%);\n  z-index: 1000;\n}\n\ndiv.logBox-scroller {\n  max-height: 170px;\n  width: 85%;\n  margin-left: 30px;\n  /* for scroll down */\n  overflow: scroll;\n  display: flex;\n  flex-direction: column-reverse;\n  overflow-anchor: auto !important;\n  border: solid 1px lightgray;\n}\n\ndiv.logBox {\n  background-color: rgba(250, 250, 250, 0.9);\n}\n\ndiv.log {\n  font-size: 12px;\n  padding: 1px;\n}\n\ndiv.log-info {\n  color: #555;\n}\n\ndiv.log-warning {\n  color: #c90;\n}\n\ndiv.log-error {\n  color: #d34;\n}\n\n.fade-out-box {\n  animation: fadeout 5s;\n  animation-fill-mode: forwards;\n}\n@keyframes fadeout {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
