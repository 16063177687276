import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { FormEventTy } from '../../AppTypes';
import { callAxios } from '../../tmsutil';

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const searchParams = useSearchParams()[0];
  const email = searchParams.get('email');
  const token = searchParams.get('access_token');
  const navigate = useNavigate();
  const { msgBox: m } = useMessageState();

  const handleSubmit = async (e: FormEventTy) => {
    e.preventDefault();
    const params = { email, password, token };
    callAxios({
      m,
      logger: null, // unauth 상태니 로깅 안보여주기,
      url: '/Auth/ResetPwd',
      params,
      onSuccess: () => navigate('/'),
      onError: (errMsgs) => {
        m.alert(`[ResetPwd fail] ${errMsgs.join('\n')}`);
      },
    });
  };

  return (
    <div className="login-wrapper">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="ResetPassword">
          <p>Password</p>
          <input
            id="ResetPassword"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <br />
        <label htmlFor="ResetConfrimPassword">
          <p>Confim Password</p>
          <input
            id="ResetConfrimPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </label>
        <br />
        <br />
        <div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}