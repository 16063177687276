import React, { useState, useEffect } from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { AlgoVh } from '../../../Tms/Ems';

interface Props {
  alvhs: AlgoVh[];
  currAlvhId: number | undefined;
  setCurrAlvhId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const columnHelper = createColumnHelper<AlgoVh>();
const columns = [
  columnHelper.accessor('Id', { header: 'Id', size: 70 }),
  columnHelper.accessor('vhId', { header: '펀드' }),
  columnHelper.accessor('qty2ord', {
    header: '예정량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('GenQty', {
    header: '생성량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('SentQty', {
    header: '전송량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('LeavesQty', {
    header: '미체결',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('CumQty', {
    header: '체결량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fillPrgsRt', {
    header: '체결률',
    meta: { formatter: (v) => v?.toFixedWithComma(1) },
  }),
  columnHelper.accessor('Done', {
    header: '완료',
    size: 50,
    meta: { type: ColumnType.Checkbox },
  }),
].map((v) => v as ColumnDef<AlgoVh, unknown>);

export default function AlgoVhTab({ alvhs, currAlvhId, setCurrAlvhId }: Props) {
  const [dgState, setDgState] = useState<DataGridState<AlgoVh>>();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currAlvhId) {
      setCurrAlvhId(dgState?.currentObj?.Id);
    }
  }, [currAlvhId, setCurrAlvhId, dgState]);

  return <DataGrid data={alvhs} columns={columns} onStateChange={setDgState} />;
}
