import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios';
import { isArrayOfStrings, mapObjEmptyStrToNull } from './utils';
import { Logger, MessageBox } from '../context/MessageContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const procResponseData = (data: any) => {
  if (data == null) return ['empty response'];
  let lines: string[] = [];
  if (Array.isArray(data)) {
    lines = lines.concat(data.map((r) => r.toString()));
  } else if (typeof data === 'object') {
    // asp.net core 파라미터 바인딩 실패할 경우 등
    if ('Title' in data) lines.push(data.Title.toString());
    if ('Errors' in data) lines.push(JSON.stringify(data.Errors));
  }
  if (lines.length === 0) {
    lines.push(JSON.stringify(data));
  }
  return lines;
};

export const getAxiosErrorMsgs = (
  msg: string | string[] | AxiosError,
  fallbackMsg?: string,
) => {
  let lines = [];
  if (typeof msg === 'string') {
    if (msg) lines.push(msg);
  } else if (isArrayOfStrings(msg)) {
    lines = msg;
  } else {
    // AxiosError
    if (msg.message) {
      lines.push(msg.message);
    }
    if (msg.response) {
      const { data } = msg.response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errs = (data as any)?.errors ?? data;
      if (errs) {
        lines = lines.concat(procResponseData(errs));
      } else if (msg.response.statusText) {
        lines.push(msg.response.statusText);
      }
    } else {
      // msg 가 string도 아니고 AxiosError도 아닐때 (성공시 res.data를 넘기거나 하면)
      lines = lines.concat(procResponseData(msg));
    }
  }
  return lines.length ? lines : [fallbackMsg ?? 'unknown response'];
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidData = any;

export interface CallAxiosArgs {
  axiosInstance: AxiosInstance;
  m: MessageBox;
  logger: Logger | null;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  confirmMsg?: string | null;
  whenInvalidMsg?: string | null;
  onSuccess?: (data: ValidData, res: AxiosResponse) => void;
  onError?: (errMsgs: string[], err: AxiosError) => void;
  onBegin?: () => void;
  contentType?: string;
  responseType?: ResponseType;
  isGet?: boolean; // 아니면 post
  title?: string;
};

export const callAxios = async ({
  axiosInstance,
  m,
  logger,
  url,
  params,
  confirmMsg,
  whenInvalidMsg,
  onSuccess,
  onError,
  onBegin,
  contentType,
  responseType,
  isGet,
  title,
}: CallAxiosArgs) => {
  onBegin?.();

  if (confirmMsg) {
    if (!(await m.confirm?.(`${confirmMsg}?`))) return;
  }

  if (whenInvalidMsg) {
    m.alert(whenInvalidMsg);
    return;
  }

  const element = document.getElementById('globalProcessingMsg');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let procTimerId: any;
  if (element) {
    procTimerId = setTimeout(() => {
      element.style.display = 'block';
    }, 1000);
  }
  const hideProcMsg = () => {
    if (procTimerId) clearTimeout(procTimerId);
    if (element) element.style.display = 'none';
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const axiosCfg0: AxiosRequestConfig<any> = {
    method: isGet ? 'get' : 'post',
    url,
  };

  // empty stirng as null (only 1 depth)
  const paramsFixed = mapObjEmptyStrToNull(params);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const axiosCfg: AxiosRequestConfig<any> = isGet
    ? { ...axiosCfg0, params: paramsFixed, paramsSerializer: { indexes: null } }
    : {
        ...axiosCfg0,
        data: paramsFixed,
        headers: {
          'Content-Type': contentType ?? 'application/json',
        },
        responseType,
      };

  const logInfo = title || !isGet;
  if (logInfo) {
    // 요청 title 있으면 로그 기록
    logger?.addLog('info', title ?? url, '요청');
  }
  const logHeader = `응답${title ? `(${title})` : ''}`;

  return axiosInstance(axiosCfg)
    .then((res) => {
      hideProcMsg();
      if (res.data.warnings?.length) {
        logger?.addLogs('warning', res.data.warnings, logHeader);
      } else if (logInfo) {
        logger?.addLog('info', 'OK', logHeader);
      }
      const data = res.data.data === undefined ? res.data : res.data.data;
      return onSuccess ? onSuccess(data, res) : m.alert(data, 'ok');
    })
    .catch(async (err) => {
      hideProcMsg();
      if (responseType === 'blob') {
        return m.alert(await err.response.data.text());
      }
      const errMsgs = getAxiosErrorMsgs(err, 'error');
      // 로깅서 한줄로 나오게 join
      logger?.addLogs('error', [errMsgs.join('\n')], logHeader);
      return onError ? onError(errMsgs, err) : m.alert(errMsgs, 'error');
    });
};

export const callAxiosGet = (args: Omit<CallAxiosArgs, 'isGet'>) =>
  callAxios({ ...args, isGet: true });

const extractDownloadFilenameWithExt = (response: AxiosResponse) => {
  const disposition = response.headers['content-disposition'];
  const fileName = decodeURIComponent(
    disposition
      .split(';')[2]
      .match(/filename*=UTF-8*.*?\2|[^;\n]*/)[0]
      .split("''")[1],
  );
  return fileName;
};

const downloadFileFromResponse = (m: MessageBox, response: AxiosResponse) => {
  const disposition = response.headers['content-disposition'];
  // 파일생성 실패인 경우 responseType: 'text' 로 실패 원인 확인
  if (disposition === undefined) {
    // eslint-disable-next-line no-alert
    m.alert('파일없음');
    return 'error';
  }
  const fileNameWithExt = extractDownloadFilenameWithExt(response);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileNameWithExt}`);
  document.body.appendChild(link);
  link.click();
  return '';
};


export const downloadFile = (args: Omit<CallAxiosArgs, 'responseType' | 'onSuccess'>) =>
  callAxios({ ...args,
    responseType: 'blob',
    onSuccess: (_, res) => downloadFileFromResponse(args.m, res),
  });