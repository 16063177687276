import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../Auth/AuthContext';
import VhclSelector from '../../shared/VhclSelector';
import StrgSelector, { SimpleStrg } from '../../shared/StrgSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil'; 
import { Holiday, VhGrpTy } from '../../Tms/Tms';
import {
  BoBS,
  BoFillAggr,
  BoPos,
  BoCashDiv,
  boCashDivSchema,
  BoSettle,
  boSettleSchemaForSinga,
} from '../../Tms/BO';
import DateSelector from '../../shared/DateSelector';
import { DftBtnStyleMx } from '../../AppTypes';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

const currMenu = '/Singa/Back';
const dftHeight = 900;

// #region
/*
// prettier-ignore
const product: UrlGridArgs<Product> = {
  url: `${currMenu}/Product`,
  title: 'Products',
  columns: ['Id', 'nm', 'crncy' ],
  headers: ['PROD_ID', 'PROD_NM', 'CCY'],
  widths: {
    Id: 80, nm: 200, crncy: 100
  },
  editable: false,
  meta: { dftColWidth: 100 },
  height: 500,
};
*/
// prettier-ignore
const holiday: UrlGridArgs<Holiday> = {
  url: `${currMenu}/Holiday`,
  title: 'Holidays',
  columns: ['d', 'ctry', 'note'],
  headers: ['d', 'ctry', 'note'],
  widths: { d: 80, ctry: 80, note: 200 },
  editable: false,
  height: 500,
};

// prettier-ignore
const boSettle: UrlGridArgs<BoSettle> = {
  url: `${currMenu}/BoSettle`,
  title: 'Reset NAV/s',
  columns: ['d', 'stId', 'nav'], // 'reinv',
  headers: ['d', 'strg', 'nav'],
  editable: true,
  height: 500,
  meta: { dftColWidth: 100 },
  schema: boSettleSchemaForSinga,
};

// prettier-ignore
const boFillAggr: UrlGridArgs<BoFillAggr> = {
  url: `${currMenu}/BoFillAggr`,
  title: 'Trades',
  columns: ['d', 'stId', 'prodId', 'prodNm', 'isBuy', 'qty', 'prc', 'fee', 'tax', 'ls', 'ty', 'src'],
  headers: ['date', 'strg', 'prod_id', 'prod_nm', 'isBuy', 'qty', 'price', 'fee', 'tax', 'LS', 'type', 'source'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
};

// prettier-ignore
const bocashDiv: UrlGridArgs<BoCashDiv> = {
  url: `${currMenu}/BoCashDiv`,
  title: 'Dividends',
  columns: ['d', 'stId', 'prodId', 'prodNm', 'div'],
  headers: ['date', 'strg', 'prod_id', 'prod_nm', 'div'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: {
    src: 120,
  },
  height: 500,
  schema: boCashDivSchema,
};

// prettier-ignore
const bopos : UrlGridArgs<BoPos> = {
  url: `${currMenu}/BoPos`,
  title: 'Positions',
  columns: ['d','stId','prodId','prodNm','posYd','prcYd','bQty','bPrc','sQty','sPrc','pos','close','div','fee','tax','prft','prftVhCcy',  ],
  headers: ['date','strg','prod_id','prod_nm','pos_yd','prc_yd','b_qty','b_prc','s_qty','s_prc','pos','close','div','fee','tax','prft_lc','prft_usd', ],
  height: dftHeight,
  meta: { dftColWidth: 80 },
  widths: {},
};

const boBS: UrlGridArgs<BoBS> = {
  url: `${currMenu}/BoBS`,
  title: 'Balances',
  columns: ['d', 'stId', 'prft', 'units', 'na', 'nav'],
  headers: ['Date', 'Strg', 'PnL', 'Units', 'NAV', 'NAV/s'],
  meta: { dftColWidth: 100 },
  height: dftHeight,
};
/*
const boDirBs: UrlGridArgs<BoDirBS> = {
  url: `${currMenu}/BoDirBS`,
  title: 'SSnc',
  columns: ['d', 'stId', 'prft', 'units', 'na', 'nav'],
  headers: ['Date', 'Strg', 'PnL', 'Units', 'NAV', 'NAV/s'],
  meta: { dftColWidth: 100 },
  height: dftHeight,
};
*/
// #endregion
type T = { Id: number }; // 임의 IId 타입

export default function Back() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || 'SINGA';
  const [strg, setStrg] = useState<SimpleStrg | null>(null);

  const [d0, setD0] = useState(d);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const btnClass = DftBtnStyleMx;

  useEffect(() => {
    setGridArgs(emptyGridArgs);
  }, [d]);

  const getParams = () => ({ d, d0, vhOrGrp: vh, st: strg?.Id });

  const query = (args: unknown) => {
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;

    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={btnClass}
        onClick={() => {
          setWarnResMsg('');
          query(a);
        }}
        label={title}
      />
    );
  };

  /*
  // #region axios
  const linkClass = 'btn btn-link';

  const clearArgs = () => {
    setWarnResMsg('');
    setGridArgs(emptyGridArgs);
  };

  const handleAxiosResult = (
    data: ValidData,
    res: AxiosResponse,
    cfmmsg: string | null,
    callbackGridArgs?: unknown,
  ) => {
    if (!res.data.warnings?.length) {
      setWarnResMsg(`${cfmmsg ?? ''} OK`);
    } else {
      setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
    }
    if (callbackGridArgs) {
      query(callbackGridArgs);
    }
  };


  const call = (
    func: PageFunc,
    params: unknown,
    confirmMsg: string,
    callbackGridArgs?: unknown,
    isGet = false,
  ) => {
    callAxios({
      m,
      logger,
      url: `/Singa/Back/${func}`,
      params,
      confirmMsg,
      onSuccess: (data, res) =>
        handleAxiosResult(data, res, confirmMsg, callbackGridArgs),
      onBegin: () => clearArgs(),
      isGet,
      title: confirmMsg,
    });
  };

  const callAxiosFunc = (
    funcNm: PageFunc,
    cfrmMsg: string,
    callbackGridArgs?: unknown,
  ) => {
    switch (funcNm) {
      case 'GenBS':
        call(
          funcNm,
          { d0, d, vhOrGrp: vh, st: strg },
          cfrmMsg,
          callbackGridArgs,
        );
        break;
      case 'GenBoPos':
        call(
          funcNm,
          { d0, d, vhOrGrp: vh, st: strg },
          cfrmMsg,
          callbackGridArgs,
        );
        break;
      default:
        setWarnResMsg('Unkown func');
    }
  };
    // #endregion
*/

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
        grp={VhGrpTy.SINGA}
      />
      <StrgSelector
        d={d}
        value={strg?.Id}
        onChange={setStrg}
        vhGrp={VhGrpTy.SINGA}
        whole
        empty
        team={false}
        // singa
      />
      <hr className="narrow light" />
      {[
        // product, // 일단 종목 테이블 감춤
        holiday,
        boFillAggr,
        bocashDiv,
        bopos,
        boBS,
        // boDirBs, 감춤
        boSettle,
      ].map((v) => getButton(v))}
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <UrlGrid
        args={gridArgs}
        params={getParams()}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
