import React, { useState, useEffect } from 'react';
import { LogWithId } from '../../../Tms/Ord';
import { ConfirmInfo } from '../../../Tms/Loan';
import { FuncCall } from '../../../tmsutil';

interface Props {
  tgtCall: FuncCall;
  d: string;
  cnfrmNeeded: ConfirmInfo[];
  cnfmLogs: LogWithId[];
}

export default function WarningBar({
  tgtCall,
  d,
  cnfrmNeeded,
  cnfmLogs,
}: Props) {
  const [cnfmLogStr, setCnfmLogStr] = useState<string>();
  useEffect(
    () => setCnfmLogStr(cnfmLogs.map((v) => v.str).join('|')),
    [cnfmLogs],
  );

  const ConfirmLoan = (
    st: string,
    prod: string,
    acpt: boolean,
    fee: number,
  ) => {
    const params = {
      d,
      st,
      prod,
      acpt,
      fee,
    };
    tgtCall('ConfirmLoan', params, {
      title: `${prod} 대차 ${acpt ? '진행' : '취소'}`,
    });
  };

  return (
    <>
      <div className="alert-light" style={{ color: 'rgb(129, 129, 130)' }}>
        {cnfmLogStr}
      </div>
      {cnfrmNeeded.map((needed) => (
        <li key={needed.Id} style={{ paddingBottom: '5px' }}>
          <span>
            {needed.prodNm} {needed.fee}% 대차 진행?
          </span>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() =>
              ConfirmLoan(needed.stId, needed.prodId, true, needed.fee)
            }
          >
            진행
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() =>
              ConfirmLoan(needed.stId, needed.prodId, false, needed.fee)
            }
          >
            취소
          </button>
        </li>
      ))}
    </>
  );
}
