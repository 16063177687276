import React, { useEffect, useState } from 'react';
import { AxiosInstance, AxiosResponse } from 'axios';
import { TableMeta, ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import DataGrid, { IId, CellMetaDicType } from './DataGrid';
import { findDiff, DiffRes, switchExpr } from '../util/utils';
import { callAxios, callAxiosGet } from '../util/axiosutils';
import { IContextMenuItem } from './ContextMenu';
import SimpleGrid, {
  ColumnArgs,
  SchemaType,
  SimpleGridArgs,
  getColumnDefs,
  isColDef,
} from './SimpleGrid';
import InputGrid, { InputGridArgs, getCellValidationMeta } from './InputGrid';
import { useMessageState } from '../context/MessageContext';
import SimpleGridDialog from './SimpleGridDialog';

export interface WebDataLog {
  Id: number;
  tstr: string;
  action: string;
  field: string | null;
  oldVal: string | null;
  newVal: string | null;
  userNm: string | null;
}
const actionName = (action: string) =>
  switchExpr(action, ['New', '입력'], ['Mod', '정정'], ['New', '삭제']) ?? 'XX';

export type UrlGridArgs<T extends IId> = ColumnArgs<T> & {
  axiosInstance: AxiosInstance,
  url: string;
  title: string;
  titleNode?: JSX.Element;
  columns: (keyof T)[] | ColumnDef<T, unknown>[];
  height: number | undefined; // height 지정 안하면 렌더링 느려짐
  hide?: boolean;
  editable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: TableMeta<any>;
  infoMsg?: string;
  schema?: SchemaType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dynamicColumns?: (res: AxiosResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: any;
    headers?: string[];
    headerGroups?: [string, number][];
  };
  auxColumns?: string[][]; // 보조 테이블 타입이 메인 테이블과 다를 때
  auxHeaders?: string[][]; // 보조 테이블 타입이 메인 테이블과 다를 때
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auxGridArgs?: SimpleGridArgs<any>[];
  onDataChange?: (data: T[], res: AxiosResponse) => void;
};

export default function UrlGrid<T extends IId>({
  args,
  params,
  refreshNeeded,
}: {
  args: UrlGridArgs<T>;
  params: object;
  refreshNeeded?: number;
}) {
  const { msgBox: m, logger } = useMessageState();
  const [data, setData] = useState<T[]>([]); // 메인 테이블 데이터
  // 보조(auxiliary) 테이블 데이터 (테이블 두개 이상일때, 두번째 테이블 데이터부터)
  // 읽기전용
  // api에서 auxData로 반환, 칼럼은 따로 지정 안하면 메인 테이블과 동일
  const [auxData, setAuxData] = useState<T[][]>([]);
  const [auxColumns, setAuxColumns] = useState<string[][] | undefined>([]);
  const [auxHeaders, setAuxHeaders] = useState<string[][] | undefined>([]);
  const [initData, setInitData] = useState<T[]>([]);
  const [cellMetas, setCellMetas] = useState<CellMetaDicType<T>>({});
  const [inputData, setInputData] = useState<T[]>([]);
  const [inputCellMetas, setInputCellMetas] = useState<CellMetaDicType<T>>({});
  const [resetInput, setResetInput] = useState(0);
  const [diff, setDiff] = useState<DiffRes<T>>();
  const [refreshAfterUpdate, setRefreshAfterUpdate] = useState(0);
  const [filterResetNeeded, setFilterResetNeeded] = useState(0);
  const [dataHistory, setDataHistory] = useState<WebDataLog[] | null>(null);
  const [warnings, setWarnings] = useState<string[]>([]);

  const { url, dynamicColumns, onDataChange } = args;

  const [dataCols, setDataCols] = useState<ColumnDef<T, unknown>[]>([]);
  const [newColsWithOldData, setNewColsWithOldData] = useState(false);

  useEffect(() => {
    // 칼럼이 바뀌었으면 기존 데이터서 에러 발생 가능. 
    // 칼럼 바뀌면 새 데이터 받기 전까지 그리드 렌더링 안하게 체크
    setNewColsWithOldData(true);

    setDataCols(getColumnDefs(args.columns, args, true));
    setFilterResetNeeded((p) => p + 1);
    setAuxColumns(args.auxColumns);
    setAuxHeaders(args.auxHeaders);
    // dep에 args 자체를 포함시키면 반복해서 호출될수 있음
    // getColumnDefs 서 사용하는 args의 headers 등은 안바뀐다고 가정
  }, [args.columns, args.auxColumns, args.auxHeaders]);

  useEffect(() => {
    setData([]);
    setAuxData([]);
    setInitData([]);
    setCellMetas({});
    setInputData([]);
    setInputCellMetas({});
    if (!url) return;
    setResetInput((p) => p + 1);
    callAxiosGet({
      axiosInstance: args.axiosInstance,
      m,
      logger,
      url,
      params,
      onSuccess: (data_, res) => {
        if (dynamicColumns) {
          const { columns, headers, headerGroups } = dynamicColumns(res);
          // eslint-disable-next-line no-param-reassign
          args = { ...args, columns, headers, headerGroups };
          setDataCols(getColumnDefs(args.columns, args, true));
        }

        setNewColsWithOldData(false); // 새 데이터 받았으니 false로

        setData(data_);
        setInitData(data_);
        onDataChange?.(data_, res);
        if (res.data.auxData) {
          setAuxData(res.data.auxData); // auxData는 읽기전용이라 initData 불필요
        }
        if (res.data.auxColumns) {
          setAuxColumns(res.data.auxColumns);
        }
        if (res.data.auxHeaders) {
          setAuxHeaders(res.data.auxHeaders); // auxData는 읽기전용이라 initData 불필요
        }
        setInputData([]);
        setInputCellMetas({});
        setResetInput((p) => p + 1);

        setWarnings(res.data.warnings ?? [])
      },
      // title: `${args.title} 조회`, 조회 로그까지 찍는건 verbose해서 제외
    });
  }, [url, refreshNeeded, refreshAfterUpdate]);

  const {
    title,
    titleNode,
    height,
    hide,
    editable,
    meta: initMeta,
    infoMsg,
    schema,
  } = args;

  const urlTok = url.split('/');
  const saveUrl = `${urlTok.slice(0, -1).join('/')}/Save${urlTok.at(-1)}`;
  const histUrl = `${urlTok.slice(0, -1).join('/')}/Hist${urlTok.at(-1)}`;

  const fieldName = (field: string) =>
    args.headers?.[args.columns.findIndex((c) => c === field)] ?? field;

  const renameFieldOfDataHistory = (hist: WebDataLog[]): WebDataLog[] =>
    hist.map((h) => ({
      ...h,
      action: actionName(h.action),
      field: h.field ? fieldName(h.field) : null,
    }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dynamicMenusOnShow = (items: T[]): Promise<IContextMenuItem<any>[]> =>
    new Promise((resolve) => {
      if (!editable) resolve([]);
      else if (!items.length || items.length > 1) resolve([]);
      else if (
        urlTok.length > 2 &&
        urlTok[2].isIn('FundMgt', 'Query') // 컨트롤러에서 endpoint로 안 옮겨진 애들으 History 조회 불가
      )
        resolve([]);
      else {
        callAxiosGet({
          axiosInstance: args.axiosInstance,
          m,
          logger,
          url: histUrl,
          params: { id: items[0].Id },
          onSuccess: (hist) => {
            resolve([
              { divider: true },
              {
                label: `변경 히스토리 ${hist.length}`,
                callback: () => setDataHistory(renameFieldOfDataHistory(hist)),
              },
            ]);
          },
          onError: () => undefined, // endpoint 안 타는 애들 굳이 alert 안하기
        });
      }
    });

  const meta: TableMeta<T> = {
    useGlobalFilter: true, // getGrid 함수에선 기본으로 보여주기
    useFilterBox: true,
    filterResetNeeded,
    height,
    hide,
    ...(editable
      ? {
          editable: true,
          areaPastable: true,
          rowDeletable: true,
          areaClearable: true,
        }
      : {}),
    bodyStyle: { ...initMeta?.bodyStyle, fontSize: '11px' }, // 기존 slicktab이랑 맞춰서
    dynamicMenusOnShow,
    ...initMeta,
  };

  const tableMeta: TableMeta<T> | undefined = {
    ...meta,
    rowAppendable: false,
    updateField: meta?.editable
      ? (original, rowIdx, colId, value) => {
          const newData = data.map((v) =>
            v.Id === original.Id ? { ...v, [colId]: value } : v,
          );
          setData(newData);

          if (schema) {
            const metas = getCellValidationMeta(
              schema,
              newData.filter((v) => v.Id === original.Id),
            );
            setCellMetas((p) => ({ ...p, ...metas }));
          }
        }
      : undefined,
    updateData: meta?.areaPastable
      ? (added, updated, removed) => {
          if (added.length) throw new Error('UrlGrid 행추가 불가');

          const newData = data
            .filter((v) => !removed.has(v.Id))
            .map((v) =>
              Object.prototype.hasOwnProperty.call(updated, v.Id)
                ? { ...v, ...updated[v.Id] }
                : v,
            )
            .concat(added);
          setData(newData);

          if (schema) {
            const editedIds = new Set(Object.keys(updated));
            const edited = newData.filter((v) => editedIds.has(String(v.Id)));
            const metas = getCellValidationMeta(schema, edited);
            setCellMetas((p) => ({ ...p, ...metas }));
          }
        }
      : undefined,
  };

  const showInputGrid = !!meta?.editable;
  const inputGridArgs: InputGridArgs<T> = {
    ...args,
    meta: {
      ...meta,
      hide: !showInputGrid,
      height: undefined,
      maxHeight: meta?.maxHeight ?? 500,
      onRowClick: undefined,
      contextMenus: undefined,
      dynamicMenusOnShow: undefined,
    },
    resetNeeded: resetInput,
    onSetData: setInputData,
    onSetCellMetas: setInputCellMetas,
  };

  useEffect(() => {
    if (!meta?.editable) return;
    if (isColDef(args.columns))
      throw new Error('칼럼 정의 사용 시 읽기 전용만 가능');
    const {
      added: addedTmp,
      changed,
      removed,
    } = findDiff(initData, data, args.columns as (keyof T)[]);
    if (addedTmp.length) throw new Error('기 그리드서 삭제 오류');
    setDiff({ added: inputData, changed, removed });
  }, [meta?.editable, initData, data, args.columns, inputData]);

  const anyDiff = diff && _.sum(Object.values(diff).map((v) => v.length)) > 0;

  const nChangeErr = _(cellMetas)
    .values()
    .map((v) => Object.keys(v).length)
    .sum();
  const nAppendErr = Object.values(inputCellMetas).filter(
    (v) => Object.keys(v).length,
  ).length;

  // if (nChangeErr) console.log(cellMetas);
  // if (nAppendErr) console.log(inputCellMetas);
  // if (nChangeErr || nAppendErr) console.log(diff);

  const saveChanges = showInputGrid
    ? async () => {
        if (!url || !diff) return; // ui서 기 체크
        if (nChangeErr || nAppendErr) {
          if (
            !(await m.confirm?.(
              '데이터 검증 오류를 무시하고 진행하시겠습니까?',
            ))
          )
            return;
        }

        const toSave = {
          ...diff,
          added: diff.added.map((v) =>
            typeof v.Id === 'number' && v.Id < 0 ? { ...v, Id: 0 } : v,
          ),
        };

        callAxios({
          axiosInstance: args.axiosInstance,
          m,
          logger,
          url: saveUrl,
          params: { ...toSave, par: params },
          onSuccess: () => setRefreshAfterUpdate((p) => p + 1),
          title: `${args.title} 변경 저장`,
        });
      }
    : undefined;

  return (
    <div className="children-mb-2">
      {title && <b>{title}</b>}
      {!!titleNode && titleNode}
      {infoMsg && (
        <div className="alert alert-slim alert-info like-pre">{infoMsg}</div>
      )}
      {!!warnings.length && (
        <div className="alert alert-slim alert-warning like-pre">{warnings.join('\n')}</div>
      )}
      {!newColsWithOldData && (
        <DataGrid
          data={data}
          columns={dataCols}
          meta={tableMeta}
          cellMetas={cellMetas}
        />
      )}
      {showInputGrid && (
        <>
          <InputGrid columns={args.columns} args={inputGridArgs} />
          <div className="children-me-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => saveChanges?.()}
              disabled={!anyDiff}
            >
              Save Changes
            </button>
            {diff && (
              <span className="alert alert-slim alert-light like-pre">{`[추가] ${diff.added.length}  [변경] ${diff.changed.length}  [삭제] ${diff.removed.length}`}</span>
            )}
            {nAppendErr > 0 && (
              <span className="alert alert-slim alert-danger">{`[추가 에러] ${nAppendErr}`}</span>
            )}
            {nChangeErr > 0 && (
              <span className="alert alert-slim alert-danger like-pre">{`[변경 에러] ${nChangeErr}`}</span>
            )}
          </div>
        </>
      )}
      {auxData.map((ad, i) => (
        <SimpleGrid
          // eslint-disable-next-line react/no-array-index-key
          key={`aux_${i}`}
          data={ad}
          columns={(auxColumns?.[i] as (keyof T)[]) ?? args.columns}
          headers={auxHeaders?.[i] ?? args.headers}
          args={{ ...args, ...(args.auxGridArgs?.[i] as SimpleGridArgs<T>) }}
        />
      ))}
      {dataHistory && (
        <SimpleGridDialog
          data={dataHistory}
          columns={['tstr', 'action', 'field', 'oldVal', 'newVal', 'userNm']}
          headers={['T', '액션', '필드', '기존값', '변경값', '변경자']}
          args={{ widths: { tstr: 120, oldVal: 150, newVal: 150 } }}
        />
      )}
    </div>
  );
}