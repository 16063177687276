import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { Basket } from './OrderInputBar';
import { DftBtnStyle } from '../../../AppTypes';
import { UrlGrid, UrlGridArgs , ValidData, callAxios } from '../../../tmsutil'; 
import ProdSelector, { SimpleProd } from '../../../shared/ProdSelector';

interface Props {
  st: string;
  bsk?: number;
}

export interface BasketMem {
  Id: number;
  bskId: number;
  prodId: string;
  unit: number;
  prodNm: string | null;
  ta: number | null;
}

export const basketMemSchema = z.object({
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  ta: z.coerce.number().nullish().readonly(),
  unit: z.coerce.number(),
});

const bskMemArgs: UrlGridArgs<BasketMem> = {
  url: '/Front/Target/BasketMem',
  title: '바스켓',
  columns: ['prodId', 'prodNm', 'ta', 'unit'],
  headers: ['종목코드', '종목명', '20일평균거래금(억)', '유닛'],
  height: 500,
  editable: true,
  schema: basketMemSchema,
  widths: { ta: 120 },
};

export default function BasketEditor({ st, bsk }: Props) {
  const [baskets, setBaskets] = useState<Basket[]>([]);
  const [basket, setBasket] = useState<Basket | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [prod, setProd] = useState<SimpleProd | null>(null);

  let initBskId = bsk;
  const { msgBox: m, logger } = useMessageState();

  const call = (
    func: string,
    params: unknown,
    onSuccess?: (data: ValidData) => void,
    isGet?: boolean,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Front/Target/${func}`,
      params,
      onSuccess,
      isGet,
    });

  const getBaskets = () => {
    call(
      'Baskets',
      { st },
      (data) => {
        const bsks: Basket[] = data;
        setBaskets(bsks);
        if (bsks.length) {
          setBasket(bsks.find((v) => v.Id === initBskId) ?? bsks[0]);
        } else setBasket(null);
      },
      true,
    );
  };

  const delBasket = async () => {
    if (!basket) {
      m.alert('선택된 바스켓 없음');
      return;
    }
    if (!(await m.confirm(`바스켓 (${basket.nm}) 삭제?`))) return;
    call('DelBasket', { st, id: basket.Id }, () => getBaskets());
  };

  const addBasket = async () => {
    const nm = await m.prompt('바스켓 이름');
    if (!nm) return;
    call('AddBasket', { st, nm }, (data) => {
      initBskId = data;
      getBaskets();
    });
  };

  const addBasketMem = async () => {
    if (!basket) {
      m.alert('바스켓 미선택');
      return;
    }
    if (!prod) {
      m.alert('종목 미선택');
      return;
    }
    call('AddBasketMem', { id: basket.Id, prod: prod.Id }, () =>
      setRefreshNeeded((p) => p + 1),
    );
  };

  useEffect(() => setRefreshNeeded((p) => p + 1), [basket]);

  useEffect(() => getBaskets(), []);

  return (
    <div className="children-mb-2">
      <div className="children-me-2">
        <span>바스켓:</span>
        <select
          value={basket?.Id ?? ''}
          style={{ minWidth: '80px' }}
          name="basketId"
          onChange={(e) =>
            setBasket(
              baskets.find((v) => v.Id === Number(e.target.value)) ?? null,
            )
          }
        >
          <option> </option>
          {baskets.map((v) => (
            <option key={v.Id} value={v.Id}>
              {v.nm}
            </option>
          ))}
        </select>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => delBasket()}
        >
          삭제
        </button>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => addBasket()}
        >
          신규 바스켓
        </button>
      </div>
      <hr className="light narrow" />
      <div className="children-me-2">
        <span>구성 종목 추가: </span>
        <ProdSelector onChange={(p) => setProd(p)} />
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => addBasketMem()}
        >
          추가
        </button>
      </div>
      <hr className="light narrow" />
      <UrlGrid
        args={bskMemArgs}
        params={{ id: basket?.Id ?? 0 }}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
