import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { FixDispMsg } from '../../../Tms/Ems';

interface Props {
  fixMsgs: FixDispMsg[];
}

const columnHelper = createColumnHelper<FixDispMsg>();
const columns = [
  columnHelper.accessor('Seq', { header: 'Seq' }),
  columnHelper.accessor('MsgType', { header: '구분' }),
  columnHelper.accessor('STimeHms', { header: 'STime', size: 60 }),
  columnHelper.accessor('TTimeHms', { header: 'TTime', size: 60 }),
  columnHelper.accessor('ClOrdID', { header: '주문번호', size: 100 }),
  columnHelper.accessor('OrigClOrdID', { header: '원주문', size: 100 }),
  columnHelper.accessor('OrdStatus', { header: '상태', size: 100 }),
  columnHelper.accessor('ExecType', { header: '타입', size: 100 }),
  columnHelper.accessor('OrderQty', { header: '주문량', size: 60 }),
  columnHelper.accessor('Price', { header: '가격', size: 60 }),
  columnHelper.accessor('EncodedText', { header: '메세지', size: 120 }),
  columnHelper.accessor('LastQty', { header: '체결량', size: 60 }),
  columnHelper.accessor('LastPx', { header: '체결가', size: 60 }),
  columnHelper.accessor('CumQty', { header: '총체결', size: 60 }),
  columnHelper.accessor('AvgPx', { header: '평단가', size: 60 }),
  columnHelper.accessor('LeavesQty', { header: '미체결', size: 60 }),
].map((v) => v as ColumnDef<FixDispMsg, unknown>);

export default function FixMsgTab({ fixMsgs }: Props) {
  const [data, setData] = useState<FixDispMsg[]>(fixMsgs);
  useEffect(() => setData(fixMsgs), [fixMsgs]);

  const meta: TableMeta<FixDispMsg> = {};

  return <DataGrid data={data} columns={columns} meta={meta} />;
}
