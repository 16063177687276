import React from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { AlgoAcct } from '../../../Tms/Ems';


interface Props {
  alaccts: AlgoAcct[];
}

const columnHelper = createColumnHelper<AlgoAcct>();
const columns = [
  columnHelper.accessor('Id', { header: 'Id', size: 70 }),
  columnHelper.accessor('acctId', { header: '계좌', size: 100 }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 30,
    meta: {
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('ex', {
    header: 'EX',
    size: 30,
    meta: {
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('GenQty', {
    header: '생성량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('SentQty', {
    header: '전송량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('LeavesQty', {
    header: '미체결',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('CumQty', {
    header: '체결량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('AvgPx', {
    header: '평단가',
    size: 70,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
].map((v) => v as ColumnDef<AlgoAcct, unknown>);

export default function AlgoAcctTab({ alaccts }: Props) {
  const meta: TableMeta<AlgoAcct> = {};
  return <DataGrid data={alaccts} columns={columns} meta={meta} />;
}
