import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { arrayTable } from 'tmslib/src/table/tables';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import {
  TmsClaim,
  TmsUser,
  TmsUserStrg,
  UserClaim,
  tmsClaimSchema,
  tmsUserSchema,
  tmsUserStrgSchema,
  userClaimSchema,
} from '../../Tms/Identity';
import {
  callAxios,
  callAxiosGet,
  checkItems,
  UrlGrid,
  UrlGridArgs,
} from '../../tmsutil';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';

const Page = '/Admin/Users';

const tmsUserDft: UrlGridArgs<TmsUser> = {
  url: `${Page}/TmsUser`,
  title: '사용자',
  // prettier-ignore
  columns: ['Id', 'Name', 'Email', 'EmailConfirmed', 'Company', 'Department', 'Allowed', 'JoinDate', 'ExpDate', 'invalid', 'LastLogIn', 'LockoutEnd', 'LockoutEnabled', 'AccessFailedCount', 'IsTmpPwd', 'Comp', 'Group'],
  // prettier-ignore
  headers: ['Id', 'Name', 'Email', '이멜확인', 'Company', '부서', 'Allowed', '가입일', '만료일', '무효', '마지막접속', '잠금종료', '잠금적용', '실패#', '임시비번', 'Comp', 'Dept'],
  height: 500,
  editable: true,
  meta: { dftColWidth: 80 },
  widths: { LastLogIn: 120, LockoutEnd: 120 },
  schema: tmsUserSchema,
};

const tmsClaim: UrlGridArgs<TmsClaim> = {
  url: `${Page}/TmsClaim`,
  title: 'Claim',
  columns: ['Id', 'IsUnique'],
  // prettier-ignore
  headers: ['Id', '유니크'],
  height: 400,
  editable: true,
  schema: tmsClaimSchema,
};

const userClaim: UrlGridArgs<UserClaim> = {
  url: `${Page}/UserClaim`,
  title: 'UserClaim',
  columns: ['userId', 'userNm', 'ClaimId', 'ClaimValue'],
  // prettier-ignore
  headers: ['유저Id', '유저명', '클래임id', '클래임값'],
  height: 400,
  editable: true,
  meta: { dftColWidth: 80 },
  schema: userClaimSchema,
};

const userStrg: UrlGridArgs<TmsUserStrg> = {
  url: `${Page}/TmsUserStrg`,
  title: 'UserStrg',
  columns: ['userGrp', 'userId', 'userNm', 'stId', 'invalid', 'dft'],
  // prettier-ignore
  headers: ['유저그룹', '유저Id', '유저명', '전략', '무효', '디폴트'],
  height: 400,
  editable: true,
  meta: { dftColWidth: 80 },
  schema: tmsUserStrgSchema,
};

interface AuthRes {
  rvhs: string[] | null;
  rsts: string[] | null;
  wvhs: string[] | null;
  wsts: string[] | null;
  novhs: string[] | null;
  nosts: string[] | null;
  mevhs: string[] | null;
  mests: string[] | null;
}

export default function Users() {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();

  const d = searchParams.get('d') || info?.currBizDay || '';

  // const d = info?.currBizDay ?? '';
  const [all, setAll] = useState(false);
  const [currUser, setCurrUser] = useState<TmsUser | null>(null);
  const [authRes, setAuthRes] = useState<AuthRes | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [currUserChanged, setCurrUserChanged] = useState(0);

  const getAuthRes = (items: TmsUser[]) => {
    if (!checkItems(items, m, true)) return;
    callAxiosGet({
      m,
      logger,
      url: `${Page}/AuthRes`,
      params: { d: searchParams.get('d') ?? d, id: items[0].Id },
      onSuccess: (data) => setAuthRes(data),
    });
  };

  useEffect(() => {
    if (currUser) getAuthRes([currUser]);
    else setAuthRes(null);
    setCurrUserChanged((p) => p + 1);
  }, [currUser]);

  const changePwd = async (items: TmsUser[]) => {
    if (!checkItems(items, m, true)) return;
    const pwd = await m.prompt('new password');
    callAxios({
      m,
      logger,
      url: `${Page}/ChangePwd`,
      params: { id: items[0].Id, pwd },
    });
  };

  const tmsUser: UrlGridArgs<TmsUser> = useMemo(
    () => ({
      ...tmsUserDft,
      meta: {
        ...tmsUserDft.meta,
        // 직접 getAuthRes 호출하면 셀 수정 잘 안됨. state 변수 도입해야
        // 아니면 편집 모드 여부 도입해서 편집 모드 때는 행 클릭 콜백 undefined로
        onRowClick: (r) => setCurrUser(r.original),
        contextMenus: [
          {
            label: '펀드/전략 권한',
            callback: getAuthRes,
          },
          {
            label: '암호 변경',
            callback: changePwd,
          },
        ],
      },
      onDataChange: () => setCurrUser(null),
    }),
    [],
  );

  return (
    <>
      <DateSelector
        value={d}
        onChange={(date) => {
          if (date !== d) {
            setSearchParams({ d: date });
          }
        }}
      />

      <label htmlFor="all" style={{ marginLeft: 10 }}>
        <input
          id="all"
          type="checkbox"
          checked={all}
          onChange={(e) => setAll(e.target.checked)}
        />
        퇴사자, 장기 미접속자 등 포함
      </label>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => setRefreshNeeded((p) => p + 1)}
      >
        조회
      </button>
      <hr className="narrow light" />
      <UrlGrid args={tmsUser} params={{ all }} refreshNeeded={refreshNeeded} />
      <hr className="narrow light" />
      {authRes &&
        arrayTable(
          [
            ['펀드(읽기)', authRes.rvhs?.join(', ')],
            ['전략(읽기)', authRes.rsts?.join(', ')],
            ['펀드(쓰기)', authRes.wvhs?.join(', ')],
            ['전략(쓰기)', authRes.wsts?.join(', ')],

            ['펀드(NavOnly)', authRes.novhs?.join(', ')],
            ['전략(NavOnly)', authRes.nosts?.join(', ')],
            ['펀드(MngrEval)', authRes.mevhs?.join(', ')],
            ['전략(MngrEval)', authRes.mests?.join(', ')],
          ],
          {
            styler: () => ({
              minWidth: '60px',
              maxWidth: '1000px',
              textAlign: 'left',
            }),
          },
        )}
      <hr className="narrow light" />
      <div className="d-flex children-me-4">
        <UrlGrid args={tmsClaim} params={{}} />
        <UrlGrid
          args={userClaim}
          params={{ id: currUser?.Id }}
          refreshNeeded={currUserChanged}
        />
        <UrlGrid
          args={userStrg}
          params={{ id: currUser?.Id }}
          refreshNeeded={currUserChanged}
        />
      </div>
    </>
  );
}
