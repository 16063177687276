import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { StrgCate, VhGrpTy, GetVhGrpName } from '../Tms/Tms';
import { utilGet } from '../tmsutil';

export type SimpleStrg = {
  Id: string;
  nm?: string;
  dft?: boolean;
  cate: StrgCate;
  tgts?: VhGrpTy[]; // 첫번째 타겟이 주타겟.
  reprVhs?: string[];
};

export interface StrgSelectorProps {
  d: string;
  value?: string | null;
  onChange: (st: SimpleStrg | null, fst?: boolean) => void;
  empty?: boolean;
  whole?: boolean;
  team?: boolean;
  vh?: string | null;
  vhGrp?: VhGrpTy | null;
  vhOrGrp?: string | null;
  exc?: VhGrpTy[];
  incReprVhs?: boolean;
  className?: string;
  useTgt?: boolean;
  blockDeal?: boolean;
  smicIvy?: boolean;
  singa?: boolean;
  nonEmptyDefault?: boolean;
}

const strgByCate = (g: SimpleStrg[]) =>
  _(g)
    .groupBy((v) => v.cate)
    .toPairs()
    .map(([cate, gg]) => (
      <React.Fragment key={cate}>
        <option key={cate} style={{ fontWeight: 'bold' }} disabled>
          {cate}
        </option>
        {gg.map((v) => (
          <option key={v.Id} value={v.Id}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {v.nm ?? (v.Id === 'OTC' ? 'AITEAM' : v.Id)}
          </option>
        ))}
      </React.Fragment>
    ))
    .value();

const strgByGrp = (g: SimpleStrg[]) =>
  g.some((v) => v.cate)
    ? strgByCate(g)
    : g.map((v) => (
      <option key={v.Id} value={v.Id}>
        {v.nm ?? v.Id}
      </option>
    ));

export const BlockDeal = 'BlockDeal';

export default function StrgSelector(props: StrgSelectorProps) {
  const {
    d,
    value: initStId,
    onChange,
    empty,
    whole,
    team,
    vh,
    vhGrp,
    vhOrGrp,
    exc,
    incReprVhs,
    className,
    useTgt,
    blockDeal,
    smicIvy,
    singa,
    nonEmptyDefault,
  } = props;

  const [strgs, setStrgs] = useState<SimpleStrg[]>([]);
  const [strg, setStrg] = useState<SimpleStrg | null>(null);
  const [cnt, setCnt] = useState(0);
  const { msgBox: m, logger } = useMessageState();

  const getDefault = () => {
    if (!strgs.length) return null;
    if (initStId) return strgs.find((v) => v.Id === initStId) ?? null;
    if (empty && !nonEmptyDefault) return null;
    return strgs.find((v) => (whole ? v.Id === 'Whole' : v.dft)) ?? strgs[0];
  };

  useEffect(() => setStrg(getDefault()), [initStId]);

  useEffect(() => {
    onChange(strg, cnt === 1);
    setCnt((p) => p + 1);
  }, [strg]);

  useEffect(() => {
    if (!strg || !strgs.filter((v) => v.Id === strg.Id).length) {
      setStrg(getDefault());
    }
  }, [strgs]);

  useEffect(() => {
    const params = {
      d,
      whole: !!whole,
      vh,
      vhGrp,
      vhOrGrp,
      exc: exc?.join(','),
      incReprVhs,
      useTgt,
      team,
      smicIvy,
      singa,
    };
    // console.log(params);
    utilGet(m, logger, 'Strgs', params, (data) => {
      const sts = data as SimpleStrg[];
      if (blockDeal)
        sts.push({ Id: BlockDeal, nm: '블락딜', cate: StrgCate.None });

      setStrgs(sts);
    });
    // 배열인 exc에 의존하면, 호출측서 exc를 useState으로 상태변수로 관리하지 않고 바로 넘기면 재반복 호출됨.
    // 바로 넘길수 있게 exc를 의존성에서 제외(exc는 바뀔일 없으므로 괜찮음).
  }, [d, vh, vhGrp, vhOrGrp]);

  return (
    <select
      value={strg?.Id || ''}
      onChange={(e) =>
        setStrg(strgs.find((v) => v.Id === e.target.value) ?? null)
      }
      className={className}
      name="stId"
    >
      {empty && <option> </option>}
      {strgs.some((v) => v.Id === 'Whole') && (
        <option value="Whole" style={{ fontWeight: 'bold' }}>
          Whole
        </option>
      )}
      {_(strgs)
        .filter((v) => v.Id !== 'Whole')
        .groupBy((v) => v.tgts?.[0] ?? '')
        .toPairs()
        .map(([tgt, g]) =>
          tgt ? (
            <optgroup key={tgt} label={GetVhGrpName(tgt as VhGrpTy)}>
              {strgByGrp(g)}
            </optgroup>
          ) : (
            <React.Fragment key={tgt}>{strgByGrp(g)}</React.Fragment>
          ),
        )
        .value()}
    </select>
  );
}
