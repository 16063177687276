import React from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { AlgoTag } from '../../../Tms/Ems';


interface Props {
  altags: AlgoTag[];
}

const columnHelper = createColumnHelper<AlgoTag>();
const columns = [
  columnHelper.accessor('tag', { header: '태그', size: 100 }),
  columnHelper.accessor('val', { header: '값', size: 100 }),
  columnHelper.accessor('hms', { header: '설정T', size: 70 }),
  columnHelper.accessor('remHms', { header: '해제T', size: 70 }),
  columnHelper.accessor('operNm', { header: '처리자', size: 70 }),
].map((v) => v as ColumnDef<AlgoTag, unknown>);

export default function AlgoTagTab({ altags }: Props) {
  const meta: TableMeta<AlgoTag> = {};
  return <DataGrid data={altags} columns={columns} meta={meta} />;
}
