// https://quilljs.com/playground/react

import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['link', 'image', 'video'],
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ size: ['small', false, 'large', 'huge'] }], // { font: [] }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // ['blockquote', 'code-block'],
  ['clean'], // remove formatting button
];

// EditorCore is an uncontrolled React component
const EditorCore = forwardRef<Quill, any>(
  (
    {
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      style,
      className,
    },
    ref: any,
  ) => {
    const containerRef = useRef<any>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
        },
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        // defaultValue를 html로 넘기려면.
        // https://quilljs.com/docs/modules/clipboard/#dangerouslypastehtml
        quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
        // https://quilljs.com/docs/api
        // quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef} style={style} className={className}></div>;
  },
);

EditorCore.displayName = 'Editor';

export default EditorCore;
