import React, { useEffect, useState } from 'react';
import { useMessageState } from './context/MessageContext';
import Dialog from './ui/Dialog';
import SimpleGrid from './table/SimpleGrid';
import Editor from './ui/Editor';
import Button from './ui/Button';

type Item = {
  Id: number;
  name: string;
};
const items: Item[] = [
  { Id: 1, name: 'xx' },
  { Id: 2, name: 'yy' },
];

export default function Test() {
  const { msgBox: m, logger } = useMessageState();

  const [showDialog, setShowDialog] = useState(false);

  const [res, setRes] = useState('');

  const a:any = [];

  useEffect(() => logger.addLog('info', 'xx'), []);

  return (
    <>
      <div className="children-me-2">
        <Button label="alert" onClick={() => m.alert('n')} />
        <Button
          label="confirm"
          onClick={async () => setRes(String(await m.confirm('test')))}
        />
        <Button
          label="prompt"
          onClick={async () => setRes(String(await m.prompt('test')))}
        />
        <Button label="toast" onClick={async () => m.toast('test')} />
        <Button label="Dialog" onClick={() => setShowDialog(true)} />
      </div>
      <Dialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        style={{ width: '300px', height: '200px' }}
      >
        test
      </Dialog>
      <SimpleGrid
        data={items}
        columns={['Id', 'name']}
        headers={['Id', '이름']}
      />
      <Editor
        value="<p>ab<strong>c</strong>d</p>"
        style={{ width: '400px', height: '300px' }}
        onBlur={(v) => setRes(v)}
      />
      <div>{res}</div>
      <br />
      <br />
      <br />
      <br />
      <button
        type="button"
        onClick={() => a.unknownFunction()}
      >
        exception
      </button>
    </>
  );
}
