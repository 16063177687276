import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { OrdStLog } from '../../../Tms/Ord';


interface Props {
  ordStLog: OrdStLog[];
}

const columnHelper = createColumnHelper<OrdStLog>();
const columns = [
  columnHelper.accessor('tHms', {
    header: 'T',
    size: 60,
  }),
  columnHelper.accessor('fld', {
    header: '필드',
    size: 100,
  }),
  columnHelper.accessor('oldValAdj', {
    header: '기존값',
    size: 130,
  }),
  columnHelper.accessor('newValAdj', {
    header: '변경값',
    size: 130,
  }),
  columnHelper.accessor('userNm', {
    header: '변경자',
    size: 70,
  }),
].map((v) => v as ColumnDef<OrdStLog, unknown>);

export default function OrdStLogTab({ ordStLog }: Props) {
  const [data, setData] = useState<OrdStLog[]>(ordStLog);
  useEffect(() => setData(ordStLog), [ordStLog]);

  const meta: TableMeta<OrdStLog> = { height: 500 };

  return <DataGrid data={data} columns={columns} meta={meta} />;
}
