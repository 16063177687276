import React from 'react';

// https://github.com/TanStack/table/blob/main/examples/react/filters/src/main.tsx
// A debounced input react component
export default function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { name, placeholder, style, className } = props; // react/jsx-props-no-spreading

  return (
    <input
      // {...props}
      name={name}
      placeholder={placeholder}
      style={style}
      value={value}
      className={className}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
