import React, { useEffect, useMemo, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { UrlGrid, UrlGridArgs, callAxios, checkItems } from '../../tmsutil';
import {
  AiCode,
  AiCodeTy,
  AiComp,
  AiFill,
  AiInv,
  AiOpt,
  AiProd,
  AiProdDiv,
  AiRfx,
  aiCompSchema,
  aiFillSchema,
  aiInvSchema,
  aiOptSchema,
  aiProdDivSchema,
  aiProdSchema,
  aiRfxSchema,
} from '../../Tms/AI';
import { ProdUserDaily } from '../../Tms/Prod';

const currMenu = '/AI/BookData';

// prettier-ignore
const aiCompDft: UrlGridArgs<AiComp> = {
  url: `${currMenu}/AiComp`,
  title: '기업',
  columns: ['Id', 'nm', 'loc', 'cmpNo', 'regNo', 'ceo', 'ipoComp', 'ipoState', 'mainBiz',
    'sec0', 'sec1', 'sec2', 'commStks', 'pfrdStks', 'stkOpts',
    'guy0', 'tel0', 'mail0', 'guy1', 'tel1', 'mail1',],
  headers: ['기업코드', '기업명', '법인구분', '법인번호', '사업자등록번호', '대표이사', '상장주관사', '상장진행현황', '주요사업',
    '대분류', '중분류', '소분류', '보통주', '우선주,CB,BW', '스톡옵션',
    '이름', '연락처', '메일주소', '이름', '연락처', '메일주소'],
  headerGroups: [
    ['기업정보', 9],
    ['섹터', 3],
    ['발행주식수', 3],
    ['담당자1', 3],
    ['담당자2', 3],
  ],
  widths: { nm: 120, mainBiz: 150 },
  height: 300,
  schema: aiCompSchema,
};

// prettier-ignore
const aiProdDft: UrlGridArgs<AiProd> = {
  url: `${currMenu}/AiProd`,
  title: '발행',
  columns: ['Id', 'prodNm', 'round', 'issueD', 'convD',
    'couponRt', 'ytm', 'ytp', 'ytc',
    'rfxPrcLmt', 'rfxBase', 'rfxUnitPrd', 'intrTy', 'spAgrr'],
  headers: ['종목코드', '종목명', '회차(종)', '발행일', '전환가능일',
    '표면이자율(%)', 'YTM(%)', 'YTP(%)', 'YTC(%)',
    '리픽싱(%)', '리픽싱기준', '리픽싱단위', '이자단위', '특약유무'],
  height: 100,
  schema: aiProdSchema,
};

// prettier-ignore
const aiRfx: UrlGridArgs<AiRfx> = {
  url: `${currMenu}/AiRfx`,
  title: '리픽싱',
  columns: ['d', 'prc', 'lb'],
  headers: ['날짜', '전환가', '하단'],
  height: 200,
  meta: { dftColWidth: 80 },
  schema: aiRfxSchema,
};

// prettier-ignore
const aiPut: UrlGridArgs<AiOpt> = {
  url: `${currMenu}/AiPut`,
  title: '조기상환청구권(Put)',
  columns: ['reqD0', 'reqD1', 'exerD', 'xp', 'rto'],
  headers: ['시작일', '종료일', '행사일', '조기상환율', '기능비중'],
  height: 200,
  meta: { dftColWidth: 80 },
  schema: aiOptSchema,
};

// prettier-ignore
const aiCall: UrlGridArgs<AiOpt> = {
  url: `${currMenu}/AiCall`,
  title: '매도청구권(Call)',
  columns: ['reqD0', 'reqD1', 'exerD', 'xp', 'rto'],
  headers: ['시작일', '종료일', '행사일', '원금상환율', '기능비중'],
  height: 200,
  meta: { dftColWidth: 80 },
  schema: aiOptSchema,
};

// prettier-ignore
const aiProdDiv: UrlGridArgs<AiProdDiv> = {
  url: `${currMenu}/AiProdDiv`,
  title: '표면이자',
  columns: ['d'],
  headers: ['날짜'],
  height: 200,
  meta: { dftColWidth: 80 },
  schema: aiProdDivSchema,
};

// prettier-ignore
const aiInvDft: UrlGridArgs<AiInv> = {
  url: `${currMenu}/AiInv`,
  title: '자산',
  columns: ['ord', 'tgt', 'oldNew', 'invD', 'unlockD', 'deposit', 'backupGuy', 'detProdId', 'note', 'otherInvs', 'dealComp', 'dealGuy', 'ourGuy', 'mktExitD', 'emerExitD', 'emerExitWay', 'normExitD', 'normExitWay',],
  headers: ['투자번호', '구분', '구주신주', '투자일', '보호예수종료일', '보관', '담당자(지원)', '분리평가코드', '기타', '공동투자자', '회사명', '담당자', '당사담당자', '예상시점', '예상시점', '방안', '예상시점', '방안',],
  headerGroups: [
    ['투자', 9],
    ['딜소싱', 3],
    ['회수(Mkt)', 1],
    ['회수(Emergency)', 2],
    ['회수(Normal)', 2],
  ],
  height: 150,
  meta: { dftColWidth: 80 },
  schema: aiInvSchema,
};

// prettier-ignore
const aiFillDft: UrlGridArgs<AiFill> = {
  url: `${currMenu}/AiFill`,
  title: '거래 내역',
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'rstr', 'isBuy', 'qty', 'prc', 'fee', 'tax', 'div', 'fx', 'stId', 'stlD', 'fillTy', 'prinTy', 'cal', 'hasStFillId', 'hasWhFillId', 'hasStDivId', 'hasWhDivId',],
  headers: ['날짜', '펀드', '종목코드', '종목명', '제한', '매수', '수량', '단가', '수수료', '세금', '현금배당', '환율', '전략', '결제일자', '거래방법', '원금구분', '투자일정', '체결st', '체결wh', '배당st', '배당wh',],
  height: 300,
  meta: { dftColWidth: 80 },
  schema: aiFillSchema,
};

// prettier-ignore
const getAiCode = (title: string, editable: boolean): UrlGridArgs<AiCode> => ({
  url: `${currMenu}/AiCode`,
  title,
  columns: ['cd'],
  headers: ['코드'],
  height: 300,
  editable,
  meta: { dftColWidth: 80 },
});

const aiPrc: UrlGridArgs<ProdUserDaily> = {
  url: `${currMenu}/AiPrc`,
  title: '장외 가격 관리',
  columns: ['d', 'prodId', 'prodNm', 'val'],
  headers: ['날짜', '종목코드', '종목명', '종가'],
  height: 300,
  meta: { dftColWidth: 80 },
};

export default function BookData() {
  const { msgBox: m, logger } = useMessageState();
  const [refreshAiComp, setRefreshAiComp] = useState(0);
  const [refreshAiProd, setRefreshAiProd] = useState(0);
  const [refreshAiProdRefs, setRefreshAiProdRefs] = useState(0);
  const [refreshAiFill, setRefreshAiFill] = useState(0);
  const [currCompId, setCurrCompId] = useState('');
  const [currProdId, setCurrProdId] = useState('');
  const [currInvId, setCurrInvId] = useState(0);
  const [editable, setEditable] = useState(false);
  const [showEtc, setShowEtc] = useState(false);

  const call = (
    func: string,
    title: string,
    params: unknown,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => void,
  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      params,
      title,
      onSuccess,
    });

  const updateAiCompId = async (items: AiComp[]) => {
    if (!checkItems(items, m, true)) return;
    const newId = await m.prompt('신규 기업 코드');
    if (!newId) return;
    const par = { id: items[0].Id, val: newId };
    call('UpdateAiCompId', '기업 코드 변경', par, () =>
      setRefreshAiComp((p) => p + 1),
    );
  };

  // 필터 등 그리드 내부 상태 유지하려고 메모에.
  const aiComp: UrlGridArgs<AiComp> = useMemo(
    () => ({
      ...aiCompDft,
      editable,
      meta: {
        dftColWidth: 100,
        contextMenus: [{ label: '기업 코드 변경', callback: updateAiCompId }],
        onRowClick: (r) => setCurrCompId(r.original.Id),
      },
    }),
    [editable],
  );

  const aiProd: UrlGridArgs<AiProd> = {
    ...aiProdDft,
    editable,
    meta: {
      dftColWidth: 80,
      onRowClick: (r) => setCurrProdId(r.original.Id),
    },
  };

  const aiInv: UrlGridArgs<AiInv> = {
    ...aiInvDft,
    editable,
    meta: {
      dftColWidth: 80,
      onRowClick: (r) => setCurrInvId(r.original.Id),
    },
  };

  const sendFill = (items: AiFill[], update: boolean) => {
    if (!checkItems(items, m, true)) return;
    const par = { inv: items[0].Id, update };
    call('SendFill', `체결 ${update ? '정정' : '신규'} 전송`, par, () =>
      setRefreshAiFill((p) => p + 1),
    );
  };
  const sendFillDisabled = (items: AiFill[], update: boolean) =>
    !items.length ||
    update !== items.some((v) => v.hasWhFillId || v.hasStFillId);
  const sendDiv = (items: AiFill[], update: boolean) => {
    if (!checkItems(items, m, true)) return;
    const par = { inv: items[0].Id, update };
    call('SendDiv', `배당 ${update ? '정정' : '신규'} 전송`, par, () =>
      setRefreshAiFill((p) => p + 1),
    );
  };
  const sendDivDisabled = (items: AiFill[], update: boolean) =>
    !items.length || update !== items.some((v) => v.hasWhDivId || v.hasStDivId);

  const aiFill: UrlGridArgs<AiFill> = {
    ...aiFillDft,
    editable,
    meta: {
      dftColWidth: 80,
      contextMenus: [
        {
          label: '체결 신규 전송',
          callback: (items) => sendFill(items, false),
          disabled: (items) => sendFillDisabled(items, false),
        },
        {
          label: '체결 정정 업데이트 (수량,가격만 반영)',
          callback: (items) => sendFill(items, true),
          disabled: (items) => sendFillDisabled(items, true),
        },
        { divider: true },
        {
          label: '배당 신규 전송',
          callback: (items) => sendDiv(items, false),
          disabled: (items) => sendDivDisabled(items, false),
        },
        {
          label: '배당 정정 업데이트 (배당금만 반영)',
          callback: (items) => sendDiv(items, true),
          disabled: (items) => sendDivDisabled(items, true),
        },
      ],
    },
  };

  useEffect(() => {
    setRefreshAiProd((p) => p + 1);
    setCurrProdId('');
    setCurrInvId(0);
  }, [currCompId]);

  useEffect(() => setRefreshAiProdRefs((p) => p + 1), [currProdId]);
  useEffect(() => setRefreshAiFill((p) => p + 1), [currInvId]);

  const prodPar = { prod: currProdId };

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <b>참고</b> &nbsp;
      <a
        style={{ textDecorationLine: 'none' }}
        href="https://docs.google.com/spreadsheets/d/1ZECZp6MS2nfoYBw8vNIEgEvJr3XURHwx2VsKQ9cZFUE/edit#gid=557380411"
      >
        요청 사항
      </a>
      | &nbsp;
      <label htmlFor="cbEditable">
        <input
          type="checkbox"
          id="cbEditable"
          onChange={(e) => setEditable(e.target.checked)}
        />
        편집 모드
      </label>
      <hr className="narrow light" />
      <UrlGrid args={aiComp} params={{}} refreshNeeded={refreshAiComp} />
      <hr className="narrow light" />
      {currCompId && (
        <UrlGrid
          args={aiProd}
          params={{ comp: currCompId }}
          refreshNeeded={refreshAiProd}
        />
      )}
      <hr className="narrow light" />
      <div className="d-flex children-me-3">
        {currProdId && (
          <UrlGrid
            args={{ ...aiRfx, editable }}
            params={prodPar}
            refreshNeeded={refreshAiProdRefs}
          />
        )}
        {currProdId && (
          <UrlGrid
            args={{ ...aiPut, editable }}
            params={prodPar}
            refreshNeeded={refreshAiProdRefs}
          />
        )}
        {currProdId && (
          <UrlGrid
            args={{ ...aiCall, editable }}
            params={prodPar}
            refreshNeeded={refreshAiProdRefs}
          />
        )}
        {currProdId && (
          <UrlGrid
            args={{ ...aiProdDiv, editable }}
            params={prodPar}
            refreshNeeded={refreshAiProdRefs}
          />
        )}
      </div>
      <hr className="narrow light" />
      {currProdId && (
        <UrlGrid
          args={aiInv}
          params={prodPar}
          refreshNeeded={refreshAiProdRefs}
        />
      )}
      <hr className="narrow light" />
      {!!currInvId && (
        <UrlGrid
          args={aiFill}
          params={{ inv: currInvId }}
          refreshNeeded={refreshAiFill}
        />
      )}
      <hr className="narrow light" />
      <label htmlFor="cbShowEtc">
        <input
          type="checkbox"
          id="cbShowEtc"
          onChange={(e) => setShowEtc(e.target.checked)}
        />
        기타 보기
      </label>
      {showEtc && (
        <>
          <hr className="narrow light" />
          <div className="d-flex">
            <UrlGrid
              args={getAiCode('섹터 - 대분류', editable)}
              params={{ ty: AiCodeTy.Sec0 }}
            />
            <UrlGrid
              args={getAiCode('섹터 - 중분류', editable)}
              params={{ ty: AiCodeTy.Sec1 }}
            />
            <UrlGrid
              args={getAiCode('섹터 - 소분류', editable)}
              params={{ ty: AiCodeTy.Sec2 }}
            />
            <UrlGrid
              args={getAiCode('거래 방법', editable)}
              params={{ ty: AiCodeTy.FillTy }}
            />
          </div>
          <hr className="narrow light" />
          <UrlGrid args={{ ...aiPrc, editable }} params={{}} />
        </>
      )}
    </div>
  );
}
