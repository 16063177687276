import React, { useState } from 'react';
import Dropdown from 'tmslib/src/ui/Dropdown';
import { DataGridState } from 'tmslib/src/table/DataGrid';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import {
  updateOrdSts,
  UpdateOrdStField,
  OrdStUpdateFields,
} from '../../Front/Target/ordutil';
import {
  useOrderState,
  HoveredBtn,
  useOrderDispatch,
} from '../../Front/Target/OrderContext';
import { OrdSt } from '../../../Tms/Ord';
import { DftBtnStyle } from '../../../AppTypes';
import { FuncCall, downloadFile } from '../../../tmsutil';

interface Props {
  oprocCall: FuncCall;
  tgtCall: FuncCall;
  OrdStDgState: DataGridState<OrdSt> | undefined;
  OrdStPrdCnt?: number | null;
}

type WorkFunc =
  | 'OffsetLongExit'
  | 'SltdOrderFile'
  | 'OrdMktWei'
  | 'OrdMktWeiVh'
  | 'ProcOrdStPrd'
  | 'EnforceUnacptdDoneBatch';

type Work = {
  title: string;
  func: WorkFunc;
  txt: string;
};
const WorkListGrps: Work[][] = [
  [
    { title: '선택주문 작업', func: 'OffsetLongExit', txt: '롱청산 상계' },
    { title: '선택주문 작업', func: 'SltdOrderFile', txt: '주문 파일' },
  ],
  [
    { title: '주문번호별 작업', func: 'OrdMktWei', txt: '시장 비중' },
    {
      title: '주문번호별 작업',
      func: 'OrdMktWeiVh',
      txt: '시장 비중(펀드별)',
    },
  ],
  [
    {
      title: '일괄 작업',
      func: 'ProcOrdStPrd',
      txt: '기간 주문 당일자 생성',
    },
    {
      title: '일괄 작업',
      func: 'EnforceUnacptdDoneBatch',
      txt: '문제증권사 미접주문 일괄강제완료',
    },
  ],
];

export default function ProcBar({
  oprocCall,
  tgtCall,
  OrdStDgState,
  OrdStPrdCnt,
}: Props) {
  const [recallQty, setRecallQty] = useState<number | null>(null);
  const [recallBrk, setRecallBrk] = useState<string | null>(null);

  const { inp, hover, updFld, updVal, keepSwap } = useOrderState();
  const dispatch = useOrderDispatch();

  const keepReqBtnHover = hover === HoveredBtn.KeepReq;
  const recallOrderBtnHover = hover === HoveredBtn.recallOrderBtnHover;
  const loanKeepReqBtnHover = hover === HoveredBtn.loanKeepReqBtnHover;
  const modBatchBtnHover = hover === HoveredBtn.ModBatch;

  const setUpdFld = (fld: UpdateOrdStField) =>
    dispatch({ type: 'updFld', fld });
  const setUpdVal = (val: string | null) => dispatch({ type: 'updVal', val });
  const setKeepSwap = (swap: boolean) => dispatch({ type: 'keepSwap', swap });
  const setBtnHover = (hover_: HoveredBtn) =>
    dispatch({ type: 'hover', hover: hover_ });

  const { msgBox: m, logger } = useMessageState();

  const UpdateOrdSt = () => {
    if (OrdStDgState === undefined) {
      return;
    }
    if (updFld === UpdateOrdStField.None) {
      m.alert('업데이트 항목 확인');
      return;
    }
    const osts = OrdStDgState.selectedObjs;
    updateOrdSts(osts, updFld, updVal, true, tgtCall, false, m);
  };

  const download = (func: string, params: unknown) =>
    downloadFile({
      m,
      logger,
      url: `/Back/OrdProc/Download${func}`,
      params,
    });

  const getSelectedOstsIds = (): number[] => {
    if (OrdStDgState === undefined) {
      return [];
    }
    const ostIds = OrdStDgState.selectedObjs.map((v) => v.Id);
    return ostIds;
  };

  const OffsetLongExit = async () => {
    if (!inp) return;
    if (inp.ord === null) {
      m.alert('주문 번호 확인');
      return;
    }

    const ostIds = getSelectedOstsIds();
    if (!ostIds.length) {
      m.alert('선택주문 없음');
      return;
    }
    if (!(await m.confirm(`${ostIds.length}개 주문 롱청산 상계? `))) return;

    const par = { ids: ostIds, ord: inp.ord };
    oprocCall('OffsetLongExit', par, { title: '롱청산 상계' });
  };

  const SltdOrderFile = () => {
    const ostIds = getSelectedOstsIds();
    if (!ostIds.length) {
      m.alert('선택주문 없음');
      return;
    }
    const params = { ids: ostIds };
    download('SltdOrderFile', params);
  };

  const OrdMktWei = (vh: boolean) => {
    if (!inp) return;
    if (inp.ord === null) {
      m.alert('주문 번호 확인 ');
      return;
    }
    const params = {
      d: inp.d,
      tgt: inp.tgt,
      ord: inp.ord,
      vh,
    };
    download('OrdMktWeiFile', params);
  };

  const ProcOrdStPrd = () => {
    if (!inp) return;
    const par = { d: inp.d, tgt: inp.tgt };
    oprocCall('ProcOrdStPrd', par, { title: '기간 주문 처리' });
  };

  const EnforceUnacptdDoneBatch = async () => {
    const brk = await m.prompt(
      '문제 발생 증권사 코드 (해당 증권사에서 미접주문 처리하지 않을것임을 먼저 확인하세요)',
    );
    if (!brk) return;
    if (!inp) return;
    const par = { d: inp.d, tgt: inp.tgt, brk };
    oprocCall('EnforceUnacptdDoneBatch', par, {
      title: `${brk} 강제 완료`,
    });
  };

  const GenRecallOrder = () => {
    const whenInvalidMsg = ifesleExpr(
      [!inp, '주문입력 준비안됨'],
      [inp.ord === null, '주문번호 확인'],
      [!recallBrk, '리콜증권사 확인'],
      [!recallQty, '리콜수량 확인'],
    );

    const par = { inp, recallBrk, recallQty };
    oprocCall('GenRecallOrder', par, {
      whenInvalidMsg,
      title: '리콜 주문 생성',
    });
  };

  const GenLoanKeepReq = () => {
    const whenInvalidMsg = ifesleExpr(
      [!inp, '주문입력 준비안됨'],
      [!inp.prodId, '종목코드 확인'],
      [!inp.chWei, '종목 비중 확인'],
    );
    const par = {
      d: inp.d,
      tgt: inp.tgt,
      st: inp.stId,
      cd: inp.prodId,
      ch: inp.chWei,
      exAll: inp.exAll,
      swap: keepSwap,
    };
    oprocCall('GenLoanKeepReq', par, {
      whenInvalidMsg,
      title: '킵 수량 생성',
    });
  };

  const mapFunc = (func: WorkFunc | null | undefined) => {
    if (!func) return;
    switch (func) {
      case 'OffsetLongExit':
        OffsetLongExit();
        break;
      case 'SltdOrderFile':
        SltdOrderFile();
        break;
      case 'OrdMktWei':
        OrdMktWei(false);
        break;
      case 'OrdMktWeiVh':
        OrdMktWei(true);
        break;
      case 'ProcOrdStPrd':
        ProcOrdStPrd();
        break;
      case 'EnforceUnacptdDoneBatch':
        EnforceUnacptdDoneBatch();
        break;
      default:
        throw new Error('wrong func');
    }
  };

  const ostUpdFieldsWithNone = OrdStUpdateFields.map((v) =>
    v.isFirst
      ? [{ val: UpdateOrdStField.None, txt: '-----', isFirst: false }, v]
      : [v],
  ).flat();

  return (
    <div style={{ width: '1500px' }} className="children-me-2">
      <select
        name="sltdOstFld"
        className={modBatchBtnHover ? 'input-highlight' : ''}
        onChange={(e) => setUpdFld(e.target.value as UpdateOrdStField)}
      >
        <option value="None"> </option>
        {ostUpdFieldsWithNone.map((v, k) => (
          <option
            key={v.val === UpdateOrdStField.None ? k : v.val}
            value={v.val}
          >
            {v.txt}
          </option>
        ))}
      </select>
      <input
        type="text"
        name="sltdOstVal"
        size={7}
        value={updVal || ''}
        onChange={(e) => setUpdVal(e.target.value as string)}
      />
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => UpdateOrdSt()}
      >
        선택 주문 적용
      </button>
      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      {WorkListGrps.map((ws) => (
        <Dropdown
          key={ws[0].title}
          style={{
            backgroundColor: '#f8f9fa',
            border: 'none',
            margin: '4px',
          }}
          value={null}
          onChange={(w) => mapFunc(w?.func)}
          options={ws}
          optionLabel={(w) => w.txt}
          placeholder={ws[0].title as string}
          className="w-full md:w-14rem"
        />
      ))}
      {OrdStPrdCnt && OrdStPrdCnt > 0 && (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'red',
            fontWeight: 'bold',
          }}
        >
          {OrdStPrdCnt}
        </button>
      )}

      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <NumericInput
        name="RecallQty"
        width="100"
        value={recallQty}
        className={recallOrderBtnHover ? 'input-highlight' : ''}
        onChange={(v) => setRecallQty(v)}
        placeholder="리콜수량"
      />
      <input
        type="text"
        name="RecallBrk"
        size={7}
        value={recallBrk || ''}
        className={recallOrderBtnHover ? 'input-highlight' : ''}
        onChange={(e) => setRecallBrk(e.target.value as string)}
        placeholder="리콜증권사"
      />
      <button
        type="button"
        className={DftBtnStyle}
        onClick={GenRecallOrder}
        onFocus={() => setBtnHover(HoveredBtn.recallOrderBtnHover)}
        onBlur={() => setBtnHover(HoveredBtn.None)}
        onMouseOver={() => setBtnHover(HoveredBtn.recallOrderBtnHover)}
        onMouseOut={() => setBtnHover(HoveredBtn.None)}
      >
        리콜 숏 청산
      </button>
      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <button
        type="button"
        className={DftBtnStyle}
        onClick={GenLoanKeepReq}
        onFocus={() => setBtnHover(HoveredBtn.loanKeepReqBtnHover)}
        onBlur={() => setBtnHover(HoveredBtn.None)}
        onMouseOver={() => setBtnHover(HoveredBtn.loanKeepReqBtnHover)}
        onMouseOut={() => setBtnHover(HoveredBtn.None)}
      >
        킵수량 생성
      </button>
      <label
        htmlFor="keepSwap"
        className={
          keepReqBtnHover || loanKeepReqBtnHover ? 'input-highlight' : ''
        }
      >
        <input
          type="checkbox"
          id="keepSwap"
          checked={keepSwap}
          onChange={(e) => setKeepSwap(e.target.checked as boolean)}
        />
        킵스왑
      </label>
    </div>
  );
}
