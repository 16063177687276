// https://velog.io/@yaytomato/%ED%94%84%EB%A1%A0%ED%8A%B8%EC%97%90%EC%84%9C-%EC%95%88%EC%A0%84%ED%95%98%EA%B2%8C-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EC%B2%98%EB%A6%AC%ED%95%98%EA%B8%B0
import React, { useState } from 'react';
import './Login.css';
import {
  Logger,
  MessageBox,
  useMessageState,
} from 'tmslib/src/context/MessageContext';
import { getCookie } from '../cookie';
import { FormEventTy } from '../../AppTypes';
import { useAuthDispatch, JWT_EXPIRY_TIME, AuthAction } from './AuthContext';
import { callAxios } from '../../tmsutil';

export const onSilentRefresh = (
  m: MessageBox,
  logger: Logger,
  dispatch: React.Dispatch<AuthAction>,
) => {
  const userid = getCookie('userid');
  if (!userid) {
    m.alert('로그인 유지 실패. 재로그인하세요');
    return;
  }
  const params = { userid };
  callAxios({
    m,
    logger,
    url: '/Auth/SilentRefresh',
    params,
    onSuccess: (data) => {
      dispatch({
        type: 'SILENT_REFRESH',
        auth: data.auth,
        info: data.info,
      });
      setTimeout(
        () => onSilentRefresh(m, logger, dispatch),
        JWT_EXPIRY_TIME - 60 * 1000,
      );
    },
    onError: (errMsgs) => {
      dispatch({
        type: 'SILENT_REFRESH_ERROR',
        errMsg: errMsgs.join('\n'),
      });
    },
  });
};

export default function Login() {
  const [userid, setUserId] = useState<string>();
  const [password, setPassword] = useState<string>();

  const dispatch = useAuthDispatch();
  const { msgBox: m, logger } = useMessageState();

  const handleSubmit = async (e: FormEventTy) => {
    e.preventDefault();
    const params = { userid, password };
    callAxios({
      m,
      logger: null, // unauth 상태니 로깅 안보여주기,
      url: '/Auth/Login',
      params,
      onSuccess: (data) => {
        dispatch({ type: 'LOGIN', res: data });
        // accessToken 만료하기 1분 전에 로그인 연장
        setTimeout(
          () => onSilentRefresh(m, logger, dispatch),
          JWT_EXPIRY_TIME - 60 * 1000,
        );
      },
      onError: (errMsgs) => {
        m.alert(`[Login fail] ${errMsgs.join('\n')}`);
        dispatch({ type: 'LOGIN_ERROR', errMsg: errMsgs.join('\n') });
      },
    });
  };

  const findId = async () => {
    const email = await m.prompt('registered email')
    if (!email) return
    callAxios({
      m,
      logger: null,
      url: '/Auth/FindId',
      params: { email },
      onSuccess: () => m.alert('An email with your ID was sent'),
      onError: (errMsgs) => m.alert(`[FindId fail] ${errMsgs.join('\n')}`)
    });
  };

  const reqPwdReset = async () => {
    const email = await m.prompt('registered email');
    if (!email) return;
    callAxios({
      m,
      logger: null,
      url: '/Auth/ReqPwdReset',
      params: { email },
      onSuccess: () => m.alert('An email with password reset link was sent'),
      onError: (errMsgs) => m.alert(`[Pwd Reset fail] ${errMsgs.join('\n')}`),
    });
  };

  
  return (
    <div className="login-wrapper">
      <h2>Please Log In</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="userId">
          <p>User Id</p>
          <input
            type="text"
            name="userId"
            onChange={(e) => setUserId(e.target.value)}
          />
        </label>
        <br />
        <br />
        <label htmlFor="password">
          <p>Password</p>
          <input
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <br />
        <div>
          <button type="submit" className="btn btn-primary">
            Log in
          </button>
        </div>
      </form>
      <hr className="narrow light" />
      <div style={{ display: 'none' }}>
        <button type="button" className="btn btn-link" onClick={() => findId()}>
          Forgot your ID?
        </button>
        <br />
        <button type="button" className="btn btn-link" onClick={() => reqPwdReset()}>
          Forgot your password?
        </button>
        <br />
        <button type="button" className="btn btn-link">
          Register as a new user
        </button>
      </div>
    </div>
  );
}
