import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { VhGrpTy, GetVhGrpName } from '../../../Tms/Tms';
import {
  RtDiff,
  updateRtObj,
  updateRtRows,
  setRtEffect,
} from '../../../rtutil';
import StatBar, { Stat } from './StatBar';
import RiskTab from './RiskTab';
import { RiskLog } from '../../../Tms/MO';
import { OrdStPrd, OrdSt } from '../../../Tms/Ord';
import { ConfirmInfo, LoanOfferReq, KeepWei } from '../../../Tms/Loan';

import ExtraTabs from './ExtraTabs';
import OrderInputBar from './OrderInputBar';
import './target.scss';
import { useAuthState } from '../../Auth/AuthContext';
import { OrderProvider } from './OrderContext';
import WarningBar, { WarnMsg } from './WarningBar';
import LsWeiTabs from './LsWeiTabs';
import { PosWeiTarget } from '../../../Tms/BO';
import { StdItem, EarnRev, IdxWei, ErSc, DropSc } from './WeiTab';
import SubOrdTab from './SubOrdTab';
import StrgSelector, { SimpleStrg } from '../../../shared/StrgSelector';
import DateSelector from '../../../shared/DateSelector';
import { genFuncCall } from '../../../tmsutil';

type TargetRes = {
  t: number;
  stat: Stat | null;
  stat_upd: { [key: string]: object } | null;
  risks: RiskLog[] | null;
  risks_diff: RtDiff<RiskLog> | null;
  ostPrds: OrdStPrd[] | null;
  ostPrds_diff: RtDiff<OrdStPrd> | null;
  loanOfferReqs: LoanOfferReq[] | null;
  loanOfferReqs_diff: RtDiff<LoanOfferReq> | null;
  unregistered: OrdSt[] | null;
  unregistered_diff: RtDiff<OrdSt> | null;
  warns: WarnMsg[] | null;
  warns_diff: RtDiff<WarnMsg> | null;
  cnfrmNeeded: ConfirmInfo[] | null;
  cnfrmNeeded_diff: RtDiff<ConfirmInfo> | null;
  lpos: PosWeiTarget[] | null;
  lpos_diff: RtDiff<PosWeiTarget> | null;
  spos: PosWeiTarget[] | null;
  spos_diff: RtDiff<PosWeiTarget> | null;
  losts: OrdSt[] | null;
  losts_diff: RtDiff<OrdSt> | null;
  sosts: OrdSt[] | null;
  sosts_diff: RtDiff<OrdSt> | null;
  stds: StdItem[] | null;
  stds_diff: RtDiff<StdItem> | null;
  earnRevs: EarnRev[] | null;
  earnRevs_diff: RtDiff<EarnRev> | null;
  idxWeis: IdxWei[] | null;
  idxWeis_diff: RtDiff<IdxWei> | null;
  erScs: ErSc[] | null;
  erScs_diff: RtDiff<ErSc> | null;
  dropScs: DropSc[] | null;
  dropScs_diff: RtDiff<DropSc> | null;
  keepWeis: KeepWei[] | null;
  keepWeis_diff: RtDiff<KeepWei> | null;
  allOsts: OrdSt[] | null;
  allOsts_diff: RtDiff<OrdSt> | null;
};

export default function TargetMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    user,
    connection,
    connected,
    info,
  } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [strg, setStrg] = useState<SimpleStrg | null>(null);

  const d = searchParams.get('d') || info?.currBizDay || '';
  const stPar = searchParams.get('st') || '';
  const tgtIdx = searchParams.get('tgtIdx') || '0';

  const lastResT = useRef(0);
  const [stat, setStat] = useState<Stat | null>(null);
  const [risks, setRisks] = useState<RiskLog[]>([]);
  const [ostPrds, setOstPrds] = useState<OrdStPrd[]>([]);
  const [loanOfferReqs, setLoanOfferReqs] = useState<LoanOfferReq[]>([]);
  const [unregistered, setUnregistered] = useState<OrdSt[]>([]);
  const [warns, setWarns] = useState<WarnMsg[]>([]);
  const [cnfrmNeeded, setCnfrmNeeded] = useState<ConfirmInfo[]>([]);
  const [lpos, setLpos] = useState<PosWeiTarget[]>([]);
  const [spos, setSpos] = useState<PosWeiTarget[]>([]);
  const [losts, setLosts] = useState<OrdSt[]>([]);
  const [sosts, setSosts] = useState<OrdSt[]>([]);
  const [stds, setStds] = useState<StdItem[]>([]);
  const [earnRevs, setEarnRevs] = useState<EarnRev[]>([]);
  const [idxWeis, setIdxWeis] = useState<IdxWei[]>([]);
  const [erScs, setErScs] = useState<ErSc[]>([]);
  const [dropScs, setDropScs] = useState<DropSc[]>([]);
  const [keepWeis, setKeepWeis] = useState<KeepWei[]>([]);
  const [allOsts, setAllOsts] = useState<OrdSt[]>([]);

  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  const stId = strg?.Id ?? null;
  const vhGrp = strg?.tgts?.[Number(tgtIdx)] ?? null;
  const vhId = strg?.reprVhs?.[Number(tgtIdx)] ?? null;

  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: { d, st: stId, vhGrp },
        reqAddr: 'RequestTarget',
        rcvAddr: 'ReceiveTarget',
        connection,
        connCond: () => !!vhGrp,
        onReceive: (res: TargetRes) => {
          setStat((prev) => updateRtObj(prev, res?.stat, res?.stat_upd));
          setRisks((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.risks,
              diff: res?.risks_diff,
            }),
          );
          setOstPrds((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ostPrds,
              diff: res?.ostPrds_diff,
            }),
          );
          setLoanOfferReqs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.loanOfferReqs,
              diff: res?.loanOfferReqs_diff,
            }),
          );
          setUnregistered((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.unregistered,
              diff: res?.unregistered_diff,
            }),
          );
          setWarns((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.warns,
              diff: res?.warns_diff,
            }),
          );
          setCnfrmNeeded((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.cnfrmNeeded,
              diff: res?.cnfrmNeeded_diff,
            }),
          );
          setLpos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.lpos,
              diff: res?.lpos_diff,
            }),
          );
          setSpos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.spos,
              diff: res?.spos_diff,
            }),
          );
          setLosts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.losts,
              diff: res?.losts_diff,
            }),
          );
          setSosts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.sosts,
              diff: res?.sosts_diff,
            }),
          );
          setStds((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.stds,
              diff: res?.stds_diff,
            }),
          );
          setEarnRevs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.earnRevs,
              diff: res?.earnRevs_diff,
            }),
          );
          setIdxWeis((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.idxWeis,
              diff: res?.idxWeis_diff,
            }),
          );
          setErScs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.erScs,
              diff: res?.erScs_diff,
            }),
          );
          setDropScs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.dropScs,
              diff: res?.dropScs_diff,
            }),
          );
          setKeepWeis((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.keepWeis,
              diff: res?.keepWeis_diff,
            }),
          );
          setAllOsts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.allOsts,
              diff: res?.allOsts_diff,
            }),
          );
        },
      }),
    [connection, connected, d, strg, tgtIdx, updateNeeded],
  );

  const showAllOsts =
    stId && info?.isAiPe(stId) && (user?.isAiPe || user?.isOmsOperator);

  const tgtCall = genFuncCall(m, logger, '/Front/Target', () =>
    setUpdateNeeded(),
  );

  return (
    <div className="target children-me-2" style={{ minWidth: '1300px' }}>
      <DateSelector
        value={d}
        onChange={(date) => {
          if (date !== d) {
            setSearchParams({
              d: date,
              st: stPar,
              tgtIdx: tgtIdx.toString(),
            });
          }
        }}
      />
      <StrgSelector
        d={d}
        value={stPar}
        onChange={(v, fst) => {
          if (v) {
            setStrg(v);
            if (!fst && v.Id !== stPar)
              setSearchParams({ d, st: v.Id, tgtIdx: '0' });
          }
        }}
        exc={[VhGrpTy.Troika]}
        incReprVhs
        useTgt
      />
      <select
        value={tgtIdx}
        onChange={(e) =>
          setSearchParams({ d, st: stPar, tgtIdx: e.target.value })
        }
        name="vhGrp"
      >
        {strg?.tgts?.map((t, k) => (
          <option key={t} value={k}>
            {GetVhGrpName(t)}
          </option>
        ))}
      </select>
      <span style={{ color: 'gray' }}>{stId}</span>
      <span style={{ color: 'gray' }} className="me-5">
        {vhId}
      </span>
      {stat && (
        <StatBar
          vhGrp={vhGrp}
          stat={stat}
          isAdvisor={user?.isAdvisor ?? false}
        />
      )}
      <hr className="light narrow" />
      <div
        className="row"
        style={{ minHeight: '200px', width: 'fit-content', minWidth: '1600px' }}
      >
        <div className="col-6">
          <RiskTab tgtCall={tgtCall} risks={risks} />
        </div>
        <div className="col-6 ps-4">
          <ExtraTabs
            tgtCall={tgtCall}
            d={d}
            stId={stId}
            vhGrp={vhGrp}
            ostPrds={ostPrds}
            loanOfferReqs={loanOfferReqs}
          />
        </div>
      </div>
      <hr className="light narrow" />
      <OrderProvider>
        <OrderInputBar
          tgtCall={tgtCall}
          isOrdProc={false}
          d={d}
          initStId={stId || ''}
          vhGrp={vhGrp}
          vhId={vhId}
          unregistered={unregistered}
        />
      </OrderProvider>
      <hr className="light narrow" />
      <WarningBar
        tgtCall={tgtCall}
        d={d}
        warns={warns}
        cnfrmNeeded={cnfrmNeeded}
      />
      <LsWeiTabs
        setUpdateNeeded={setUpdateNeeded}
        tgtCall={tgtCall}
        d={d}
        vhId={vhId}
        stId={stId}
        lpos={lpos}
        spos={spos}
        losts={losts}
        sosts={sosts}
        stds={stds}
        earnRevs={earnRevs}
        idxWeis={idxWeis}
        erScs={erScs}
        dropScs={dropScs}
      />
      <hr className="light narrow" />
      <b>킵 보유 비중</b>
      <SimpleGrid
        data={keepWeis}
        columns={['prodId', 'prodNm', 'wei', 'used', 'wei2']}
        headers={['종목', '종목명', '장전(%)', '사용(%)', '현재(%)']}
        args={{
          widths: { wei: 70, used: 70, wei2: 70 },
          dftFormatter: (v) => (typeof v === 'number' ? v.toFixed(2) : v),
        }}
      />
      {showAllOsts && (
        <>
          <hr className="light narrow" />
          <b>전체 주문 리스트</b>
          <SubOrdTab call={tgtCall} osts={allOsts} allOsts />
        </>
      )}
    </div>
  );
}
