import React, { useEffect, useState } from 'react';

export default function MultiCheckBox<T>({
  name,
  selected: initialSelected,
  options,
  onChangeSelected,
  onChangeEach,
  labels,
}: {
  name: string;
  selected: T[];
  options: T[];
  onChangeSelected?: (selected: T[]) => void;
  onChangeEach?: (item: T, selected: boolean) => void;
  labels?: string[];
}) {
  const [selected, setSelected] = useState<T[]>(initialSelected);

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as T;
    const index = selected.indexOf(value);
    if (index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, ...[value]]);
    }

    onChangeEach?.(value, index === -1);
  };

  const isSelected = (value: T) => selected.includes(value);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    onChangeSelected?.(selected);
  }, [selected]);

  return (
    <>
      {options.map((o, i) => (
        <label key={`${o}`} htmlFor={`${name}_${o}`}>
          <input
            key={`${o}`}
            type="checkbox"
            name={name}
            id={`${name}_${o}`}
            value={`${o}`}
            checked={isSelected(o)}
            onChange={onChecked}
          />
          {labels ? labels[i] : `${o}`}
        </label>
      ))}
    </>
  );
}
