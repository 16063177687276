import React from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { OrdVh } from '../../../Tms/Ord';


interface Props {
  ovhs: OrdVh[];
}

const columnHelper = createColumnHelper<OrdVh>();
const columns = [
  columnHelper.accessor('Id', { header: 'Id', size: 70 }),
  columnHelper.accessor('vhId', { header: '펀드' }),
  columnHelper.accessor('orgq', {
    header: '원수량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('qty', {
    header: '현수량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('osq', {
    header: '상계량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('sentq', {
    header: '전송량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('smq', {
    header: 'm전송',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('acptq', {
    header: '접수량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('cnclq', {
    header: '취소량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('rejq', {
    header: '거부량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fq', {
    header: '체결량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('wq', {
    header: '미체결',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('q2o', {
    header: '남은량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fillPrc', {
    header: '체결단가',
    size: 70,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('errMsgDisp', {
    header: '에러',
    size: 100,
    meta: {
      styler: () => ({ color: 'red' }),
    },
  }),
  columnHelper.accessor('info', { header: '참고' }),
].map((v) => v as ColumnDef<OrdVh, unknown>);

export default function OrdVhTab({ ovhs }: Props) {
  return <DataGrid data={ovhs} columns={columns} />;
}
