import { z } from 'zod';
import { Curncy, curncySchema, PosBookTy, posBookTySchema } from './Common';
import {
  zodTimeNullable,
  zodNumber,
  zodPosiNumber,
  zodRangeNullable,
  zodDate,
  zodDateNullable,
} from './ZodType';

export enum StkTy {
  Comp = 'Comp',
  Pfrd = 'Pfrd',
  ETF = 'ETF',
  ETN = 'ETN',
  SPAC = 'SPAC',
  REITs = 'REITs',
  MutuF = 'MutuF',
  Ship = 'Ship',
  Infra = 'Infra',
  DR = 'DR',
  Frgn = 'Frgn',
  Stapled = 'Stapled',
}
export const stkTySchema = z.nativeEnum(StkTy);

export enum RightTy {
  Vanilla = 'Vanilla',
  CB = 'CB',
  BW = 'BW',
  EB = 'EB',
  RCPS = 'RCPS',
  CPS = 'CPS',
  BWD = 'BWD',
}
export const rightTySchema = z.nativeEnum(RightTy);

export enum AstTy {
  Unknown = 'Unknown',
  KStk = 'KStk',
  KBond = 'KBond',
  KFut = 'KFut',
  K2F = 'K2F',
  KQF = 'KQF',
  KNDF = 'KNDF',
  KSF = 'KSF',
  KBF = 'KBF',
  KUF = 'KUF',
  KStkShort = 'KStkShort',
  KStkSwap = 'KStkSwap',
  UStk = 'UStk',
  UBond = 'UBond',
  UFut = 'UFut',
  UFund = 'UFund',
}
export const astTySchema = z.nativeEnum(AstTy);

export const getAstTyName = (ty: AstTy) => {
  switch (ty) {
    case AstTy.KStk:
      return '국내주식';
    case AstTy.KFut:
      return '국내선물';
    case AstTy.UStk:
      return '해외주식';
    case AstTy.UFut:
      return '해외선물';
    default:
      return ty.toString();
  }
};

export enum Exchange {
  Unlisted = 'Unlisted',
  KSE = 'KSE',
  KSQ = 'KSQ',
  KNX = 'KNX',
  KRX = 'KRX',
  XNYS = 'XNYS',
  XNAS = 'XNAS',
  XLON = 'XLON',
  XTKS = 'XTKS',
  XJAS = 'XJAS',
  XSHG = 'XSHG',
  XSHE = 'XSHE',
  XHKG = 'XHKG',
  XSES = 'XSES',
  XTAI = 'XTAI',
  ARCX = 'ARCX',
  XNGS = 'XNGS',
  XNMS = 'XNMS',
  XASE = 'XASE',
  XETR = 'XETR',
  XVTX = 'XVTX',
  XPAR = 'XPAR',
  XMAD = 'XMAD',
  XAMS = 'XAMS',
  XBRU = 'XBRU',
  XNZE = 'XNZE',
  XASX = 'XASX',
  XLIS = 'XLIS',
  XHEL = 'XHEL',
  XSWX = 'XSWX',
  XTSE = 'XTSE',
  XMEX = 'XMEX',
  XDUB = 'XDUB',
  XSTO = 'XSTO',
  XWBO = 'XWBO',
  XCSE = 'XCSE',
  MTAA = 'MTAA',
  XOSL = 'XOSL',
  XTAE = 'XTAE',
  ROCO = 'ROCO',
  XNCM = 'XNCM',
  OOTC = 'OOTC',
  XSSC = 'XSSC',
  XSEC = 'XSEC',
  KOTC = 'KOTC',
  BATS = 'BATS',
  EUREX = 'EUREX',
  CME = 'CME',
  XJSE = 'XJSE',
}
export const exchangeSchema = z.nativeEnum(Exchange);

export enum Sz {
  NA = 'NA',
  L = 'L',
  M = 'M',
  S = 'S',
}

export enum RstrTy {
  None = 'None',
  Right = 'Right',
  Bonus = 'Bonus',
  Lock = 'Lock',
  Mez = 'Mez',
  Div = 'Div',
  Split = 'Split',
  Tras = 'Tras',
  Promise = 'Promise',
}
export const rstrTySchema = z.nativeEnum(RstrTy);

export enum VentNewTy {
  None = 'None',
  VentNew = 'VentNew',
  OldVentNewCbBw = 'OldVentNewCbBw',
}
export const ventNewTySchema = z.nativeEnum(VentNewTy);

export enum VentCate {
  Etc = 'Etc',
  Vent35 = 'Vent35',
  VentNew = 'VentNew',
}
export const ventCateSchema = z.nativeEnum(VentCate);

export enum ProdCusTy {
  InvTrust = 'InvTrust',
  PEF = 'PEF',
  Assoc = 'Assoc',
  CP = 'CP',
  EtcMkt = 'EtcMkt',
  FixedIncome = 'FixedIncome',
}

export enum CusTy {
  InvTrust = 'InvTrust',
  PEF = 'PEF',
  Assoc = 'Assoc',
  CP = 'CP',
  EtcMkt = 'EtcMkt',
  FixedIncome = 'FixedIncome',
}
export const cusTySchema = z.nativeEnum(CusTy);

export interface Product {
  Id: string;
  nm: string;
  nm2: string;
  krCd: string | null;
  ltd: string | null;
  under: string | null;
  prcCd: string | null;
  noMTM: boolean;
  stkTy: StkTy | null;
  rstrTy: RstrTy | null;
  ventNewTy: VentNewTy | null;
  rightTy: RightTy | null;
  cusTy: ProdCusTy | null;
  tics: string | null;
  wics: string | null;
  gics: string | null;
  gicsT: string | null;
  exch: Exchange | null;
  IsListed: boolean;
  // crncy: Curncy;
  crncy: string;
  quoteUnit: string | null;
  fm: number | null;
  addedt: string;
  listd: string | null;
  bbTkr: string | null;
  mafs: string | null;
  isin: string | null;
  lev: number | null;
  makeAdj: boolean | null;
  astTy: AstTy;
  invTy: string;
  bpv: number;
  trdbl: boolean | null;
  trdUnit: number | null;
  vent_d0: string | null;
  vent_d1: string | null;
  adjCheckD: string | null;
  src: string | null;
  exchSym: string | null;
}

export const productSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  krCd: z.string().nullish(),
  ltd: zodDateNullable,
  under: z.string().nullish(),
  prcCd: z.string().nullish(),
  noMTM: z.coerce.boolean(),
  stkTy: stkTySchema.nullish(),
  rstrTy: rstrTySchema.nullish(),
  ventNewTy: ventNewTySchema.nullish(),
  rightTy: rightTySchema.nullish(),
  cusTy: cusTySchema.nullish(),
  tics: z.string().nullish(),
  wics: z.string().nullish(),
  gics: z.string().nullish(),
  gicsT: z.string().nullish(),
  exch: exchangeSchema.nullish(),
  crncy: curncySchema,
  quoteUnit: z.string().nullish(),
  fm: zodRangeNullable(1, 12),
  addedt: z.string().nullish().readonly(),
  listd: zodDateNullable,
  bbTkr: z.string().nullish(),
  mafs: z.string().nullish(),
  isin: z.string().nullish(),
  lev: zodNumber(true),
  makeAdj: z.coerce.boolean().nullish(),
  bpv: zodPosiNumber(false),
  trdbl: z.coerce.boolean().nullish(),
  trdUnit: zodPosiNumber(true),
  vent_d0: zodDateNullable,
  vent_d1: zodDateNullable,
  adjCheckD: zodDateNullable,
  src: z.string().nullish().readonly(),
  exchSym: z.string().nullish(),
});

export interface AstMrgnRto {
  Id: number;
  ast: AstTy;
  rto: number;
  astNm: string;
}

export const astMrgnRtoSchema = z.object({
  ast: astTySchema,
  rto: zodPosiNumber(false),
  astNm: z.string().readonly(),
});

export interface BpvHist {
  Id: number;
  d: string;
  prodId: string;
  bpv: number;
  prodNm: string | null;
}

export const bpvHistSchema = z.object({
  d: zodDate,
  prodId: z.string(),
  bpv: zodPosiNumber(false),
  prodNm: z.string().nullish().readonly(),
});

export interface FutInfo {
  Id: string;
  nm: string | null;
  under: string | null;
  tics: string | null;
  crncy: Curncy;
  bpv: number;
  tickSz: number | null;
  bbId: string | null;
  bbMkt: string | null;
  nd_t0: string | null;
  nd_t1: string | null;
  KIdp: number | null;
  p0: number | null;
  p1: number | null;
  skipKisp: boolean | null;
}

export const futInfoSchema = z.object({
  Id: z.string(),
  nm: z.string().nullish(),
  under: z.string(),
  tics: z.string().nullish(),
  crncy: curncySchema,
  bpv: zodPosiNumber(false),
  tickSz: zodPosiNumber(true),
  bbId: z.string(),
  bbMkt: z.string(),
  nd_t0: zodTimeNullable,
  nd_t1: zodTimeNullable,
  KIdp: zodRangeNullable(1, 5), // 숫자는 필요없지만
  p0: zodPosiNumber(true),
  p1: zodPosiNumber(true),
  skipKisp: z.boolean().nullish(),
});

export enum ProddailySources {
  None = 'None',
  Manual = 'Manual',
  FromYstd = 'FromYstd',
  KrxRt = 'KrxRt',
  KrxBatch = 'KrxBatch',
  KrxLog = 'KrxLog',
  QuantiWise = 'QuantiWise',
  QwFactSet = 'QwFactSet',
  FngFtp = 'FngFtp',
  BbRt = 'BbRt',
  BbYstd = 'BbYstd',
  BbWeekly = 'BbWeekly',
  BbHist = 'BbHist',
  IndiRt = 'IndiRt',
  IndiBatch = 'IndiBatch',
  IexRt = 'IexRt',
  IexClose = 'IexClose',
  MAFS = 'MAFS',
  MznCalcd = 'MznCalcd',
  KisExpRt = 'KisExpRt',
  KisExpStl = 'KisExpStl',
  YahooRt = 'YahooRt',
  YahooHist = 'YahooHist',
  NaverRt = 'NaverRt',
  NaverHist = 'NaverHist',
  NasdaqFtp = 'NasdaqFtp',
  SnpFtp = 'SnpFtp',
  AdjF = 'AdjF',
  SolFtp = 'SolFtp',
  ETFBmKrw = 'ETFBmKrw',
}
export const proddailySourcesSchema = z.nativeEnum(ProddailySources);

export interface ProdDaily {
  Id: number;
  prodNm: string | null;
  d: string;
  prodId: string;
  b: number;
  o: number;
  h: number;
  l: number;
  c: number;
  v: number;
  ta: number;
  sh: number;
  mc: number | null;
  stl: number | null;
  updt: string | null;
  src: ProddailySources | null;
  adjf: number | null;
  bidp: number | null;
  askp: number | null;
}

export const prodDailySchema = z.object({
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  b: zodPosiNumber(false),
  o: zodPosiNumber(false),
  h: zodPosiNumber(false),
  l: zodPosiNumber(false),
  c: zodPosiNumber(false),
  v: zodPosiNumber(false),
  ta: zodPosiNumber(false),
  sh: zodPosiNumber(false),
  mc: zodPosiNumber(true),
  stl: zodPosiNumber(true),
  updt: z.string().nullish().readonly(),
  src: proddailySourcesSchema.nullish().readonly(),
});

export interface ProdBatch {
  Id: number;
  d: string;
  prodId: string;
  b: number;
  isbasepChgd: boolean | null;
  openBaseP: number | null;
  pubp: number | null;
  evalp: number | null;
  facep: number | null;
  excp: number | null;
  ubp: number | null;
  lbp: number | null;
  thBaseP: number | null;
  exDiv: number | null;
  sh: number | null;
  updt: string | null;
  prodNm: string | null;
}

export const prodBatchSchema = z.object({
  d: z.string(),
  prodId: z.string(),
  b: z.coerce.number(),
  isbasepChgd: z.coerce.boolean(),
  openBaseP: z.coerce.number().nullish(),
  pubp: zodPosiNumber(true),
  evalp: zodPosiNumber(true),
  facep: zodPosiNumber(true),
  excp: zodPosiNumber(true),
  ubp: zodPosiNumber(true),
  lbp: zodPosiNumber(true),
  thBaseP: zodPosiNumber(true),
  exDiv: zodPosiNumber(true),
  sh: zodPosiNumber(true),
  updt: z.string().nullish().readonly(),
  prodNm: z.string().nullish().readonly(),
});

export enum ProdActionTy {
  VI = 'VI',
  OverHeat = 'OverHeat',
  Illiquid = 'Illiquid',
  AbnormalJump = 'AbnormalJump',
  OverSellShort = 'OverSellShort',
  Supervised = 'Supervised',
  Suspension = 'Suspension',
  OpenBaseP = 'OpenBaseP',
  SinglePrice = 'SinglePrice',
  InsuffSh = 'InsuffSh',
  Liquidation = 'Liquidation',
  CautionAtt = 'CautionAtt',
  NoCollateral = 'NoCollateral',
  Caution = 'Caution',
  Warn = 'Warn',
  Danger = 'Danger',
  NotSave = 'NotSave',
}
export const prodActionTySchema = z.nativeEnum(ProdActionTy);

export interface ProdAction {
  Id: number;
  d: string;
  d0: string | null;
  d1: string | null;
  t0: string | null;
  t1: string | null;
  actTy: ProdActionTy;
  prodId: string;
  prodNm: string | null;
}

export const prodActionSchema = z.object({
  d: zodDate,
  d0: zodDateNullable,
  d1: zodDateNullable,
  t0: zodTimeNullable,
  t1: zodTimeNullable,
  actTy: prodActionTySchema,
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
});

export interface ProdCashDiv {
  Id: number;
  prodId: string;
  book: PosBookTy;
  ind: string;
  bookd: string;
  div: number;
  payd: string | null;
  prodNm: string | null;
}

export const prodCashDivSchema = z.object({
  prodId: z.string(),
  book: posBookTySchema,
  ind: zodDate,
  bookd: zodDate,
  div: zodNumber(false),
  payd: zodDateNullable,
  prodNm: z.string().nullish().readonly(),
});

export enum ProdUserDailyType {
  None = 'None',
  SettlePrc = 'SettlePrc',
  Stat = 'Stat',
  SwapFx = 'SwapFx',
  MktWeekly = 'MktWeekly',
  Singa = 'Singa',
  AiPrc = 'AiPrc',
  AiShares = 'AiShares',
  UserNav = 'UserNav',
}
export const prodUserDailyTypeSchema = z.nativeEnum(ProdUserDailyType);

export interface ProdUserDaily {
  Id: number;
  d: string;
  ty: ProdUserDailyType;
  prodId: string;
  val: number;
  userId: string;
  keep: boolean;
  prodNm: string | null;
  userNm: string | null;
}

export const prodUserDailySchema = z.object({
  d: z.string().nullish(),
  ty: prodUserDailyTypeSchema.nullish(),
  prodId: z.string(),
  val: zodNumber(false),
  userId: z.string().nullish().readonly(),
  keep: z.coerce.boolean().nullish(),
  prodNm: z.string().nullish().readonly(),
  userNm: z.string().nullish().readonly(),
});

export interface ProdRiskCfg {
  Id: number;
  d: string;
  prodId: string;
  maxDmaRto: number;
  maxBrkRto: number;
  maxCumRto: number;
  volCumRto: number;
  prodNm?: string;
}

export const prodRiskCfgSchema = z.object({
  prodId: z.string(),
  maxDmaRto: zodPosiNumber(false),
  maxBrkRto: zodPosiNumber(false),
  maxCumRto: zodPosiNumber(false),
  volCumRto: zodPosiNumber(false),
});

// Query 해외주식배당정보
export interface ProdCashDivInfo {
  Id: number;
  prodId: string;
  annd: string;
  exd: string;
  recd: string;
  payd: string;
  amt: number;
  freq?: string | null;
  ty?: string | null;
  updt: string;
  userId: string;
  nm?: string | null;
  bbTkr?: string | null;
}
