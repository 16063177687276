import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import { z } from 'zod';
import { ColumnType } from 'tmslib/src/table/DataGrid';
import InputGrid from 'tmslib/src/table/InputGrid';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { LS, EX, lsSchema } from '../../../Tms/Common';
import { FuncCall, callAxios, isKAst, isUAst } from '../../../tmsutil';
import { useOrderDispatch, useOrderState, HoveredBtn } from './OrderContext';
import { useAuthState } from '../../Auth/AuthContext';
import { updateOrdSts } from './ordutil';
import { OrdSt } from '../../../Tms/Ord';
import { DftBtnStyle } from '../../../AppTypes';
import { VhGrpTy } from '../../../Tms/Tms';
import { zodNumber, zodPosiInt, zodPosiNumber, zodRangeNullable } from '../../../Tms/ZodType';

interface Props {
  tgtCall: FuncCall;
  isOrdProc: boolean;
  osts: OrdSt[];
  vhId?: string | null;
}

export type BatchOrd = {
  Id: number;
  cd: string;
  ls?: LS;
  ch?: number;
  exAll?: boolean;
  ord?: number;
  vhIn?: string;
  qty?: number;
  prc?: number;
};

const batchOrdSchema = z.object({
  cd: z.string(),
  ls: lsSchema.nullish(),
  ch: zodRangeNullable(0, 1),
  exAll: z.coerce.boolean().nullish(),
  ord: zodPosiInt(true),
  vhIn: z.string().nullish(),
  qty: zodNumber(true),
  prc: zodPosiNumber(true),
});

const columnHelper = createColumnHelper<BatchOrd>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columnDic: { [key: string]: ColumnDef<BatchOrd, any> } = {
  cd: columnHelper.accessor('cd', { header: '종목코드', size: 80 }),
  ls: columnHelper.accessor('ls', {
    header: 'LS',
    size: 50,
    meta: {
      type: ColumnType.Combobox,
      valTxts: [
        { val: '', txt: ' ' },
        { val: LS.L, txt: 'L' },
        { val: LS.S, txt: 'S' },
      ],
    },
  }),
  ch: columnHelper.accessor('ch', { header: 'Wei', size: 80 }),
  exAll: columnHelper.accessor('exAll', {
    header: '전량청산',
    size: 60,
    meta: {
      type: ColumnType.Checkbox,
    },
  }),
  ord: columnHelper.accessor('ord', { header: '주문번호', size: 70 }),
  vhIn: columnHelper.accessor('vhIn', { header: '펀드지정', size: 80 }),
  qty: columnHelper.accessor('qty', { header: '수량', size: 80 }),
  prc: columnHelper.accessor('prc', { header: '제한가', size: 80 }),
};
const getColumns = (isOrdProc: boolean, isAiPe: boolean) => {
  const cols = ifesleExpr(
    [isOrdProc, ['cd', 'ls', 'ch', 'exAll', 'ord', 'vhIn', 'qty']],
    [isAiPe, ['cd', 'vhIn', 'qty', 'exAll', 'prc']],
  ) ?? ['cd', 'ch', 'exAll', 'prc'];
  return cols.map((v) => columnDic[v] as ColumnDef<BatchOrd, unknown>);
};

export default function BatchOrdTab({ tgtCall, isOrdProc, osts, vhId }: Props) {
  const [ords, setOrds] = useState<BatchOrd[]>([]);
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const { inp, updFld, updVal, keepSwap } = useOrderState();

  const addOrdStBatch = () => {
    if (!ords.length) {
      m.alert('배치 주문 없음');
      return;
    }
    if (user?.isAdvisor) {
      if (ords.some((v) => isKAst(v.cd)) && ords.some((v) => isUAst(v.cd))) {
        m.alert('일괄 주문은 국내/해외 동시 불가');
        return;
      }
    }
    const par = {
      inp: {
        ...inp,
        prodId: 'WBATCHTMP',
        vhIn: inp.tgt === VhGrpTy.ETF && !inp.vhIn ? vhId ?? null : inp.vhIn,
        isOrdProc,
      },
      ords,
    };
    tgtCall('AddOrdStBatch', par, {
      title: '일괄 주문 입력',
      additionalOnSuccess: () => m.alert('OK'),
    });
  };

  const addOrdStPrdBatch = () => {
    if (!ords.length) {
      m.alert('배치 주문 없음');
      return;
    }
    if (info?.isJunior(inp.stId)) {
      m.alert('주니어 기간 주문 금지');
      return;
    }
    if (inp.prdD1 == null || inp.prdD1 <= inp.d) {
      m.alert('기간주문 종료일 확인');
      return;
    }
    if (isOrdProc) {
      m.alert('타겟에서만 호출 가능');
      return;
    }
    const par = {
      inp: {
        ...inp,
        prodId: 'WBATCHTMP',
      },
      prdD1: inp.prdD1,
      ords,
    };
    tgtCall('AddOrdStPrdBatch', par, {
      title: '수량/기간 주문',
    });
  };

  const modOrdStBatch = () => {
    if (!ords.length) {
      m.alert('배치 주문 없음');
      return;
    }
    if (!isOrdProc) {
      m.alert('주문지시서만 호출 가능');
      return;
    }
    const { ord } = inp;
    if (ord == null) {
      m.alert('주문번호 미입력');
      return;
    }
    const cds = new Set(ords.map((v) => v.cd));
    const sltdOsts = osts.filter((v) => v.ord === ord && cds.has(v.prodId));
    updateOrdSts(sltdOsts, updFld, updVal, true, tgtCall, false, m);
  };

  const keepReqBatch = () => {
    if (!ords.length) {
      m.alert('배치 입력 없음');
      return;
    }
    if (_.uniq(ords.map((v) => v.cd)).length > 1) {
      m.alert('복수 종목 처리 불가');
      return;
    }
    if (ords.some((v) => v.ch != null)) {
      m.alert('킵 배치 테이블 비중 입력불가');
      return;
    }
    if (ords.some((v) => v.vhIn == null)) {
      m.alert('킵 펀드 없음');
      return;
    }
    if (ords.some((v) => v.qty == null || v.qty === 0)) {
      m.alert('킵 수량 없음');
      return;
    }
    if (_.uniq(ords.map((v) => Math.sign(v.qty ?? 0))).length > 1) {
      m.alert('킵 요청/청산 한방향만 가능');
      return;
    }
    const ex = (ords[0].qty ?? 0) > 0 ? EX.E : EX.X;
    const qtys = ords.map((v) => ({
      vh: v.vhIn,
      qty: Math.abs(v.qty ?? 0),
      exAll: v.exAll ?? false,
    }));
    const params = {
      d: inp.d,
      tgt: inp.tgt,
      st: inp.stId,
      cd: ords[0].cd,
      ex,
      qtys,
      swap: keepSwap,
    };
    callAxios({
      m,
      logger,
      url: '/Back/OrdProc/GenKeepReqBatch',
      params,
      onSuccess: () => m.alert('OK'),
    });
  };

  const dispatch = useOrderDispatch();
  const setBtnHover = (hover: HoveredBtn) => dispatch({ type: 'hover', hover });

  const columns = useMemo(
    () => getColumns(isOrdProc, info?.isAiPe(inp.stId) ?? false),
    [isOrdProc, info?.isAiPe(inp.stId) ?? false],
  );

  return (
    <div className="d-flex flex-row children-me-2">
      <InputGrid
        columns={columns}
        args={{ onSetData: setOrds, schema: batchOrdSchema }}
      />

      <button
        type="button"
        className={DftBtnStyle}
        style={{ height: '28px' }}
        onClick={() => addOrdStBatch()}
        onFocus={() => setBtnHover(HoveredBtn.Batch)}
        onBlur={() => setBtnHover(HoveredBtn.None)}
        onMouseOver={() => setBtnHover(HoveredBtn.Batch)}
        onMouseOut={() => setBtnHover(HoveredBtn.None)}
      >
        일괄 주문 입력
      </button>
      {info?.isAiPe(inp.stId) && (
        <button
          type="button"
          className={DftBtnStyle}
          style={{ height: '28px' }}
          onClick={() => addOrdStPrdBatch()}
          onFocus={() => setBtnHover(HoveredBtn.PrdBatch)}
          onBlur={() => setBtnHover(HoveredBtn.None)}
          onMouseOver={() => setBtnHover(HoveredBtn.PrdBatch)}
          onMouseOut={() => setBtnHover(HoveredBtn.None)}
        >
          수량/기간 주문
        </button>
      )}
      {isOrdProc && (
        <button
          type="button"
          className={DftBtnStyle}
          style={{ height: '28px' }}
          onClick={() => modOrdStBatch()}
          onFocus={() => setBtnHover(HoveredBtn.ModBatch)}
          onBlur={() => setBtnHover(HoveredBtn.None)}
          onMouseOver={() => setBtnHover(HoveredBtn.ModBatch)}
          onMouseOut={() => setBtnHover(HoveredBtn.None)}
        >
          복수종목 일괄적용
        </button>
      )}
      {isOrdProc && (
        <button
          type="button"
          className={DftBtnStyle}
          style={{ height: '28px' }}
          onClick={() => keepReqBatch()}
          onFocus={() => setBtnHover(HoveredBtn.KeepReq)}
          onBlur={() => setBtnHover(HoveredBtn.None)}
          onMouseOver={() => setBtnHover(HoveredBtn.KeepReq)}
          onMouseOut={() => setBtnHover(HoveredBtn.None)}
        >
          킵 배치 생성
        </button>
      )}
    </div>
  );
}
