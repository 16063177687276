import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { TableMeta } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import Editor from 'tmslib/src/ui/Editor';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import {
  callAxios,
  ValidData,
  downloadFile,
  callAxiosGet,
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  checkItems,
  getSplitFileNm,
} from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import {
  TargetType,
  CmplReport,
  CmplReportItem,
  CmplReportItemFile,
  WorkType,
} from '../../Tms/MO';
import { permittedExtensions } from '../../Tms/Tms';
import FileUploadDialog from '../../shared/FileUploadDialog';

const page = '/Middle/CheckReport';
const btnClass = DftBtnStyleMx;
const linkClass = 'btn btn-link';
type T = { Id: number }; // 임의 IId 타입
type PageFunc =
  | 'GetTgts'
  | 'GenCmplReport'
  | 'CopyLastMonthReportItems'
  | 'SaveRptInfo'
  | 'DelFile'
  | 'CmplReportItem'
  | 'ReportItem'
  | 'SaveReportItemDetail';

interface rptInfo {
  Id: number;
  ty: string;
  nm: string | null;
  t: string | null;
  note: string | null;
}

// 전체 부서
// prettier-ignore
const columnsAll = ['Id', 'code_', 'cate0Note', 'cateNote', 'itemNote', 'period', 'deadline', 'reportTo', 'regName','work', 'note', 'attached', 't', 'tgt'];
// prettier-ignore
const headersAll =  ['번호', '코드', '분류', '항목', '상세', '주기', '기한', '보고처', '관련 법규','처리', '비고', '첨부', '저장일시', '부서'];

const cmplReportItemDft: UrlGridArgs<CmplReportItem> = {
  url: `${page}/CmplReportItem`, // getCmplReport()
  title: '월간 점검 항목',
  // prettier-ignore
  columns: ['code_', 'cate0Note', 'cateNote', 'itemNote', 'period', 'deadline', 'reportTo', 'regName', 'work', 'note', 'attached', 't'],
  // prettier-ignore
  headers: ['코드', '분류', '항목', '상세', '주기', '기한', '보고처', '관련 법규', '처리', '비고', '첨부', '저장일시'],
  // prettier-ignore
  widths: {code_: 80,	cate0Note: 80,	cateNote: 150,	itemNote: 500,	period: 50,	deadline: 100,	reportTo: 100,	regName: 150,	work: 120,	note: 200,	attached: 40,	},
  editable: false,
  height: 400,
  checkboxes: ['attached'],
  meta: {
    noVirtualize: true,
    dftColWidth: 80,
  },
  dftStyler: (v, c) => {
    const css:CSSProperties = { textAlign: 'left', whiteSpace: 'pre' };
    if (c === 'work' || c === 'note')
      return { ...css, backgroundColor: '#ffa' };
    return css;
  },
};

type Flds = 'work' | 'note';
let changedFlds: Flds[] = [];

export default function CheckReport() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [tgts, setTgts] = useState<TargetType[]>([]);
  const [tgt, setTgt] = useState<TargetType | null>(null);
  const [rpt, setRpt] = useState<CmplReport | null>(null);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [itemId, setItemId] = useState<number | null>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [work, setWork] = useState<WorkType | null>(null);
  const [note, setNote] = useState('');
  const [regDetail, setRegDetail] = useState('');
  const [files, setFiles] = useState<CmplReportItemFile[]>([]);
  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
  const [fileUploadUrl, setFileUploadUrl] = useState<string>('');
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const dataCnt = useRef(0);

  const clearArgs = () => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
    setShowDetail(false);
  };

  const setDetail = (r: CmplReportItem) => {
    setShowDetail(true);
    setItemId(r.Id);
    setWork(r.work ?? WorkType.해당사항없음); // 처리
    setNote(r.note ?? ''); // 비고
    setRegDetail(r.regDetail ?? '');
    setFiles(r.files);
  };

  const fileUpload = () => {
    setFileUploadVisible(true);
    setFileUploadTitle('파일추가');
    setFileUploadUrl(`${page}/UploadFileUpload`);
  };

  const getFile = (funcNm: string, fnm: string | null) => {
    downloadFile({
      m,
      logger,
      url: `${page}/Download${funcNm}`,
      params: { fnm },
    });
  };

  let cmplReportItem: UrlGridArgs<CmplReportItem>;
  const cmplReportItemMeta: TableMeta<CmplReportItem> = {
    ...cmplReportItemDft.meta,
    onRowClick: (r) => {
      setDetail(r.original);
    },
  };

  const onDataChange = (data: CmplReportItem[], res: AxiosResponse) => {
    // 그리드 데이터 삭제시 세부항목 reset
    if (dataCnt.current !== 0 && data.length < dataCnt.current)
      setShowDetail(false);

    dataCnt.current = data.length; // 현재값 업데이트

    if (res.data.extras.length > 1 || res.data.extras.length === 0) {
      // 전체부서 10건
      setRpt(null);
    } else {
      setRpt(res.data.extras[0] as CmplReport);
    }
  };

  if (tgt === '전체부서') {
    cmplReportItem = {
      ...cmplReportItemDft,
      columns: columnsAll as (keyof CmplReportItem)[],
      headers: headersAll,
      meta: cmplReportItemMeta,
      onDataChange,
    };
  } else {
    cmplReportItem = {
      ...cmplReportItemDft,
      meta: cmplReportItemMeta,
      onDataChange,
    };
  }

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;

    return (
      <button
        type="button"
        className={btnClass}
        onClick={() => {
          query(a);
        }}
      >
        {title}
      </button>
    );
  };

  const handleAxiosResult = (
    data: ValidData,
    res: AxiosResponse,
    cfmmsg?: string | null,
    callbackGridArgs?: unknown,
    funcNm?: PageFunc,
  ) => {
    if (res.data.warnings?.length) {
      setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
    }
    if (funcNm === 'SaveRptInfo') {
      setRefreshNeeded((p) => p + 1);
    }
    if (callbackGridArgs) {
      query(callbackGridArgs);
    }
  };

  const call = (
    funcNm: PageFunc,
    params: unknown,
    args?: {
      callbackGridArgs?: unknown;
      confirmMsg?: string;
    },
  ) =>
    callAxios({
      m,
      logger,
      url: `${page}/${funcNm}`,
      params,
      confirmMsg: args?.confirmMsg,
      onSuccess: (data, res) =>
        handleAxiosResult(
          data,
          res,
          args?.confirmMsg,
          args?.callbackGridArgs,
          funcNm,
        ),
    });

  const filesTemplate = files.map((v) => (
    <span key={v.fnm}>
      {getSplitFileNm(v.fnm)}
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('FileDownload', v.fnm)}
      >
        파일
      </button>
      <button
        type="button"
        className={btnClass}
        onClick={() =>
          call(
            'DelFile',
            { id: v.Id },
            {
              confirmMsg: '파일삭제',
              callbackGridArgs: cmplReportItem,
            },
          )
        }
      >
        삭제
      </button>
    </span>
  ));

  const setChangedFldsCheck = (fld: Flds) => {
    if (!changedFlds.contains(fld)) {
      changedFlds.push(fld);
    }
  };

  const saveReportItemDetailButton = () => (
    <button
      type="button"
      className="btn btn-secondary btn-sm position-relative"
      onClick={() => {
        changedFlds.forEach((v) => {
          let val;
          if (v === 'work') val = work;
          if (v === 'note') val = note;
          call('SaveReportItemDetail', { id: itemId, fld: v, val, tgt });
        });
        if (changedFlds.length > 0) {
          query(cmplReportItem);
          changedFlds = [];
        }
      }}
    >
      전체 저장
    </button>
  );

  const detailTemplate = () => (
    <>
      <hr className="narrow light" />
      <div id="detailContainer" className="row" style={{ width: 1200 }}>
        <div className="col">
          <hr className="narrow light" />
          <div>{filesTemplate}</div>
          <hr className="narrow light" />
          <button type="button" onClick={() => fileUpload()}>
            파일추가
          </button>
          <hr className="narrow light" />
          <b>[처리]</b>
          <div>
            <select
              name="workSelect"
              value={work as WorkType}
              onChange={(e) => {
                setChangedFldsCheck('work');
                setWork(e.target.value as WorkType);
              }}
            >
              {Object.values(WorkType).map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          <hr className="narrow light" />
          <b>[비고]</b>
          <div>
            <textarea
              value={note}
              onChange={(e) => {
                setChangedFldsCheck('note');
                setNote(e.target.value);
              }}
              style={{ height: 80, width: 500 }}
            />
          </div>
          {saveReportItemDetailButton()}
          <hr className="narrow light" />
          <b>[법규 상세]</b>
          <div>
            <Editor value={regDetail} style={{ height: 800 }} readOnly />
          </div>
        </div>
      </div>
    </>
  );

  const callGet = (funcNm: PageFunc, params: unknown) =>
    callAxiosGet({
      m,
      logger,
      url: `${page}/${funcNm}`,
      params,
      onSuccess: (data) => {
        if (data) {
          if (funcNm === 'GetTgts') {
            setTgts(data);
            setTgt(data[0]); // 초기값
          }
          if (funcNm === 'ReportItem' && itemId === data.Id) {
            setDetail(data);
          }
        }
      },
    });

  useEffect(() => {
    callGet('GetTgts', {});
  }, []);

  useEffect(() => {
    if (tgt == null) return;
    setItemId(null);
    query(cmplReportItem);
  }, [d, tgt]);

  useEffect(() => {
    if (itemId != null) {
      callGet('ReportItem', { id: itemId });
    }
  }, [refreshNeeded]);

  const rptBody: rptInfo[] = [
    {
      Id: 0,
      ty: '작성자',
      nm: rpt?.writerNm as string,
      t: rpt?.writerT as string,
      note: rpt?.writerNote as string,
    },
    {
      Id: 1,
      ty: '점검자',
      nm: rpt?.checkerNm as string,
      t: rpt?.checkerT as string,
      note: rpt?.checkerNote as string,
    },
    {
      Id: 2,
      ty: '승인자',
      nm: rpt?.cnfrmNm as string,
      t: rpt?.cnfrmT as string,
      note: rpt?.cnfrmNote as string,
    },
  ];

  const saveRptInfo = async (items: rptInfo[], ty: string) => {
    if (!checkItems(items, m, true)) return;
    const title = `${items[0].ty} ${ty} 저장?`;
    let fld = ifesleExpr(
      [items[0].ty === '작성자', 'writer'],
      [items[0].ty === '점검자', 'checker'],
      [items[0].ty === '승인자', 'cnfrm'],
    );
    fld += ty === '담당자' ? 'T' : 'Note'; // ex) writerT update
    let rptNote = null;
    if (ty === '비고') {
      rptNote = await m.prompt('비고');
    }
    call(
      'SaveRptInfo',
      { id: rpt?.Id, fld, val: rptNote },
      {
        confirmMsg: title,
        callbackGridArgs: cmplReportItem,
      },
    );
  };

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <select
          name="tgtSelect"
          value={tgt ?? tgts[0]}
          onChange={(e) => setTgt(e.target.value as TargetType)}
        >
          {tgts.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        {getButton(cmplReportItem)}
        <br />
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            call(
              'GenCmplReport',
              { d },
              {
                confirmMsg: '리포트 양식 생성',
                callbackGridArgs: cmplReportItem,
              },
            )
          }
        >
          (리포트 양식 갱신)
        </button>
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            call(
              'CopyLastMonthReportItems',
              { d, tgt },
              {
                confirmMsg: '전월 데이터 불러오기 (작성된 내용은 삭제)',
                callbackGridArgs: cmplReportItem,
              },
            )
          }
        >
          (전월불러오기)
        </button>
      </div>
      <div className="col-8">
        {rpt && (
          <SimpleGrid
            data={rptBody}
            columns={['ty', 'nm', 't', 'note']}
            headers={['대상', '이름', '숭인(작성)일시', '비고']}
            args={{
              widths: { t: 150, note: 200 },
              meta: {
                dftColWidth: 100,
                contextMenus: [
                  {
                    label: '작성자',
                    callback: (items: rptInfo[]) =>
                      saveRptInfo(items, '담당자'),
                  },
                  {
                    label: '비고',
                    callback: (items: rptInfo[]) => saveRptInfo(items, '비고'),
                  },
                ],
              },
            }}
          />
        )}
      </div>
      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <div className="col-3">
        <UrlGrid
          args={gridArgs}
          params={{ d, tgt }}
          refreshNeeded={refreshNeeded}
        />
      </div>
      {showDetail && detailTemplate()}
      <FileUploadDialog
        headerTitle={fileUploadTitle}
        fileExtensionLimit={permittedExtensions}
        fileSizeLimit={5}
        params={{ id: itemId }}
        url={fileUploadUrl}
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        setRefreshNeeded={setRefreshNeeded}
      />
    </div>
  );
}
