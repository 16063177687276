import React, { useEffect, useRef } from 'react';
import { LogWithId } from '../../../Tms/Ord';

export default function OrdLogTab({
  ordLastLogs,
  amtLastLogs,
}: {
  ordLastLogs: LogWithId[];
  amtLastLogs: LogWithId[];
}) {
  const domLastLogs = useRef<HTMLDivElement>(null);
  const domLastAmts = useRef<HTMLDivElement>(null);

  /* const [ordLastLogs_, setOrdLogsGen] = useState<LogWithId[]>([]);
    const [amtLastLogs_, setamtLastLogs] = useState<LogWithId[]>([]);

   useEffect(()=> {
        setOrdLogsGen(ordLastLogs)
    }, [ordLastLogs])

    useEffect(()=> {
        setamtLastLogs(amtLastLogs)
    }, [amtLastLogs])
    useEffect(() => {
        if(ordLastLogs_.length !== 0 ){
            if(domLastLogs !== null && domLastLogs.current !== null ){
                domLastLogs.current.scrollTo(0, (ordLastLogs_?.length+1)  * (25) )
            }
        }
        if(amtLastLogs_.length > 0){
            if(domLastAmts !== null && domLastAmts.current !== null ){
                domLastAmts.current.scrollTo(0, (amtLastLogs_?.length * 25))
            }
        }
    }, [ordLastLogs_, amtLastLogs_])
    */

  useEffect(() => {
    if (ordLastLogs.length !== 0) {
      if (domLastLogs !== null && domLastLogs.current !== null) {
        domLastLogs.current.scrollTo(
          0,
          (ordLastLogs == null ? 0 : ordLastLogs.length + 1) * 25,
        );
      }
    }
    if (amtLastLogs.length > 0) {
      if (domLastAmts !== null && domLastAmts.current !== null) {
        domLastAmts.current.scrollTo(
          0,
          amtLastLogs == null ? 0 : amtLastLogs.length * 25,
        );
      }
    }
  }, [ordLastLogs, amtLastLogs]);

  return (
    <div
      style={{ width: '1500px', paddingLeft: '10px' }}
      className="d-flex justify-content-between"
    >
      <div
        ref={domLastAmts}
        style={{
          backgroundColor: '#e1dada',
          height: '150px',
          width: '750px',
          overflowY: 'scroll',
        }}
      >
        {amtLastLogs.map((log) => (
          <div key={log.Id} style={{ fontSize: '12px', marginLeft: '15px' }}>
            {' '}
            {log.str}{' '}
          </div>
        ))}
      </div>
      <div
        ref={domLastLogs}
        style={{
          backgroundColor: '#e1dada',
          height: '150px',
          width: '750px',
          overflowY: 'scroll',
        }}
      >
        {ordLastLogs.map((log) => (
          <div key={log.Id} style={{ fontSize: '12px', marginLeft: '15px' }}>
            {' '}
            {log.str}{' '}
          </div>
        ))}
      </div>
    </div>
  );
}
