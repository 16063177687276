import React, { useEffect, useState } from 'react';
import SimpleGrid, { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { SimpleTableDataType, arrayTable } from 'tmslib/src/table/tables';
import { round } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import { VhGrpTy } from '../../Tms/Tms';
import { callAxiosGet } from '../../tmsutil';

type RtAttr = {
  Id: string;
  sec: string | null;
  prodId: string;
  prodNm: string | null;
  prodRt: number | null;
  wei0: number | null;
  wei1: number | null;
  rtAttr0: number | null;
  rtAttr1: number | null;
  excess: number | null;
};

export default function RetAttrib() {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [d, setD] = useState<string>(info?.currBizDay || '');
  const [d0, setD0] = useState<string | null>(info?.currBizDay || '');
  const intVhId = 'TEK';
  const [vh, setVh] = useState<string | null>(intVhId);
  const [rets, setRets] = useState<SimpleTableDataType[][]>([]);
  const [top10, setTop10] = useState<RtAttr[]>([]);
  const [btm10, setBtm10] = useState<RtAttr[]>([]);

  const getData = () => {
    if (!d0 || !vh) return;
    callAxiosGet({
      m,
      logger,
      url: '/ETF/RetAttrib/Data',
      params: { d, d0, vh: vh ?? null },
      onSuccess: (data) => {
        if (data.msg) m.alert(data.msg);

        const ex = round(data.fundRt - data.bmRt, 2);
        setRets([
          [vh, data.bmNm, '초과성과'],
          [data.fundRt, data.bmRt, ex],
        ]);
        setTop10(data.top10);
        setBtm10(data.btm10);
      },
    });
  };

  useEffect(() => getData(), [d, d0, vh]);

  const flds: (keyof RtAttr)[] = [
    'sec',
    'prodId',
    'prodNm',
    'prodRt',
    'wei0',
    'wei1',
    'rtAttr0',
    'rtAttr1',
    'excess',
  ];
  const headers = [
    '섹터',
    '종목코드',
    '종목명',
    '수익률',
    '펀드',
    'BM',
    '펀드',
    'BM',
    '초과',
  ];
  const headerGroups: [string, number][] = [
    ['종목', 4],
    ['비중 (%)', 2],
    ['기여 (pp)', 3],
  ];

  const dftFormatter = (v: SimpleGridDataType, c: keyof RtAttr) => {
    if (c === 'prodRt' || c.startsWith('wei')) return v?.toFixed(1);
    if (c.startsWith('rtAttr') || c === 'excess') return v?.toFixed(2);
    return v;
  };
  const dftStyler = (v: SimpleGridDataType, c: keyof RtAttr) => {
    if (c === 'prodRt' || c === 'excess') return { color: v?.getSignColor() };
    return null;
  };
  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => setD(date)}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        value={vh}
        grp={VhGrpTy.ETF}
        onChange={(v) => setVh(v?.Id ?? intVhId)}
        empty
      />
      <hr className="narrow light" />
      <b>펀드 수익률 (%)</b>
      {arrayTable(rets, {
        formatter: (v) => v?.toFixed(2),
        styler: (v) => ({ color: v?.getSignColor() }),
        tableStyle: { width: '300px', fontSize: '13px' },
      })}

      <hr className="narrow light" />
      <b>초과 수익 기여 종목 Top 10</b>
      <SimpleGrid
        data={top10}
        columns={flds}
        headers={headers}
        args={{
          headerGroups,
          dftFormatter,
          dftStyler,
        }}
      />

      <hr className="narrow light" />
      <b>초과 수익 기여 종목 Bottom 10</b>
      <SimpleGrid
        data={btm10}
        columns={flds}
        headers={headers}
        args={{
          headerGroups,
          dftFormatter,
          dftStyler,
        }}
      />
    </div>
  );
}
