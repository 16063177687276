import React, { useEffect } from 'react';
import OutsideHandler from './OutsideHandler';

export default function Dropdown<T>({
  value: initialValue,
  options,
  onChange,
  optionLabel,
  placeholder,
  style,
  className,
}: {
  value: T | null;
  options: T[];
  onChange: (value: T | null) => void;
  optionLabel?: (value: T) => string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
}) {
  const [value, setValue] = React.useState<T | null>(initialValue);

  const [drop, setDrop] = React.useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <OutsideHandler
      style={{ position: 'relative', display: 'inline' }}
      callback={() => setDrop(false)}
    >
      <button
        className={`btn btn-sm dropdown-toggle ${className}`}
        type="button"
        style={style}
        onClick={() => setDrop((v) => !v)}
      >
        {placeholder ?? ''}
      </button>
      {drop && (
        <ul
          style={{
            position: 'absolute',
            left: 0,
            border: '1px solid lightgray',
            zIndex: 100,
            backgroundColor: 'white',
          }}
          className="list-unstyled"
        >
          {options.map((o, i) => (
            <li key={`drop_${i}`} className="p-2">
              <a href="#" className="dropdown-item" onClick={() => onChange(o)}>
                {optionLabel?.(o) ?? String(o)}
              </a>
            </li>
          ))}
        </ul>
      )}
    </OutsideHandler>
  );
}
