import React, { useEffect, useState, useRef } from 'react';

import {
  Table,
  RowData,
  Row,
  Column,
  Cell,
  flexRender,
  ColumnDef,
  HeaderGroup,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  FilterFn,
  ColumnFiltersState,
  getFacetedUniqueValues,
  getExpandedRowModel,
  useReactTable,
  TableMeta,
  CellContext,
  getFacetedRowModel,
} from '@tanstack/react-table';
import _ from 'lodash';
import Window from '../ui/Window';
import DebouncedInput from '../ui/DebouncedInput';
import FilterBox, { IFilterBoxProps } from './FilterBox';
import './datagrid.scss';
import ContextMenu, {
  IContextMenuProps,
  IContextMenuItem,
} from './ContextMenu';
import {
  PasteClipboard,
  copyToClipboard,
  selectAll,
  moveCurrentCellLeftRight,
  moveCurrentCellUpDown,
  selectRangeLeftRight,
  selectRangeUpDown,
  clearSelectedArea,
} from './datagridutil';
import OutsideHandler from '../ui/OutsideHandler';
import { accumArr } from '../util/utils';

export enum ColumnType {
  Text,
  Checkbox,
  Combobox,
}

enum CellEditState {
  None,
  Selected,
  Editing,
}

export interface IId {
  Id: number | string;
  _stringId?: string; // for InputGrid
}

export const GlobalDftColWidth = 50;

// uuid 도 string이라서 의미별로 정의
export type SemanticIdType = 'number' | 'string' | 'uuid';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    height?: number;
    maxHeight?: number;
    hide?: boolean;
    dftColWidth?: number;
    headStyle?: React.CSSProperties;
    bodyStyle?: React.CSSProperties;
    cellStyle?: React.CSSProperties;
    footStyle?: React.CSSProperties;
    editable?: boolean;
    rowClassifier?: (r: Row<TData>) => string;
    onRowClick?: (r: Row<TData>) => void;
    onCellClick?: (c: Cell<TData, unknown>) => void;
    updateField?: (
      orignal: TData,
      rowIndex: number,
      columnId: string,
      value: unknown,
    ) => void;
    updateData?: (
      added: TData[],
      updated: { [key: number | string]: { [key in keyof TData]: unknown } },
      removed: Set<number | string>,
    ) => void;
    containerClass?: string;
    multiSelectOnClick?: boolean;
    useGlobalFilter?: boolean;
    useFilterBox?: boolean;
    contextMenus?: IContextMenuItem<TData>[];
    dynamicMenusOnShow?: (items: TData[]) => Promise<IContextMenuItem<TData>[]>;
    rowAppendable?: boolean; // 행 추가 가능
    rowDeletable?: boolean; // 행 삭제 가능
    areaPastable?: boolean; // 붙여넣기 가능
    areaClearable?: boolean; // 선택 영역 삭제 가능
    showRowNum?: boolean;
    detailRender?: (r: Row<TData>) => React.ReactNode;
    hasDetail?: (r: Row<TData>) => boolean;
    expandAllDetails?: boolean; // close all when false
    hideTBody?: boolean;
    editOnKeyDown?: boolean; // 즉시수정모드 (키 입력 바로 적용)
    filterResetNeeded?: number;
    idType?: SemanticIdType;
    noVirtualize?: boolean;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    type?: ColumnType;
    valTxts?: { val: string; txt: string }[];
    headStyle?: React.CSSProperties;
    frozen?: boolean;
    textAlign?: 'left' | 'center' | 'right';
    formatter?: (
      v: TValue,
      r: Row<TData>,
    ) => string | null | undefined | React.ReactNode;
    styler?: (v: TValue, r: Row<TData>) => React.CSSProperties | null;
    footStyler?: (data: TData[]) => React.CSSProperties | null;
    title?: (v: TValue, r: Row<TData>) => string | null;
    cellClassifier?: (v: TValue, r: Row<TData>) => string | null;
    rowSpanFn?: (r0: Row<TData>, r1: Row<TData>) => boolean;
    tooltip?: boolean;
  }

  interface FilterFns {
    multiSelect: FilterFn<unknown>;
  }
}

export type DataGridState<TData extends IId> = {
  currentObj: TData | undefined;
  selectedObjs: TData[];
};

export type CellMeta = {
  style?: React.CSSProperties;
  className?: string;
  title?: string;
};

export type CellMetaDicType<TData> = {
  [key: string]: { [key in keyof Partial<TData>]: CellMeta };
};

interface Props<TData extends IId> {
  data: TData[];
  columns: ColumnDef<TData, unknown>[];
  meta?: TableMeta<TData>;
  onStateChange?: React.Dispatch<
    React.SetStateAction<DataGridState<TData> | undefined>
  >;
  // rowid: colid
  cellMetas?: CellMetaDicType<TData>;
}

function GenerateTHeads<TData extends IId>(table: Table<TData>) {
  const {
    options,
    options: { meta: tableMeta },
    getHeaderGroups,
  } = table;
  const grps = getHeaderGroups();
  const isGrpHeaders = grps.length > 1;
  const colspan = accumArr(grps[0].headers.map((v) => v.colSpan));

  return (
    <thead style={{ position: 'sticky', top: '-1px', zIndex: 2 }}>
      {grps.map((headerGroup, i) => (
        <tr key={headerGroup.id}>
          {tableMeta?.showRowNum && (
            <th aria-label="showRowNum" style={{ width: '30px' }} />
          )}
          {tableMeta?.detailRender && <th aria-label="detailRender" />}

          {headerGroup.headers.map((header, j) => {
            const { meta: columnDefMeta } = header.column.columnDef;
            const style = {
              ...(tableMeta?.headStyle ?? {}),
              ...(columnDefMeta?.headStyle ?? {}),
              ...(isGrpHeaders && ((i === 0 && j > 0) || colspan.includes(j))
                ? { borderLeft: 'solid 1px #aaa', marginRight: '10px' }
                : {}),
              width: header.getSize(),
            };
            return (
              <th key={header.id} style={style} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <button
                    type="button"
                    {...{
                      onClick: header.column.getCanSort()
                        ? (e: React.MouseEvent) => {
                            options.setFilterBoxProps({
                              table,
                              column: header.column,
                              top: e.clientY,
                              left: e.clientX,
                            });
                          }
                        : undefined,
                    }}
                  >
                    {header.column.getIsFiltered() && (
                      <span style={{ backgroundColor: 'yellow' }}>⊂</span>
                    )}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    <span style={{ backgroundColor: 'yellow' }}>
                      {{
                        asc: ' ↑ ',
                        desc: ' ↓ ',
                      }[header.column.getIsSorted() as string] ?? null}
                    </span>
                  </button>
                )}
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    className: `resizer ${
                      header.column.getIsResizing() ? 'isResizing' : ''
                    }`,
                  }}
                />
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}

const selectRowRange = <TData extends IId>(
  rows: Row<TData>[],
  i0: number,
  i1: number,
) => {
  let [k0, k1] = [i0, i1];
  if (k0 > k1) [k0, k1] = [k1, k0];
  const n = rows.length;
  for (let k = 0; k < n; k += 1) {
    rows[k].toggleSelected(k >= k0 && k <= k1);
  }
};

const MaxCols = 1000;

const GenerateTRows = <TData extends IId>(
  rows: Row<TData>[],
  props: Props<TData>,
  table: Table<TData>,
  cellMetas?: CellMetaDicType<TData>,
): JSX.Element[] => {
  const tabState = table.getState();
  const currRowId = tabState.currentRowId;
  const currColId = tabState.currentColumnId;
  const tabOpt = table.options;
  const tableMeta = tabOpt.meta;
  const allRows = table.getRowModel().flatRows;
  const grps = table.getHeaderGroups();
  const isGrpHeaders = grps.length > 1;
  const colspan = accumArr(grps[0].headers.map((v) => v.colSpan));

  return rows
    .flatMap((row, i) => {
      const rowSelected = row.getIsSelected();
      const rowClass = [
        props.meta?.rowClassifier?.(row),
        rowSelected ? 'selected' : undefined,
        row.id === currRowId ? 'current' : undefined,
      ]
        .filter((v) => v)
        .join(' ');

      const onRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
        if (e.shiftKey) {
          if (currRowId) {
            // 셀 텍스트 선택되는거 없애주기
            // window.getSelection()?.removeAllRanges(); // css에서 처리함 (user-select: none)
            const i0 = allRows.findIndex((r) => r.id === currRowId);
            const i1 = allRows.findIndex((r) => r.id === row.id);
            selectRowRange(allRows, i0, i1);
          }
        } else if (e.ctrlKey) {
          row.toggleSelected();
        } else {
          if (!props.meta?.multiSelectOnClick) {
            table.resetRowSelection();
          }
          row.toggleSelected();
        }

        if (!e.shiftKey && row.id !== currRowId) {
          tabOpt.setCurrentRowId(row.id);
        }

        if (tableMeta?.hasDetail?.(row)) {
          row.toggleExpanded();
        }

        if (props.meta?.onRowClick) {
          props.meta?.onRowClick?.(row);
        }
      };

      // #region mouse dragging
      const onRowMouseDown = (e: React.MouseEvent<HTMLTableRowElement>) => {
        if (e.button === 0 && !e.shiftKey && !e.altKey && !e.ctrlKey) {
          tabOpt.setIsDragging(true);
          tabOpt.setCurrentRowId(row.id);
        }
      };
      const onRowMouseOver = () => {
        if (tabState.isDragging && currRowId) {
          const i0 = rows.findIndex((r) => r.id === currRowId);
          const i1 = allRows.findIndex((r) => r.id === row.id);
          if (i0 !== i1) selectRowRange(rows, i0, i1);
          else table.resetRowSelection(); // 같은 행이면 mouse up 이후에 올 onClick 에서 현재 행 선택함
        }
      };
      const onRowMouseUp = () => {
        tabOpt.setIsDragging(false);
      };
      // #endregion mouse dragging

      const setContextMenu = (x: number, y: number) => {
        tabOpt.setContextMenuProps({
          table,
          top: y,
          left: x,
          menus: props.meta?.contextMenus,
          dynamicMenusOnShow: props.meta?.dynamicMenusOnShow,
        });
        tabOpt.setCurrentRowId(row.id);
        const sltdRows = table.getSelectedRowModel().rows;
        if (
          !props.meta?.multiSelectOnClick &&
          !sltdRows.some((r) => r.id === row.id)
        ) {
          table.resetRowSelection();
        }
        row.toggleSelected(true);
      };

      const onRowContextMenu = (e: React.MouseEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        setContextMenu(e.clientX, e.clientY);
      };

      let touchStartedT = Number(new Date());

      const onRowTouchStart = (e: React.TouchEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        touchStartedT = Number(new Date());
      };

      const onRowTouchEnd = (e: React.TouchEvent<HTMLTableRowElement>) => {
        const passedT = Number(new Date()) - touchStartedT;
        if (passedT > 500 && e.changedTouches.length) {
          const pt = e.changedTouches[e.changedTouches.length - 1];
          setContextMenu(pt.clientX, pt.clientY);
        }
      };

      const trRef =
        row.id === currRowId ? table.options.currentRowRef : undefined;
      const colSltdLB = tabState.columnSelectionLB ?? MaxCols;
      const colSltdUB = tabState.columnSelectionUB ?? -1;

      const expanded = row.getIsExpanded();
      let detailInfo = '';
      if (tableMeta?.hasDetail?.(row)) {
        detailInfo = expanded ? '-' : '+';
      }
      const cells = row.getVisibleCells();
      const totalCellCount =
        cells.length +
        (tableMeta?.showRowNum ? 1 : 0) +
        (tableMeta?.detailRender ? 1 : 0);

      const cellMetaRows = cellMetas?.[row.id];
      return [
        <tr
          key={row.id}
          ref={trRef}
          className={rowClass}
          onClick={onRowClick}
          onMouseDown={onRowMouseDown}
          onFocus={onRowMouseOver}
          onMouseOver={onRowMouseOver}
          onMouseUp={onRowMouseUp}
          onContextMenu={onRowContextMenu}
          onTouchStart={onRowTouchStart}
          onTouchEnd={onRowTouchEnd}
        >
          {tableMeta?.showRowNum && <th>{i + 1}</th>}
          {tableMeta?.detailRender && <th>{detailInfo}</th>}

          {cells.map((cell, j) => {
            const cellValue = cell.getValue();
            const { meta: columnMeta } = cell.column.columnDef;
            const cellMeta = cellMetaRows?.[cell.column.id as keyof TData];
            const title =
              cellMeta?.title ??
              columnMeta?.title?.(cellValue, row) ??
              undefined;
            const style = {
              ...tableMeta?.cellStyle,
              ...columnMeta?.styler?.(cellValue, row),
              width: cell.column.getSize(),
              ...(isGrpHeaders && colspan.includes(j)
                ? {
                    borderLeft: 'solid 1px #aaa',
                  }
                : {}),
              ...(columnMeta?.textAlign
                ? { textAlign: columnMeta?.textAlign }
                : {}),
              ...cellMeta?.style,
            };
            const cellClass = [
              columnMeta?.cellClassifier?.(cellValue, row),
              row.id === currRowId && cell.column.id === currColId
                ? 'current'
                : undefined,
              rowSelected && j >= colSltdLB && j <= colSltdUB
                ? 'selected'
                : undefined,
              cellMeta?.className,
            ]
              .filter((v) => v)
              .join(' ');

            const onCellClick = (e: React.MouseEvent<HTMLTableCellElement>) => {
              // console.log(cell.getValue())

              if (e.shiftKey) {
                tabOpt.setColumnSelectionLB(Math.min(colSltdLB, j));
                tabOpt.setColumnSelectionUB(Math.max(colSltdUB, j));
              } else {
                tabOpt.setColumnSelectionLB(j);
                tabOpt.setColumnSelectionUB(j);
              }

              if (!e.shiftKey) {
                tabOpt.setCurrentColumnId(cell.column.id);
              }

              if (props.meta?.onCellClick) {
                props.meta?.onCellClick?.(cell);
              }
            };

            // #region mouse dragging
            const onCellMouseDown = (
              e: React.MouseEvent<HTMLTableCellElement>,
            ) => {
              if (e.button === 0 && !e.shiftKey && !e.altKey && !e.ctrlKey) {
                tabOpt.setCurrentColumnId(cell.column.id);
                tabOpt.setColumnSelectionLB(j);
                tabOpt.setColumnSelectionUB(j);
              }
            };
            const onCellMouseOver = () => {
              // e: React.MouseEvent<HTMLTableCellElement>
              if (tabState.isDragging && currColId) {
                const currColIdx = cells.findIndex(
                  (c) => c.column.id === currColId,
                );
                const lb =
                  j > colSltdLB && j < currColIdx ? j : Math.min(colSltdLB, j);
                tabOpt.setColumnSelectionLB(lb);
                const ub =
                  j < colSltdUB && j > currColIdx ? j : Math.max(colSltdUB, j);
                tabOpt.setColumnSelectionUB(ub);
              }
            };
            // #endregion mouse dragging

            if (columnMeta?.rowSpanFn) {
              const fst = i === 0 || !columnMeta?.rowSpanFn(rows[i - 1], row);
              if (fst) {
                let nspan = 1;
                for (let k = i + 1; k < rows.length; k += 1) {
                  if (columnMeta?.rowSpanFn(rows[k], row)) nspan += 1;
                  else break;
                }
                return (
                  <td
                    key={cell.id}
                    role="gridcell"
                    className={cellClass}
                    style={style}
                    title={title}
                    rowSpan={nspan}
                    onClick={onCellClick}
                    onMouseDown={onCellMouseDown}
                    onFocus={onCellMouseOver}
                    onMouseOver={onCellMouseOver}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              }
              return null;
            }
            return (
              <td
                id={`cell${cell.id}`}
                key={cell.id}
                role="gridcell"
                className={cellClass}
                style={style}
                title={title}
                onClick={onCellClick}
                onMouseDown={onCellMouseDown}
                onFocus={onCellMouseOver}
                onMouseOver={onCellMouseOver}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>,

        expanded ? (
          <tr key={`${row.id}_detail`} className="detail">
            <td colSpan={totalCellCount}>{tableMeta?.detailRender?.(row)}</td>
          </tr>
        ) : undefined,
      ];
    })
    .filter((v) => v)
    .map((v) => v as JSX.Element); // React.Fragment로 tr 여러개 반환하면 가상스크롤시 tr에 스타일 적용이 안됨. 이유는 모르겠음. 그래서 배열 -> flatMap
};

function GenerateTFoot<TData extends IId>(table: Table<TData>) {
  const {
    options: { meta: tableMeta },
    getAllFlatColumns,
    getFooterGroups,
  } = table;
  return (
    getAllFlatColumns().some((c) => c.columnDef.footer) && (
      <tfoot>
        {getFooterGroups().map((footerGroup: HeaderGroup<TData>) => (
          <tr key={footerGroup.id}>
            {tableMeta?.showRowNum && <th aria-label="showRowNum" />}
            {tableMeta?.detailRender && (
              <th aria-label="shodetailRenderwRowNum" />
            )}

            {footerGroup.headers.map((header) => {
              const columnMeta = header.column.columnDef.meta;
              const dataForFootStyler = columnMeta?.footStyler
                ? header
                    .getContext()
                    .table.getRowModel()
                    .flatRows.map((v: Row<TData>) => v.original)
                : [];
              const style = {
                ...tableMeta?.footStyle,
                ...columnMeta?.footStyler?.(dataForFootStyler),
                width: header.column.getSize(),
                ...(columnMeta?.textAlign
                  ? { textAlign: columnMeta?.textAlign }
                  : {}),
              };
              return (
                <td key={header.id} colSpan={header.colSpan} style={style}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </td>
              );
            })}
          </tr>
        ))}
      </tfoot>
    )
  );
}
// test
function onTableKeyDown<TData extends IId>(
  e: React.KeyboardEvent<HTMLSpanElement>,
  table: Table<TData>,
) {
  const tabState = table.getState();
  const tabOpt = table.options;
  if (tabState.cellEditState === CellEditState.Editing) {
    switch (e.key) {
      case 'Tab':
      case 'Enter':
        e.preventDefault();
        // currentCell 이 바뀌면 IsCellEditing이 false가 되지만, 테이블 끝에선 currCell이 안바뀔수 있으므로
        tabOpt.setCellEditState(CellEditState.None);
        tabOpt.tableRef.current?.focus();
        if (e.key === 'Tab') {
          moveCurrentCellLeftRight(table, e.shiftKey ? -1 : 1, false);
        }
        if (e.key === 'Enter') {
          moveCurrentCellUpDown(table, 1, false, tabOpt.meta?.rowAppendable);
        }
        break;
      default:
        break;
    }
    return;
  }

  const pageRows = 30;
  switch (e.key) {
    case 'Tab':
      e.preventDefault();
      moveCurrentCellLeftRight(table, e.shiftKey ? -1 : 1, e.ctrlKey);
      break;
    case 'ArrowLeft':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeLeftRight(table, -1, e.ctrlKey);
      } else {
        moveCurrentCellLeftRight(table, -1, e.ctrlKey);
      }
      break;
    case 'ArrowRight':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeLeftRight(table, 1, e.ctrlKey);
      } else {
        moveCurrentCellLeftRight(table, 1, e.ctrlKey);
      }
      break;
    case 'ArrowUp':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeUpDown(table, -1, e.ctrlKey);
      } else {
        moveCurrentCellUpDown(table, -1, e.ctrlKey);
      }
      break;
    case 'ArrowDown':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeUpDown(table, 1, e.ctrlKey);
      } else {
        moveCurrentCellUpDown(table, 1, e.ctrlKey);
      }
      break;
    case 'PageUp':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeUpDown(table, -pageRows, false);
      } else {
        moveCurrentCellUpDown(table, -pageRows, false);
      }
      break;
    case 'PageDown':
      e.preventDefault();
      if (e.shiftKey) {
        selectRangeUpDown(table, pageRows, false);
      } else {
        moveCurrentCellUpDown(table, pageRows, false);
      }
      break;
    case 'Enter':
    case 'F2':
      e.preventDefault();
      tabOpt.setCellEditState(CellEditState.Editing);
      break;

    case 'v':
    case 'V':
      if (e.ctrlKey) {
        e.preventDefault();
        if (tabOpt.meta?.areaPastable) {
          PasteClipboard(table);
        }
      }
      break;
    case 'c':
    case 'C':
      if (e.ctrlKey) {
        e.preventDefault();
        copyToClipboard(table, true); // by cell
      }
      break;
    case 'a':
    case 'A':
      if (e.ctrlKey) {
        e.preventDefault();
        selectAll(table, true);
      }
      break;

    case 'Delete':
      e.preventDefault();
      if (tabOpt.meta?.areaClearable) {
        clearSelectedArea(table, true);
      }
      break;

    default:
      break;
  }

  // 다시 포커스 안해주면 붙여넣기 후에 activeElement가 body 로 넘어가서 키보드로 셀 이동이 안됨
  table.options.tableRef.current?.focus();

  if (tabOpt.meta?.editOnKeyDown) {
    // if (tabState.cellEditState !== CellEditState.Editing) {
    if (!e.ctrlKey && !e.altKey) {
      if (e.key.length === 1 && e.key.match(/\w/)) {
        tabOpt.editByKeyDown.current = true;
        tabOpt.setCellEditState(CellEditState.Editing);
        tabOpt.setEditingVal(e.key);
      }
    }
    // }
  }
}

function escapeEditingMode<TData extends IId>(
  ctx: CellContext<TData, unknown>,
  setValue: React.Dispatch<unknown>,
) {
  const initialValue = ctx.getValue();
  const ts = ctx.table.getState();
  const ec = ts.editingCell;
  const isEditingCell =
    ts.cellEditState === CellEditState.Editing &&
    (!ec || (ec.row.id === ctx.row.id && ec.column.id === ctx.column.id));
  if (isEditingCell) {
    setValue(initialValue);
    ctx.table.options.setEditingVal(initialValue);
  }
  ctx.table.options.setCellEditState(CellEditState.None);
  ctx.table.options.tableRef.current?.focus();
}

function FrozenCell<TData extends IId>({
  ctx,
}: {
  ctx: CellContext<TData, unknown>;
}) {
  const {
    column: {
      columnDef: { meta },
    },
  } = ctx;
  const ty = meta?.type ?? ColumnType.Text;
  const value = ctx.getValue();
  if (ty === ColumnType.Checkbox) {
    const bVal = (value as boolean) ?? false;
    return (
      <input
        type="checkbox"
        checked={bVal}
        readOnly
        tabIndex={-1}
        name={ctx.column.id}
      />
    );
  }
  return (
    <span className="frozen">
      {meta?.formatter?.(value, ctx.row) ?? (value as string) ?? ''}
    </span>
  );
}

function EditingCell<TData extends IId>({
  ctx,
  value,
  setValue,
  selectText,
}: {
  ctx: CellContext<TData, unknown>;
  value: unknown;
  setValue: React.Dispatch<unknown>;
  selectText: boolean;
}) {
  const {
    column: {
      columnDef: { meta },
    },
  } = ctx;
  const ty = meta?.type ?? ColumnType.Text;
  // console.log(value, ty);
  if (ty === ColumnType.Checkbox) {
    return (
      <input
        type="checkbox"
        name={ctx.column.id}
        checked={(value as boolean) ?? false}
        onChange={(e) => setValue(e.target.checked)}
        className="editing"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    );
  }

  if (ty === ColumnType.Combobox) {
    const sVal = (value as string) ?? '';
    const valTxts = meta?.valTxts;
    return (
      <select
        value={sVal}
        name={ctx.column.id}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) =>
          e.key === 'Escape' && escapeEditingMode(ctx, setValue)
        }
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      >
        {sVal === '' && !valTxts?.some((v) => v.val === '') && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <option> </option>
        )}
        {valTxts?.map((v, k) => (
          <option key={v.val ?? k} value={v.val}>
            {v.txt}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      key="textEditingInput"
      value={(value as string) ?? ''}
      name={ctx.column.id}
      type="text"
      onChange={(e) => setValue(e.target.value)}
      onFocus={(e) => !selectText || e.target.select()}
      onKeyDown={(e) => {
        ctx.table.options.editByKeyDown.current = false; // 즉시수정모드를 트리거한 키 이후의 키에서 false로
        if (e.key === 'Escape') escapeEditingMode(ctx, setValue);
      }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  );
}

function EditableCell<TData extends IId>({
  ctx,
  value,
}: {
  ctx: CellContext<TData, unknown>;
  value: unknown;
}) {
  const {
    column: {
      columnDef: { meta },
    },
  } = ctx;

  const ty = meta?.type ?? ColumnType.Text;

  const ts = ctx.table.getState();

  const isSelected = ts.cellEditState === CellEditState.Selected;
  const onClickState = isSelected
    ? CellEditState.Editing
    : CellEditState.Selected;
  const isCurentCell =
    ts.currentColumnId === ctx.column.id && ts.currentRowId === ctx.row.id;
  const isCurrAndSltd = isCurentCell && isSelected;
  const setEditState = ctx.table.options.setCellEditState;
  if (ty === ColumnType.Checkbox) {
    return (
      <input
        type="checkbox"
        name={ctx.column.id}
        checked={(value as boolean) ?? false}
        className={isCurrAndSltd ? 'edit-selected' : ''}
        readOnly
        tabIndex={-1} // 이거 해줘야 tab으로 셀내비 할때 이 셀에서 두번 안걸림
        onClick={() => setEditState(onClickState)}
      />
    );
  }

  if (ty === ColumnType.Combobox) {
    const sVal = (value as string) ?? '';
    const valTxts = meta?.valTxts;
    const txt = valTxts?.find((v) => v.val === sVal)?.txt;
    // txt가 빈 문자일이면 아래서 공백으로 대체해야 클릭 가능
    return (
      <button
        type="button"
        className={`cellContent ${isCurrAndSltd ? 'edit-selected' : ''}`}
        onClick={() => setEditState(onClickState)}
      >
        {txt || ' '}
      </button>
    );
  }

  return (
    <button
      type="button"
      className={`cellContent ${isCurrAndSltd ? 'edit-selected' : ''}`}
      onClick={() => {
        setEditState(onClickState);
      }}
    >
      {meta?.formatter?.(value, ctx.row) ?? (value as string) ?? ' '}
    </button>
  );
}

function getDefaultColumn<TData extends IId>(
  props: Props<TData>,
): Partial<ColumnDef<TData>> {
  return {
    cell: props.meta?.editable
      ? (ctx: CellContext<TData, unknown>) => {
          const frozen = ctx.column.columnDef.meta?.frozen ?? false;
          if (frozen) return <FrozenCell ctx={ctx} />;
          const initialValue = ctx.getValue();
          const ts = ctx.table.getState();
          const ec = ts.editingCell;
          // isCellEditing = true로 되었을 때 editingCell은 아직 undefined인 상태에서 렌더링될수 있음
          const isEditingCell =
            ts.cellEditState === CellEditState.Editing &&
            (!ec ||
              (ec.row.id === ctx.row.id && ec.column.id === ctx.column.id));
          const initOrEditingVal = isEditingCell ? ts.editingVal : initialValue;
          const [value, setValue] = useState(initOrEditingVal);
          const tabOpt = ctx.table.options;
          useEffect(() => {
            if (isEditingCell) {
              tabOpt.setEditingVal(value);
            }
          }, [value, isEditingCell, tabOpt]);

          // 외부 소스 변경 시 싱크
          useEffect(() => {
            if (!isEditingCell) {
              setValue(initialValue);
            }
          }, [initialValue, isEditingCell]);

          const isCurentCell =
            ts.currentColumnId === ctx.column.id &&
            ts.currentRowId === ctx.row.id;

          if (isEditingCell && !isCurentCell) {
            // console.log(ts.currentRowId, ctx.row.id)
          }

          if (isCurentCell && isEditingCell) {
            // editOnKeyDown의 경우, 초기값/수정값 체크안하면 입력중에 5초마다 텍스트 전체선택되어서 불편
            // const selectText =
            //   !tabOpt.meta?.editOnKeyDown || tabOpt.editByKeyDown.current;
            const selectText = false; // 일단 false로. 클릭시 텍스트 선택으로 바꿔달라면 위에걸로 바꾸기
            return (
              <OutsideHandler
                callback={() => tabOpt.setCellEditState(CellEditState.None)}
              >
                <EditingCell
                  ctx={ctx}
                  value={value}
                  setValue={setValue}
                  selectText={selectText}
                />
              </OutsideHandler>
            );
          }
          return <EditableCell ctx={ctx} value={value} />;
        }
      : (ctx: CellContext<TData, unknown>) => <FrozenCell ctx={ctx} />,
    filterFn: 'multiSelect',
    size: props.meta?.dftColWidth ?? GlobalDftColWidth,
  };
}

declare module '@tanstack/react-table' {
  export interface CoreTableState {
    currentRowId: string | null;
    currentColumnId: string | null;
    columnSelectionLB: number | null;
    columnSelectionUB: number | null;
    isActive: boolean;
    isDragging: boolean;
    cellEditState: CellEditState; // 각 셀별 상태가 아니라, 수정중인 셀은 두개이상일수 없으므로 테이블서 관리
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editingCell: Cell<any, unknown> | null;
    editingVal: unknown;
  }

  export interface CoreOptions<TData extends RowData> {
    tableRef: React.RefObject<HTMLTableElement>;
    currentRowRef: React.RefObject<HTMLTableRowElement>;
    setCurrentRowId: (v: string | null) => void;
    setCurrentColumnId: (v: string | null) => void;
    setColumnSelectionLB: (v: number | null) => void;
    setColumnSelectionUB: (v: number | null) => void;
    getColumnSelectionRange: () => Column<TData, unknown>[];
    setIsDragging: (v: boolean) => void;
    setCellEditState: (v: CellEditState) => void;
    setEditingVal: (v: unknown) => void;
    editByKeyDown: React.MutableRefObject<boolean>;
    setContextMenuProps: (v: IContextMenuProps<TData> | null) => void;
    setFilterBoxProps: (v: IFilterBoxProps<TData> | null) => void;
    filterBoxProps: IFilterBoxProps<TData> | null;
  }
}

export default function DataGrid<TData extends IId>({
  data,
  columns,
  meta,
  onStateChange,
  cellMetas,
}: Props<TData>) {
  const [gridData, setGridData] = useState<TData[]>(data); // extended data with appendable rows
  useEffect(() => {
    /* rowAppendable일 때 자동으로 한줄 추가가 아니라 사용측에서 직접 관리로 변경
       => 사용측에서 관리하니 음수 id 등을 가정할 필요 없음
     if (meta?.rowAppendable) {
      // 기 데이터에 음수 Id 있으면 그거보다 하나 낮게. 아니면 -1부터
      const currMinId = Math.min(0, _.min(data.map((v) => v.Id)) ?? 0);
      setGridData(data.concat([{ Id: currMinId - 1 } as TData]));
    } else 
    */
    setGridData(data);
  }, [data, meta?.rowAppendable]);

  const props = {
    data: gridData,
    columns,
    meta,
    onStateChange,
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const currentRowRef = useRef<HTMLTableRowElement>(null);

  const defaultColumn = getDefaultColumn(props);

  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    if (!meta?.filterResetNeeded) return;
    setSorting([]);
    setColumnFilters([]);
    setGlobalFilter('');
  }, [meta?.filterResetNeeded]);

  const [currentRowId, setCurrentRowId] = useState<string | null>(null);
  const [currentColumnId, setCurrentColumnId] = useState<string | null>(null);
  const [columnSelectionLB, setColumnSelectionLB] = useState<number | null>(
    null,
  );
  const [columnSelectionUB, setColumnSelectionUB] = useState<number | null>(
    null,
  );

  const [isActive, setIsActive] = useState(true);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  // const [appendableRows, setAppendableRows] = useState<number>(1)

  const [cellEditState, setCellEditState] = useState<CellEditState>(
    CellEditState.None,
  );
  const [editingCell, setEditingCell] = useState<Cell<TData, unknown>>();
  const [editingVal, setEditingVal] = useState<unknown>();
  const editByKeyDown = useRef(false); // 즉시수정모드를 트리거했는지 여부

  const [contextMenuProps, setContextMenuProps] =
    useState<IContextMenuProps<TData> | null>(null);
  const [filterBoxProps, setFilterBoxProps] =
    useState<IFilterBoxProps<TData> | null>(null);

  let table: Table<TData>;

  const getColumnSelectionRange = (): Column<TData, unknown>[] =>
    table
      .getVisibleLeafColumns()
      .filter(
        (c, i) =>
          i >= (columnSelectionLB ?? MaxCols) && i <= (columnSelectionUB ?? -1),
      );

  const colsWithValTxts = new Map(
    columns
      .filter((v) => v.meta?.valTxts)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((v) => [(v as any).accessorKey, v.meta?.valTxts]),
  );
  const globalFilterFn = (
    row: Row<TData>,
    columnId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterValue: any,
    // addMeta: (meta: FilterMeta) => void,
  ) => {
    const filterVal = filterValue?.toString().toLowerCase();
    if (!filterVal) return true;
    const contains = Object.entries(row.original).some(([k, v]) => {
      if (k === 'Id' && typeof v === 'number') return false;
      if (typeof v === 'boolean') return false;
      const vstr = v?.toString();
      const txt = colsWithValTxts.has(k)
        ? colsWithValTxts.get(k)?.find((r) => r.val === vstr)?.txt
        : undefined;
      return (txt ?? vstr)?.toLowerCase().includes(filterVal);
    });
    if (contains) return true;
    return false;
  };

  const multiSelectFilterFn = (
    row: Row<TData>,
    columnId: string,
    filterValues: Set<string>,
  ) => {
    const val = row.getValue(columnId)?.toString() ?? '';
    const txt = colsWithValTxts.has(columnId)
      ? colsWithValTxts.get(columnId)?.find((r) => r.val === val)?.txt
      : undefined;
    return filterValues.has(txt ?? val);
  };

  table = useReactTable({
    data: gridData,
    columns,
    meta,

    defaultColumn,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    state: {
      currentRowId,
      currentColumnId,
      columnSelectionLB,
      columnSelectionUB,
      isActive,
      isDragging,
      cellEditState,
      editingCell,
      editingVal,
      rowSelection,
      sorting,
      columnFilters,
      globalFilter,
    },

    tableRef,
    currentRowRef,
    setCurrentRowId,
    setCurrentColumnId,
    setColumnSelectionLB,
    setColumnSelectionUB,
    getColumnSelectionRange,
    setIsDragging,
    setEditingVal,
    setCellEditState,
    editByKeyDown,
    setContextMenuProps,
    setFilterBoxProps,
    filterBoxProps,

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: true, // ctrl,shift 등으로 복수 선택하려면 여기 true

    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    onColumnFiltersChange: setColumnFilters,

    globalFilterFn,

    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),

    filterFns: {
      multiSelect: multiSelectFilterFn,
    },

    // https://tanstack.com/table/v8/docs/api/core/table#getrowid
    // ilongid 의 id 를 id로 사용. 세팅안해주면 index 가 id
    getRowId: (v: TData) => String(v.Id),
  });

  useEffect(() => {
    const ids = new Set(gridData.map((v) => v.Id?.toString()));
    if (currentRowId && !ids.has(currentRowId)) {
      setCurrentRowId(null);
    }
    const disappeared = Object.keys(rowSelection).filter((k) => !ids.has(k));
    if (disappeared.length) {
      const slt = { ...rowSelection }; // check let to const
      for (let k = 0; k < disappeared.length; k += 1) {
        delete slt[disappeared[k]];
      }
      setRowSelection(slt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  useEffect(() => {
    const currentObj = !currentRowId
      ? undefined
      : gridData.find((v) => v.Id?.toString() === currentRowId);
    const selectedObjs =
      Object.values(rowSelection).filter((v) => v).length === 0
        ? []
        : gridData.filter((o) => rowSelection[String(o.Id)]);
    onStateChange?.({ currentObj, selectedObjs });
  }, [gridData, currentRowId, rowSelection]);

  useEffect(() => {
    if (cellEditState !== CellEditState.None)
      setCellEditState(CellEditState.None);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRowId, currentColumnId]);

  const tableMeta = table.options.meta;

  const { rows } = table.getRowModel();
  const rowHeight = 20; // 스크롤 없는 경우는 대략 17 정도지만 스크롤 있을 땐 20이 보기좋은듯. 기존도 20

  const hasHeight =
    tableMeta?.height ||
    (tableMeta?.maxHeight &&
      (rows.length + 1) * rowHeight > tableMeta.maxHeight);

  const virtualize = !tableMeta?.noVirtualize && hasHeight;

  useEffect(() => {
    if (cellEditState === CellEditState.Editing) {
      const currRow = rows.find((v) => v.id === currentRowId);
      const cell = currRow
        ?.getVisibleCells()
        .find((v) => v.column.id === currentColumnId);
      const ctx = cell?.getContext();
      const initVal = ctx?.getValue();
      setEditingCell(cell);
      setEditingVal(initVal);
    } else {
      const currEditingVal = editingVal;
      setEditingVal(undefined);
      editByKeyDown.current = false;
      setEditingCell(undefined);

      if (!editingCell) return;

      const ctx = editingCell.getContext();
      const initVal = ctx?.getValue();
      if ((currEditingVal?.toString() ?? '') === (initVal?.toString() ?? ''))
        return;

      if (editingCell.column.id === 'Id') {
        const newId = currEditingVal as string;
        const oldId = initVal as string;
        if (rowSelection[oldId]) {
          table.setRowSelection(() => ({ [newId]: true, [oldId]: false }));
        }
        if (String(currentRowId) === String(oldId)) {
          setCurrentRowId(newId);
        }
      }

      // console.log('call updateField');
      meta?.updateField?.(
        editingCell.row.original,
        editingCell.row.index,
        editingCell.column.id,
        currEditingVal,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellEditState]);

  useEffect(() => {
    if (!virtualize) return;
    if (!currentRowId) return;
    const currentRowIdx = rows.findIndex((r) => r.id === currentRowId);
    if (currentRowIdx < 0) return;
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) return;
    const currentRowRect = currentRowRef.current?.getBoundingClientRect();
    if (!currentRowRect || currentRowRect.top - 30 < containerRect.top) {
      containerRef.current?.scrollTo({ top: currentRowIdx * rowHeight });
      return;
    }
    if (currentRowRect.bottom > containerRect.bottom - 30) {
      const containerH = containerRect.bottom - containerRect.top;
      containerRef.current?.scrollTo({
        top: currentRowIdx * rowHeight - containerH + 100,
      });
    }
  }, [currentRowId]); // rows 도 dependency에 넣으면 스크롤해서 다른 부분 보는 중에 리프레시마다 currentRow로 자꾸 옮겨감

  const {
    table: fbTable,
    column: fbColumn,
    top: fbTop,
    left: fbLeft,
  } = filterBoxProps ?? {};
  const [scrollPosition, setScrollPosition] = useState(0);

  const onScroll = React.useMemo(
    () =>
      _.throttle(
        (e) => {
          setScrollPosition(e.target.scrollTop);
        },
        50,
        { leading: false },
      ),
    [],
  );

  const itop = useRef(fbTop);
  useEffect(() => {
    if (itop.current === undefined && fbTop !== undefined) {
      itop.current = fbTop;
    }
  }, [fbTop]);

  const close = useRef(false);
  const handleScroll = () => {
    if (filterBoxProps != null && close.current === false) {
      setFilterBoxProps({
        ...filterBoxProps,
        top: (itop.current ?? 0) - document.documentElement.scrollTop,
      });
    }
  };
  useEffect(() => {
    close.current = false;
  }, [setFilterBoxProps]);

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
    if (filterBoxProps != null && close.current === false) {
      window.addEventListener('scroll', handleScroll);
    }
  }, [filterBoxProps, close.current]);

  const closeFilterBox = () => {
    close.current = true;
    setFilterBoxProps(null);
  };

  const closeContextMenu = () => setContextMenuProps(null);
  const useFilterBox = meta?.useFilterBox ?? true;
  const globalFilterInput = tableMeta?.useGlobalFilter ? (
    <DebouncedInput
      value={globalFilter ?? ''}
      name="globalFilter"
      onChange={(value) => setGlobalFilter(String(value))}
      placeholder="Search all columns..."
    />
  ) : null;

  useEffect(() => {
    if (meta?.expandAllDetails == null) return;
    const expanded = meta?.expandAllDetails;
    rows.map((row) => {
      if (meta?.hasDetail?.(row)) row.toggleExpanded(expanded);
      return undefined;
    });
  }, [meta, rows]);

  // document.body가 active가 된다거나 하면 현재 셀 css 바꿔줌
  useEffect(() => {
    if (
      document.activeElement &&
      !tableRef.current?.contains(document.activeElement)
    ) {
      setIsActive(false);
    }
  });

  // 테이블 바깥 클릭할때도 현재 셀 css 바꿔줌
  const onClickOutside = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (!isActive) {
      setEditingCell(undefined);
      setEditingVal(undefined);
      setCellEditState(CellEditState.None);
    }
  }, [isActive]);

  const {
    table: cmTable,
    top: cmTop,
    left: cmLeft,
    menus: cmMenus,
    dynamicMenusOnShow,
  } = contextMenuProps ?? {};

  const outerStyle: React.CSSProperties = {
    width: 'fit-content',
    display: tableMeta?.hide ? 'none' : undefined,
  };

  const containerStyle: React.CSSProperties = { width: 'fit-content' };
  let tbodyHeight = 0;
  let topPadding = 0;

  // 헤더 그룹이 있을 때 테이블 width도 같이 주면 칼럼 size 적용없이 동일 폭이 적용됨
  // table width 를 아예 안주면 virtualize된 테이블의 경우 헤더랑 바디랑 칼럼 폭이 어긋남
  const tableStyle: React.CSSProperties =
    !virtualize && table.getHeaderGroups().length > 1
      ? {}
      : { width: table.getTotalSize() };

  if (hasHeight) {
    const containerHeight = tableMeta?.height ?? tableMeta?.maxHeight ?? 100;

    const theadHeight =
      table.getHeaderGroups().length > 1 ? rowHeight * 2 + 6 : rowHeight + 1;
    tbodyHeight = containerHeight - theadHeight;
    containerStyle.height = `${containerHeight}px`;
    topPadding = theadHeight + 2; // 첫줄 보기좋게
    const bottomBuffer = !rows.length
      ? 0
      : Math.min(70, Math.round(rows.length * 0.2) + 30);
    // const bottomBuffer = 70;
    tableStyle.height = rowHeight * rows.length + theadHeight + bottomBuffer;
    // 행수가 작을때 대비해서 containerHeight - 1이랑 비교
    tableStyle.height = Math.max(containerHeight - 1, tableStyle.height);
    if (virtualize) tableStyle.position = 'relative';
  }
  const containerClasses = `datagrid ${hasHeight ? 'scroll' : ''} ${
    tableMeta?.containerClass ?? ''
  }`;
  const tableClasses = `datagrid noselect ${hasHeight ? 'scroll' : ''}`;
  const getChildren = (i0: number, i1: number) =>
    GenerateTRows(rows.slice(i0, i1), props, table, cellMetas);

  const outerClassName = `dg-outer ${isActive ? 'dg-active' : 'dg-inactive'}`;
  const rowCountStatus = `선택 ${Object.keys(rowSelection).length} | 필터 ${
    rows.length === data.length ? '-' : rows.length
  } | 전체 ${data.length}`;
  return (
    <OutsideHandler style={{ width: 'fit-content' }} callback={onClickOutside}>
      <div
        className={outerClassName}
        style={outerStyle}
        onFocus={() => setIsActive(true)}
      >
        <div>
          {globalFilterInput}
          {globalFilterInput && (
            <>
              &nbsp;
              <span style={{ color: 'gray' }}>{rowCountStatus}</span>
            </>
          )}
        </div>
        <div
          ref={containerRef}
          onScroll={virtualize ? onScroll : undefined}
          style={containerStyle}
          className={containerClasses}
        >
          {fbTable && fbColumn && useFilterBox && (
            <FilterBox
              table={fbTable}
              column={fbColumn}
              top={fbTop ?? 0}
              left={fbLeft ?? 0}
              close={closeFilterBox}
            />
          )}
          {cmTable && (
            <ContextMenu
              close={closeContextMenu}
              table={cmTable}
              top={cmTop ?? 0}
              left={cmLeft ?? 0}
              menus={cmMenus ?? []}
              dynamicMenusOnShow={dynamicMenusOnShow}
            />
          )}

          <table
            role="grid"
            ref={tableRef}
            style={tableStyle}
            className={tableClasses}
            // 셀 인풋 포커스가 테이블 포커스로 옮겨가서 이거 안함. 할려면 editOnKey 아닐때만?
            // onClick={() => tableRef.current?.focus({ preventScroll: true })}
            onKeyDown={(e) => onTableKeyDown(e, table)}
            tabIndex={0} // keydown event에 필요
          >
            {GenerateTHeads(table)}

            {meta?.hideTBody !== true && (
              <tbody style={tableMeta?.bodyStyle}>
                {virtualize ? (
                  <Window
                    containerHeight={tbodyHeight}
                    rowHeight={rowHeight}
                    getChildren={getChildren}
                    length={rows.length}
                    topPadding={topPadding}
                    scrollPosition={scrollPosition}
                  />
                ) : (
                  GenerateTRows(rows, props, table, cellMetas)
                )}
              </tbody>
            )}

            {GenerateTFoot(table)}
          </table>
        </div>
      </div>
    </OutsideHandler>
  );
}
