import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { AlgoOrder } from '../../../Tms/Ems';
import { callAxios, checkItems } from '../../../tmsutil';

interface Props {
  algos: AlgoOrder[];
  currAlgoId: number | undefined;
  setCurrAlgoId: React.Dispatch<React.SetStateAction<number | undefined>>;
  spot?: boolean;
  d: string;
  isAlgoSpot?: boolean;
}

const columnHelper = createColumnHelper<AlgoOrder>();
const getColumns = (spot: boolean) =>
  [
    columnHelper.accessor('Id', { header: 'Id', size: 60 }),
    spot
      ? columnHelper.accessor('mainExec', { header: '채널', size: 60 })
      : null,
    columnHelper.accessor('vhRepr', { header: '펀드', size: 70 }),
    spot
      ? columnHelper.accessor('prodId', { header: '종목코드', size: 70 })
      : null,
    spot
      ? columnHelper.accessor('prodNm', { header: '종목명', size: 120 })
      : null,
    spot ? columnHelper.accessor('stId', { header: '전략', size: 70 }) : null,
    columnHelper.accessor('state', { header: '상태' }),
    columnHelper.accessor('algoTy', { header: '유형', size: 50 }),
    columnHelper.accessor('hms0', { header: '시작', size: 60 }),
    columnHelper.accessor('hms1', { header: '종료', size: 60 }),
    columnHelper.accessor('byBrkDisp', {
      header: '브',
      size: 30,
      meta: {
        type: ColumnType.Checkbox,
      },
    }),
    columnHelper.accessor('qty2ord', {
      header: '예정량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('price', { header: '가격' }),
    columnHelper.accessor('OrderQty', {
      header: '주문량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('CumQty', {
      header: '체결량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('AvgPx', {
      header: '평단가',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('LeavesQty', {
      header: '미체결',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('Done', {
      header: '완료',
      size: 30,
      meta: {
        type: ColumnType.Checkbox,
      },
    }),
    columnHelper.accessor('ErrorMsg', {
      header: '에러',
      size: 130,
      meta: { styler: () => ({ color: 'red' }) },
    }),
    columnHelper.accessor('WarnMsg', { header: '경고' }),
    columnHelper.accessor('ordMsg', { header: '지시', size: 130 }),
    columnHelper.accessor('operNm', { header: '주문자' }),
    spot ? null : columnHelper.accessor('IntvDisp', { header: '간격' }),
    spot ? null : columnHelper.accessor('partRate', { header: '참여율' }),
    spot ? null : columnHelper.accessor('MaxLeavesQty', { header: 'L한도' }),
    spot ? null : columnHelper.accessor('FillDelayDisp', { header: '딜레이' }),
    spot ? null : columnHelper.accessor('CostTick', { header: 'cost' }),
  ]
    .filter((v) => v)
    .map((v) => v as ColumnDef<AlgoOrder, unknown>);

export default function AlgoOrderTab({
  algos,
  currAlgoId,
  setCurrAlgoId,
  spot,
  d,
  isAlgoSpot,
}: Props) {
  const [dgState, setDgState] = useState<DataGridState<AlgoOrder>>();
  const { msgBox: m, logger } = useMessageState();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currAlgoId) {
      setCurrAlgoId(dgState?.currentObj?.Id);
    }
  }, [currAlgoId, setCurrAlgoId, dgState]);

  const cols = useMemo(() => getColumns(spot ?? false), [spot]);

  const call = (func: string, params: unknown) =>
    callAxios({
      m,
      logger,
      url: `/Back/AlgoSpot/${func}`,
      params,
    });

  const modAlog = async (items: AlgoOrder[], fld: string) => {
    if (!checkItems(items, m, true)) return;

    let prc = null;
    let msg = null;
    if (fld === 'prc') {
      prc = await m.prompt('정정가격');
    } else if (fld === 'msg') {
      msg = await m.prompt('주문지시');
    }
    const params = { d, id: items[0].Id, prc, qty: null, msg };
    call('ModAlgo', params);
  };

  const cnclAlgo = async (items: AlgoOrder[]) => {
    if (!checkItems(items, m, true)) return;
    const params = { d, id: items[0].Id };
    call('CnclAlgo', params);
  };

  // 브로커 주문 강제완료처리
  const updateAlgo = async (items: AlgoOrder[], fld: string) => {
    if (!checkItems(items, m, true)) return;
    const params = { d, id: items[0].Id, fld };
    call('UpdateAlgo', params);
  };

  const cxtMenus: IContextMenuItem<AlgoOrder>[] = [];
  if (isAlgoSpot) {
    cxtMenus.push(
      {
        label: '가격 정정',
        callback: (items: AlgoOrder[]) => modAlog(items, 'prc'),
        disabled: (items: AlgoOrder[]) => items.some((v) => v.Done),
      },
      {
        label: '주문지시 정정',
        callback: (items: AlgoOrder[]) => modAlog(items, 'msg'),
        disabled: (items: AlgoOrder[]) => items.some((v) => v.Done),
      },
      {
        label: '전량 취소',
        callback: (items: AlgoOrder[]) => cnclAlgo(items),
        disabled: (items: AlgoOrder[]) => items.some((v) => v.Done),
      },
      {
        label: '브로커 주문',
        callback: (items: AlgoOrder[]) => updateAlgo(items, 'byBrk'),
        disabled: (items: AlgoOrder[]) => items.some((v) => v.Done),
      },
      {
        label: '강제완료처리',
        callback: (items: AlgoOrder[]) => updateAlgo(items, 'enforceDone'),
        disabled: (items: AlgoOrder[]) => items.some((v) => v.Done),
      },
    );
  }
  const meta: TableMeta<AlgoOrder> = {
    containerClass: 'ordproc-ordst',
    contextMenus: cxtMenus,
  };
  return (
    <DataGrid
      data={algos}
      columns={cols}
      meta={meta}
      onStateChange={setDgState}
    />
  );
}
