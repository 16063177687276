import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType } from 'tmslib/src/table/DataGrid';
import DebouncedInput from 'tmslib/src/ui/DebouncedInput';
import { AlgoSlice } from '../../../Tms/Ems';

interface Props {
  algoSliceBatch: AlgoSlice[];
  IsRealTime: boolean;
  setIsRealTime: React.Dispatch<React.SetStateAction<boolean>>;
  LastSecs: number;
  setLastSecs: React.Dispatch<React.SetStateAction<number | null>>;
  BatchT0: string;
  setBatchT0: React.Dispatch<React.SetStateAction<string | null>>;
}

const columnHelper = createColumnHelper<AlgoSlice>();
const columns = [
  columnHelper.accessor('prodId', {
    header: '종목',
    size: 60,
  }),
  columnHelper.accessor('prodNm', {
    header: '종목명',
    size: 100,
  }),
  columnHelper.accessor('procHms', {
    header: '처리T',
  }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 30,
    meta: {
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('ex', {
    header: 'EX',
    size: 30,
    meta: {
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('acctId', {
    header: '계좌',
    size: 50,
  }),
  columnHelper.accessor('byBrk', {
    header: '브',
    size: 30,
    meta: { type: ColumnType.Checkbox },
  }),
  columnHelper.accessor('OrderQty', {
    header: '주문량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('CumQty', {
    header: '체결량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('AvgPx', {
    header: '평단가',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('LeavesQty', {
    header: '미체결',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('Done', {
    header: '완료',
    meta: { type: ColumnType.Checkbox },
  }),
  columnHelper.accessor('lessQtyRsn', {
    header: '덜주문',
  }),
  columnHelper.accessor('ErrorMsg', {
    header: '에러',
    size: 70,
  }),
  columnHelper.accessor('condFailHms', {
    header: '시도T',
  }),
  columnHelper.accessor('ordMsg', {
    header: '지시',
  }),
  columnHelper.accessor('infoMsg', {
    header: '참고',
  }),
].map((v) => v as ColumnDef<AlgoSlice, unknown>);

export default function AlgoSliceBatchTab({
  algoSliceBatch,
  IsRealTime,
  setIsRealTime,
  LastSecs,
  setLastSecs,
  BatchT0,
  setBatchT0,
}: Props) {
  const [data, setData] = useState<AlgoSlice[]>(algoSliceBatch);

  useEffect(() => setData(algoSliceBatch), [algoSliceBatch]);

  useEffect(() => {
    setIsRealTime(IsRealTime);
  }, [IsRealTime, setIsRealTime]);

  useEffect(() => {
    setBatchT0(BatchT0);
  }, [BatchT0, setBatchT0]);

  const meta: TableMeta<AlgoSlice> = {
    height: 500,
    useGlobalFilter: true,
  };

  return (
    <div className="row" style={{ width: '1000px' }}>
      <div className="col">
        <label htmlFor="IsRealTime">
          <input
            type="checkbox"
            id="IsRealTime"
            checked={IsRealTime}
            onChange={(e) => setIsRealTime(e.target.checked as boolean)}
          />
          실시간 (최근
          <DebouncedInput
            style={{ width: 50 }}
            value={LastSecs ?? '60'}
            onChange={(value) => {
              setLastSecs(Number(value));
            }}
            placeholder="60"
            name="lastSecs"
          />
          초)
        </label>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <DebouncedInput
          style={{ width: 90 }}
          value={BatchT0 ?? ''}
          onChange={(value) => {
            setBatchT0(value as string);
          }}
          placeholder="HH:mm"
          name="batchT0"
        />
        &nbsp;&nbsp;
      </div>
      <hr className="light narrow" />
      <div>
        <DataGrid data={data} columns={columns} meta={meta} />
      </div>
    </div>
  );
}
