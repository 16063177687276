import { z } from 'zod';
import { AstTy, astTySchema } from './Prod';
import { Executor, executorSchema, banSideSchema, banTySchema } from './Ord';
import { Curncy, curncySchema, PosBookTy, posBookTySchema } from './Common';
import { PosRstrTy } from './BO';
import {
  zodNumber,
  zodPosiInt,
  zodPosiNumber,
  zodRange,
  zodRangeNullable,
  zodTime,
  zodTimeNullable,
  zodDate,
  zodDateNullable,
} from './ZodType';

export enum AcctGrp {
  일반법인 = '일반법인',
  PBS = 'PBS',
  장내파생 = '장내파생',
  해외장내파생 = '해외장내파생',
  LP = 'LP',
  LP장내파생 = 'LP장내파생',
  LP해외장내파생 = 'LP해외장내파생',
  해외주식 = '해외주식',
  타임고유 = '타임고유',
  신한P = '신한P',
  신한K = '신한K',
  메리츠B = '메리츠B',
  신한Qraft = '신한Qraft',
}
export const acctGrpSchema = z.nativeEnum(AcctGrp);

export enum AccountTags {
  None = 'None',
  AcctBS = 'AcctBS',
  Test = 'Test',
}
export const accountTagsSchema = z.nativeEnum(AccountTags);

export interface Account {
  Id: string;
  nm: string;
  nm2: string | null;
  brkId: string;
  htsUser: string | null;
  accNo: string | null;
  KsdCd: string | null;
  kstkFeeRto: number | null;
  ord: number;
  stId: string | null;
  vhId: string;
  brkLimit: string | null;
  short_: boolean;
  fix: boolean;
  dma: boolean;
  emsx: boolean;
  dstrId: string | null;
  astTy: AstTy;
  tag: AccountTags;
  book: PosBookTy;
  invalid: boolean;
  acctGrp: AcctGrp;
  executor: Executor;
}

export const accountSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  nm2: z.string().nullish(),
  brkId: z.string(),
  htsUser: z.string().nullish(),
  accNo: z.string().nullish(),
  KsdCd: z.string().nullish(),
  kstkFeeRto: zodPosiNumber(true),
  ord: zodPosiNumber(false),
  stId: z.string().nullish(),
  vhId: z.string(),
  brkLimit: z.string().nullish(),
  short_: z.coerce.boolean(),
  fix: z.coerce.boolean(),
  dma: z.coerce.boolean(),
  emsx: z.coerce.boolean(),
  dstrId: z.string().nullish(),
  astTy: astTySchema,
  tag: accountTagsSchema,
  book: posBookTySchema,
  invalid: z.coerce.boolean(),
  acctGrp: acctGrpSchema,
  executor: executorSchema,
});

export interface AcctFee {
  Id: number;
  acctId: string;
  astTy: AstTy;
  flat: boolean;
  fee: number;
}
export const acctFeeSchema = z.object({
  acctId: z.string(),
  astTy: astTySchema,
  flat: z.coerce.boolean(),
  fee: zodRange(0, 1),
});

export interface BoVhStl {
  Id: number;
  vhId: string;
  ord: number;
  d0: string;
  reinvD: string | null;
  reinvNav: number | null;
  d1: string;
  stlNav: number | null;
  stlNav2: number | null;
  taxna: number | null;
  units: number | null;
  skipDrop: boolean;
  divPrft: number | null;
  taxnav: number | null;
}

export const boVhStlSchema = z.object({
  vhId: z.string(),
  ord: zodPosiInt(false),
  d0: zodDate,
  d1: zodDate,
  stlNav: zodPosiNumber(true),
  skipDrop: z.coerce.boolean(),
  divPrft: zodNumber(true),
  taxnav: zodPosiNumber(true),
});

export interface Broker {
  Id: string;
  nm: string;
  nm2: string;
  StpCompID: string | null;
  StpCompID2: string | null;
  KsdCd: string | null;
  invalid: boolean;
  KStkDma: boolean;
}

export const brokerSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  nm2: z.string(),
  StpCompID: z.string().nullish(),
  StpCompID2: z.string().nullish(),
  KsdCd: z.string().nullish(),
  invalid: z.coerce.boolean(),
  KStkDma: z.coerce.boolean(),
});

export interface BrokerFam {
  Id: number;
  brkId: string;
  prodId: string;
  prodNm: string | null;
}

export enum Country {
  KR = 'KR',
  US = 'US',
  CN = 'CN',
  JP = 'JP',
  SG = 'SG',
  HK = 'HK',
  TW = 'TW',
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  IE = 'IE',
  IT = 'IT',
  MX = 'MX',
  NL = 'NL',
  NO = 'NO',
  NZ = 'NZ',
  PT = 'PT',
  SE = 'SE',
  XX = 'XX',
}
export const countrySchema = z.nativeEnum(Country);

export interface Ctry {
  Id: string;
  nm: string;
  tzi: string;
  tzdb: string;
  exch_t0: string;
  exch_t1: string;
  exch_lbt0: string | null;
  exch_lbt1: string | null;
  kor_t0: string;
  kor_t1: string;
  kor_lbt0: string;
  kor_lbt1: string;
}

export const ctrySchema = z.object({
  Id: z.string(),
  nm: z.string(),
  tzi: z.string(),
  tzdb: z.string(),
  exch_t0: zodTime,
  exch_t1: zodTime,
  exch_lbt0: zodTimeNullable,
  exch_lbt1: zodTimeNullable,
  kor_t0: zodTimeNullable.readonly(),
  kor_t1: zodTimeNullable.readonly(),
  kor_lbt0: zodTimeNullable.readonly(),
  kor_lbt1: zodTimeNullable.readonly(),
});

export const brokerFamSchema = z.object({
  brkId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
});

export enum DateInfoTy {
  None = 'None',
  StockMeeting = 'StockMeeting',
}
export const dateInfoTySchema = z.nativeEnum(DateInfoTy);

export interface DateInfo {
  Id: number;
  d: string;
  ty: DateInfoTy;
  note: string | null;
}

export interface DateInfo2 {
  Id: string; // uuid
  d: string;
  ty: DateInfoTy;
  note: string | null;
}

export const dateInfoSchema = z.object({
  d: zodDate,
  ty: dateInfoTySchema.nullish(), // 주식팀 직전영업일,
  note: z.string().nullish(),
});

export interface DstrMst {
  Id: string;
  nm: string | null;
  ord: number;
}

export const dstrMstSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  ord: zodPosiInt(false),
});

export interface DstrAmt {
  Id: number;
  dstrId: string;
  d: string;
  acctFeert: number;
  amt: number;
  feert: number;
  dstrNm: string | null;
}

export const dstrAmtSchema = z.object({
  dstrId: z.string(),
  dstrNm: z.string().nullish().readonly(),
  d: zodDate,
  acctFeert: zodRange(0, 1),
  amt: zodNumber(false),
  feert: zodRange(0, 1),
});

export enum VhGrpTy {
  Unknown = 'Unknown',
  OldFund = 'OldFund',
  ARS = 'ARS',
  MAP = 'MAP',
  VJP = 'VJP',
  Japan = 'Japan',
  SSPI = 'SSPI',
  TFIM = 'TFIM',
  VLS = 'VLS',
  HFund = 'HFund',
  ArsU = 'ArsU',
  MapU = 'MapU',
  HFund2 = 'HFund2',
  VFund = 'VFund',
  SMIC = 'SMIC',
  Test = 'Test',
  SINGA = 'SINGA',
  AIFund = 'AIFund',
  FoHFs = 'FoHFs',
  FoHFsC = 'FoHFsC',
  PAFund = 'PAFund',
  PAFundC = 'PAFundC',
  ETF = 'ETF',
  Troika = 'Troika',
  IVY = 'IVY',
  HBFund = 'HBFund',
  PEFund = 'PEFund',
  PEAssoc = 'PEAssoc',
  AIAssoc = 'AIAssoc',
  Undefined = 'Undefined',
}
export const vhGrpTySchema = z.nativeEnum(VhGrpTy);

export interface AcctGrpMst {
  Id: string;
  tgt: VhGrpTy;
  brkId: string;
  astTy: AstTy;
  acctGrp: AcctGrp;
  KStkDma: boolean;
}

export const acctGrpMstSchema = z.object({
  tgt: vhGrpTySchema,
  brkId: z.string().nullish(),
  acctGrp: acctGrpSchema,
  astTy: astTySchema,
  KStkDma: z.coerce.boolean(),
});
export enum StrgCate {
  None = 'None',
  LongShort = 'LongShort',
  LongOnly = 'LongOnly',
  Junior = 'Junior',
  Advisor = 'Advisor',
  AI = 'AI',
  ETF = 'ETF',
  Etc = 'Etc',
  Singa = 'Singa',
  SMIC = 'SMIC',
  IVY = 'IVY',
  Virtual = 'Virtual',
  Team = 'Team',
}
export const strgCateSchema = z.nativeEnum(StrgCate);

export enum StrgSubCate {
  LongShortUS = 'LongShortUS',
  LongOnlyKor = 'LongOnlyKor',
  JuniorKor = 'JuniorKor',
  JuniorUS = 'JuniorUS',
}
export const strgSubCateSchema = z.nativeEnum(StrgSubCate);

export interface Strg {
  Id: string;
  nm: string;
  nm2: string | null;
  ordKey: string | null;
  costRto: number;
  cate: StrgCate;
  subCate: StrgSubCate | null;
  dftTgt: VhGrpTy | null;
  vhIn: string | null;
  vhEx: string | null;
  note: string | null;
  lastD: string | null;
  bmId: string | null;
  bm0: number | null;
  nav0: number | null;
  exRt0: number | null;
  resetd: string | null;
  lsd: string | null;
  subTrdGrp: string | null;
}

export const strgSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  nm2: z.string().nullish(),
  ordKey: z.string().nullish(),
  costRto: zodPosiNumber(false),
  cate: strgCateSchema,
  subCate: strgSubCateSchema.nullish(),
  dftTgt: vhGrpTySchema.nullish(),
  vhIn: z.string().nullish(),
  vhEx: z.string().nullish(),
  note: z.string().nullish(),
  lastD: zodDateNullable,
  bmId: z.string().nullish(),
  bm0: zodPosiNumber(true),
  nav0: zodPosiNumber(true),
  exRt0: zodPosiNumber(true),
  resetd: zodDateNullable,
  subTrdGrp: z.string().nullish(),
});

export enum StInfo {
  None = 'None',
  UseTgt = 'UseTgt',
}
export const stInfoSchema = z.nativeEnum(StInfo);
export interface UstkCost {
  Id: number;
  brkId: string;
  isSwap: boolean;
  ctryId: string;
  fee: number;
  effd: string;
  exFee: number;
}

export const ustkCostSchema = z.object({
  brkId: z.string(),
  isSwap: z.coerce.boolean(),
  ctryId: z.string(),
  fee: zodRange(0, 1),
  effd: zodDate,
  exFee: zodRangeNullable(0, 1),
});

export interface UFutFeeInfo {
  Id: number;
  futrootId: string;
  brkId: string;
  tgt: VhGrpTy;
  effd: string;
  fee: number;
  crncy: Curncy;
  under: string | null;
  bbId: string | null;
  bbMkt: string | null;
}

export const uFutFeeInfoSchema = z.object({
  futrootId: z.string(),
  brkId: z.string(),
  tgt: vhGrpTySchema,
  effd: zodDate,
  fee: zodNumber(false), // 정액  z.coerce.number(),
  crncy: curncySchema.nullish().readonly(),
  under: z.string().nullish().readonly(),
  bbId: z.string().nullish().readonly(),
  bbMkt: z.string().nullish().readonly(),
});

export interface UstkTax {
  Id: number;
  ctryId: string;
  effd: string;
  toSwap: boolean;
  bTaxStk: number;
  sTaxStk: number;
  bTaxEtf: number;
  sTaxEtf: number;
  exProds: string | null;
}

export const ustkTaxSchema = z.object({
  ctryId: z.string(),
  effd: z.string(),
  bTaxStk: zodRangeNullable(0, 1),
  sTaxStk: zodRangeNullable(0, 1),
  bTaxEtf: zodRangeNullable(0, 1),
  sTaxEtf: zodRangeNullable(0, 1),
  stlDays: zodRangeNullable(0, 1),
  toSwap: z.coerce.boolean(),
  exProds: z.string().nullish(),
});

export enum VhclTy {
  Fund = 'Fund',
  Swap = 'Swap',
  TFIM = 'TFIM',
  Etc = 'Etc',
  Assoc = 'Assoc',
  Virtual = 'Virtual',
  Singa = 'Singa',
  Aggregate = 'Aggregate',
}
export const vhclTySchema = z.nativeEnum(VhclTy);

export enum VlclClass {
  Regular = 'Regular',
  Sub = 'Sub',
  // Virtual = 'Virtual',
  Test = 'Test',
  Invalid = 'Invalid',
  Umbrella = 'Umbrella',
  SubClass = 'SubClass',
}
export const vlclClassSchema = z.nativeEnum(VlclClass);
export interface Vehicle {
  Id: string;
  nm: string;
  lev: string | null;
  initD: string;
  lastD: string;
  ord: number;
  ty: VhclTy;
  cls: VlclClass;
  KsdCd: string;
  mafs: string;
  krCd: string;
  brkId: string | null;
  grp: VhGrpTy;
  crncy: Curncy;
  pfmFee: number | null;
  ctry: Country;
  cuUnits: number | null;
  cmplExpD: string | null;
  cmplGrp: string | null;
  summaryOrd: number | null;
  bmId: string | null;
  etpId: string | null;
  termFile: string | null;
}

export const vehicleSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  lev: zodPosiNumber(false),
  initD: zodDate,
  lastD: zodDateNullable,
  ord: zodPosiNumber(false),
  ty: vhclTySchema,
  cls: vlclClassSchema,
  KsdCd: z.string().nullish(),
  mafs: z.string().nullish(),
  krCd: z.string().nullish(),
  brkId: z.string().nullish(),
  grp: vhGrpTySchema,
  crncy: curncySchema,
  pfmFee: zodPosiNumber(true),
  ctry: countrySchema,
  cuUnits: zodPosiNumber(true),
  cmplExpD: z.string().nullish(),
  cmplGrp: z.string().nullish(),
  summaryOrd: zodPosiNumber(true),
  bmId: z.string().nullish(),
  etpId: z.string().nullish(),
  termFile: z.string().nullish().readonly(),
});

export enum IndiTrdGrp {
  None = 'None',
  Stk = 'Stk',
  AI = 'AI',
  PE = 'PE',
  ETF = 'ETF',
  Multi = 'Multi',
}
export const indiTrdGrpSchema = z.nativeEnum(IndiTrdGrp);

export const GetVhGrpName = (vhGrp: VhGrpTy) => {
  switch (vhGrp) {
    case VhGrpTy.HFund:
      return '헤지';
    case VhGrpTy.VFund:
      return '벤처';
    case VhGrpTy.AIFund:
      return '대체';
    case VhGrpTy.FoHFs:
      return '재간접';
    case VhGrpTy.PAFund:
      return '공모';
    case VhGrpTy.Troika:
      return '트로이카';
    case VhGrpTy.HBFund:
      return '하이브리드';
    case VhGrpTy.PEFund:
      return 'PE';
    case VhGrpTy.PEAssoc:
      return 'PE조합';
    case VhGrpTy.AIAssoc:
      return '대체조합';
    case VhGrpTy.TFIM:
      return '고유';
    case VhGrpTy.ETF:
      return 'ETF';
    case VhGrpTy.VLS:
      return 'VLS';
    default:
      return vhGrp.toString();
  }
};

export const GetVirTotVhcl = (vhGrp: VhGrpTy) => {
  switch (vhGrp) {
    case VhGrpTy.HFund:
      return 'VTT';
    case VhGrpTy.VFund:
      return 'VTV';
    case VhGrpTy.AIFund:
      return 'VAI';
    case VhGrpTy.PAFund:
      return 'VPA';
    case VhGrpTy.Troika:
      return 'VTR';
    case VhGrpTy.HBFund:
      return 'VHB';
    case VhGrpTy.PEFund:
      return 'VPE';
    case VhGrpTy.ETF:
      return 'VETF';
    default:
      return vhGrp.toString();
  }
};

export interface VhGrpStrg {
  Id: number;
  grp: VhGrpTy;
  stId: string;
  d0: string;
  d1: string;
  ord: number;
  info: StInfo;
  costRto: number | null;
  teamId: string | null;
}

export const vhGrpStrgSchema = z.object({
  grp: vhGrpTySchema,
  stId: z.string(),
  d0: zodDate,
  d1: zodDate,
  ord: zodPosiInt(false),
  info: stInfoSchema,
  costRto: zodPosiNumber(true),
  teamId: z.string().nullish(),
});

export interface StrgVhInEx {
  Id: string;
  d0: string;
  d1: string;
  stId: string;
  vhId: string;
  incl: boolean;
}

export const strgVhInExSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  stId: z.string(),
  vhId: z.string(),
  incl: z.coerce.boolean(),
});

export interface VhGrpInfo {
  Id: number;
  grp: VhGrpTy;
  reprVhId: string | null;
  trdGrp: IndiTrdGrp;
}

export const vhGrpInfoSchema = z.object({
  grp: vhGrpTySchema,
  reprVhId: z.string().nullish(),
  trdGrp: indiTrdGrpSchema,
});

export enum AIGrpTy {
  KVF_OPEN = 'KVF_OPEN',
  KVF_CLOSED = 'KVF_CLOSED',
  AI = 'AI',
  AI_Sector = 'AI_Sector',
  MZF_Waterfall = 'MZF_Waterfall',
  MZF = 'MZF',
  MZF_Alpha = 'MZF_Alpha',
  PJT = 'PJT',
  HY = 'HY',
  GLF = 'GLF',
  None = 'None',
}
export const AIGrpTySchema = z.nativeEnum(AIGrpTy);

export interface VhInfo {
  Id: string;
  nm: string | null;
  cmplExpD: string | null;
  pfmFee: number | null;
  initD: string | null;
  aiGrpNm: string | null;
  aiGrpId: AIGrpTy | null;
  strgDesc: string | null;
  assetDesc: string | null;
  brokerDesc: string | null;
  bncDesc: string | null;
  bmDesc: string | null;
  // bncDesc2: string | null;
}

export const vhInfoSchema = z.object({
  Id: z.string().nullish().readonly(),
  nm: z.string().nullish().readonly(),
  cmplExpD: z.string().nullish().readonly(),
  pfmFee: z.coerce.number().nullish().readonly(),
  initD: z.string().nullish().readonly(),
  aiGrpNm: z.string().nullish().readonly(),
  aiGrpId: AIGrpTySchema,
  strgDesc: z.string().nullish(),
  assetDesc: z.string().nullish(),
  brokerDesc: z.string().nullish(),
  bncDesc: z.string().nullish(),
  bmDesc: z.string().nullish(),
  // bncDesc2: z.string().nullish(),
});

export enum SwapTy {
  ARS = 'ARS',
  PIS = 'PIS',
  TARS = 'TARS',
  TPIS = 'TPIS',
}
export const swapTySchema = z.nativeEnum(SwapTy);

export interface ArsInfo {
  Id: string;
  nm: string | null;
  lev: number | null;
  brkId: string | null;
  ShCd: string | null;
  ShSub: number | null;
  ShNm: string | null;
  ShInitD: string | null;
  ShLastD: string | null;
  ShTy: SwapTy | null;
  netLmt: number | null;
  grsLmt: number | null;
  mgtFee: number | null;
  pfmFee: number | null;
  huddle: number | null;
  feePrd: number | null;
  swapFee: number | null;
  prevId: string | null;
  rollOver: boolean;
  lossCut: number | null;
  insuRt: number | null;
  memo: string | null;
  // banEnt: boolean;
  // banExt: boolean;
  // shLastD2: string | null;
}

export const ArsInfoSchema = z.object({
  Id: z.string().nullish().readonly(),
  nm: z.string().nullish().readonly(),
  lev: z.coerce.number().nullish().readonly(),
  brkId: z.string().nullish().readonly(),
  ShCd: z.string().nullish().readonly(),
  ShSub: z.coerce.number().nullish().readonly(),
  ShNm: z.string().nullish().readonly(),
  ShInitD: z.string().nullish().readonly(),
  ShLastD: z.string().nullish().readonly(),
  ShTy: swapTySchema.nullish().readonly(),
  netLmt: z.coerce.number().nullish().readonly(),
  grsLmt: z.coerce.number().nullish().readonly(),
  mgtFee: z.coerce.number().nullish().readonly(),
  pfmFee: z.coerce.number().nullish().readonly(),
  huddle: z.coerce.number().nullish().readonly(),
  feePrd: z.coerce.number().nullish().readonly(),
  swapFee: z.coerce.number().nullish().readonly(),
  prevId: z.string().nullish().readonly(),
  rollOver: z.coerce.boolean().nullish().readonly(),
  lossCut: z.coerce.number().nullish().readonly(),
  insuRt: z.coerce.number().nullish().readonly(),
  memo: z.string().nullish().readonly(),
});

export interface HolidaySession {
  Id: number;
  d: string;
  ctry: Country;
  note: string | null;
  exch_t0: string | null;
  exch_t1: string | null;
  kor_t0: string | null;
  kor_t1: string | null;
}

export const holidaySessionSchema = z.object({
  d: zodDate,
  ctry: countrySchema,
  note: z.string().nullish(),
  exch_t0: zodTimeNullable,
  exch_t1: zodTimeNullable,
  kor_t0: z.string().nullish().readonly(),
  kor_t1: z.string().nullish().readonly(),
});

export enum HolidayTypes {
  General,
  MarketOnly,
}
export const holidayTypesSchema = z.nativeEnum(HolidayTypes);

export interface Holiday {
  Id: number;
  d: string;
  ctry: Country;
  ty: HolidayTypes;
  note: string | null;
}
export const holidaysSchema = z.object({
  d: zodDate,
  ctry: countrySchema,
  note: z.string().nullish(),
});

export enum MemoTy {
  None = 'None',
  SwapPl = 'SwapPl',
  PerfFee = 'PerfFee',
  ShFundPos = 'ShFundPos',
  ShTrnc = 'ShTrnc',
  OtcProd = 'OtcProd',
  MafsPos = 'MafsPos',
  CmplReview = 'CmplReview',
  CBA = 'CBA',
  StWei = 'StWei',
  AiDur = 'AiDur',
  CmplUnfair = 'CmplUnfair',
  FillAmt = 'FillAmt',
  CmplKsdShort = 'CmplKsdShort',
  SwapLimitAmt = 'SwapLimitAmt',
  Saem = 'Saem',
  ChatbotHelp = 'ChatbotHelp',
}

export interface Memo {
  Id: number;
  d: string;
  ty: MemoTy;
  memo: string | null;
  vhId: string;
  book: PosBookTy;
  stId: string;
  prodId: string;
  rstr: PosRstrTy;
  brkId: string;
  userId: string | null;
}

export const ordBanGrpSchema = z.object({
  grp: vhGrpTySchema, // z.lazy(() => vhGrpTySchema), // TODO
  vhId: z.string().nullish(),
  stId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  side: banSideSchema,
  d0: zodDate,
  d1: zodDate,
  ty: banTySchema,
  memo: z.string().nullish(),
  operNm: z.string().nullish().readonly(),
});

export const ordBrkAmtTgtSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  tgt: vhGrpTySchema,
  brkId: z.string(),
  astTy: astTySchema,
  acctGrp: acctGrpSchema,
  vhIn: z.string().nullish(),
  vhEx: z.string().nullish(),
  amtTgt: zodPosiNumber(false), // numericStringSchema(false),
  amtDone: z.coerce.number().nullish().readonly(),
  rto: z.coerce.number().nullish().readonly(),
  invalid: z.boolean().nullish(),
  ystdDone: z.coerce.number().nullish().readonly(),
  RemainedDays: z.coerce.number().nullish().readonly(),
  TodayTgt: z.coerce.number().nullish().readonly(),
  TodaySent: z.coerce.number().nullish().readonly(),
  ToSendToday: z.coerce.number().nullish().readonly(),
  TodayBrkGen: z.coerce.number().nullish().readonly(),
});

export const ordBrkDftSchema = z.object({
  tgt: vhGrpTySchema,
  brkId: z.string(),
  astTy: astTySchema,
  acctGrp: acctGrpSchema,
  vhIn: z.string().nullish(),
  vhEx: z.string().nullish(),
  rto: zodPosiNumber(false),
});

export const ordMapSchema = z.object({
  src: vhGrpTySchema,
  tgt: vhGrpTySchema,
  stId: z.string(),
});

export const ordRiskCfgSchema = z.object({
  maxAmtGen: zodPosiNumber(false), // numericStringSchema(false),
  maxAmt1m: zodPosiNumber(false), // numericStringSchema(false),
  maxAmt10m: zodPosiNumber(false), // numericStringSchema(false),
  opgaHitRto: zodRange(0, 1),
  maxCashRto: zodRange(0, 1),
});

export const prodCashDivInfoSchema = z.object({
  prodId: z.string(),
  nm: z.string(),
  bbTkr: z.string(),
  annd: zodDate,
  exd: zodDate,
  recd: zodDate,
  payd: zodDate,
  amt: zodPosiNumber(false), // numericStringSchema(false),
  freq: z.string().nullish(),
  ty: z.string().nullish(),
});

export const permittedExtensions = [
  'txt',
  'pdf',
  'csv',
  'hwp',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'jpg',
];
