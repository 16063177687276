import React, { useState } from 'react';

export default function NumericInput({
  value: initialValue,
  onChange: onFinalChange,
  width,
  ...props
}: {
  value: number | null;
  onChange: (value: number | null) => void;
  width?: number | string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>) {
  const [value, setValue] = useState(initialValue?.toString() ?? '');

  React.useEffect(() => {
    setValue(initialValue?.toString() ?? '');
  }, [initialValue]);

  const { name, placeholder, style, className, disabled, readOnly } = props; // react/jsx-props-no-spreading

  return (
    <input
      // {...props}
      type="number"
      name={name}
      placeholder={placeholder}
      style={{ ...(width ? { width: `${width}px` } : {}), ...style }}
      value={value}
      className={className}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        const numVal = value.toNumber();
        if (numVal !== initialValue) onFinalChange(numVal);
      }}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}
