import React, { useRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid, { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { mapValueToString, parseBool } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../../Auth/AuthContext';
import {
  RtDiff,
  updateRtObj,
  updateRtRows,
  setRtEffect,
} from '../../../rtutil';
import {
  GetLoanReqSortName,
  LoanFail,
  LoanReq,
  LoanReqLog,
  LoanReqVh,
  LoanReqVhLog,
  LoanReqSort,
  LoanState,
  LoanSum,
  SwapSet,
} from '../../../Tms/Loan';
import LoanReqTab from './LoanReqTab';
import LoanStateBar from './LoanStateBar';
import LoanReqVhTab from './LoanReqVhTab';
import DateSelector from '../../../shared/DateSelector';

type LoanReqRes = {
  t: number;
  state: LoanState | null;
  state_upd: { [key: string]: object | null } | null;
  reqs: LoanReq[] | null;
  reqs_diff: RtDiff<LoanReq> | null;
  logs: LoanReqLog[] | null;
  logs_diff: RtDiff<LoanReqLog> | null;
  rvhs: LoanReqVh[] | null;
  rvhs_diff: RtDiff<LoanReqVh> | null;
  rvhlogs: LoanReqVhLog[] | null;
  rvhlogs_diff: RtDiff<LoanReqVhLog> | null;
  cashSum: LoanSum[] | null;
  cashSum_diff: RtDiff<LoanSum> | null;
  swapSum: LoanSum[] | null;
  swapSum_diff: RtDiff<LoanSum> | null;
  swapSet: SwapSet[] | null;
  swapSet_diff: RtDiff<SwapSet> | null;
  fails: LoanFail[] | null;
  fails_diff: RtDiff<LoanFail> | null;
};

export default function LoanReqMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { connection, connected, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const keep: boolean = parseBool(searchParams.get('keep')) ?? false;
  const d = searchParams.get('d') || info?.currBizDay || '';
  const incDone: boolean = parseBool(searchParams.get('incDone')) ?? false;
  const incCncld: boolean = parseBool(searchParams.get('incCncld')) ?? false;
  const incDead: boolean = parseBool(searchParams.get('incDead')) ?? false;
  const swapOnly: boolean = parseBool(searchParams.get('swapOnly')) ?? false;
  const sort0: LoanReqSort =
    (searchParams.get('sort0') as LoanReqSort) || LoanReqSort.ProdId;
  const desc0: boolean = parseBool(searchParams.get('desc0')) ?? false;
  const sort1: LoanReqSort =
    (searchParams.get('sort1') as LoanReqSort) || LoanReqSort.Ord;
  const desc1: boolean = parseBool(searchParams.get('desc1')) ?? false;

  const { user } = useAuthState();

  const lastResT = useRef(0);

  const [currReqId, setCurrReqId] = useState<number>();
  const [currRvhId, setCurrRvhId] = useState<number>();

  const [loanState, setLoanState] = useState<LoanState | null>(null);
  const [reqs, setReqs] = useState<LoanReq[]>([]);
  const [logs, setLogs] = useState<LoanReqLog[]>([]);
  const [rvhs, setRvhs] = useState<LoanReqVh[]>([]);
  const [rvhLogs, setRvhLogs] = useState<LoanReqVhLog[]>([]);
  const [cashSum, setCashSum] = useState<LoanSum[]>([]);
  const [swapSum, setSwapSum] = useState<LoanSum[]>([]);
  const [swapSet, setSwapSet] = useState<SwapSet[]>([]);
  const [fails, setFails] = useState<LoanFail[]>([]);

  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  useEffect(() => {
    setLogs([]);
    setRvhs([]);
    setRvhLogs([]);
  }, [currReqId]);

  useEffect(() => {
    setRvhLogs([]);
  }, [currRvhId]);

  const reqParams = {
    keep,
    d,
    incDone,
    incCncld,
    incDead,
    swapOnly,
    sort0,
    desc0,
    sort1,
    desc1,
    currReqId,
    currRvhId,
  };
  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: reqParams,
        reqAddr: 'RequestLoanReq',
        rcvAddr: 'ReceiveLoanReq',
        connection,
        onReceive: (res: LoanReqRes) => {
          setLoanState((prev) => updateRtObj(prev, res?.state, res?.state_upd));
          setReqs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.reqs,
              diff: res?.reqs_diff,
            }),
          );
          setLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.logs,
              diff: res?.logs_diff,
            }),
          );
          setRvhs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.rvhs,
              diff: res?.rvhs_diff,
            }),
          );
          setRvhLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.rvhlogs,
              diff: res?.rvhlogs_diff,
            }),
          );
          setCashSum((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.cashSum,
              diff: res?.cashSum_diff,
            }),
          );
          setSwapSum((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.swapSum,
              diff: res?.swapSum_diff,
            }),
          );
          setSwapSet((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.swapSet,
              diff: res?.swapSet_diff,
            }),
          );
          setFails((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.fails,
              diff: res?.fails_diff,
            }),
          );
        },
      }),
    [
      connection,
      connected,
      d,
      keep,
      updateNeeded,
      incDone,
      incCncld,
      incDead,
      swapOnly,
      sort0,
      desc0,
      sort1,
      desc1,
      currReqId,
      currRvhId,
    ],
  );

  const sortOptions = Object.keys(LoanReqSort).map((v) => (
    <option key={v} value={v}>
      {GetLoanReqSortName(v as LoanReqSort)}
    </option>
  ));

  const sumColumns = (user?.isHouse ? ['brkId'] : [])
    .concat(['prodId', 'prodNm', 'cnt', 'amt', 'fee'])
    .map((v) => v as keyof LoanSum);
  const sumColHeads = (user?.isHouse ? ['PBS'] : []).concat([
    '종목코드',
    '종목명',
    '건수',
    '수량',
    '요율',
  ]);
  const sumFormatter = (v: SimpleGridDataType, c: keyof LoanSum) => {
    if (c === 'fee') return v?.toFixed(2);
    return typeof v === 'number' ? v.toFixedWithComma(0) : v;
  };
  const swapSetColumns = (user?.isHouse ? ['brkId'] : [])
    .concat(['vhId', 'prodId', 'prodNm', 'availq', 'needq', 'finalq', 'fee'])
    .map((v) => v as keyof SwapSet);
  const swapSetColHeads = (user?.isHouse ? ['PBS'] : []).concat([
    '펀드',
    '종목코드',
    '종목명',
    '가능량',
    '필요량',
    '확정량',
    '요율',
  ]);
  const swapSetFormatter = (v: SimpleGridDataType, c: keyof SwapSet) => {
    if (c === 'fee') return v?.toFixed(2);
    return typeof v === 'number' ? v.toFixedWithComma(0) : v;
  };

  const toParams = mapValueToString;

  return (
    <div className="children-mb-1" style={{ minWidth: '1600px' }}>
      <div className="children-me-2">
        <DateSelector
          value={d}
          onChange={(date) =>
            setSearchParams(toParams({ ...reqParams, d: date }))
          }
        />
        <label htmlFor="incDone">
          <input
            type="checkbox"
            checked={incDone}
            id="incDone"
            onChange={(e) =>
              setSearchParams(
                toParams({ ...reqParams, incDone: e.target.checked }),
              )
            }
          />
          완료건 포함
        </label>
        <label htmlFor="incCncld">
          <input
            type="checkbox"
            checked={incCncld}
            id="incCncld"
            onChange={(e) =>
              setSearchParams(
                toParams({ ...reqParams, incCncld: e.target.checked }),
              )
            }
          />
          취소건 포함
        </label>
        {user?.isHouse && (
          <label htmlFor="incDeald">
            <input
              type="checkbox"
              checked={incDead}
              id="incDeald"
              onChange={(e) =>
                setSearchParams(
                  toParams({ ...reqParams, incDead: e.target.checked }),
                )
              }
            />
            미전송 취소 포함
          </label>
        )}
        <label htmlFor="swapOnly">
          <input
            type="checkbox"
            checked={swapOnly}
            id="swapOnly"
            onChange={(e) =>
              setSearchParams(
                toParams({ ...reqParams, swapOnly: e.target.checked }),
              )
            }
          />
          스왑만
        </label>
        <span className="badge bg-light text-dark">
          정렬 1
          <select
            name="sort0"
            value={sort0}
            onChange={(e) =>
              setSearchParams(toParams({ ...reqParams, sort0: e.target.value }))
            }
          >
            {sortOptions}
          </select>
          <label htmlFor="swapOnly" style={{ fontWeight: 'normal' }}>
            <input
              type="checkbox"
              checked={desc0}
              id="desc0"
              onChange={(e) =>
                setSearchParams(
                  toParams({ ...reqParams, desc0: e.target.checked }),
                )
              }
            />
            내림차순
          </label>
        </span>
        <span className="badge bg-light text-dark">
          정렬 2
          <select
            name="sort1"
            value={sort1}
            onChange={(e) =>
              setSearchParams(toParams({ ...reqParams, sort1: e.target.value }))
            }
          >
            {sortOptions}
          </select>
          <label htmlFor="desc1" style={{ fontWeight: 'normal' }}>
            <input
              type="checkbox"
              checked={desc1}
              id="desc1"
              onChange={(e) =>
                setSearchParams(
                  toParams({ ...reqParams, desc1: e.target.checked }),
                )
              }
            />
            내림차순
          </label>
        </span>
      </div>
      <div className="alert alert-slim alert-info like-pre">
        {`  • 수정모드 : 클릭, F2, Enter  |  수정취소 : Esc  |  일괄수정 : Ctrl + Enter  |  (가능량, 요율 칼럼만 수정가능)
  • 기타 셀 이동, 영역 선택 등은 엑셀과 유사`}
      </div>
      <hr className="light narrow" />
      <LoanStateBar state={loanState} />
      <hr className="light narrow" />
      <div className="row" style={{ width: '1900px' }}>
        <div className="col">
          <LoanReqTab
            setUpdateNeeded={setUpdateNeeded}
            d={d}
            keep={keep}
            cnfrmMin={loanState?.feeCnfrmMin ?? 100}
            reqs={reqs}
            fails={fails}
            currId={currReqId}
            setCurrId={setCurrReqId}
          />
        </div>
        <div className="col" style={{ marginLeft: '20px' }}>
          <h5>
            <span className="badge bg-secondary">변경 로그</span>
          </h5>
          <div style={{ minHeight: '300px' }}>
            <SimpleGrid
              data={logs}
              columns={['tstr', 'ty', 'oldVal', 'newVal', 'userNm', 'info']}
              headers={['T', '필드', '기존값', '변경값', '변경자', '참고']}
              args={{
                meta: { dftColWidth: 70 },
              }}
            />
          </div>
          <h5>
            <span className="badge bg-secondary">펀드별 수량</span>
          </h5>
          <div style={{ minHeight: '400px' }}>
            <LoanReqVhTab
              items={rvhs}
              currId={currRvhId}
              setCurrId={setCurrRvhId}
            />
          </div>
          <h5>
            <span className="badge bg-secondary">펀드 로그</span>
          </h5>
          <div style={{ minHeight: '100px' }}>
            <SimpleGrid
              data={rvhLogs}
              columns={['tstr', 'ty', 'oldVal', 'newVal', 'userNm']}
              headers={['T', '필드', '기존값', '변경값', '변경자']}
              args={{
                meta: { dftColWidth: 70 },
              }}
            />
          </div>
        </div>
      </div>
      <hr className="light narrow" />
      <b>실시간 집계</b>
      <div
        className="row"
        style={{ width: '1600px', height: '800px', overflowY: 'scroll' }}
      >
        <div className="col">
          <h5>
            <span className="badge bg-secondary">직접 대차</span>
          </h5>
          <SimpleGrid
            data={cashSum}
            columns={sumColumns}
            headers={sumColHeads}
            args={{
              dftFormatter: sumFormatter,
            }}
          />
        </div>
        <div className="col">
          <h5>
            <span className="badge bg-secondary">스왑 대차</span>
          </h5>
          <SimpleGrid
            data={swapSum}
            columns={sumColumns}
            headers={sumColHeads}
            args={{
              dftFormatter: sumFormatter,
            }}
          />
        </div>
        <div className="col">
          <h5>
            <span className="badge bg-secondary">스왑 세팅 필요</span>
          </h5>
          <SimpleGrid
            data={swapSet}
            columns={swapSetColumns}
            headers={swapSetColHeads}
            args={{
              dftFormatter: swapSetFormatter,
            }}
          />
        </div>
      </div>
    </div>
  );
}
