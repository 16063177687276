import React, { useEffect, useState } from 'react';
import Dialog from 'tmslib/src/ui/Dialog';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import Button from 'tmslib/src/ui/Button';
import { PosWeiTarget, PosRstrTy } from '../../../Tms/BO';
import { useAuthState } from '../../Auth/AuthContext';
import { FuncCall, callAxiosGet } from '../../../tmsutil';

interface Props {
  call: FuncCall;
  diffTgt: PosWeiTarget | null;
  setDiffTgt: React.Dispatch<React.SetStateAction<PosWeiTarget | null>>;
}

export default function DiffOrderDlg({ call, diffTgt, setDiffTgt }: Props) {
  const [diffT0, setDiffT0] = useState<string>('');
  const [diffT1, setDiffT1] = useState<string>('');
  const [diffTgtWeiStr, setDiffTgtWeiStr] = useState<string>('');
  const [diffVhIn, setDiffVhIn] = useState<string>('');
  const [diffTgtMin, setDiffTgtMin] = useState<number | null>(null);
  const [diffTgtMax, setDiffTgtMax] = useState<number | null>(null);

  const [chtVhWeiOpts, setChtVhWeiOpts] = useState<Highcharts.Options>({
    chart: {
      type: 'column',
      width: 470,
      height: 150,
      marginLeft: 50,
    },
    credits: { enabled: false },
    title: { text: '펀드별 비중(%)', margin: 0, style: { fontSize: '14px' } },
    legend: { enabled: false },
    yAxis: { title: { text: null } },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
    accessibility: { enabled: false },
  });

  const [chtVhQtyOpts, setChtVhQtyOpts] = useState<Highcharts.Options>({
    chart: {
      type: 'column',
      width: 470,
      height: 150,
      marginLeft: 50,
    },
    credits: { enabled: false },
    title: { text: '예상 주문 수량', margin: 0, style: { fontSize: '14px' } },
    legend: { enabled: false },
    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
    accessibility: { enabled: false },
  });

  const { user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const setVhWeiChart = () => {
    if (!diffTgt) return;
    const t = diffTgt;
    const params = {
      d: t.d,
      tgt: t.tgt,
      st: t.stId,
      prod: t.prodId,
      rstr: t.rstr,
      diffTgtWei: diffTgtWeiStr.toNumber(),
      suspended: t.suspended ?? false,
    };
    callAxiosGet({
      m,
      logger,
      url: '/Front/Target/VhWeiChart',
      params,
      onSuccess: (data) => {
        setDiffTgtMin(data.diffTgtMin);
        setDiffTgtMax(data.diffTgtMax);
        const { vhs, weis, qtys } = data;
        const minW = Number(_.min(weis) ?? 0) - 0.2;
        const maxW = Number(_.max(weis) ?? 0) + 0.2;
        setChtVhWeiOpts((p) => ({
          ...p,
          xAxis: { categories: vhs },
          yAxis: { title: { text: null }, min: minW, max: maxW },
          series: [{ data: weis, type: 'column' }],
        }));
        setChtVhQtyOpts((p) => ({
          ...p,
          xAxis: { categories: vhs },
          series: [
            {
              data: qtys,
              type: 'column',
              color: 'red',
              negativeColor: 'blue',
            },
          ],
        }));
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setVhWeiChart(), [diffTgt]);

  const genDiffOrder = () => {
    if (!diffTgt) {
      m.alert('대상 미선택');
      return;
    }
    const t = diffTgt;
    if (t.rstr !== PosRstrTy.None) {
      m.alert('매매제한 없는 종목만 가능');
      return;
    }
    const diffTgtWei = diffTgtWeiStr.toNumber();
    if (diffTgtWei === 0) {
      m.alert('목표 비중이 0이면 전량청산을 이용하세요');
      return;
    }
    if (diffTgtWei != null && diffTgtMax != null && diffTgtWei > diffTgtMax) {
      m.alert('목표 비중이 펀드별 비중 최대값 초과');
      return;
    }
    if (diffTgtWei != null && diffTgtMin != null && diffTgtWei < diffTgtMin) {
      m.alert('목표 비중이 펀드별 비중 최소값 미만');
      return;
    }

    const params = {
      d: t.d,
      tgt: t.tgt,
      st: t.stId,
      prod: t.prodId,
      tgtVh: diffTgt.vhId,
      diffTgtWei: diffTgtWei ?? '',
      diffVhIn,
      diffT0,
      diffT1,
    };
    call('GenDiffOrder', params);
  };

  const diffDlgFooter = (
    <div className="d-flex justify-content-end children-ms-3">
      <Button onClick={() => setDiffTgt(null)} btnRole='Cancel' label="취소" />
      <Button
        onClick={() => {
          genDiffOrder();
          setDiffTgt(null);
        }}
        btnRole='Ok'
        label="주문 생성"
      />
    </div>
  );

  return (
    <Dialog
      header={`[Diff 주문 생성] ${diffTgt?.prodNm}`}
      footer={diffDlgFooter}
      style={{ width: '500px' }}
      visible={diffTgt != null}
      onHide={() => setDiffTgt(null)}
    >
      <div className="m-0">
        <HighchartsReact highcharts={Highcharts} options={chtVhWeiOpts} />
        <HighchartsReact highcharts={Highcharts} options={chtVhQtyOpts} />
        <div id="chVhQty" />
        <div className="row children-mb-1">
          <div className="col-5">주문 시간:</div>
          <div className="col-7">
            <span>시작:</span>
            <input
              type="text"
              size={4}
              placeholder="즉시"
              readOnly={user?.isAdvisor}
              value={diffT0}
              onChange={(e) => setDiffT0(e.target.value)}
            />
            &nbsp;
            <span>종료:</span>
            <input
              type="text"
              size={4}
              placeholder="장종료"
              readOnly={user?.isAdvisor}
              value={diffT1}
              onChange={(e) => setDiffT1(e.target.value)}
            />
            &nbsp;&nbsp;
          </div>
          <div className="col-5">목표 비중 (%, 생략가능):</div>
          <div className="col-7">
            <input
              type="text"
              size={6}
              placeholder={`${diffTgt?.vhId || ''} 기준`}
              value={diffTgtWeiStr}
              onChange={(e) => setDiffTgtWeiStr(e.target.value)}
              onBlur={() => setVhWeiChart()}
            />
            (가능 범위 : {diffTgtMin} ~ {diffTgtMax})
          </div>
          <div className="col-5">일부 펀드 지정 (생략가능):</div>
          <div className="col-7">
            <input
              type="text"
              size={20}
              placeholder="(예) TTH,TTA"
              readOnly={user?.isAdvisor}
              value={diffVhIn}
              onChange={(e) => setDiffVhIn(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
