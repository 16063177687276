import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { gradationStyle, parseBool } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useAuthState } from '../../Auth/AuthContext';
import { DftBtnStyle } from '../../../AppTypes';
import { callAxiosGet } from '../../../tmsutil';
import { LsWeiBySec } from '../../../Tms/Pf';
import DVhStSelector from '../../../shared/DVhStSelector';

export default function PfAnalMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || info?.dftVh || '';
  const st =
    searchParams.get('st') ||
    (vh === info?.dftVh ? info?.dftSt || '' : 'Whole');

  const grp: boolean = parseBool(searchParams.get('grp')) ?? true;
  const useTgt: boolean = parseBool(searchParams.get('useTgt')) ?? false;

  const [secWeis, setSecWeis] = useState<LsWeiBySec[]>([]);

  const getData = () => {
    if (!d || !vh || !st) return;
    const params = { d, vh, st, grp, useTgt };
    callAxiosGet({
      m,
      logger,
      url: '/Front/PfAnal/PfAnal',
      params,
      onSuccess: (data) => {
        setSecWeis(data.sec);
      },
    });
  };

  useEffect(getData, [d, vh, st, grp, useTgt]);

  const urlPar = {
    d,
    vh,
    st,
    grp: grp.toString(),
    useTgt: useTgt.toString(),
  };

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DVhStSelector
          value={[d, vh, st]}
          onChange={(d_, vhId, stId) =>
            setSearchParams({
              ...urlPar,
              d: d_,
              vh: vhId || '',
              st: stId || '',
            })
          }
          vh={{ smicIvy: true }}
          st={{ whole: true, smicIvy: true }}
        />
        <label htmlFor="grp">
          <input
            type="checkbox"
            checked={grp}
            id="grp"
            onChange={(e) =>
              setSearchParams({ ...urlPar, grp: e.target.checked.toString() })
            }
          />
          그룹
        </label>
        <label htmlFor="useTgt">
          <input
            type="checkbox"
            checked={useTgt}
            id="useTgt"
            onChange={(e) =>
              setSearchParams({
                ...urlPar,
                useTgt: e.target.checked.toString(),
              })
            }
          />
          주문 타겟 반영
        </label>
        <button type="button" className={DftBtnStyle} onClick={() => getData()}>
          Get
        </button>
      </div>

      <hr className="narrow light" />
      <SimpleGrid
        data={secWeis}
        columns={[
          'sec',
          'lnm',
          'ltw',
          'snm',
          'stw',
          'secltw',
          'secstw',
          'secntw',
        ]}
        headers={['Sec', 'Long', '%', 'Short', '%', 'L%', 'S%', 'Net']}
        args={{
          widths: { sec: 70, lnm: 180, snm: 180 },
          rowSpanFns: {
            sec: (r0, r1) => r0.original.sec === r1.original.sec,
            secltw: (r0, r1) => r0.original.sec === r1.original.sec,
            secstw: (r0, r1) => r0.original.sec === r1.original.sec,
            secntw: (r0, r1) => r0.original.sec === r1.original.sec,
          },
          meta: { dftColWidth: 35 },
          dftFormatter: (v) => v?.toFixed(1),
          dftStyler: (v, c) => {
            const textAlign = c === 'sec' ? 'center' : undefined;
            const fontWeight = c.isIn('sec', 'ltw', 'stw', 'secntw')
              ? 'bold'
              : undefined;
            let col: React.CSSProperties | undefined;
            if (c === 'ltw') col = gradationStyle((v as number) * 30);
            if (c === 'stw') col = gradationStyle(-(v as number) * 30);
            if (c === 'secntw') col = { color: v?.getSignColor() }
            return { textAlign, fontWeight, ...col };
          },
        }}
      />
    </>
  );
}
