import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import getAppRoutes from './AppRoutes';
import Layout from './pages/Layout';
import Login, { onSilentRefresh } from './pages/Auth/Login';
// https://learn.microsoft.com/en-us/javascript/api/@microsoft/signalr/?view=signalr-js-latest
import { useAuthDispatch, useAuthState } from './pages/Auth/AuthContext';
import { getMainAxios, utilGet } from './tmsutil';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import ResetPassword from './pages/Auth/ResetPassword';

function App() {
  const ax = getMainAxios();
  ax.defaults.withCredentials = true;

  const isProduction = process.env.NODE_ENV === 'production';
  ax.defaults.baseURL = '/api';
  if (isProduction) {
    ax.defaults.baseURL = '/next/api';
  }
  const signalrUrl = isProduction ? '/next/hubs' : '/hubs';

  const [infoMsg, setInfoMsg] = useState<string | null>(null);

  const { user, connection } = useAuthState();
  const authDispatch = useAuthDispatch();

  const { msgBox: m, logger } = useMessageState();

  const [cookie] = useCookies();

  useEffect(() => {
    if (window.location.pathname.indexOf('ResetPassword') >= 0) {
      // console.log('rest password : no refresh')
      return; // 자동 로그인 안되게.
    }

    // F5 refresh 하면 user 등 메모리 정보는 없어지니 쿠키 정보로 silentrefresh
    if (cookie.userid && !user) {
      // F5시 로긴 화면 잠깐 뜨는게 없애려면
      // react-async로 onSilentRefresh 완료 전엔 로딩 화면 보여주기
      onSilentRefresh(m, logger, authDispatch);
    }
  }, [cookie.userid, user, authDispatch]);

  useEffect(() => {
    if (user && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${signalrUrl}/main`, {
          accessTokenFactory: () => user.accessToken,
        })
        .withAutomaticReconnect([3000, 6000, 9000, 12000, 15000])
        .build();

      authDispatch({ type: 'NEW_CONNECTION', connection: newConnection });
    }
  }, [user, connection, signalrUrl, authDispatch]);

  useEffect(() => {
    // console.log('connection changed', connection)
    if (connection) {
      connection.onreconnecting(() => {
        // setInfoMsg('reconnecting..')
        authDispatch({ type: 'SET_CONNECTED', connected: false });
      });
      connection.onreconnected(() => {
        setInfoMsg(null);
        authDispatch({ type: 'SET_CONNECTED', connected: true });
      });
      connection.onclose(() => {
        setInfoMsg('disconnected');
        authDispatch({ type: 'SET_CONNECTED', connected: false });
        authDispatch({ type: 'LOGOUT' });
      });
      if (connection.state === 'Disconnected') {
        connection
          .start()
          .then(() => {
            setInfoMsg(null);
            authDispatch({ type: 'SET_CONNECTED', connected: true });
          })
          .catch(() => {
            setInfoMsg('connection failed');
            authDispatch({ type: 'SET_CONNECTED', connected: false });
            authDispatch({ type: 'LOGOUT' });
          });
      }
    }
  }, [connection, authDispatch]);

  useEffect(() => {
    if (!user) return;
    utilGet(m, logger, 'AppInfo', {}, (data) =>
      authDispatch({ type: 'SET_APPINFO', info: data }),
    );
  }, [user, authDispatch]);

  if (!user) {
    // 구 웹의 로그인 화면서 로그인해야.
    const home = '/'; // isProduction ? '/next/' : '/';
    return (
      <Routes>
        <Route index element={<Login />} />
        <Route path='/Auth/ResetPassword' element={<ResetPassword />} />
        <Route
          path="*"
          element={
            <p>
              접속이 끊겼습니다.
              <a href={home}>첫화면</a>
              으로 가세요
            </p>
          }
        />
      </Routes>
    );
  }

  return (
    <Layout>
      {infoMsg && (
        <div className="alert alert-warning alert-slim">{infoMsg}</div>
      )}
      <div id="globalProcessingMsg" style={{ display: 'none' }}>
        요청 처리 중...
      </div>
      <Routes>
        {getAppRoutes().map((route) => {
          const { element, path, index } = route;
          return (
            <Route
              key={index ? '/' : path}
              path={`${isProduction ? '/next' : ''}${path}`}
              index={index}
              element={element}
            />
          );
        })}
      </Routes>
    </Layout>
  );
}

export default App;
