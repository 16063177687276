import React, { ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import _ from 'lodash';
import NavMenu from './NavMenu';
import { useAuthState } from './Auth/AuthContext';

export default function Layout({ children }: { children: ReactNode }) {
  const isProduction = process.env.NODE_ENV === 'production';
  const home = isProduction ? '/next/' : '/';

  const { user, info } = useAuthState();
  const { pathname, search } = useLocation();
  const noLeftMenu = [home, `${home}Chat/Chatbot`].contains(pathname);
  const pathtok = pathname.split('/');
  const grpIdx = isProduction ? 2 : 1;
  const currGrp = pathtok.length > grpIdx && pathtok[grpIdx];
  const currGrpMenus = (user?.menus ?? []).filter((m) => m.grp === currGrp);
  const leftMenus = _.chain(currGrpMenus)
    .groupBy((v) => v.subGrp)
    .map((grp, key) => ({ key, grp }))
    .value();
  const getActive = (url: string) => {
    const urlnorm = isProduction ? url : url.replace('/next/', '/');
    let active = urlnorm === pathname;
    if (pathname === '/PBS/LoanReq') {
      if (urlnorm.endsWith('keep=true'))
        active = search.startsWith('?keep=true');
      else active = active && !search.startsWith('?keep=true');
    }
    return active;
  };
  const currMenu = currGrpMenus.find((v) => getActive(v.url));
  if (currMenu) document.title = `[${currGrp}] ${currMenu.title}`;

  return (
    <>
      <NavMenu />
      <div className="container-fluid">
        {info?.isDevTest && (
          <div
            className="alert alert-danger"
            style={{ padding: '2px', fontWeight: 'bold' }}
          >
            THIS IS DEV TEST SITE
          </div>
        )}
        {noLeftMenu && children}
        {!noLeftMenu && (
          <div className="row">
            <div className="col-md-2 left-submenu">
              {leftMenus.map((subs) => (
                <React.Fragment key={subs.key}>
                  <h6 className="text-muted">{subs.key}</h6>
                  <ul className="nav nav-pills flex-md-column">
                    {subs.grp
                      .filter((m) => !m.hide)
                      .map((m) => (
                        <li key={m.url} className="nav-item">
                          {/* 기존 웹으로 연결되니 history 무관해짐. NavLink 대신 a 태그써야 클릭시 바로 이동됨 */}
                          {isProduction && (
                            <a
                              href={m.url}
                              className={[
                                getActive(m.url) ? 'active' : '',
                                m.obsolete ? 'obsolete' : '',
                                'nav-link',
                              ].join(' ')}
                            >
                              {user?.useEng && m.etitle != null
                                ? m.etitle
                                : m.title}
                            </a>
                          )}
                          {!isProduction && (
                            <NavLink
                              to={m.url.replace('/next/', '/')}
                              end
                              className={() =>
                                [
                                  getActive(m.url) ? 'active' : '',
                                  m.obsolete ? 'obsolete' : '',
                                  'nav-link',
                                ].join(' ')
                              }
                            >
                              {user?.useEng && m.etitle != null
                                ? m.etitle
                                : m.title}{' '}
                            </NavLink>
                          )}
                        </li>
                      ))}
                  </ul>
                  <hr style={{ visibility: 'hidden', margin: '2px 0' }} />
                </React.Fragment>
              ))}
            </div>
            <div className="col-md-10">{children}</div>
          </div>
        )}
      </div>
    </>
  );
}
