import React from 'react';
import Button, { ButtonProps } from 'tmslib/src/ui/Button';
import HelpBadge from './HelpBadge';
import { useAuthState } from '../pages/Auth/AuthContext';

export default function ButtonWithHelp({
  helpid,
  ...props
}: {
  helpid: string;
} & ButtonProps) {
  const { showHelp } = useAuthState();
  return (
    <span>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button {...props}/>
      {showHelp && <HelpBadge tagid={helpid} />}
    </span>
  );
}
