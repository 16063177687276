import React, { useEffect, useState } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { EX, LS } from '../../../Tms/Common';
import { useAuthState } from '../../Auth/AuthContext';
import { VhGrpTy } from '../../../Tms/Tms';
import { DftBtnStyle } from '../../../AppTypes';
import DateSelector from '../../../shared/DateSelector';
import ProdSelector, { SimpleProd } from '../../../shared/ProdSelector';
import { ValidData, callAxios, checkItems } from '../../../tmsutil';

interface Props {
  d: string;
  vh: string;
  st: string;
}

interface OrdVirtual {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  sgn: number;
  wei: number;
  ch: number;
  exitAll: boolean;
  done: boolean;
  errMsg: string | null;
  prodNm: string | null;
  tstr: string | null;
}

interface TgtUniv {
  Id: number;
  tgt: VhGrpTy;
  prodId: string;
  invalid: boolean;
  prodNm: string | null;
}

interface VirTgt {
  Id: number;
  ls: LS;
  prodId: string | null;
  prodNm: string | null;
  currW: number | null;
  ordW: number | null;
  exitAll: boolean;
  ordId: number | null;
  tgtW: number;
}

type OrderPar = {
  d: string;
  vh: string;
  st: string;
  prod: string;
  ls: LS;
  ch: number;
  exAll: boolean;
  repl: boolean;
};

interface K2memb {
  Id: number;
  prodId: string | null;
  prodNm: string | null;
  sec: string | null;
  wei: number | null;
}

interface K2sec {
  Id: number;
  sec: string | null;
  wei: number | null;
}

export default function VirtualOrder({ d, vh, st }: Props) {
  const [thisWeekOnly, setThisWeekOnly] = useState(true);
  const [orderHistory, setOrderHistory] = useState<OrdVirtual[]>([]);
  const [smicUniv, setSmicUniv] = useState<TgtUniv[]>([]);
  const [ords, setOrds] = useState<OrdVirtual[]>([]);
  const [tgtsL, setTgtsL] = useState<VirTgt[]>([]);
  const [tgtsS, setTgtsS] = useState<VirTgt[]>([]);
  const [viols, setViols] = useState<string[]>([]);
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const [ordDate, setOrdDate] = useState<string>(info?.nextBizDay ?? '');
  const [prod, setProd] = useState<SimpleProd | null>(null);
  const [ls, setLs] = useState(LS.L);
  const [weiCh, setWeiCh] = useState<number | null>(null);
  const [exAll, setExAll] = useState(false);

  const [k2memb, setK2memb] = useState<K2memb[]>([]);
  const [k2sec, setK2sec] = useState<K2sec[]>([]);

  const call = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
    title?: string,
    isGet?: boolean,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Fund/Portfolio/${func}`,
      params,
      onSuccess,
      isGet,
      title,
    });

  const get = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
  ) => call(func, params, onSuccess, undefined, true);

  useEffect(() => {
    const params = { d, vh, st, thisweek: thisWeekOnly };
    get('VirOrdHistory', params, (data) => setOrderHistory(data));
  }, [d, vh, st, thisWeekOnly]);

  useEffect(() => {
    if (vh !== 'SMIC') return;
    const params = { tgt: 'SMIC', invalid: false };
    get('TgtUniv', params, (data) => setSmicUniv(data));
  }, [d, vh]);

  useEffect(() => {
    get('K2memb', {}, (data) => {
      setK2memb(data.memb);
      setK2sec(data.sec);
    });
  }, []);

  const getOrds = () => {
    const params = { d, vh, st };
    get('VirOrds', params, (data) => {
      setOrds(data.ords);
      setTgtsL(data.tgtsL);
      setTgtsS(data.tgtsS);
      setViols(data.viols);
    });
  };

  useEffect(() => getOrds(), [d, vh, st]);

  const addOrder = (replInfo?: Partial<OrderPar>) => {
    if (!(replInfo?.prod ?? prod?.Id)) {
      m.alert('종목 미선택');
      return;
    }
    if (!(replInfo?.ch ?? weiCh)) {
      m.alert('비중 미입력');
      return;
    }
    const par: OrderPar = {
      d: ordDate,
      vh,
      st,
      prod: prod?.Id ?? '',
      ls,
      ch: weiCh ?? 0,
      exAll,
      repl: false,
      ...replInfo,
    };
    call('AddVirOrd', par, () => getOrds(), '주문 입력');
  };

  const updateWei = async (items: OrdVirtual[]) => {
    if (!checkItems(items, m, true)) return;
    const wei = await m.prompt('주문 비중');
    if (!wei) return;
    const w = Number(wei);
    const v = items[0];
    addOrder({
      d: v.d,
      prod: v.prodId,
      ls: v.ls,
      ch: w,
      exAll: v.exitAll,
      repl: true,
    });
  };

  const deleteOrder = (items: OrdVirtual[]) => {
    if (!checkItems(items, m, true)) return;
    const par = { id: items[0].Id };
    call('DelVirOrd', par, () => getOrds(), '주문 삭제');
  };

  const longOnly = vh === 'SMIC' || vh === 'IVY';
  const tgts = longOnly ? [tgtsL] : [tgtsL, tgtsS];

  return (
    <>
      <hr className="light narrow" />
      <h5>Order History</h5>
      <label htmlFor="virThisWeekOnly">
        <input
          type="checkbox"
          id="virThisWeekOnly"
          checked={thisWeekOnly}
          onChange={(e) => setThisWeekOnly(e.target.checked)}
        />{' '}
        this week only
      </label>
      <SimpleGrid
        data={orderHistory}
        columns={['d', 'tstr', 'prodId', 'prodNm', 'ls', 'ex', 'wei']}
        headers={[
          '반영일',
          '주문시각',
          '종목코드',
          '종목명',
          'L/S',
          'E/X',
          '주문(%)',
        ]}
        args={{
          meta: { showRowNum: true },
          widths: { tstr: 80 },
          dftStyler: (v, c, r) =>
            c === 'ls' || c === 'ex'
              ? { color: r.original.sgn?.getSignColor() }
              : null,
        }}
      />
      <hr className="light narrow" />
      {(vh === 'SMIC' || vh === 'IVY') && (
        <>
          <h5>Regulations</h5>
          <ul>
            {vh === 'SMIC' && (
              <>
                <li>유니버스는 코스피 200 종목 및 세션에서 다룬 종목</li>
                <li>세션 종목들 비중 합 최대 20%</li>
              </>
            )}
            {vh !== 'SMIC' && <li>유니버스는 코스피 200 종목</li>}
            <li>종목당 한도 5% 이내 (삼성전자는 30% 이내)</li>
            <li>코스피 200 GICS 산업군 기준 개별 섹터 비중 2배 이내</li>
            <li>주식 편입비 90% 이상, 100% 이하 유지</li>
            <li>월요일 아침 8시 59분까지 주문 입력</li>
            <li style={{ listStyleType: 'none', color: 'gray' }}>
              (매매수수료 0.1%, 매도세 0.2%, 운용보수 0%, 월요일 아침 시가 체결)
            </li>
          </ul>

          <ul className="alert alert-info" style={{ width: '700px' }}>
            <li>
              <a href="https://docs.google.com/document/d/1VZSt7S39nz2fFIVxNOYyB5Tn39M3ca-2JKzPP-4gC3U/edit?usp=sharing">
                주문 방법 매뉴얼
              </a>
            </li>
            <li>
              [2023-12-01 변경 사항] 주문 정정 및 삭제는 Working Orders 에서
              우클릭 메뉴 이용하세요.
            </li>
            <li>
              [주의 사항] 본 화면의 기능들은 <b>구글 크롬</b> 브라우저 위주로
              테스트되어 있습니다.
            </li>
            <li>
              문의 사항이 있거나 문제 발생 시, dev@timefolio.co.kr 로 바로
              연락주세요.
            </li>
          </ul>
        </>
      )}
      {vh === 'SMIC' && (
        <>
          <h5>SMIC Session Universe</h5>
          <SimpleGrid
            data={smicUniv}
            columns={['prodId', 'prodNm']}
            headers={['세션종목', '종목명']}
            args={{ meta: { showRowNum: true } }}
          />
        </>
      )}

      <h5>주문 입력</h5>
      <div style={{ marginLeft: '10px' }} className="children-me-2">
        <span>주문반영일:</span>
        <DateSelector
          name="ordDate"
          value={ordDate}
          onChange={(v) => setOrdDate(v)}
        />
        <span>종목:</span>
        <ProdSelector onChange={(v) => setProd(v)} />
        <select
          name="virLS"
          value={ls}
          onChange={(e) => setLs(e.target.value as LS)}
          disabled={longOnly}
        >
          <option value={LS.L}>L</option>
          <option value={LS.S}>S</option>
        </select>
        <span>주문(%):</span>
        <NumericInput
          name="virCh"
          width="70"
          value={weiCh}
          onChange={(v) => setWeiCh(v)}
        />
        <label htmlFor="virExAll">
          <input
            type="checkbox"
            id="virExAll"
            checked={exAll}
            onChange={(e) => setExAll(e.target.checked)}
          />
          전량 청산
        </label>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => addOrder()}
        >
          주문 입력
        </button>
      </div>

      <ul className="alert alert-danger alert-slim" style={{ width: '700px' }}>
        {viols.map((v, k) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={k}>{v}</li>
        ))}
      </ul>

      <h5>Working Orders</h5>
      <div style={{ marginLeft: '10px' }}>
        <SimpleGrid
          data={ords}
          columns={[
            'd',
            'tstr',
            'prodId',
            'prodNm',
            'ls',
            'ch',
            'exitAll',
            'errMsg',
          ]}
          headers={[
            '주문반영일',
            '주문시각',
            '종목코드',
            '종목명',
            'L/S',
            '비중',
            '전량청산',
            '에러',
          ]}
          args={{
            meta: {
              showRowNum: true,
              dftColWidth: 60,
              contextMenus: [
                { label: '비중 수정', callback: updateWei },
                { label: '주문 삭제', callback: deleteOrder },
              ],
            },
            widths: { tstr: 100, errMsg: 100 },
          }}
        />
      </div>

      {tgts.map((tgt, k) => (
        <div key={k === 0 ? 'L' : 'S'}>
          <h5>{k === 0 ? 'Long' : 'Short'} Target</h5>
          <div style={{ marginLeft: '10px' }}>
            <SimpleGrid
              data={tgt}
              columns={['prodId', 'prodNm', 'currW', 'ordW', 'tgtW']}
              headers={['종목코드', '종목명', '보유', '주문', '타겟']}
              args={{
                meta: { showRowNum: true, dftColWidth: 60 },
                dftFormatter: (v) => (typeof v === 'number' ? v.toFixed(2) : v),
                stylers: { ordW: (v) => ({ color: v?.getSignColor() }) },
              }}
            />
          </div>
        </div>
      ))}

      <hr className="light narrow" />
      <h5>코스피 200 종목 정보</h5>
      <SimpleGrid
        data={k2memb}
        columns={['Id', 'prodId', 'prodNm', 'sec', 'wei']}
        headers={['#', '종목코드', '종목명', '섹터', '비중(%)']}
        args={{ meta: { height: 400 }, widths: { sec: 100 } }}
      />
      <hr className="light narrow" />
      <h5>코스피 200 섹터 정보</h5>
      <SimpleGrid
        data={k2sec}
        columns={['sec', 'wei']}
        headers={['섹터', '비중(%)']}
        args={{ widths: { sec: 100 } }}
      />
    </>
  );
}
