import React, { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import * as go from 'gojs';
import SimpleGrid, { SimpleGridArgs } from 'tmslib/src/table/SimpleGrid';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  ValidData,
  callAxios,
  callAxiosGet,
} from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import {
  CronJob,
  cronJobSchema,
  Auth,
  CronJobPrereq,
  CronJobLog,
  CronJobReq,
  cronJobPrereqSchema,
  Cate,
} from '../../Tms/CronJob';

const currMenu = '/Back/CronJob';
const dftHeight = 800;
type T = { Id: number }; // 임의 IId 타입
type PageFunc =
  | 'CronJobReq'
  | 'CronJobLog'
  | 'CronJobPrereq'
  | 'CronJobPrereqNexts'
  | 'CronJobNexts'
  | 'DeleteCronJobLog'
  | 'SetDoneCronJob'
  | 'AddCronJobReq';

const cronJobDft: UrlGridArgs<CronJob> = {
  url: `${currMenu}/CronJob`,
  title: '작업 스케줄',
  // prettier-ignore
  columns: ['Id',	'cate',	'func',	'note',	'arg1',	't0',	't1',	'intv',	'rand',	'repeat',	'skipDays',	'skipOnKorHoli',	'eoyHoli',	'chan',	'runner',	'skip',	'auth',	'ord',	'devToo',	'endT',	'elapsed',	'ty',	'msg',],
  // prettier-ignore
  headers: ['Id',	'구분',	'함수',	'이름',	'arg',	't0',	't1',	'intv(초)',	'랜덤(초)',	'반복',	'휴일',	'한휴건',	'연말휴일',	'텔레',	'실행',	'skip',	'Auth',	'ord',	'DevToo',	'endT',	'elapsed',	'ty',	'msg',],
  editable: true,
  // prettier-ignore
  widths: {Id: 40,	func: 150,	note: 200,	arg1: 100,	t0: 60,	t1: 60,	repeat: 40,	rand: 70,	skipOnKorHoli: 40,	eoyHoli: 40,	skip: 40,	devToo: 40,	endT: 100,	msg: 200,},
  height: dftHeight,
  schema: cronJobSchema,
  dftStyler: (v, c, r) =>
    c === 'note' && r.original.auth === Auth.Back
      ? { backgroundColor: 'yellow' }
      : null,
};

// prettier-ignore
const cronJobPrereqPrevsDft: UrlGridArgs<CronJobPrereq> = {
  url: `${currMenu}/CronJobPrereq`,
  title: '선행 작업',
  columns: ['jobId', 'jobNm', 'preId', 'preNm'],
  headers: ['jobId', 'jobNm', 'preId', 'preNm'],
  widths: { jobNm: 200, preNm: 200 },
  height: 100,
  editable: true,
  schema: cronJobPrereqSchema,
};

// prettier-ignore
const cronJobPrereqNexts: SimpleGridArgs<CronJobPrereq> = {
  title: '후행 작업',
  headers: ['jobId', 'jobNm', 'nextId', 'nextNm'],
  widths: { preNm: 200, jobNm: 200 },
};

// 일반사용자
// prettier-ignore
const userColFlds = [
    'Id', 'cate', 'note', 't0', 't1', 'intv',
    'repeat', 'skipDays', 'skipOnKorHoli', 'eoyHoli',
    'chan', 'ord', 'skip', 'endT', 'elapsed', 'ty', 'msg',
  ];
// prettier-ignore
const userColHeaders = [
    'Id', '구분', '이름', 't0', 't1', 'intv(초)',
    '반복', '휴일', '한휴건', '연말휴일',
    '텔레',  'ord', 'skip', 'endT', 'elapsed', 'ty', 'msg',
  ];

// prettier-ignore
const cronJobReqDft: SimpleGridArgs<CronJobReq> = {
  title: '작업 요청',
  headers: ['reqT', 'doneT', 'jobNm', 'userNm', 'msg'],
  widths: { reqT: 120, doneT: 120, jobNm: 200, msg: 250 },
};

// prettier-ignore
const cronJobLogDft: SimpleGridArgs<CronJobLog> = {
  title: '최근 로그',
  headers: ['startT', 'endT', 'ty', 'msg'],
  widths: { startT: 120, endT: 120, ty: 80, msg: 250 },
};

export default function CronJobMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [sortByT0, setSortByT0] = useState<boolean>(false);
  const [hideSkip, setHideSkip] = useState<boolean>(true);
  const [jobId, setJobId] = useState<number>(0);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [refreshNeededReq, setRefreshNeededReq] = useState(0);
  const [jobList, setGridJobList] =
    useState<UrlGridArgs<CronJob>>(emptyGridArgs);
  const [jobPrereq, setGridJobPrereq] =
    useState<UrlGridArgs<CronJobPrereq>>(emptyGridArgs);
  const [jobNexts, setSimpleJobNexts] = useState<T[] | null>(null);
  const [jobReq, setSimpleJobReq] = useState<CronJobReq[] | null>(null);
  const [jobLog, setSimpleJobLog] = useState<CronJobLog[] | null>(null);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [isGoJS, setIsGoJS] = useState<boolean>(false);

  const cronJobPrereqPrevs: UrlGridArgs<CronJobPrereq> = {
    ...cronJobPrereqPrevsDft,
    onDataChange: (data, res) => setSimpleJobNexts(res.data.extras),
  };

  const clearArgs = () => {
    setGridJobList(emptyGridArgs);
    setGridJobPrereq(emptyGridArgs);
    setSimpleJobNexts(null);
    setSimpleJobReq(null);
    setSimpleJobLog(null);
  };

  const getParams = (title: string) => {
    if (title === '선행 작업' || title === '후행 작업') {
      return { id: jobId };
    }
    return { d, sortByT0, hideSkip };
  };

  const callGet = (funcNm: PageFunc, params: unknown) =>
    callAxiosGet({
      m,
      logger,
      url: `${currMenu}/${funcNm}`,
      params,
      onSuccess: (data) => {
        if (funcNm === 'CronJobReq') {
          // 작업요청
          setSimpleJobReq(data);
        } else if (funcNm === 'CronJobLog') {
          // 최근로그
          setSimpleJobLog(data);
        }
      },
    });

  const handleAxiosResult = (
    data: ValidData,
    res: AxiosResponse,
    funcNm?: PageFunc | null,
  ) => {
    if (!res.data.warnings?.length) {
      m.alert('OK');
    } else {
      m.alert(res.data.warnings);
    }
    if (funcNm === 'DeleteCronJobLog') {
      callGet('CronJobLog', { d, id: jobId });
    }
  };

  const call = async (
    funcNm: PageFunc,
    params: unknown,
    args?: {
      confirmMsg?: string | null;
    },
  ) => {
    if (funcNm === 'AddCronJobReq') {
      const now = new Date();
      const day7 = now.addDays(-7).toFormatString('yyyy-MM-dd');
      if (d < day7) {
        if (!(await m.confirm('일주일 이전 스케줄. 진행하시겠습니까?'))) return;
      }
    }
    callAxios({
      m,
      logger,
      url: `${currMenu}/${funcNm}`,
      params,
      confirmMsg: args?.confirmMsg,
      onSuccess: (data, res) => handleAxiosResult(data, res, funcNm),
    });
  };

  const cronJobReq = {
    ...cronJobReqDft,
    meta: {
      dftColWidth: 90,
      useGlobalFilter: false,
      onRowClick: (r: Row<CronJobReq>) => {
        setJobId(r.original.jobId);
        callGet('CronJobLog', { d, id: r.original.jobId });
        let { msg } = r.original;
        if (msg != null) {
          msg = msg.replace(/;;/g, '\n');
          setWarnResMsg(msg);
        }
      },
    },
  };

  // urlgrid2 선행작업
  const queryForCronJobPrereq = () => {
    setGridJobPrereq(emptyGridArgs);
    setRefreshNeededReq((p) => p + 1);
    setGridJobPrereq(cronJobPrereqPrevs);
  };

  const cronjob = {
    ...cronJobDft,
    meta: {
      dftColWidth: 50,
      contextMenus: [
        {
          label: '실행 요청',
          callback: (items: CronJob[]) =>
            call('AddCronJobReq', { d, id: items[0].Id }),
        },
        {
          label: '강제 완료 처리',
          callback: (items: CronJob[]) =>
            call(
              'SetDoneCronJob',
              { d, id: items[0].Id },
              { confirmMsg: `${items[0].note} 강제 완료 처리` },
            ),
        },
      ],
      onRowClick: (r: Row<CronJob>) => {
        setJobId(r.original.Id); // 아래 jobid 적용 안됨
        queryForCronJobPrereq(); // 선행
        callGet('CronJobLog', { d, id: r.original.Id });
      },
    },
  };

  const queryForCronjob = () => {
    setGridJobList(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    const args = cronjob as UrlGridArgs<CronJob>;
    if (user?.isDev) {
      setGridJobList(args);
    } else {
      const iargsNotDev = { ...args };
      iargsNotDev.columns = userColFlds as (keyof CronJob)[];
      iargsNotDev.headers = userColHeaders;
      setGridJobList(iargsNotDev);
    }
  };

  const cronJobLog = {
    ...cronJobLogDft,
    meta: {
      dftColWidth: 100,
      contextMenus: [
        {
          label: '삭제',
          callback: (objs: CronJobLog[]) =>
            call('DeleteCronJobLog', { id: objs[0].Id }),
        },
      ],
      onRowClick: (r: Row<CronJobLog>) => {
        setJobId(r.original.jobId);
        let { msg } = r.original;
        if (msg != null) {
          msg = msg.replace(/;;/g, '\n');
          setWarnResMsg(msg);
        }
      },
    },
  };

  // Invalid div id; div already has a Diagram associated with it. TODO
  const setCronJobDAG = (cate: Cate, inodes: CronJob[], ilinks: CronJob[]) => {
    const $ = go.GraphObject.make;
    const dag = new go.Diagram(`dag${cate}`, {
      'toolManager.hoverDelay': 200,
    });

    const shapeConfig = {
      figure: 'RoundedRectangle',
      fill: 'lightgray',
      strokeWidth: 3,
      width: 130,
      height: 30,
    };
    const textConfig = { margin: 5, width: 120 }; // , verticalAlignment: go.Spot.Center }

    const nodeInfo = (job: CronJob) => {
      // Tooltip info for a node data object
      const str = `${job.t0} ${job.func} ${job.Id}`;
      if (job.msg) str.concat('\n').concat(job.msg);
      return str;
    };

    dag.nodeTemplate = $(
      go.Node,
      'Auto',
      $(go.Shape, shapeConfig, new go.Binding('stroke', 'color')),
      $(go.TextBlock, textConfig, new go.Binding('text', 'note')),
      {
        // define a tooltip for each node that displays the color as text
        toolTip: $(
          'ToolTip',
          $(go.TextBlock, { margin: 4 }, new go.Binding('text', '', nodeInfo)),
        ),
        // define a context menu for each node
        contextMenu: $(
          'ContextMenu',
          $('ContextMenuButton', { margin: 2 }, $(go.TextBlock, '실행 요청'), {
            click(e: go.InputEvent, obj: go.GraphObject) {
              call('AddCronJobReq', { d, id: obj.part?.data.Id });
            },
          }),
          $(
            'ContextMenuButton',
            { margin: 2 },
            $(go.TextBlock, '강제 완료 처리'),
            {
              click(e: go.InputEvent, obj: go.GraphObject) {
                call(
                  'SetDoneCronJob',
                  {
                    d,
                    id: obj.part?.data.Id,
                  },
                  { confirmMsg: `${obj.part?.data.note} 강제 완료 처리` },
                );
              },
            },
          ),
        ),
      },
    ); // dag.nodeTemplate

    // function -> map
    const nodes = inodes.map((r) => {
      const col =
        ifesleExpr(
          [r.ty === 'FinalFail', 'red'],
          [r.ty === 'Error', 'pink'],
          [r.ty === 'Done', 'green'],
          [r.ty === 'NotYet', 'yellow'],
          [r.ty === 'Error', 'pink'],
        ) ?? 'lightgray';
      const notet = r.repeat ? `Ⓡ ${r.note}` : r.note;
      return Object.assign(r, { key: r.Id, color: col, note: notet });
    });

    dag.model = new go.GraphLinksModel(nodes, ilinks);

    if (cate === Cate.Open || cate === Cate.Mkt) {
      dag.layout = new go.TreeLayout({
        nodeSpacing: 5,
        sorting: go.TreeLayout.SortingAscending,
        comparer: function tree(va, vb) {
          const da = va.node?.data;
          const db = vb.node?.data;
          if (da.t0 < db.t0) return -1;
          if (da.t1 > db.t1) return 1;
          return 0;
        },
      });
    } else {
      dag.layout = new go.GridLayout({
        spacing: new go.Size(5, 5),
        sorting: go.GridLayout.Ascending,
        comparer: function grid(va, vb) {
          const da = va.data;
          const db = vb.data;
          if (da.t0 < db.t0) return -1;
          if (da.t1 > db.t1) return 1;
          return 0;
        },
      });
    }

    const addListener = (e: go.DiagramEvent) => {
      const part = e.subject.part as go.DiagramEvent;
      if (part instanceof go.Node) {
        setJobId(part.data.Id);
        queryForCronJobPrereq(); // 선행
        callGet('CronJobLog', { d, id: part.data.Id }); // 최근 로그
      }
    };
    dag.addDiagramListener('ObjectSingleClicked', addListener);
  };

  // Array.prototype.map() expects a return value from arrow function
  // map -> forEach
  const getCronJobDAG = () => {
    const cates = [Cate.Open, Cate.Mkt, Cate.Real, Cate.MktR];
    cates.forEach((v) => {
      const cate = v;
      callAxiosGet({
        m,
        logger,
        url: `${currMenu}/CronJobDAG`,
        params: { d, cate },
        onSuccess: (data) => {
          const nodes: CronJob[] = data.nodes as CronJob[];
          const links: CronJob[] = data.links as CronJob[];
          setCronJobDAG(cate, nodes, links); // nodes, links
        },
      });
    });
  };

  useEffect(() => {
    clearArgs();
    if (!d) return;
    if (!isGoJS) {
      getCronJobDAG();
      setIsGoJS(true);
    }
    queryForCronjob();
    callGet('CronJobReq', { d });
  }, [d]);

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <div className="row">
        <div className="col">
          <DateSelector
            value={d}
            onChange={(date) => {
              if (date !== d) {
                setSearchParams({ d: date });
                setIsGoJS(false);
                navigate(0); // reload
              }
            }}
          />
          &nbsp;&nbsp;
          <button
            key="재조회"
            type="button"
            onClick={() => {
              setIsGoJS(false);
              navigate(0);
            }}
          >
            재조회
          </button>
          &nbsp;&nbsp;
          <label htmlFor="sortByT0">
            <input
              type="checkbox"
              checked={sortByT0 ?? false}
              id="sortByT0"
              onChange={(e) => setSortByT0(e.target.checked)}
            />
            시간순
          </label>
          &nbsp;&nbsp;
          <label htmlFor="hideSkip">
            <input
              type="checkbox"
              checked={hideSkip ?? false}
              id="hideSkip"
              onChange={(e) => setHideSkip(e.target.checked)}
            />
            Skip제외
          </label>
        </div>
      </div>
      <hr className="narrow light" />
      <div className="row" style={{ width: '1600px' }}>
        <div className="col-8">
          <div
            id="dagOpen"
            style={{
              width: '100%',
              height: '700px',
              backgroundColor: '#DAE4E4',
            }}
          >
            {' '}
          </div>
        </div>
        <div className="col-4">
          <div
            id="dagMkt"
            style={{
              width: '100%',
              height: '700px',
              backgroundColor: '#DAE4E4',
            }}
          >
            {' '}
          </div>
        </div>
        <div className="col-8">
          <div
            id="dagReal"
            style={{
              width: '100%',
              height: '200px',
              backgroundColor: '#DAE4E4',
            }}
          >
            {' '}
          </div>
        </div>
        <div className="col-4">
          <div
            id="dagMktR"
            style={{
              width: '100%',
              height: '200px',
              backgroundColor: '#DAE4E4',
            }}
          >
            {' '}
          </div>
        </div>
      </div>
      <hr className="narrow light" />
      <UrlGrid
        args={jobList}
        params={getParams(jobList.title)}
        refreshNeeded={refreshNeeded}
      />
      {warnResMsg && (
        <div className="alert alert-slim alert-info like-pre">{warnResMsg}</div>
      )}
      <hr className="light narrow" />
      <div className="row" style={{ width: '1600px' }}>
        <div className="col-4">
          <UrlGrid // 선행작업
            args={jobPrereq}
            params={getParams(jobPrereq.title)}
            refreshNeeded={refreshNeededReq}
          />
        </div>
        <div className="col-4">
          {jobNexts && (
            <SimpleGrid // 후행작업
              data={jobNexts as CronJobPrereq[]}
              columns={['preId', 'preNm', 'jobId', 'jobNm']}
              headers={cronJobPrereqNexts.headers}
              args={cronJobPrereqNexts}
            />
          )}
        </div>
      </div>
      <hr className="light narrow" />
      <div className="row" style={{ width: '1600px' }}>
        <div className="col-6">
          {jobReq && (
            <SimpleGrid
              data={jobReq}
              columns={['reqT', 'doneT', 'jobNm', 'userNm', 'msg']}
              headers={cronJobReq.headers}
              args={cronJobReq}
            />
          )}
        </div>
        <div className="col-6">
          {jobLog && (
            <SimpleGrid
              data={jobLog}
              columns={['startT', 'endT', 'ty', 'msg']}
              headers={cronJobLog.headers}
              args={cronJobLog}
            />
          )}
        </div>
      </div>
    </div>
  );
}
