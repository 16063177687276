import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType } from 'tmslib/src/table/DataGrid';
import { AlgoStat } from '../../../Tms/Ems';


interface Props {
  algoStat: AlgoStat[];
}

const columnHelper = createColumnHelper<AlgoStat>();
const columns = [
  columnHelper.accessor('prodId', {
    header: '종목',
    size: 70,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodNm', {
    header: '종목명',
    size: 150,
    meta: { frozen: true },
  }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 30,
    meta: {
      frozen: true,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('ex', {
    header: 'EX',
    size: 30,
    meta: {
      frozen: true,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('swap', {
    header: 'Swap',
    size: 50,
    meta: { type: ColumnType.Checkbox, frozen: true },
  }),
  columnHelper.accessor('fq', {
    header: '체결량',
    size: 70,
    meta: { frozen: true, formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fa', {
    header: '금액(백만원)',
    size: 80,
    meta: {
      frozen: true,
      formatter: (v) => v?.toFixedWithComma(0),
    },
  }),
].map((v) => v as ColumnDef<AlgoStat, unknown>);

export default function AlgoStatTab({ algoStat }: Props) {
  const [data, setData] = useState<AlgoStat[]>(algoStat);
  useEffect(() => setData(algoStat), [algoStat]);

  const meta: TableMeta<AlgoStat> = {
    height: 500,
    useGlobalFilter: true,
  };

  return <DataGrid data={data} columns={columns} meta={meta} />;
}
