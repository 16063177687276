import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useAuthState } from '../Auth/AuthContext';
import {
  callAxiosGet,
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
} from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { ConflictGrid, MezRel } from '../../Tms/MO';
import { DftBtnStyleMx } from '../../AppTypes';

const page = '/Middle/Conflict';
const btnClass = DftBtnStyleMx;

// prettier-ignore
const columnDft = ['prodId', 'prodNm', 'd', 'hbq', 'hbp', 'abq', 'abp', 'bpRto', 'bqRto', 'hpos', 'apos', 'c']
// prettier-ignore
const headerDft = ['종목코드', '종목명', '날짜', '펀드매수량', '펀드매수가', 'ARS매수량', 'ARS매수가', '가격비율', '관여율', '펀드포지션', 'ARS포지션', '종가']
// prettier-ignore
const conflict: UrlGridArgs<ConflictGrid> = {
  url: `${page}/Data`,
  title: '매수 선행',
  columns: columnDft as (keyof ConflictGrid)[],
  headers: headerDft,
  widths: {},
  meta: {
    dftColWidth: 90,
    useGlobalFilter: false,
  },
  height: undefined,
  // prettier-ignore
  auxColumns: [
    [...columnDft],
    ['prodId', 'prodNm', 'hq', 'hp', 'aq', 'ap'],
  ],
  // prettier-ignore
  auxHeaders: [
    [...headerDft],
    ['종목코드', '종목명', '펀드수량', '펀드가격', 'ARS수량', 'ARS가격'],
  ],
  auxGridArgs: [{ title: '매도 선행' }, { title: '통정 매매' }],
};

export default function Conflict() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [gridConflict, setGridConflict] =
    useState<UrlGridArgs<ConflictGrid>>(emptyGridArgs);
  const [gridMezRel, setGridMezRel] = useState<MezRel[]>([]);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const queryForConflict = () => {
    setGridConflict(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridConflict(conflict);
  };

  const getData = () => {
    queryForConflict();
    callAxiosGet({
      m,
      logger,
      url: `${page}/MezRel`,
      params: { d },
      onSuccess: (data) => {
        if (data) {
          setGridMezRel(data);
        }
      },
    });
  };

  useEffect(() => {
    getData();
  }, [d]);

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => queryForConflict()}
        >
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <UrlGrid
          args={gridConflict}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      </div>
      <div>
        <SimpleGrid
          data={gridMezRel}
          // prettier-ignore
          headers={['종목코드', '종목명', '거래소', '전략', '롱진입', '롱청산', '숏진입', '숏청산', '포지션']}
          // prettier-ignore
          columns={['prodId', 'prodNm', 'exch', 'stId', 'le', 'lx', 'se', 'sx', 'pos']}
          args={{
            title: '보유 메자닌 관련 매매',
            meta: { dftColWidth: 100 },
            dftFormatter: (v, c) => {
              if (['le', 'lx', 'se', 'sx', 'pos'].indexOf(c) >= 0) {
                return v?.toFixedWithComma(0);
              }
              return v;
            },
          }}
        />
      </div>
    </div>
  );
}
