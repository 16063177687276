import React, { useEffect } from 'react';

export interface Tab {
  header: string | JSX.Element;
  content: JSX.Element;
}
export default function TabView({
  tabs,
  style,
}: {
  tabs: Tab[];
  style?: React.CSSProperties;
}) {
  const [currIdx, setCurrIdx] = React.useState(0);
  const currTab = tabs[currIdx];
  return (
    <>
      <nav>
        <div className="nav nav-tabs">
          {tabs.map((t, i) => (
            <button
              key={`tab_${i}`}
              className={`nav-link ${i === currIdx ? 'active' : ''} py-1 px-2`}
              style={{ fontSize: '13px' }}
              onClick={() => setCurrIdx(i)}
            >
              {t.header}
            </button>
          ))}
        </div>
      </nav>
      <div className='p-2'>{!!currTab && currTab.content}</div>
    </>
  );
}
