import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
  CellContext,
} from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { MessageBox, useMessageState } from 'tmslib/src/context/MessageContext';
import Button from 'tmslib/src/ui/Button';
import { LS } from '../../../Tms/Common';
import {
  OrdSt,
  OrdPrcTyValTxts,
  OrderTimeValTxts,
  OrdStState,
} from '../../../Tms/Ord';
import {
  updateOrdSts,
  UpdateOrdStField,
  reqOrderPermit,
  OrderPermitType,
  getUpdateFieldFromOrdStProp,
  OrdPermitTypeOnTarget,
  OrdPermitTypeOnTargetEtc,
} from './ordutil';
import { FuncCall, isKSFut, isKStk, isUStk } from '../../../tmsutil';
import { useAuthState } from '../../Auth/AuthContext';
import { DftBtnStyleMx } from '../../../AppTypes';
import OrderDialog, { OrderDlgArgs } from './OrderDialog';

interface props {
  call: FuncCall;
  stId: string | undefined;
  osts: OrdSt[];
}

const permitTypes = OrdPermitTypeOnTarget.concat(OrdPermitTypeOnTargetEtc);
const findPermitTy = (c: CellContext<OrdSt, null | undefined>) => {
  const ptys = permitTypes.filter((v) => {
    const isTy = c.row.original.errMsgDisp?.includes(v[1]);
    return isTy === true;
  });
  return ptys;
};

const columnHelper = createColumnHelper<OrdSt>();
const getColumns = (
  isAdvisor: boolean,
  setOrdStDlgArgs: React.Dispatch<React.SetStateAction<OrderDlgArgs>>,
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  call: FuncCall,
  m: MessageBox,
  stId: string | undefined,
) =>
  [
    columnHelper.accessor('modify', {
      header: '수정',
      cell: (c) => (
        <Button
          onClick={() => {
            setOrdStDlgArgs({
              title: '미등록 주문 수정',
              ordst: c.row.original,
              visible: true,
              setVisible: setModalVisible,
            });
          }}
          btnRole='Icon'
          icon='Pencil'
        />
      ),
      size: 37,
      meta: {
        frozen: true,
        styler: () => ({ textAlign: 'center' }),
      },
    }),
    columnHelper.accessor('delete', {
      header: '삭제',
      cell: (c) => (
        <Button
          onClick={async () => {
            if (!(await m.confirm('주문을 삭제하시겠습니까?'))) return;
            updateOrdSts(
              [c.row.original],
              UpdateOrdStField.Cancel,
              null,
              false,
              call,
              true,
              m,
            );
          }}
          btnRole='Icon'
          icon='X'
        />
      ),
      size: 37,
      meta: {
        frozen: true,
        styler: () => ({ textAlign: 'center' }),
      },
    }),
    columnHelper.accessor('tgtStr', { header: '펀드', size: 40 }),
    columnHelper.accessor('prodId', {
      header: '종목',
      size: 80,
      meta: {
        textAlign: 'center',
        frozen: true,
      },
    }),
    columnHelper.accessor('prodNm', {
      header: '종목명',
      size: 200,
      meta: {
        textAlign: 'center',
        frozen: true,
      },
    }),
    columnHelper.accessor('ls', {
      header: 'LS',
      size: 30,
      meta: {
        styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
        frozen: true,
      },
    }),
    columnHelper.accessor('ex', {
      header: 'EX',
      size: 30,
      meta: {
        styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
        frozen: true,
      },
    }),
    columnHelper.accessor('wei', {
      header: '비중*',
      size: 60,
      meta: { formatter: (v) => v.toFixedWithComma(4, 2) },
    }),
    columnHelper.accessor('exitAll', {
      header: '전량',
      size: 30,
      meta: { type: ColumnType.Checkbox, frozen: true },
    }),
    columnHelper.accessor('totQty', {
      header: '수량',
      size: 50,
      meta: { frozen: true },
    }),
    columnHelper.accessor('limitPrc', {
      header: '제한가*',
      size: 60,
    }),
    columnHelper.accessor('prcTy', {
      header: '유형*',
      size: 60,
      meta: {
        type: ColumnType.Combobox,
        valTxts: isAdvisor ? OrderTimeValTxts : OrdPrcTyValTxts,
      },
    }),
    columnHelper.accessor('hm0', {
      header: isAdvisor ? '시작T' : '시작T*',
      meta: { frozen: isAdvisor },
    }),
    columnHelper.accessor('hm1', {
      header: isAdvisor ? '종료T' : '종료T*',
      meta: { frozen: isAdvisor },
    }),
    columnHelper.accessor('note', { header: '메모*', size: 150 }),
    columnHelper.accessor('errMsgDisp', {
      header: '에러/참고',
      cell: (c) => {
        const o = c.row.original;
        return (
          <ul className="ord-msgs">
            {o.errMsgDisp && <li style={{ color: 'red' }}>{o.errMsgDisp}</li>}
            {o.warn && <li style={{ color: 'violet' }}>{o.warn}</li>}
            {o.info && <li>{o.info}</li>}
          </ul>
        );
      },
      size: 300,
      meta: {
        frozen: true,
        styler: () => ({ color: 'red', fontWeight: 'bold' }),
        title: (v) => v ?? '',
      },
    }),
    columnHelper.accessor('reqPermit', {
      header: '허용 ',
      size: 100,
      meta: {
        frozen: true,
        styler: () => ({ textAlign: 'center' }),
      },
      // cell: (c) => permitTpl(c),
      cell: (c) => {
        const permitlist = findPermitTy(c);
        if (stId === 'FHF' && permitlist.some(v => v[0] === OrderPermitType.FHF승인요)) {
          return (
            <Button
              key={`${c.row.original?.Id}재간접 승인`}
              label="재간접 승인"
              onClick={() => {
                reqOrderPermit(
                  [c.row.original],
                  OrderPermitType.FHF승인요,
                  call,
                  m,
                );
              }}
              title="FHF승인요"
              btnRole='Link'
            />
          );
        }

        const res = permitlist.map((v) => (
          <Button
            key={`${c.row.original?.Id}${v}`}
            label={v[1]}
            onClick={() => {
              if (v == null) {
                return;
              }
              reqOrderPermit(
                [c.row.original],
                v[0] as OrderPermitType,
                call,
                m,
              );
            }}
            title={v[0]}
            btnRole='Link'
          />
        ));

        if (stId === 'KHD2' && permitlist.some(v => v[0] === OrderPermitType.ETF한도초과)) {
          res.push(
            <Button
              key={`${c.row.original?.Id}ETF한도 초과`}
              label="ETF한도 초과"
              onClick={() => {
                reqOrderPermit(
                  [c.row.original],
                  OrderPermitType.ETF한도초과,
                  call,
                  m,
                );
              }}
              title="ETF한도 초과"
              btnRole='Link'
            />,
          );
        }
        return res;
      },
    }),
  ].map((v) => v as ColumnDef<OrdSt, unknown>);

export default function UnregisteredTab({ call, stId, osts }: props) {
  const [data, setData] = useState<OrdSt[]>(osts);
  useEffect(() => setData(osts), [osts]);

  const { user } = useAuthState();
  const { msgBox: m } = useMessageState();

  const update = (items: OrdSt[], fld: UpdateOrdStField, val?: string) =>
    updateOrdSts(items, fld, val ?? null, false, call, true, m);

  const [dgState, setDgState] = useState<DataGridState<OrdSt>>();
  const [modalVisible, setModalVisible] = useState(false);
  const [ordStDlgArgs, setOrdStDlgArgs] = useState<OrderDlgArgs>({
    title: '미등록주문',
    ordst: undefined,
    visible: modalVisible,
    setVisible: setModalVisible,
  });
  const cxtMenus: IContextMenuItem<OrdSt>[] = [
    {
      label: '삭제',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.SetRemoved),
    },
    { divider: true },
    {
      label: '주식선물 괴리율',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.FutMaxSprd),
      disabled: (items: OrdSt[]) => items.some((v) => !isKSFut(v.prodId)),
    },
    {
      label: '킵 사용/리턴',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.UseKeep),
      disabled: (items: OrdSt[]) =>
        items.some((v) => !isKStk(v.prodId) || v.ls !== LS.S),
    },
    { label: '주문 허용 요쳥', title: true },
  ];
  if (stId === 'FHF') {
    cxtMenus.push({
      label: '재간접 승인',
      underTitle: true,
      callback: (items: OrdSt[]) =>
        reqOrderPermit(items, OrderPermitType.FHF승인요, call, m),
    });
  } else {
    OrdPermitTypeOnTarget.map((ty) => {
      const t = {
        label: ty[1],
        underTitle: true,
        callback: (items: OrdSt[]) =>
          reqOrderPermit(items, ty[0] as OrderPermitType, call, m),
        disabled: (items: OrdSt[]) => items.some((v) => !v.errMsgDisp),
      };
      cxtMenus.push(t);
      return t;
    });
  }

  if (stId === 'KHD2') {
    cxtMenus.push({
      label: 'ETF한도 초과',
      underTitle: true,
      callback: (items: OrdSt[]) =>
        reqOrderPermit(items, OrderPermitType.ETF한도초과, call, m),
      disabled: (items: OrdSt[]) => items.some((v) => !v.errMsgDisp),
    });
  }

  cxtMenus.push({ divider: true });
  cxtMenus.push({
    label: '해외주식 정지불가 동의',
    callback: (items: OrdSt[]) => update(items, UpdateOrdStField.NoCnclOK),
    disabled: (items: OrdSt[]) =>
      items.some((v) => !v.errMsgDisp || !isUStk(v.prodId)),
  });

  const meta: TableMeta<OrdSt> = {
    showRowNum: true,
    editable: true,
    updateField: (original, rowIdx, colId, value) => {
      const fld = getUpdateFieldFromOrdStProp(colId as keyof OrdSt);
      update([original], fld, value as string);
      const newData = data.map((v) =>
        v.Id === original.Id ? { ...v, [colId]: value } : v,
      );
      setData(newData);
    },
    rowClassifier: (r) =>
      r.original.state === OrdStState.Pending ? 'alert alert-warning' : '',
    contextMenus: cxtMenus,
  };

  const registerOrdSts = (all: boolean) => {
    let ids = all
      ? data.map((v) => v.Id)
      : dgState?.selectedObjs.map((v) => v.Id);
    if (user?.isAdvisor) {
      const gens = new Set(
        data.filter((v) => v.state === OrdStState.Generated).map((v) => v.Id),
      );
      ids = ids?.filter((v) => gens.has(v));
    }
    if (!ids || !ids.length) {
      m.alert('등록할 주문 없음');
      return;
    }

    const par = { ids };
    call('RegisterOrdSts', par);
  };

  const columns = useMemo(
    () =>
      getColumns(
        user?.isAdvisor ?? false,
        setOrdStDlgArgs,
        setModalVisible,
        call,
        m,
        stId,
      ),
    [user?.isAdvisor, stId],
  );

  return (
    <div className="children-me-2">
      <b>미등록주문</b>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => registerOrdSts(true)}
      >
        주문 일괄 등록
      </button>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => registerOrdSts(false)}
      >
        선택 주문 등록
      </button>
      <DataGrid
        data={data}
        columns={columns}
        meta={meta}
        onStateChange={setDgState}
      />
      <OrderDialog call={call} args={ordStDlgArgs} setArgs={setOrdStDlgArgs} />
    </div>
  );
}
