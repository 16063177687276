// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog.dialog {
  border: solid 1px gray;
  padding: 0.8em;
  top: 5%;
  left: 50%;
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%);
}
`, "",{"version":3,"sources":["webpack://./../tmslib/src/ui/dialog.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,OAAO;EACP,SAAS;EACT,aAAa;EACb,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":["dialog.dialog {\n  border: solid 1px gray;\n  padding: 0.8em;\n  top: 5%;\n  left: 50%;\n  margin-top: 0;\n  margin-left: 0;\n  transform: translate(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
