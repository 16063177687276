import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { GetVhGrpName, VhGrpTy } from '../Tms/Tms';
import { utilGet } from '../tmsutil';

export interface SimpleVhcl {
  Id: string;
  meta?: boolean;
  txt?: string;
  grp?: VhGrpTy;
  crncy: string;
}

export interface VhclSelectorProps {
  d: string;
  value?: string | null;
  onChange: (vh: SimpleVhcl | null, fst?: boolean) => void;
  empty?: boolean; // 기본 빈값 허용
  meta?: boolean;
  all?: boolean;
  grp?: VhGrpTy;
  tfim?: boolean;
  htrk?: boolean;
  smicIvy?: boolean;
  className?: string;
}

export default function VhclSelector(props: VhclSelectorProps) {
  const {
    d,
    value: initVhId,
    onChange,
    empty,
    meta,
    all,
    grp,
    tfim,
    htrk,
    smicIvy,
    className,
  } = props;
  const [vhcls, setVhcls] = useState<SimpleVhcl[]>([]);
  const [vhcl, setVhcl] = useState<SimpleVhcl | null>(null);
  const [cnt, setCnt] = useState(0);
  const { msgBox: m, logger } = useMessageState();

  const getDefault = () => {
    if (!vhcls.length) return null;
    if (initVhId) return vhcls.find((v) => v.Id === initVhId) ?? null;
    if (empty) return null;
    return vhcls[0];
  };

  useEffect(() => setVhcl(getDefault()), [initVhId]);

  useEffect(() => {
    onChange(vhcl, cnt === 1);
    setCnt((p) => p + 1);
  }, [vhcl]);

  useEffect(() => {
    if (!vhcl || !vhcls.filter((v) => v.Id === vhcl.Id).length) {
      setVhcl(getDefault());
    }
  }, [vhcls]);

  useEffect(() => {
    const params = {
      d,
      meta: !!meta,
      all,
      tgts: grp,
      tfim,
      htrk,
      smicIvy,
    };
    utilGet(m, logger, 'Vhcls', params, (data) => {
      setVhcls(data);
    });
  }, [d, grp]); // d, vteam 이외에는 화면서 바뀔일 없음

  return (
    <select
      value={vhcl?.Id || ''}
      onChange={(e) =>
        setVhcl(vhcls.find((v) => v.Id === e.target.value) ?? null)
      }
      className={className}
      name="vh"
    >
      {empty && <option> </option>}
      {_(vhcls)
        .groupBy((v) => v.grp ?? (v.meta ? '펀드 그룹' : ''))
        .toPairs()
        .map(([tgt, g]) => (
          <optgroup key={tgt} label={GetVhGrpName(tgt as VhGrpTy)}>
            {g.map((v) => (
              <option key={v.Id} value={v.Id}>
                {v.txt ?? v.Id}
              </option>
            ))}
          </optgroup>
        ))
        .value()}
    </select>
  );
}
