import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Editor from 'tmslib/src/ui/Editor';
import SmallBadge, { SmallBadgeProps } from 'tmslib/src/ui/SmallBadge';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../pages/Auth/AuthContext';
import { DftBtnStyle } from '../AppTypes';
import { utilGet, utilPost } from '../tmsutil';

const DftClassName = 'badge badge-pill bg-light text-dark';

export default function HelpBadge({
  tagid,
  initHelp,
  className,
  fontSize,
  padding,
  children,
}: {
  tagid: string;
  initHelp?: string;
} & React.PropsWithChildren<SmallBadgeProps>) {
  const location = useLocation();
  const page = location.pathname.replace('/next', '');
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [help, setHelp] = useState<string>('');
  const [showHelp, setShowHelp] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [editing, setEditing] = useState(false);
  // const editor = useRef<Editor>();

  const getHelp = () => {
    const params = { page, tagid };
    utilGet(m, logger, 'WebHelp', params, (data) => setHelp(data));
  };

  const saveHelp = () => {
    // console.log(editor.current?.getContent());
    const params = { page, tagid, help };
    utilPost(m, logger, 'SaveWebHelp', params, () => {
      getHelp();
      m.toast('저장 완료');
    });
  };

  useEffect(() => {
    if (showHelp && editing) setEditing(false);
  }, [showHelp]);

  useEffect(() => {
    if (initHelp && !help) setHelp(initHelp);
  }, [initHelp]);

  const hasHelp = info?.helpIds.has(`${page}/${tagid}`) ?? false;
  const anyHelp = help && help !== '<p></p>'
  const dftContent = hasHelp || anyHelp ? '?' : '`';

  const tooltipId = `tooltip_${tagid}`;
  return (
    <span
      style={{
        border: 'none',
        position: 'relative',
        top: '-0.3rem',
        height: '10px',
        display: 'inline-block',
      }}
      id={`help_${tagid}`}
      onMouseEnter={() => {
        if (!editing) {
          getHelp();
          setShowHelp(true);
        }
      }}
      onMouseLeave={() => !pinned && !editing && setShowHelp(false)}
    >
      <SmallBadge
        id={tooltipId}
        className={className ?? DftClassName}
        fontSize={fontSize ?? '80%'}
        padding={padding}
      >
        {children ?? dftContent}
      </SmallBadge>
      <div
        style={{
          padding: '7px',
          display: showHelp ? 'block' : 'none',
          width: '700px',
          height: editing || help !== '' ? '450px' : '100px',
          border: 'solid 1px gray',
          borderRadius: '6px',
          backgroundColor: '#f3f3f3',
          position: 'absolute',
          zIndex: 2,
          top: '100%',
          left: '50%',
          marginTop: '-5px',
          marginLeft: '-20px',
        }}
      >
        <div
          style={{ marginBottom: '5px' }}
          className="d-flex justify-content-between"
        >
          <span>
            <label htmlFor={`help_pinned_${tagid}`} className="mx-2">
              <input
                type="checkbox"
                id={`help_pinned_${tagid}`}
                checked={pinned}
                onChange={(e) => setPinned(e.target.checked)}
              />
              도움말 박스 고정
            </label>

            <label htmlFor={`help_editing_${tagid}`} className="mx-2">
              <input
                type="checkbox"
                id={`help_editing_${tagid}`}
                checked={editing}
                onChange={(e) => setEditing(e.target.checked)}
              />
              수정 모드
            </label>
          </span>
          <button
            type="button"
            className={DftBtnStyle}
            onClick={() => saveHelp()}
            disabled={!editing}
          >
            수정 사항 저장
          </button>
        </div>

        <Editor
          style={{ height: '400px', backgroundColor: 'white' }}
          value={help}
          onBlur={(html) => {
            setHelp(html);
          }}
          readOnly={!editing}
        />
      </div>
    </span>
  );
}
