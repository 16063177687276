// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component?page=1&tab=scoredesc#tab-top

import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideHandler(
  ref: React.RefObject<HTMLDivElement>,
  callback?: () => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback?.();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideHandler({
  callback,
  style,
  children,
}: {
  callback?: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideHandler(wrapperRef, callback);

  return (
    <div style={style} ref={wrapperRef}>
      {children}
    </div>
  );
}
