import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './portfolio.scss';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import Exporting from 'highcharts/modules/exporting';
import exportingcsv from 'highcharts/modules/export-data';
import { callAxiosGet } from '../../../tmsutil';

Exporting(Highcharts);
exportingcsv(Highcharts);

interface Props {
  vh: string;
  st: string;
  isEtf: boolean;
  prod: string | undefined;
}

const chtOpt: Highcharts.Options = {
  chart: {
    alignTicks: false,
    type: 'line',
    width: 700,
    height: 470,
    zooming: {
      type: 'x',
    },
  },
  title: { text: '' },
  colors: ['#0a0', '#000', '#777'],
  xAxis: { type: 'datetime', crosshair: true },
  yAxis: [
    { title: { text: '수익률' } },
    {
      title: { text: '비중' },
      opposite: true,
      max: 20,
      min: -20,
      gridLineDashStyle: 'Dash',
    },
  ],
  credits: { enabled: false },
  legend: { itemStyle: { fontSize: '10px' } },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
    column: { color: 'red', negativeColor: 'blue' },
  },
  accessibility: { enabled: false },
};

export default function PosHistChart({ vh, st, isEtf, prod }: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [posHist, setPostHist] = useState<Highcharts.Options>(chtOpt);

  if (!(vh && st && prod)) return null;

  useEffect(() => {
    callAxiosGet({
      m,
      logger,
      url: '/Fund/Portfolio/PosHistChart',
      params: { vh, st, prod },
      onSuccess: (data) => {
        if (!data.cht.length) return;
        const chtSrs = [...data.cht];
        chtSrs[0].yAxis = 1;
        chtSrs[0].type = 'column';
        chtSrs[3].visible = false;
        setPostHist((p) => ({
          ...p,
          series: chtSrs,
          title: { text: data.prodNm },
        }));
      },
      onError: () => undefined,
    });
  }, [vh, st, prod]);

  let left = st === 'Whole' && vh !== 'SINGA' ? '1250px' : '1400px';
  if (isEtf) left = st === 'Whole' ? '1550px' : '1750px';

  return (
    <div className="pos-hist-chart" style={{ left }}>
      <div style={{ padding: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>종목 보유 비중 / 수익률</span>
      </div>
      {posHist != null && (
        <HighchartsReact highcharts={Highcharts} options={posHist} />
      )}
    </div>
  );
}
