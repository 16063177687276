import React, { useEffect } from 'react';

export default function RadioGroup<T>({
  name,
  value: initialValue,
  options,
  onChange,
  labels,
}: {
  name: string;
  value: T | null;
  options: T[];
  onChange: (value: T | null) => void;
  labels?: string[];
}) {
  const [value, setValue] = React.useState<T | null>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <>
      {options.map((o, i) => (
        <label key={`${o}`} htmlFor={`${name}_${o}`}>
          <input
            type="radio"
            name={name}
            id={`${name}_${o}`}
            value={`${o}`}
            checked={value === o}
            onChange={(e) => setValue(e.target.value as T)}
          />
          {labels ? labels[i] : `${o}`}
        </label>
      ))}
    </>
  );
}
