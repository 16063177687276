import React, { useState, useEffect } from 'react';
import MultiCheckBox from 'tmslib/src/ui/MultiCheckBox';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import {
  FuncCall,
  UserCfgTy,
  callAxiosGet,
  selectedUserCfgs,
  updateUserCfg,
} from '../../../tmsutil';
import { LS } from '../../../Tms/Common';
import WeiTab, {
  DropSc,
  EarnRev,
  ErSc,
  IdxWei,
  StdItem,
  WeiTabConfig,
} from './WeiTab';
import { PosWeiTarget } from '../../../Tms/BO';
import { OrdSt } from '../../../Tms/Ord';
import { useAuthState } from '../../Auth/AuthContext';

interface Props {
  setUpdateNeeded: () => void;
  tgtCall: FuncCall;
  d: string;
  vhId: string | null;
  stId: string | null;
  lpos: PosWeiTarget[];
  spos: PosWeiTarget[];
  losts: OrdSt[];
  sosts: OrdSt[];
  stds: StdItem[];
  earnRevs: EarnRev[];
  idxWeis: IdxWei[];
  erScs: ErSc[];
  dropScs: DropSc[];
}

type TmpConfig = {
  rstrD1: { [key: string]: string };
  diffShowThreshold: number | null;
  showYstdWei: boolean;
  ystdNa: number | null;
  refVhId: string | null;
  showRefWei: boolean | null;
};

const tgtShowCfgs = [
  UserCfgTy.TgtShowCurrPrc,
  UserCfgTy.TgtShowStdDev,
  UserCfgTy.TgtShowEarnRev,
  UserCfgTy.TgtShowIdxWei,
  UserCfgTy.TgtShowErSc,
  UserCfgTy.TgtShowDropSc,
];

const tgtShowCfgNms = [
  '현재가',
  '표준편차',
  '추정치 변경',
  '지수구성비중',
  '추정치 점수',
  '가격 점수',
];

export default function LsWeiTabs({
  setUpdateNeeded,
  tgtCall,
  d,
  vhId,
  stId,
  lpos,
  spos,
  losts,
  sosts,
  stds,
  earnRevs,
  idxWeis,
  erScs,
  dropScs,
}: Props) {
  const [cfg, setCfg] = useState<WeiTabConfig>({});
  const { user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const [sltdTgtShowCfgs, setSltdTgtShowCfgs] = useState<UserCfgTy[]>([]);

  useEffect(() => {
    selectedUserCfgs(m, logger, tgtShowCfgs, (sltd) =>
      setSltdTgtShowCfgs(sltd),
    );
  }, []);

  useEffect(() => {
    setCfg((c) => ({
      ...c,
      showCurrPrc: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowCurrPrc),
      showStdDev: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowStdDev),
      showEarnRev: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowEarnRev),
      showIdxWei: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowIdxWei),
      showErSc: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowErSc),
      showDropSc: sltdTgtShowCfgs.contains(UserCfgTy.TgtShowDropSc),
    }));
  }, [sltdTgtShowCfgs]);

  useEffect(() => {
    if (!d || !vhId || !stId) return;
    if (user?.isAdvisor) return;
    const params = { d, vh: vhId, st: stId };
    callAxiosGet({
      m,
      logger,
      url: '/Front/Target/WeiTabCfg',
      params,
      onSuccess: (data) => setCfg((c) => ({ ...c, ...(data as TmpConfig) })),
    });
  }, [d, vhId, stId]);

  return (
    <>
      {!user?.isAdvisor && (
        <>
          <hr className="light narrow" />
          <b>칼럼 설정</b>
          <MultiCheckBox
            name="tgtShowCfgs"
            options={tgtShowCfgs}
            selected={sltdTgtShowCfgs}
            labels={tgtShowCfgNms}
            onChangeEach={(item, selected) =>
              updateUserCfg(m, logger, item, selected, () =>
                setSltdTgtShowCfgs(
                  selected
                    ? [...sltdTgtShowCfgs, ...[item]]
                    : sltdTgtShowCfgs.filter((v) => v !== item),
                ),
              )
            }
          />
        </>
      )}
      <hr className="light narrow" />
      <WeiTab
        setUpdateNeeded={setUpdateNeeded}
        call={tgtCall}
        stId={stId}
        vhId={vhId}
        cfg={cfg}
        ls={LS.L}
        pos={lpos}
        osts={losts}
        stds={stds}
        earnRevs={earnRevs}
        idxWeis={idxWeis}
        erScs={erScs}
        dropScs={dropScs}
      />
      <hr className="light narrow" />
      <WeiTab
        setUpdateNeeded={setUpdateNeeded}
        call={tgtCall}
        stId={stId}
        vhId={vhId}
        cfg={cfg}
        ls={LS.S}
        pos={spos}
        osts={sosts}
        stds={stds}
        earnRevs={earnRevs}
        idxWeis={idxWeis}
        erScs={erScs}
        dropScs={dropScs}
      />
    </>
  );
}
