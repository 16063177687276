import React, { useEffect } from 'react';

export default function Tooltip({
  tooltip,
  style,
  children,
}: {
  tooltip: React.ReactNode;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  const [show, setShow] = React.useState(false);
  
  const tipStyle: React.CSSProperties = {
    display: show ? 'block' : 'none',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 100,
    border: '1px solid lightgray',
    marginTop: '-30px',
    marginLeft: '10px',
  };
  return (
    <span style={{ position: 'relative' }} onMouseOver={() => setShow(true)}>
      {children}
      <div
        className="p-1"
        style={{ ...style, ...tipStyle }}
        onMouseOut={() => setShow(false)}
      >
        {tooltip}
      </div>
    </span>
  );
}
