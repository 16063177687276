import React, { useEffect, useState } from 'react';
import { switchExpr } from 'tmslib/src/util/utils';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { VhGrpTy } from '../../../Tms/Tms';
import { OrdProcReqState, OrdProcState } from '../../../Tms/Ord';
import { useAuthState } from '../../Auth/AuthContext';
import { DftBtnStyle } from '../../../AppTypes';
import { callAxios } from '../../../tmsutil';

interface Props {
  d: string;
  vhGrp: VhGrpTy;
  ordProcState: OrdProcState | null;
  inMins: number;
  setInMins: (v: number) => void;
}

export enum OrdProcRunner {
  Main = 'Main',
  Second = 'Second',
}

export enum OrdProcReqTy {
  ProcDom = 'ProcDom',
  ProcUStk = 'ProcUStk',
  ProcUFut = 'ProcUFut',
  SendManual = 'SendManual',
}

export const GetReqTyDesc = (ty: OrdProcReqTy) => {
  switch (ty) {
    case OrdProcReqTy.ProcDom:
      return '국내 처리';
    case OrdProcReqTy.ProcUStk:
      return '해외주식 처리';
    case OrdProcReqTy.ProcUFut:
      return '해외선물 처리';
    case OrdProcReqTy.SendManual:
      return '수기 처리';
    default:
      return '';
  }
};

const allowTy = [
  OrdProcReqTy.ProcDom,
  OrdProcReqTy.ProcUStk,
  OrdProcReqTy.ProcUFut,
  OrdProcReqTy.SendManual,
];

export default function ProcBar({
  d,
  vhGrp,
  ordProcState,
  inMins,
  setInMins,
}: Props) {
  const [runner, setRunner] = useState<OrdProcRunner>(OrdProcRunner.Main);
  const [reqStateMsg, setReqStateMsg] = useState<string | null>(
    ordProcState?.reqStateMsg || '',
  );
  const [reqState, setReqState] = useState<OrdProcReqState>(
    ordProcState?.state || OrdProcReqState.None,
  );
  const [domWaiting, setDomWaiting] = useState<number>(
    ordProcState?.domWaiting || 0,
  );
  const [ustkWaiting, setUstkWaiting] = useState<number>(
    ordProcState?.ustkWaiting || 0,
  );
  const [ufutWaiting, setUfutWaiting] = useState<number>(
    ordProcState?.ustkWaiting || 0,
  );
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const AddOrdProcReq = async (ty: OrdProcReqTy) => {
    const params = {
      d,
      tgt: vhGrp,
      ty,
      inMins,
      runner,
    };
    callAxios({
      m,
      logger,
      url: '/Back/OrdProc/AddOrdProcReq',
      params,
      onSuccess: () => {
        setReqState(OrdProcReqState.Requested);
        setReqStateMsg('OK');
      },
      onError: () => setReqStateMsg('Error'),
      title: `주문 처리 요청 ${vhGrp} ${GetReqTyDesc(ty)}`,
    });
  };

  const GetReqCnt = (ty: OrdProcReqTy) => {
    switch (ty) {
      case OrdProcReqTy.ProcDom:
        return domWaiting;
      case OrdProcReqTy.ProcUStk:
        return ustkWaiting;
      case OrdProcReqTy.ProcUFut:
        return ufutWaiting;
      case OrdProcReqTy.SendManual:
        return 0;
      default:
        return -1;
    }
  };

  useEffect(() => {
    setReqState(ordProcState?.state || OrdProcReqState.None);
    setReqStateMsg(ordProcState?.reqStateMsg || '');
    setDomWaiting(ordProcState?.domWaiting || 0);
    setUstkWaiting(ordProcState?.ustkWaiting || 0);
    setUfutWaiting(ordProcState?.ufutWaiting || 0);
  }, [d, vhGrp, ordProcState]);

  return (
    <>
      <div className="children-me-2">
        {info?.isDevTest && (
          <select
            value={runner}
            name="sltdRunner"
            onChange={(e) => setRunner(e.target.value as OrdProcRunner)}
          >
            <option>Main</option>
            <option>Second</option>
          </select>
        )}
        <NumericInput
          name="inMins_"
          value={inMins || 10}
          width="50"
          onChange={(v) => setInMins(v ?? 10)}
          placeholder="10"
        />
        분 이내 &nbsp;
        {allowTy.map((v) => (
          <button
            type="button"
            key={v}
            className={DftBtnStyle}
            onClick={() => AddOrdProcReq(v)}
          >
            {GetReqTyDesc(v)}
            {GetReqCnt(v) > 0 && (
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {' '}
                {GetReqCnt(v)}
              </span>
            )}
          </button>
        ))}
      </div>
      <div>
        <span
          style={{
            color:
              switchExpr(
                reqState,
                [OrdProcReqState.Fail, 'red'],
                [OrdProcReqState.Requested, 'blue'],
              ) ?? 'gray',
            // eslint-disable-next-line react/jsx-closing-bracket-location
          }}
        >
          처리상태:
          {reqStateMsg}
        </span>
      </div>
    </>
  );
}
