import React, { useEffect, useState } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import StrgSelector from '../../shared/StrgSelector';
import { AllocDaily } from '../../Tms/BO';
import { ValidData, callAxios, utilGet, checkItems } from '../../tmsutil';

export default function Allocation() {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [d, setD] = useState<string>(info?.currBizDay || '');
  const [d0, setD0] = useState<string | null>(null);
  const [st, setSt] = useState<string | null>(null);
  const [allocOnly, setAllocOnly] = useState(true);
  const [daily, setDaily] = useState<AllocDaily[]>([]);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const vh = 'TTM';

  useEffect(() => {
    utilGet(m, logger, 'FundPeriod', { d, vh }, (data) => setD0(data.d0));
  }, []);

  const call = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
    isGet?: boolean,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Master/Allocation/${func}`,
      params,
      onSuccess,
      isGet,
    });

  const getDaily = () => {
    if (!d0) return;
    const params = { d, d0, st: st ?? null, allocOnly };
    call(
      'Daily',
      params,
      (data) => {
        setDaily(data.daily);
        setD0(data.d0);
      },
      true,
    );
  };

  useEffect(() => getDaily(), [d, d0, st, allocOnly, refreshNeeded]);

  const cnfrm = (objs: AllocDaily[], subj: 'Mngr' | 'Cmpl' | 'Oper' | 'NoAlloc') => {
    if (!checkItems(objs, m, true)) return;
    call('Confirm', { id: objs[0].Id, subj }, () =>
      setRefreshNeeded((p) => p + 1),
    );
  };

  const ctxMenu: IContextMenuItem<AllocDaily>[] = [
    {
      label: '매니저 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Mngr'),
    },
    {
      label: '리스크 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Cmpl'),
    },
    {
      label: '운용지원 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Oper'),
    },
    { divider: true },
    {
      label: '유예',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'NoAlloc'),
    },
  ];

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => setD(date)}
        onChangeD0={(date0) => setD0(date0)}
      />
      <StrgSelector
        d={d}
        vh={vh}
        onChange={(s) => setSt(s?.Id ?? null)}
        empty
        team
      />
      <label htmlFor="allocOnly">
        <input
          type="checkbox"
          id="allocOnly"
          checked={allocOnly}
          onChange={(e) => setAllocOnly(e.target.checked)}
        />
        기준일만 조회
      </label>
      <hr className="narrow light" />
      <SimpleGrid
        data={daily}
        columns={[
          'd',
          'allocDate',
          'stId',
          'navReal',
          'navAvg',
          'allocPrc',
          'tgtLev',
          'tgtAlloc',
          'noAlloc',
          'mngrCnfrm',
          'cmplCnfrm',
          'operCnfrm',
          'tgtPfStd',
        ]}
        headers={[
          '날짜',
          '기준일',
          '운용역',
          '기준가',
          '5일평균',
          '기준값',
          '목표한도',
          '배분',
          '유예',
          '매니저',
          '리스크',
          '운용지원',
          '표준편차',
        ]}
        args={{
          meta: { height: 1000, contextMenus: ctxMenu },
          checkboxes: ['allocDate', 'noAlloc', 'mngrCnfrm', 'cmplCnfrm', 'operCnfrm'],
          widths: { tgtLev: 80, tgtAlloc: 50 },
          formatters: {
            navReal: (v) => v?.toFixedWithComma(2),
            navAvg: (v) => v?.toFixedWithComma(2),
            allocPrc: (v) => v?.toFixedWithComma(2),
          },
        }}
      />
    </div>
  );
}
