import React, { useEffect, useState, useRef } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';

import './summary.scss';
import Tooltip from 'tmslib/src/ui/Tooltip';

import { useNavigate, useSearchParams } from 'react-router-dom';
import DataGrid from 'tmslib/src/table/DataGrid';

import { ifesleExpr, switchExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { RtDiff, updateRtRows, setRtEffect } from '../../rtutil';
import { UserCfgTy, selectedUserCfgs, updateUserCfg } from '../../tmsutil';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import { UserGroup } from '../../Tms/Identity';

type SummaryRow = {
  Id: number;
  ord: number;
  grp?: string;
  cls?: string;
  vhId: string;
  stId: string;
  stNm?: string;
  navReal: number;
  rtReal: number;
  chReal: number;
  chPct: string;
  navBook?: number;
  chBook?: number;
  afterAsia?: number;
  exRt?: number;
  mrgn?: number;
  initD?: Date;
  naDisp?: number;
  laDisp?: number;
  lw?: number;
  sw?: number;
  nw?: number;
  chRealF?: number;
  inFundRto?: number;
  warn?: string;
  vhNm?: string;
  team?: string;
  isTeam: boolean;
  isMem: boolean;
};

type VhInfo = {
  Id: string;
  nm?: string;
  cmplExpD?: Date;
  pfmFee?: number;
  initD?: Date;
  aiGrpNm?: string;
  strgDesc?: string;
  assetDesc?: string;
  brokerDesc?: string;
  bncDesc2?: string;
  bmDesc?: string;
};

type SummaryRes = {
  t: number;
  smry: SummaryRow[];
  smry_diff: RtDiff<SummaryRow> | null;
  vhinfo: VhInfo[];
};

const stIdLeftMrgn = (r: SummaryRow) =>
  ifesleExpr([r.stId === 'Whole', 2], [r.isMem, 22]) ?? 12;

const columnHelper = createColumnHelper<SummaryRow>();

const grpRenderer = (grp?: string) => {
  if (!grp) return grp;
  const grpStr =
    switchExpr(
      grp,
      ['VT', '통합'],
      ['HFund', '헤지'],
      ['PAFund', '공모'],
      ['FoHFs', '재간접'],
    ) ?? grp;
  const tok = grpStr.split('_');
  if (tok.length === 1) return tok[0];
  return (
    <span>
      {tok[0]}
      <br />({tok[1]})
    </span>
  );
};

let vhInfoDic: Map<string, VhInfo> = new Map();

const tooltipStyle = {
  fontSize: '11px',
  maxWidth: '400px',
};
const vhInfoRenderer = (r: SummaryRow) => {
  const vhId =
    switchExpr(r.vhId, ['VTT', '헤지'], ['VTV', '벤처'], ['VPA', '공모']) ??
    r.vhId;
  const info = vhInfoDic.get(r.vhId);
  if (r.stId === 'Whole' && (info || r.vhNm)) {
    const assetDesc = info?.assetDesc ? `(${info.assetDesc})` : '';
    const bncDesc = info?.bncDesc2 ? (
      <>
        <br />
        주요수익자 : {info.bncDesc2}
      </>
    ) : null;
    const tooltipData = info ? (
      <div style={{ textAlign: 'left' }}>
        <b>{info.nm}</b>
        <br />
        <span>
          {info.aiGrpNm} {assetDesc}
        </span>
        <br />
        <br />
        설정일 :{info.initD?.toString().split('T')[0] || ''}
        <br />
        만기일 :{info.cmplExpD?.toString().split('T')[0] || ''}
        <br />
        주요전략 :{info.strgDesc || ''}
        <br />
        주요판매사 :{info.brokerDesc || ''}
        {bncDesc}
        <br />
        기준수익률 :{info.bmDesc || ''}
        <br />
        성과보수율 :{info.pfmFee?.toFixed(1) || ''}
      </div>
    ) : (
      <b>{r.vhNm}</b>
    );
    return (
      <Tooltip tooltip={tooltipData} style={tooltipStyle}>
        {vhId}
      </Tooltip>
    );
  }
  return vhId;
};

const columns = [
  columnHelper.accessor('grp', {
    header: 'Group',
    cell: (c) => grpRenderer(c.getValue()),
    size: 70,
    meta: {
      styler: () => ({ fontWeight: 'bold' }),
      rowSpanFn: (r0, r1) => r0.original.grp === r1.original.grp,
    },
  }),
  columnHelper.accessor('vhId', {
    header: 'Fund',
    cell: (c) => vhInfoRenderer(c.row.original),
    size: 60,
    meta: {
      styler: () => ({ fontWeight: 'bold' }),
      rowSpanFn: (r0, r1) => r0.original.vhId === r1.original.vhId,
    },
  }),
  columnHelper.accessor('stId', {
    header: 'Mngr',
    size: 90,
    meta: {
      rowSpanFn: (r0, r1) =>
        r0.original.grp === r1.original.grp &&
        r0.original.stId === r1.original.stId,
      formatter: (v, r) => (r.original.stNm ?? v).replace('IVY_', ''),
      styler: (v, r) => ({
        textAlign: 'left',
        paddingLeft: `${stIdLeftMrgn(r.original)}px`,
      }),
    },
  }),
  columnHelper.accessor('navReal', {
    header: 'Nav',
    size: 80,
    meta: { formatter: (v) => v.toFixedWithComma(2) },
  }),
  columnHelper.accessor('chReal', {
    header: 'Ch',
    meta: {
      formatter: (v) => v.toFixed(2),
      styler: (v, r) => ({ color: r.original.chReal.getSignColor() }),
    },
  }),
  columnHelper.accessor('chPct', {
    header: 'Ch(%)',
    meta: { styler: (v, r) => ({ color: r.original.chReal.getSignColor() }) },
  }),
  columnHelper.accessor('chRealF', {
    header: '해외',
    meta: {
      formatter: (v) => v?.toFixed(2),
      styler: (v, r) => ({ color: r.original.chRealF?.getSignColor() }),
    },
  }),
  columnHelper.accessor('afterAsia', {
    header: '야간',
    meta: {
      formatter: (v, r) =>
        v?.toFixed(r.original.vhId.isIn('SMIC', 'IVY') ? 0 : 2),
      styler: (v, r) => ({
        color: r.original.vhId.isIn('SMIC', 'IVY')
          ? 'gray'
          : r.original.afterAsia?.getSignColor(),
      }),
    },
  }),
  columnHelper.accessor('navBook', {
    header: 'NAV(북)',
    size: 80,
    meta: { formatter: (v) => v?.toFixedWithComma(2) },
  }),
  columnHelper.accessor('chBook', {
    header: 'Ch(북)',
    meta: {
      formatter: (v) => v?.toFixed(2),
      styler: (v, r) => ({ color: r.original.chBook?.getSignColor() }),
    },
  }),
  columnHelper.accessor('initD', {
    header: 'Inception',
    size: 70,
    meta: {
      rowSpanFn: (r0, r1) => r0.original.vhId === r1.original.vhId,
      formatter: (v) => v?.toString().split('T')[0].slice(2),
    },
  }),
  columnHelper.accessor('exRt', {
    header: '초/마',
    meta: {
      formatter: (v, r) => (r.original.mrgn || v)?.toFixed(2),
      styler: (v, r) => ({ color: r.original.exRt?.getSignColor() || 'gray' }),
    },
  }),
  columnHelper.accessor('naDisp', { header: 'Limit' }),
  columnHelper.accessor('inFundRto', {
    header: '펀드%',
    meta: { formatter: (v) => v?.toFixed(1) },
  }),
  columnHelper.accessor('laDisp', {
    header: 'L.Amt',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('lw', {
    header: 'L%',
    meta: { formatter: (v) => v?.toFixed(1) },
  }),
  columnHelper.accessor('sw', {
    header: 'S%',
    meta: { formatter: (v) => v?.toFixed(1) },
  }),
  columnHelper.accessor('nw', {
    header: 'N%',
    meta: {
      formatter: (v) => v?.toFixed(1),
      styler: () => ({ fontWeight: 'bold' }),
    },
  }),
  columnHelper.accessor('warn', {
    header: 'Warn',
    meta: { styler: () => ({ color: 'red' }) },
  }),
].map((v) => v as ColumnDef<SummaryRow, unknown>);

export default function Summary() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, connection, connected, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [mineOnly, setMineOnly] = useState<boolean>();
  const [showAll, setShowAll] = useState<boolean>();
  const [rows, setRows] = useState<SummaryRow[]>([]);
  const lastResT = useRef(0);

  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: { d, mineOnly, showAll },
        reqAddr: 'RequestSummary',
        rcvAddr: 'ReceiveSummary',
        connection,
        connCond: () => mineOnly !== undefined && showAll !== undefined,
        onReceive: (res: SummaryRes) => {
          setRows((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.smry,
              diff: res.smry_diff,
            }),
          );
          if (res.vhinfo) {
            vhInfoDic = new Map(res.vhinfo.map((v) => [v.Id, v]));
          }
        },
      }),
    [connection, connected, d, mineOnly, showAll],
  );

  useEffect(() => {
    selectedUserCfgs(
      m,
      logger,
      [UserCfgTy.SmrMineOnly, UserCfgTy.SmrShowAll],
      (sltd) => {
        setMineOnly(sltd.contains(UserCfgTy.SmrMineOnly));
        setShowAll(sltd.contains(UserCfgTy.SmrShowAll));
      },
    ).catch((error: string) => {
      m.alert(error);
    });
  }, []);

  const pfMenu = user?.menus.find(
    (v) => v.grp === 'Fund' && v.name === 'Portfolio',
  );
  const isProduction = process.env.NODE_ENV === 'production';
  const pfUrl =
    (isProduction ? pfMenu?.url : pfMenu?.rawurl) ?? '/Fund/Portfolio';

  const meta: TableMeta<SummaryRow> = {
    rowClassifier: (r) => `${r.original.cls} center`,
    onCellClick: (c) => {
      if (c.column.columnDef.header?.toString().isIn('Group', 'Fund')) return;
      const r = c.row.original;
      if (r.vhId.isIn('MKT', 'VTT', 'VTV', 'VPA')) return;

      // 마케팅은 섬머리서만 매니저별 기준가 볼수 있고 실제 포트 등은 못보기 때문에 잘못된 클릭 안되게.(IVY,SMIC 제외)
      if (
        user?.Group === UserGroup.Mkt &&
        !r.vhId.isIn('IVY', 'SMIC') &&
        r.stId !== 'Whole'
      )
        return;

      navigate(`${pfUrl}?d=${d}&vh=${r.vhId}&st=${r.stId}`);
    },
    containerClass: 'summary',
  };

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className="children-me-2"
      >
        <DateSelector
          value={d}
          onChange={(date) => date !== d && setSearchParams({ d: date })}
        />
        <label htmlFor="mineOnly">
          <input
            type="checkbox"
            checked={mineOnly ?? false}
            id="mineOnly"
            onChange={(e) =>
              updateUserCfg(
                m,
                logger,
                UserCfgTy.SmrMineOnly,
                e.target.checked,
                (v) => setMineOnly(v),
              )
            }
          />
          {user?.useEng ? 'Mine Only ' : '내 전략만 보기'}
        </label>
        <label htmlFor="showAll">
          <input
            type="checkbox"
            checked={showAll ?? false}
            id="showAll"
            onChange={(e) =>
              updateUserCfg(
                m,
                logger,
                UserCfgTy.SmrShowAll,
                e.target.checked,
                (v) => setShowAll(v),
              )
            }
          />
          {user?.useEng ? 'Show All strgs' : '전 펀드별 전략 보기'}
        </label>
      </div>
      <DataGrid data={rows} columns={columns} meta={meta} />
      <br />
    </>
  );
}
