import React from 'react';
import { Bookmark, CheckLg, Pencil, PlusLg, Unlock, X, XLg } from 'react-bootstrap-icons';

export type ButtonRole = 'Default' | 'Ok' | 'Cancel' | 'Icon' | 'Link';

export type Icon = 'Check' | 'Plus' | 'X' | 'x' | 'Unlock' | 'Pencil' | 'Bookmark';

// https://icons.getbootstrap.com/
const mapIcon = (icon: Icon | null) => {
  if (!icon) return null;

  switch (icon) {
    case 'Check':
      return <CheckLg />;
    case 'Plus':
      return <PlusLg />;
    case 'X':
      return <XLg />;
    case 'x':
      return <X />;
    case 'Unlock':
      return <Unlock />;
    case 'Pencil':
      return <Pencil />;
    case 'Bookmark':
      return <Bookmark />;
  }
};

// return [label, icon, className]
const getDefault = (role: ButtonRole): [string, Icon | null, string] => {
  const dftCls = 'btn btn-sm';
  switch (role) {
    case 'Default':
      return ['', null, `${dftCls} btn-outline-secondary`];
    case 'Ok':
      return [role, 'Check', `${dftCls} btn-primary`];
    case 'Cancel':
      return [role, 'X', `${dftCls} btn-link`];
    case 'Icon':
      return ['', null, `${dftCls} btn-outline-primary rounded-circle`];
    case 'Link':
      return ['', null, `${dftCls} btn-link`];
  }
};

export interface ButtonProps {
  onClick?: () => void;
  btnRole?: ButtonRole;
  icon?: Icon;
  label?: string;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  noDisplayWhenDisabled?: boolean;
  innerRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export default function Button({
  onClick,
  btnRole,
  icon,
  label,
  className,
  title,
  style,
  disabled,
  noDisplayWhenDisabled,
  innerRef,
}: ButtonProps) {
  if (disabled && noDisplayWhenDisabled) return <></>;

  const [labelDft, iconDft, classNameDft] = getDefault(btnRole ?? 'Default');

  const txt = label ?? labelDft;
  return (
    <button
      type="button"
      className={className ?? classNameDft}
      title={title}
      style={style}
      ref={innerRef}
      onClick={onClick}
      disabled={disabled}
    >
      {mapIcon(icon ?? iconDft)}
      {txt ? <>&nbsp;</> : <></>}
      {txt}
    </button>
  );
}
