import { z } from 'zod';

export enum PosBookTy {
  Undefined = 'Undefined',
  Cash = 'Cash',
  SS_KSwap = 'SS_KSwap',
  KI_KSwap = 'KI_KSwap',
  MA_KSwap = 'MA_KSwap',
  SH_KSwap = 'SH_KSwap',
  DS_KSwap = 'DS_KSwap',
  KB_KSwap = 'KB_KSwap',
  XX_KSwap = 'XX_KSwap',
  SS_USwap = 'SS_USwap',
  KI_USwap = 'KI_USwap',
  MA_USwap = 'MA_USwap',
  SH_USwap = 'SH_USwap',
  KB_USwap = 'KB_USwap',
  UnknownSwap = 'UnknownSwap',
  Kyo_KFut = 'Kyo_KFut',
  KI_KFut = 'KI_KFut',
  SS_KFut = 'SS_KFut',
  KW_KFut = 'KW_KFut',
  MA_KFut = 'MA_KFut',
  NH_KFut = 'NH_KFut',
  UnknownKFut = 'UnknownKFut',
  KI_UFut = 'KI_UFut',
  KI_UFut_GM = 'KI_UFut_GM',
  KSD = 'KSD',
  SS_PBS = 'SS_PBS',
  KI_PBS = 'KI_PBS',
  MA_PBS = 'MA_PBS',
  SH_PBS = 'SH_PBS',
  KB_PBS = 'KB_PBS',
  UnknownPbs = 'UnknownPbs',
}
export const posBookTySchema = z.nativeEnum(PosBookTy);

export enum Curncy {
  Unknown = 'Unknown',
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
  CHF = 'CHF',
  HKD = 'HKD',
  SGD = 'SGD',
  KRW = 'KRW',
  CNY = 'CNY',
  TWD = 'TWD',
  AUD = 'AUD',
  CAD = 'CAD',
  BRL = 'BRL',
  INR = 'INR',
  DKK = 'DKK',
  MYR = 'MYR',
  NOK = 'NOK',
  SEK = 'SEK',
  IDR = 'IDR',
  THB = 'THB',
  CZK = 'CZK',
  VND = 'VND',
  MXN = 'MXN',
  NZD = 'NZD',
  ZAR = 'ZAR',
  TKR = 'TKR',
}
export const curncySchema = z.nativeEnum(Curncy);

export enum BuySell {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum LS {
  L = 'L',
  F = 'F',
  S = 'S',
}
export const lsSchema = z.nativeEnum(LS);
export function ls2num(ls: LS): number {
  if (ls === LS.F) throw new Error('LS.F');
  return ls === LS.L ? 1 : -1;
}

export function getLsName(ls: LS): string {
  if (ls === LS.F) throw new Error('LS.F');
  return ls === LS.L ? '롱' : '숏';
}

export enum EX {
  E = 'E',
  X = 'X',
}
export const exSchema = z.nativeEnum(EX);

export function ex2num(ex: EX): number {
  return ex === EX.E ? 1 : -1;
}

export function getExName(ex: EX): string {
  return ex === EX.E ? '진입' : '청산';
}

export enum Period {
  Yearly = 'Yearly',
  HalfYearly = 'HalfYearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  HalfMonthly = 'HalfMonthly',
  Weekly = 'Weekly',
  Daily = 'Daily',
  Hourly = 'Hourly',
}
export const periodSchema = z.nativeEnum(Period);
