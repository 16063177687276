import React, { useState } from 'react';
import Dialog from 'tmslib/src/ui/Dialog';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { callAxiosGet, getMainAxios } from '../tmsutil';

const fileExtensionValid = (
  name: string,
  fileExtensionLimit: string[],
): boolean => {
  const lastIndex = name.lastIndexOf('.');
  if (lastIndex < 0) {
    return false;
  }
  const extension = name.substring(lastIndex + 1).toLowerCase();
  if (!(fileExtensionLimit.indexOf(extension) > -1) || extension === '') {
    return false;
  }
  return true;
};

interface Props {
  headerTitle: string;
  fileExtensionLimit: string[];
  fileSizeLimit: number;
  params?: object;
  url: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshNeeded: React.Dispatch<React.SetStateAction<number>>;
}

type AntiforgeryToken = {
  RequestToken: string;
  FormFieldName: string;
};

export default function FileUploadPage({
  headerTitle,
  fileExtensionLimit,
  fileSizeLimit,
  params,
  url,
  visible,
  setVisible,
  setRefreshNeeded,
}: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [file, setFile] = useState<File>();

  const fileUploadValidHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];

    if (files === undefined) return;
    if (!fileExtensionValid(files.name, fileExtensionLimit)) {
      target.value = '';
      m.alert(`업로드 가능한 확장자 (${fileExtensionLimit}) 아님`);
      return;
    }
    const maxFileSize = fileSizeLimit * 1024 * 1024;
    if (files.size > maxFileSize) {
      target.value = '';
      m.alert(`업로드 가능한 최대 용량 ${maxFileSize} MB 초과`);
      return;
    }
    setFile(files);
  };


  const callWithAntiforgertyToken = (
    tokenUrl: string,
    setToken: (token: AntiforgeryToken) => void,
    callback: () => void,
    callOnFail: boolean,
  ) => callAxiosGet({
      m,
      logger,
      url: tokenUrl,
      params: {},
      onSuccess: (data) => {
        setToken(data);
        callback();
      },
      onError: () => { if (callOnFail) callback();}
    })

  const fileUploadHandler = async () => {
    if (file !== undefined) {
      const formData = new FormData();
      formData.append('file', file);

      if (params != null) {
        Object.keys(params).forEach((key) => {
          const val = params[key as keyof object];
          formData.append(key, val);
        });
      }

      callWithAntiforgertyToken(
        `${url}AntiforgeryToken`,
        (token) => formData.append(token.FormFieldName, token.RequestToken),
        () => {
          getMainAxios()
            .post(url, formData)
            .then(() => {
              m.alert('OK');
              setRefreshNeeded((p) => p + 1);
              setVisible(false);
            })
            .catch((error) => m.alert(error));
        },
        true,
      );
    }
  };

  return (
    <Dialog
      header={headerTitle}
      visible={visible}
      onHide={() => setVisible(false)}
    >
      <input type="file" onChange={fileUploadValidHandler} />
      <br />
      <br />
      <br />
      <button type="button" onClick={fileUploadHandler}>
        업로드
      </button>
      <hr />
    </Dialog>
  );
}
