import React, { useState, useEffect } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { StrgCate, VhGrpTy } from '../Tms/Tms';
import VhclSelector, { SimpleVhcl, VhclSelectorProps } from './VhclSelector';
import StrgSelector, { SimpleStrg, StrgSelectorProps } from './StrgSelector';
import DateSelector from './DateSelector';

interface Props {
  value: [string, string | null, string | null];
  onChange: (
    d: string,
    vhId: string | null,
    stId: string | null,
    vh: SimpleVhcl | null,
    st: SimpleStrg | null,
  ) => void;
  vh: Omit<VhclSelectorProps, 'd' | 'value' | 'onChange'>;
  st: Omit<
    StrgSelectorProps,
    'd' | 'vh' | 'value' | 'onChange' | 'empty' | 'nonEmptyDefault'
  >;
  dates?: string[]
}

export default function DVhStSelector(props: Props) {
  const { value, onChange, vh: vhProps, st: stProps, dates } = props;
  const [initD, initVhId, initStId] = value;

  const [d, setD] = useState(initD);
  const [vhId, setVhId] = useState(initVhId);
  const [stId, setStId] = useState(initStId);

  useEffect(() => setD(initD), [initD]);
  useEffect(() => setVhId(initVhId), [initVhId]);
  useEffect(() => setStId(initStId), [initStId]);

  const [vhcl, setVhcl] = useState<SimpleVhcl | null>(null);
  const [strg, setStrg] = useState<SimpleStrg | null>(null);

  const [invalidStrg, setInvalidStrg] = useState(false);
  const vhOrVir = `${strg?.cate === StrgCate.Team ? 'V' : ''}${vhId}`;

  const { msgBox: m } = useMessageState();

  useEffect(() => {
    // 팀전략은 일부펀드만 가능하고, 디비화 안해놓아서 코드 박음
    const invalidTeam =
      (stId?.isIn('STOCK1', 'STOCK2') && !vhId?.isIn('TTM', 'TTE')) ||
      (stId === 'STOCKT' && vhId !== 'TRP');

    if (invalidTeam) {
      const mainVh = stId === 'STOCKT' ? 'TRP' : 'TTM | TTE'
      m.alert(`해당 펀드 팀전략 미존재. 펀드가 ${mainVh} 인지 확인하세요.`);
    } else {
      onChange(d, vhId, stId, vhcl, strg);
    }
  }, [d, vhId, stId, vhcl, strg]);

  return (
    <span className="children-me-2">
      {dates
      ? 
      <select value={d} onChange={e => setD(e.target.value)}>
        {dates.map(v => <option key={v}>{v}</option>)}
      </select>
      : 
      <DateSelector
        value={d}
        onChange={(date) => {
          if (date !== d) setD(date);
        }}
      />}
      {d && (
        <VhclSelector
          d={d}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...vhProps}
          value={vhId}
          onChange={(v) => {
            if (v) {
              setVhId(v.Id);
              setVhcl(v);
              if (v?.Id !== vhId) {
                const invalid =
                  // 기존 선택 전략이 새로운 펀드서 무효면 Whole
                  (v.grp && strg?.tgts && !strg.tgts.contains(v.grp)) ||
                  // ETF는 펀드별로 전략 달라서 타겟만 맞아서 될게 아님
                  (vhcl && v.grp === VhGrpTy.ETF && stId !== 'Whole');
                if (invalid) setStId(null);
              }
            }
          }}
        />
      )}

      {vhcl && (
        <StrgSelector
          d={d}
          vh={vhId}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...stProps}
          value={stId}
          empty
          nonEmptyDefault
          onChange={(v) => {
            setInvalidStrg(!v);
            if (v) {
              setStId(v.Id);
              setStrg(v);
            }
          }}
        />
      )}

      <span style={{ color: 'gray' }}>{vhOrVir}</span>
      <span style={{ color: 'gray' }}>{stId}</span>
      {invalidStrg && <span style={{ color: 'red' }}>전략 재선택 필요</span>}
    </span>
  );
}
