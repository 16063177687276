import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { AlgoOrder, AlgoState } from '../../../Tms/Ems';
import { VhGrpTy } from '../../../Tms/Tms';
import { DftBtnStyleMx } from '../../../AppTypes';
import { FuncCall } from '../../../tmsutil';

interface Props {
  oprocCall: FuncCall;
  d: string;
  vhGrp: VhGrpTy;
  algoBatch: AlgoOrder[];
}

export function getColorByState(algo: AlgoOrder): string {
  switch (algo.state) {
    case AlgoState.Generated:
      return 'red';
    case AlgoState.Allowed:
      return 'blue';
    case AlgoState.Loaded:
      return 'green';
    default:
      return 'black';
  }
}

const columnHelper = createColumnHelper<AlgoOrder>();
const columns = [
  columnHelper.accessor('Id', {
    header: 'Id',
    size: 60,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodId', {
    header: '종목',
    size: 70,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodNm', {
    header: '종목명',
    size: 150,
    meta: { frozen: true },
  }),
  columnHelper.accessor('vhRepr', {
    header: '상품',
    size: 70,
    meta: { frozen: true },
  }),
  columnHelper.accessor('stId', {
    header: '전략',
    size: 60,
    meta: { frozen: true },
  }),
  columnHelper.accessor('qty2ord', {
    header: '예정량',
    size: 60,
    meta: {
      frozen: true,
      formatter: (v: number | undefined) => v?.toFixedWithComma(0),
    },
  }),
  columnHelper.accessor('isBuy', {
    header: '매수',
    meta: { type: ColumnType.Checkbox, frozen: true },
  }),
  columnHelper.accessor('state', {
    header: '상태',
    size: 70,
    meta: {
      frozen: true,
      styler: (v, r) => ({
        color: getColorByState(r.original),
      }),
    },
  }),
  columnHelper.accessor('hms0', {
    header: '시작T',
    size: 50,
    meta: {
      frozen: true,
      formatter: (v: string | undefined) => v?.slice(0, 5),
    },
  }),
  columnHelper.accessor('hms1', {
    header: '종료T',
    size: 50,
    meta: {
      frozen: true,
      formatter: (v: string | null | undefined) => v?.slice(0, 5),
    },
  }),
  columnHelper.accessor('byBrkDisp', {
    header: '브',
    size: 30,
    meta: { type: ColumnType.Checkbox, frozen: true },
  }),
  columnHelper.accessor('ErrorMsg', {
    header: '에러',
    size: 100,
    meta: { frozen: true },
  }),
  columnHelper.accessor('WarnMsg', {
    header: '경고',
    size: 50,
    meta: { frozen: true },
  }),
].map((v) => v as ColumnDef<AlgoOrder, unknown>);

export enum AllowType {
  All = 'All',
  Dom = 'Dom',
  UStk = 'UStk',
  UFut = 'UFut',
}

const fields = [
  'allowed',
  '---',
  'skip',
  'unskip',
  '---',
  'dsgndBrk',
  'enforcedBrk',
  '---',
  'swap',
  'cash',
  '---',
  'byBrk',
  'enforceDone',
];

export function GetAlgoBatchFieldsName(fld: string) {
  switch (fld) {
    case 'allowed':
      return '주문 허용';
    case 'skip':
      return '주문 제외';
    case 'unskip':
      return '주문 제외 취소';
    case 'dsgndBrk':
      return '증권사 변경';
    case 'enforcedBrk':
      return '증권사 강제 변경';
    case 'swap':
      return '스왑 진입';
    case 'cash':
      return '캐시 진입';
    case 'byBrk':
      return '브로커주문';
    case 'enforceDone':
      return '강제완료처리';
    case '---':
      return '--------';
    default:
      return null;
  }
}

export const GetAllowTypeDesc = (ty: AllowType) => {
  switch (ty) {
    case AllowType.All:
      return '전체';
    case AllowType.Dom:
      return '국내';
    case AllowType.UStk:
      return '해외주식';
    case AllowType.UFut:
      return '해외선물';
    default:
      return '';
  }
};

const allowTys = [AllowType.All, AllowType.Dom, AllowType.UStk, AllowType.UFut];

export default function AlgoBatchTab({
  oprocCall,
  d,
  vhGrp,
  algoBatch,
}: Props) {
  const [data, setData] = useState<AlgoOrder[]>(algoBatch);
  const [sltdAlgoVal, setSltdAlgoVal] = useState<string | null>(null);
  const [fld, setFld] = useState<string>('allowed');
  const [dgState, setDgState] = useState<DataGridState<AlgoOrder>>();
  const meta: TableMeta<AlgoOrder> = {
    // width: 800,
    height: 500,
    useGlobalFilter: true,
  };

  useEffect(() => setData(algoBatch), [algoBatch]);

  const { msgBox: m } = useMessageState();

  const updateAlgos = async () => {
    if (fld === '---') return;
    const fldnm = GetAlgoBatchFieldsName(fld);
    if (fldnm == null) {
      m.alert(`잘못된 업데이트 필드 ${fld}`);
      return;
    }
    if (dgState === undefined) return;
    const ids = dgState.selectedObjs.map((v) => v.Id);
    if (ids.length === 0) {
      m.alert('선택된 주문 없음');
      return;
    }
    if (!(await m.confirm(`${ids.length}개 주문 ${fldnm} ? `))) return;
    const params = { ids, fld, val: sltdAlgoVal };
    oprocCall('UpdateAlgos', params, { title: fldnm });
  };

  const allowAlgoBatch = async (ty: AllowType) => {
    const msg = `${GetAllowTypeDesc(ty)}일괄 주문 허용`;
    if (!(await m.confirm(`${msg} ?`))) return;
    const params = { d, tgt: vhGrp, allow: ty };
    oprocCall('AllowAlgoBatch', params, { title: msg });
  };

  return (
    <div className="row" style={{ width: '1000px' }}>
      <div className="col">
        <select name="batchField" onChange={(e) => setFld(e.target.value)}>
          {fields.map((v, k) => (
            <option key={v === '---' ? v + k : v} value={v}>
              {' '}
              {GetAlgoBatchFieldsName(v)}{' '}
            </option>
          ))}
        </select>
        <input
          type="text"
          name="sltdAlgoVal"
          style={{ marginLeft: '10px' }}
          size={10}
          value={sltdAlgoVal || ''}
          onChange={(e) => setSltdAlgoVal(e.target.value as string)}
        />
        <button
          type="button"
          className={DftBtnStyleMx}
          style={{ marginLeft: '10px' }}
          onClick={() => updateAlgos()}
        >
          선택주문 적용
        </button>
        <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
          | 일괄허용{' '}
        </span>
        {allowTys.map((v) => (
          <button
            type="button"
            key={v}
            style={{ width: '70px' }}
            className={DftBtnStyleMx}
            onClick={() => allowAlgoBatch(v)}
          >
            {GetAllowTypeDesc(v)}
          </button>
        ))}
      </div>
      <hr className="light narrow" />
      <div>
        <DataGrid
          data={data}
          columns={columns}
          meta={meta}
          onStateChange={setDgState}
        />
      </div>
    </div>
  );
}
