import React, { useCallback, useEffect, useState } from 'react';
import './autocomplete.scss';
import { AutoCompleteProps } from './AutoComplete';

// iphone에서 datalist 제대로 작동안해서 별개로

export default function AutoCompleteWoDatalist<T>({
  field,
  suggestions,
  itemTemplate,
  completeMethod,
  onChange,
  size,
  inputClassName,
  title,
  inputStyle,
  placeholder
}: AutoCompleteProps<T>) {
  const [currQry, setCurrQry] = useState<string>('');
  const [showList, setShowList] = useState(false);

  const onInputChange = (query: string) => {
    setCurrQry(query);
    if (query.length > 1) completeMethod(query);
  };

  useEffect(() => {
    setShowList(true);
  }, [suggestions]);

  const onItemSelect = (val: string) => {
    setCurrQry(val);
    const picked = suggestions.find((v) => val === String(v[field]));
    onChange(picked ?? null);
    setShowList(false);
  };

  useEffect(() => {
    if (!currQry) onChange(null);
  }, [currQry]);

  // https://velog.io/@hyejeong/Keyboard-Navigation-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0
  const [cursor, setCursor] = useState(-1);
  
  const keyboardNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (!showList) return;
      if (e.key === 'ArrowDown') {
        setCursor((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
      }
      if (e.key === 'ArrowUp') {
        setCursor((prev) => (prev > 0 ? prev - 1 : 0));
      }
      if (e.key === 'Escape') {
        setCursor(-1);
        setShowList(false);
      }
      if (e.key === 'Enter' && cursor >= 0 && suggestions[cursor]) {
        onItemSelect(String(suggestions[cursor][field]));
      }
    },
    [suggestions, showList, cursor],
  );

  useEffect(() => {
    window.addEventListener('keydown', keyboardNavigation);
    return () => {
      window.removeEventListener('keydown', keyboardNavigation);
    };
  }, [keyboardNavigation]);

  return (
    <span className="autocomplete-wrapper">
      <input
        value={currQry}
        placeholder={placeholder}
        onChange={(e) => onInputChange(e.target.value)}
        size={size}
        className={inputClassName}
        title={title}
        style={inputStyle}
      />
      {showList && !!suggestions.length && (
        <ul className="autocomplete-items-wrapper">
          {suggestions.map((v, index) => (
            <li
              key={String(v[field])}
              className={(index === cursor ? 'current' : '')}
              onClick={() => onItemSelect(String(v[field]))}
              onMouseMove={() => setCursor(index)}
            >
              {itemTemplate(v)}
              {` (${v[field]})`}
            </li>
          ))}
        </ul>
      )}
    </span>
  );
}
