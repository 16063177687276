import React from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { OrdStPrd } from '../../../Tms/Ord';
import { ex2num } from '../../../Tms/Common';
import { FuncCall, checkItems } from '../../../tmsutil';

const columnHelper = createColumnHelper<OrdStPrd>();
const columns = [
  columnHelper.accessor('tgtStr', { header: '펀드', size: 40 }),
  columnHelper.accessor('prodId', { header: '종목', size: 60 }),
  columnHelper.accessor('prodNm', { header: '종목명', size: 100 }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 30,
    meta: {
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('wei', {
    header: '비중(%)',
    size: 60,
    meta: {
      formatter: (v, r) =>
        `${(ex2num(r.original.ex) * v).toFixed(2)}${
          r.original.exitAll ? '(전)' : ''
        }`,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('fxdQty', { header: '수량', size: 60 }),
  columnHelper.accessor('t0', { header: 't0', size: 40 }),
  columnHelper.accessor('t1', { header: 't1', size: 40 }),
  columnHelper.accessor('nGen', {
    header: '회차',
    size: 40,
    meta: { formatter: (v, r) => `${v} / ${r.original.nDays}` },
  }),
  columnHelper.accessor('d0', {
    header: '시작일',
    size: 50,
    meta: { formatter: (v) => v.slice(5) },
  }),
  columnHelper.accessor('d1', {
    header: '종료일',
    size: 50,
    meta: { formatter: (v) => v.slice(5) },
  }),
  columnHelper.accessor('stateStr', { header: '상태', size: 40 }),
  columnHelper.accessor('prgsRtToday', {
    header: '당일%',
    size: 50,
    meta: { formatter: (v) => v.toFixed(0) },
  }),
  columnHelper.accessor('prgsRt', {
    header: '누적%',
    size: 50,
    meta: { formatter: (v) => v.toFixed(0) },
  }),
].map((v) => v as ColumnDef<OrdStPrd, unknown>);

export default function OrdStPrdTab({
  call,
  d,
  data,
}: {
  call: FuncCall;
  d: string;
  data: OrdStPrd[];
}) {
  const { msgBox: m } = useMessageState();

  const mod = async (fld: string, items: OrdStPrd[]) => {
    if (!checkItems(items, m)) return;
    const val = await m.prompt('변경값');
    if (val == null) return; // empty string은 허용
    const par = { ids: items.map((v) => v.Id), fld, val };
    call('ModOrdStPrd', par);
  };

  const cancel = (items: OrdStPrd[]) => {
    if (!checkItems(items, m)) return;
    const par = { ids: items.map((v) => v.Id), d };
    call('CancelOrdStPrd', par);
  };

  const del = (items: OrdStPrd[]) => {
    if (!checkItems(items, m)) return;
    if (items.some((v) => v.stateStr === '작동')) {
      m.alert('정지부터 하세요');
      return;
    }
    const par = { ids: items.map((v) => v.Id) };
    call('DelOrdStPrd', par);
  };

  const meta: TableMeta<OrdStPrd> = {
    maxHeight: 160,
    contextMenus: [
      { label: 't0 변경', callback: (items) => mod('t0', items) },
      { label: 't1 변경', callback: (items) => mod('t1', items) },
      { label: '정지', callback: cancel },
      { label: '삭제', callback: del },
    ],
  };
  return <DataGrid data={data} columns={columns} meta={meta} />;
}
