import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { switchExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid, { SimpleGridArgs } from 'tmslib/src/table/SimpleGrid';
import Editor from 'tmslib/src/ui/Editor';
import NumericInput from 'tmslib/src/ui/NumericInput';
import Button from 'tmslib/src/ui/Button';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import { ValidData, callAxios, renameProdGrp, saveProdGrp } from '../../tmsutil';
import { LsWeiBySec } from '../../Tms/Pf';
import DVhStSelector from '../../shared/DVhStSelector';
import ProdSelector from '../../shared/ProdSelector';
import { LS } from '../../Tms/Common';
import DebouncedInput from 'tmslib/src/ui/DebouncedInput';

const page = '/Front/WeeklyPlan';

const columns: (keyof LsWeiBySec)[] = [
  'sec',
  'lnm',
  'ltw',
  'snm',
  'stw',
  'secltw',
  'secstw',
  'secntw',
];

const headers: string[] = ['Sec', 'Long', '%', 'Short', '%', 'L%', 'S%', 'Net'];

const args: SimpleGridArgs<LsWeiBySec> = {
  widths: { sec: 70, lnm: 130, snm: 130, memo: 80 },
  rowSpanFns: {
    sec: (r0, r1) => r0.original.sec === r1.original.sec,
    secltw: (r0, r1) => r0.original.sec === r1.original.sec,
    secstw: (r0, r1) => r0.original.sec === r1.original.sec,
    secntw: (r0, r1) => r0.original.sec === r1.original.sec,
    memo: (r0, r1) => r0.original.sec === r1.original.sec,
  },
  meta: {
    dftColWidth: 35,
    footStyle: { backgroundColor: 'lightgray' },
    editOnKeyDown: true,
  },
  dftFormatter: (v) => v?.toFixed(1),
  dftStyler: (v, c, r) => {
    const textAlign: 'center' | 'left' | undefined = switchExpr(
      c,
      ['sec', 'center'],
      ['memo', 'left'],
    );
    const whiteSpace = c === 'sec' || c === 'memo' ? 'normal' : undefined;
    const fontWeight = c.isIn('sec', 'secntw') ? 'bold' : undefined;
    const o = r.original;
    let color: string | undefined;
    if (c.isIn('lnm', 'ltw'))
      color = ((o.ltw ?? 0) - (o.lcw ?? 0)).getSignColor();
    if (c.isIn('snm', 'stw'))
      color = ((o.stw ?? 0) - (o.scw ?? 0)).getSignColor();
    if (c === 'secltw')
      color = ((o.secltw ?? 0) - (o.seclcw ?? 0)).getSignColor();
    if (c === 'secstw')
      color = ((o.secstw ?? 0) - (o.secscw ?? 0)).getSignColor();
    if (c === 'secntw') color = v?.getSignColor() ?? undefined;
    let backgroundColor: string | undefined;
    if (c === 'ltw' && (o.ltw ?? 0) > 0 && (o.lcw ?? 0) === 0)
      backgroundColor = '#fbb';
    if (c === 'stw' && (o.stw ?? 0) > 0 && (o.scw ?? 0) === 0)
      backgroundColor = '#fbb';
    return { textAlign, whiteSpace, fontWeight, color, backgroundColor };
  },
  footers: {
    sec: 'Sum',
    ltw: (data) => {
      const v = _.sumBy(data, 'ltw');
      const color = (v - _.sumBy(data, 'lcw')).getSignColor();
      return <span style={{ color }}>{v.toFixed(1)}</span>;
    },
    stw: (data) => {
      const v = _.sumBy(data, 'stw');
      const color = (v - _.sumBy(data, 'scw')).getSignColor();
      return <span style={{ color }}>{v.toFixed(1)}</span>;
    },
    secltw: (data) => {
      const v = _.sumBy(data, 'secltw');
      const color = (v - _.sumBy(data, 'seclcw')).getSignColor();
      return <span style={{ color }}>{v.toFixed(1)}</span>;
    },
    secstw: (data) => {
      const v = _.sumBy(data, 'secstw');
      const color = (v - _.sumBy(data, 'secscw')).getSignColor();
      return <span style={{ color }}>{v.toFixed(1)}</span>;
    },
    secntw: (data) => {
      const v = _.sumBy(data, (r) => (r.ltw ?? 0) - (r.stw ?? 0));
      const color = v.getSignColor();
      return <span style={{ color, fontWeight: 'bold' }}>{v.toFixed(1)}</span>;
    },
  },
  footStylers: {
    sec: () => ({ textAlign: 'center', fontWeight: 'bold' }),
  },
};

type NewProd = {
  prod?: string;
  grp?: string;
  ls: LS;
  w?: number;
};
export default function WeeklyPlan() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const [dates, setDates] = useState<string[]>([]);
  const d = searchParams.get('d') || (dates.length ? dates[0] : '');
  const vh = searchParams.get('vh') || info?.dftVh || '';
  const st =
    searchParams.get('st') ||
    (vh === info?.dftVh ? info?.dftSt || '' : 'Whole');

  const [baseWei, setBaseWei] = useState<LsWeiBySec[]>([]);
  const [planWei, setPlanWei] = useState<LsWeiBySec[]>([]);
  const [todayWei, setTodayWei] = useState<LsWeiBySec[]>([]);
  const [mkMemo, setMkMemo] = useState('');
  const [autoSaveMsg, setAutoSaveMsg] = useState('');

  const [newProd, setNewProd] = useState<NewProd>({ ls: LS.L });

  const call = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
    isGet?: boolean,
  ) =>
    callAxios({
      m,
      logger,
      url: `${page}/${func}`,
      params,
      onSuccess,
      isGet,
    });

  const getDates = () => {
    call('Dates', {}, (data) => setDates(data), true);
  };

  useEffect(getDates, []);

  const getData = () => {
    if (!d || !vh || !st) return;
    const params = { d, vh, st };
    call(
      'Data',
      params,
      (data) => {
        setBaseWei(data.baseWei);
        setPlanWei(data.planWei);
        setTodayWei(data.todayWei);
        setMkMemo(data.mkMemo);
      },
      true,
    );
  };

  const saveMktMemo = (memo: string, auto?: boolean) => {
    if (!d || !vh || !st) return;
    const params = { d, vh, st, grp: '__MK__', memo };
    call('SaveMemo', params, () => {
      if (auto) {
        setAutoSaveMsg(`[${new Date().toFormatString('HH:mm:ss')}] 자동저장됨`);
        setTimeout(() => setAutoSaveMsg(''), 3000);
      }
    });
  };

  useEffect(getData, [d, vh, st]);

  const updateField = (
    orignal: LsWeiBySec,
    rowIndex: number,
    columnId: string,
    value: unknown,
  ) => {
    const newPlanWei = (p: LsWeiBySec[]) =>
      p.map((r) => (r.Id === orignal.Id ? { ...r, [columnId]: value } : r));

    if (columnId === 'ltw' || columnId === 'stw') {
      const prod = columnId === 'ltw' ? orignal.lcd : orignal.scd;
      const ls = columnId === 'ltw' ? 1 : -1;
      const params = { d, vh, st, prod, w: ls * Number(value) };
      call('UpdatePlan', params, () => setPlanWei((p) => newPlanWei(p)));
    }
    if (columnId === 'memo') {
      const params = { d, vh, st, grp: orignal.sec, memo: value };
      call('SaveMemo', params, () => setPlanWei((p) => newPlanWei(p)));
    }
  };

  const deletePlan = (id: number) =>
    call('DeletePlan', { id }, () => getData());

  const updateProdGrp = (prod: string, oldGrp: string) =>
    saveProdGrp(m, logger, st, prod, oldGrp, () => getData());

  const contextMenus: IContextMenuItem<LsWeiBySec>[] = [
    {
      label: (items) => `롱 플랜 삭제 (${items[0].lnm ?? ''})`,
      disabled: (items) => !items[0].lPlanId,
      callback: (items) => deletePlan(items[0].lPlanId ?? 0),
    },
    {
      label: (items) => `숏 플랜 삭제 (${items[0].snm ?? ''})`,
      disabled: (items) => !items[0].sPlanId,
      callback: (items) => deletePlan(items[0].sPlanId ?? 0),
    },
    { divider: true },
    {
      label: (items) => `롱 종목 그룹 정정 (${items[0].lnm ?? ''})`,
      disabled: (items) => !items[0].lcd,
      callback: (items) => updateProdGrp(items[0].lcd ?? '', items[0].sec),
    },
    {
      label: (items) => `숏 종목 그룹 정정 (${items[0].snm ?? ''})`,
      disabled: (items) => !items[0].scd,
      callback: (items) => updateProdGrp(items[0].scd ?? '', items[0].sec),
    },
    {
      label: '그룹 이름 정정',
      callback: (items) => renameProdGrp(m, logger, st, items[0].sec, () => getData()),
    },
  ];

  const addNewProd = () => {
    if (!newProd.prod) {
      m.alert('종목 확인');
      return;
    }
    if (!newProd.w) {
      m.alert('비중 확인');
      return;
    }
    const ls = newProd.ls === LS.L ? 1 : -1;
    const params = {
      d,
      vh,
      st,
      prod: newProd.prod,
      w: ls * newProd.w,
      newCd: true,
      grp: newProd.grp,
    };
    call('UpdatePlan', params, () => {
      setNewProd({ prod: newProd.prod, ls: LS.L }); // 종목코드 남겨둠
      getData();
    });
  };

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DVhStSelector
          value={[d, vh, st]}
          onChange={(d_, vhId, stId) =>
            setSearchParams({
              d: d_,
              vh: vhId || '',
              st: stId || '',
            })
          }
          vh={{ smicIvy: true }}
          st={{ whole: true, smicIvy: true }}
          dates={dates}
        />
        <button type="button" className={DftBtnStyle} onClick={() => getData()}>
          Get
        </button>
      </div>

      <hr className="narrow light" />
      <div className="d-flex children-me-5">
        <div>
          <b>기준일 종가 보유</b>
          <SimpleGrid
            data={baseWei}
            columns={columns}
            headers={headers}
            args={args}
          />
        </div>
        <div>
          <b>당주 변경 계획</b>
          <SimpleGrid
            data={planWei}
            columns={[...columns, 'memo']}
            headers={[...headers, 'memo']}
            args={{
              ...args,
              editables: ['ltw', 'stw', 'memo'],
              meta: { ...args.meta, editable: true, updateField, contextMenus },
            }}
          />
          <div className="d-flex children-me-2">
            <span>
              Code:
              <ProdSelector
                onChange={(e) =>
                  setNewProd((p) => ({ ...p, prod: e?.Id ?? '' }))
                }
              />
            </span>
            <span>
              Group:
              <DebouncedInput
                type="text"
                value={newProd.grp ?? ''}
                placeholder="생략 가능"
                onChange={(v) =>
                  setNewProd((p) => ({ ...p, grp: v as string }))
                }
                style={{ width: '110px' }}
              />
            </span>
            <select
              value={newProd.ls}
              onChange={(e) =>
                setNewProd((p) => ({ ...p, ls: e.target.value as LS }))
              }
            >
              <option>{LS.L}</option>
              <option>{LS.S}</option>
            </select>
            <span>
              Wei(%):
              <NumericInput
                value={newProd.w ?? null}
                onChange={(v) =>
                  setNewProd((p) => ({ ...p, w: v ?? undefined }))
                }
                style={{ width: '60px' }}
              />
            </span>
            <Button label="신규종목 추가" onClick={() => addNewProd()} />
          </div>
        </div>
        <div>
          <b>현재 보유 + 주문</b>
          <SimpleGrid
            data={todayWei}
            columns={columns}
            headers={headers}
            args={args}
          />
        </div>
      </div>

      <hr className="narrow light" />
      <Editor
        style={{ width: '1000px' }}
        value={mkMemo}
        onBlur={(html) => setMkMemo(html)}
        onChange={(html) => {
          saveMktMemo(html, true);
        }}
        infoMsg={autoSaveMsg}
        showInfoMsg
        wait={3000}
      />
    </>
  );
}
