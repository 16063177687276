import React, { useContext, createContext, useReducer, ReactNode } from 'react';
import { VhGrpTy } from '../../../Tms/Tms';
import { LS } from '../../../Tms/Common';
import { OrdPrcTy2 } from '../../../Tms/Ord';
import { UpdateOrdStField } from './ordutil';

interface OrderInut {
  d: string;
  tgt: VhGrpTy;
  ord: number | null;
  stId: string;
  prodId: string;
  prodNm: string;
  ls: LS;
  chWei: number | null;
  exAll: boolean;
  pty: OrdPrcTy2;
  t0: string | null;
  t1: string | null;
  prc: number | null;
  note: string | null;
  vhIn: string | null;
  vhEx: string | null;
  prdD1: string | null;
}

export enum HoveredBtn {
  None,
  Batch,
  PrdBatch,
  Basket,
  Period,
  ModBatch,
  KeepReq,
  recallOrderBtnHover,
  loanKeepReqBtnHover,
}

interface OrderState {
  inp: OrderInut;
  hover: HoveredBtn;
  updFld: UpdateOrdStField;
  updVal: string | null;
  keepSwap: boolean;
}

const initialState: OrderState = {
  inp: {
    d: '',
    tgt: VhGrpTy.OldFund,
    ord: 0,
    stId: '',
    prodId: '',
    prodNm: '',
    ls: LS.L,
    chWei: null,
    exAll: false,
    pty: OrdPrcTy2.Opp,
    t0: null,
    t1: null,
    prc: null,
    note: null,
    vhIn: null,
    vhEx: null,
    prdD1: null,
  },
  hover: HoveredBtn.None,
  updFld: UpdateOrdStField.None,
  updVal: null,
  keepSwap: false,
};

export type OrderAction =
  | { type: 'inp/d_tgt'; d: string; tgt: VhGrpTy }
  | { type: 'inp/ord'; ord: number | null }
  | { type: 'inp/stId'; stId: string }
  | { type: 'inp/product'; Id: string; nm: string }
  | { type: 'inp/ls'; ls: LS }
  | { type: 'inp/chWei'; chWei: number | null }
  | { type: 'inp/exAll'; exAll: boolean }
  | { type: 'inp/pty'; pty: OrdPrcTy2 }
  | { type: 'inp/t0'; t0: string }
  | { type: 'inp/t1'; t1: string }
  | { type: 'inp/t0_t1'; t0: string; t1: string }
  | { type: 'inp/prc'; prc: number | null }
  | { type: 'inp/note'; note: string }
  | { type: 'inp/vhIn'; vhIn: string }
  | { type: 'inp/vhEx'; vhEx: string }
  | { type: 'inp/prdD1'; prdD1: string }
  | { type: 'hover'; hover: HoveredBtn }
  | { type: 'updFld'; fld: UpdateOrdStField }
  | { type: 'updVal'; val: string | null }
  | { type: 'keepSwap'; swap: boolean };

const OrderStateContext = createContext(initialState);
const OrderDispatchContext = createContext<React.Dispatch<OrderAction>>(() => {
  // do nothing;
});

export function useOrderState() {
  const context = useContext(OrderStateContext);
  if (context === undefined) {
    throw new Error('useOrderState must be used within a OrderProvider');
  }
  return context;
}

export function useOrderDispatch() {
  const context = useContext(OrderDispatchContext);
  if (context === undefined) {
    throw new Error('useOrderDispatch must be used within a OrderProvider');
  }
  return context;
}

function orderReducer(state: OrderState, action: OrderAction): OrderState {
  switch (action.type) {
    case 'inp/d_tgt':
      return { ...state, inp: { ...state.inp, d: action.d, tgt: action.tgt } };
    case 'inp/ord':
      return { ...state, inp: { ...state.inp, ord: action.ord } };
    case 'inp/stId':
      return { ...state, inp: { ...state.inp, stId: action.stId } };
    case 'inp/product':
      return {
        ...state,
        inp: { ...state.inp, prodId: action.Id, prodNm: action.nm },
      };
    case 'inp/ls':
      return { ...state, inp: { ...state.inp, ls: action.ls } };
    case 'inp/chWei':
      return { ...state, inp: { ...state.inp, chWei: action.chWei } };
    case 'inp/exAll':
      return { ...state, inp: { ...state.inp, exAll: action.exAll } };
    case 'inp/pty':
      return { ...state, inp: { ...state.inp, pty: action.pty } };
    case 'inp/t0':
      return { ...state, inp: { ...state.inp, t0: action.t0 } };
    case 'inp/t1':
      return { ...state, inp: { ...state.inp, t1: action.t1 } };
    case 'inp/t0_t1': {
      return { ...state, inp: { ...state.inp, t0: action.t0, t1: action.t1 } };
    }
    case 'inp/prc':
      return { ...state, inp: { ...state.inp, prc: action.prc } };
    case 'inp/note':
      return { ...state, inp: { ...state.inp, note: action.note } };
    case 'inp/vhIn':
      return { ...state, inp: { ...state.inp, vhIn: action.vhIn } };
    case 'inp/vhEx':
      return { ...state, inp: { ...state.inp, vhEx: action.vhEx } };
    case 'inp/prdD1':
      return { ...state, inp: { ...state.inp, prdD1: action.prdD1 } };
    case 'hover':
      return { ...state, hover: action.hover };
    case 'updFld':
      return { ...state, updFld: action.fld };
    case 'updVal':
      return { ...state, updVal: action.val };
    case 'keepSwap':
      return { ...state, keepSwap: action.swap };
    default:
      return { ...state };
  }
}
export function OrderProvider({ children }: { children: ReactNode }) {
  const [order, dispatch] = useReducer(orderReducer, initialState);

  return (
    <OrderStateContext.Provider value={order}>
      <OrderDispatchContext.Provider value={dispatch}>
        {children}
      </OrderDispatchContext.Provider>
    </OrderStateContext.Provider>
  );
}
