import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType } from 'tmslib/src/table/DataGrid';
import { EmsxRouteMsg } from '../../../Tms/Ems';


interface Props {
  emsxMsgs: EmsxRouteMsg[];
}

const columnHelper = createColumnHelper<EmsxRouteMsg>();
const columns = [
  columnHelper.accessor('hms', {
    header: 'Time',
    size: 60,
  }),
  columnHelper.accessor('sequence', {
    header: 'EmsxSeq',
    size: 100,
  }),
  columnHelper.accessor('status', {
    header: '상태',
    size: 60,
  }),
  columnHelper.accessor('order_type', {
    header: '주문유형',
    size: 60,
  }),
  columnHelper.accessor('amount', {
    header: '주문량',
    size: 60,
    meta: {
      type: ColumnType.Checkbox,
      formatter: (v) => v?.toFixedWithComma(0),
    },
  }),
  columnHelper.accessor('limit_price', {
    header: '가격',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('notes', { header: '메세지', size: 100 }),
  columnHelper.accessor('last_shares', {
    header: '체결량',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('last_price', { header: '체결가', size: 60 }),
  columnHelper.accessor('filled', {
    header: '총체결',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('avg_price', {
    header: '평단가',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('working', {
    header: '미체결',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
].map((v) => v as ColumnDef<EmsxRouteMsg, unknown>);

export default function EmsxMsgTab({ emsxMsgs }: Props) {
  const [data, setData] = useState<EmsxRouteMsg[]>(emsxMsgs);
  useEffect(() => setData(emsxMsgs), [emsxMsgs]);

  const meta: TableMeta<EmsxRouteMsg> = {};

  return <DataGrid data={data} columns={columns} meta={meta} />;
}
