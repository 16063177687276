import React, { useRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { RtDiff, updateRtRows, setRtEffect } from '../../rtutil';
import { UrlGrid, UrlGridArgs , callAxios, checkItems } from '../../tmsutil'; 
import DateSelector from '../../shared/DateSelector';
import {
  OrderPermit,
  OrderPermitBatch,
  ViolPermit,
  orderPermitBatchSchema,
} from '../../Tms/MO';

type PermitRes = {
  t: number;
  opermits: OrderPermit[] | null;
  opermits_diff: RtDiff<OrderPermit> | null;
  vpermits: ViolPermit[] | null;
  vpermits_diff: RtDiff<ViolPermit> | null;
};

// prettier-ignore
const orderPermitBatch: UrlGridArgs<OrderPermitBatch> = {
  url: '/Middle/Permit/OrderPermitBatch',
  title: '주문 일괄 허용',
  columns: ['d', 'ty', 'stId', 'prodId', 'prodNm', 'permit', 't0', 't1', 'cnfrmNm', 'cnfrmNote', 'cnfrmT',],
  headers: ['날짜', '항목', '전략', '종목코드', '종목명', '허용', '시작', '종료', '허용자', '컴플노트', '확인T',],
  widths: { ty: 80, cnfrmNote: 200, },
  editable: true,
  height: undefined,
  infoMsg: `항목: 주문금액과다(1), 종목한도초과(2), 넷한도초과(3), 롱한도(5%)초과(4)
전체전략: Whole 
전종목: WALL`,
  schema: orderPermitBatchSchema,
};

export default function Permit() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { connection, connected, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const d0 = searchParams.get('d0') || d;

  const lastResT = useRef(0);

  const [opermits, setOpermits] = useState<OrderPermit[]>([]);
  const [vpermits, setVpermits] = useState<ViolPermit[]>([]);
  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  useEffect(() => setUpdateNeeded(), [d, d0]);

  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: { d, d0 },
        reqAddr: 'RequestPermit',
        rcvAddr: 'ReceivePermit',
        connection,
        onReceive: (res: PermitRes) => {
          setOpermits((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.opermits,
              diff: res?.opermits_diff,
            }),
          );
          setVpermits((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.vpermits,
              diff: res?.vpermits_diff,
            }),
          );
        },
      }),
    [connection, connected, d, d0, updateNeeded],
  );

  const call = (func: string, params: unknown) =>
    callAxios({
      m,
      logger,
      url: `/Middle/Permit/${func}`,
      params,
      onSuccess: () => setUpdateNeeded(),
      title: '주문 허용 요청 설정',
    });

  const setPermit = async (
    ty: 'Order' | 'Viol',
    items: (OrderPermit | ViolPermit)[],
    permit: true | false | 'note',
  ) => {
    if (!checkItems(items, m, true)) return;
    let note = null;
    if (permit === 'note') {
      note = await m.prompt('허용/불가 사유');
    }
    const par = {
      id: items[0].Id,
      permit: permit === 'note' ? null : permit,
      note,
    };
    call(`Set${ty}Permit`, par);
  };

  return (
    <div className="children-mb-1" style={{ minWidth: '1600px' }}>
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, d0 })}
        onChangeD0={(date0) =>
          date0 !== d0 && setSearchParams({ d, d0: date0 })
        }
      />
      <hr className="light narrow" />
      <UrlGrid
        args={orderPermitBatch}
        params={{ d, d0 }}
        refreshNeeded={updateNeeded}
      />
      <hr className="light narrow" />
      <SimpleGrid
        data={opermits}
        columns={[
          'd',
          'desc',
          'stId',
          'tgt',
          'prodId',
          'prodNm',
          'ls',
          'ex',
          'wei',
          'hm0',
          'hm1',
          'reqNm',
          'reqNote',
          'reqT',
          'permit',
          'cnfrmNm',
          'cnfrmNote',
          'cnfrmT',
        ]}
        headers={[
          '날짜',
          '항목',
          '전략',
          '타겟',
          '종목코드',
          '종목명',
          'LS',
          'EX',
          '비중%',
          '시작T',
          '종료T',
          '요청자',
          '요청사유',
          '요청T',
          '허용',
          '허용자',
          '컴플노트',
          '확인T',
        ]}
        args={{
          checkboxes: ['permit'],
          title: '주문별 허용 요청',
          dftFormatter: (v, c) => (c === 'wei' ? v?.toFixed(2) : v),
          dftStyler: (v, c, r) =>
            c === 'ls' || c === 'ex'
              ? { color: r.original.sgn?.getSignColor() }
              : null,
          meta: {
            contextMenus: [
              {
                label: '허용',
                callback: (items) => setPermit('Order', items, true),
              },
              {
                label: '불가',
                callback: (items) => setPermit('Order', items, false),
              },
              {
                label: '사유 입력',
                callback: (items) => setPermit('Order', items, 'note'),
              },
            ],
          },
          widths: {
            desc: 80,
            reqNote: 150,
            reqT: 60,
            cnfrmNote: 150,
            cnfrmT: 60,
          },
        }}
      />
      <hr className="light narrow" />
      <SimpleGrid
        data={vpermits}
        columns={[
          'd',
          'msg',
          'stId',
          'reqNm',
          'reqNote',
          'reqT',
          'permit',
          'cnfrmNm',
          'cnfrmNote',
          'cnfrmT',
        ]}
        headers={[
          '날짜',
          '항목',
          '전략',
          '요청자',
          '요청사유',
          '요청T',
          '허용',
          '허용자',
          '컴플노트',
          '확인T',
        ]}
        args={{
          checkboxes: ['permit'],
          title: '사흘 위반 허용 요청',
          meta: {
            contextMenus: [
              {
                label: '허용',
                callback: (items) => setPermit('Viol', items, true),
              },
              {
                label: '불가',
                callback: (items) => setPermit('Viol', items, false),
              },
              {
                label: '사유 입력',
                callback: (items) => setPermit('Viol', items, 'note'),
              },
            ],
          },
          widths: {
            msg: 80,
            reqNote: 150,
            reqT: 60,
            cnfrmNote: 150,
            cnfrmT: 60,
          },
        }}
      />
    </div>
  );
}
