import { z } from 'zod';
import { switchExpr } from 'tmslib/src/util/utils';
import { LS, EX, lsSchema, exSchema } from './Common';
import { Vehicle, VhGrpTy, AcctGrp } from './Tms';
import { AstTy } from './Prod';
import { zodDate } from './ZodType';

export enum Executor {
  TEST = 'TEST',
  FIX = 'FIX',
  INDI = 'INDI',
  MZML = 'MZML',
  SIM = 'SIM',
  OFF = 'OFF',
  PEND = 'PEND',
  FIX2 = 'FIX2',
  TEST2 = 'TEST2',
  Offset = 'Offset',
  EMSX = 'EMSX',
  EmsxUmb = 'EmsxUmb',
  EmsxTest = 'EmsxTest',
}
export const executorSchema = z.nativeEnum(Executor);

export enum OrdPrcTy2 {
  My = 'My',
  Opp = 'Opp',
  Fixed = 'Fixed',
  Market = 'Market',
  BfClose = 'BfClose',
  AfClose = 'AfClose',
  AfAuc = 'AfAuc',
  OpenAuc = 'OpenAuc',
  CloseAuc = 'CloseAuc',
}

export const GetOrdPrcTyName = (ty: OrdPrcTy2) => {
  switch (ty) {
    case OrdPrcTy2.My:
      return 'CD(자기)';
    case OrdPrcTy2.Opp:
      return 'CD';
    case OrdPrcTy2.Fixed:
      return '지정가';
    case OrdPrcTy2.Market:
      return '시장가';
    case OrdPrcTy2.BfClose:
      return '장전종가';
    case OrdPrcTy2.AfClose:
      return '장후종가';
    case OrdPrcTy2.AfAuc:
      return '장후단일';
    case OrdPrcTy2.OpenAuc:
      return '시작동호';
    case OrdPrcTy2.CloseAuc:
      return '마감동호';
    default:
      return 'Wrong OrdPrcTy2';
  }
};

export const OrdPrcTyValTxts = Object.keys(OrdPrcTy2)
  // .filter((v) => isNaN(Number(v))) StringEnum 이라 필요없음
  .filter((v) => v !== OrdPrcTy2.My)
  .map((v) => ({ val: v, txt: GetOrdPrcTyName(v as OrdPrcTy2) }));

export enum OrderTime {
  KStkAllDay = 'KStkAllDay',
  KStkFirstHalf = 'KStkFirstHalf',
  KStkH11 = 'KStkH11',
  KStkH14 = 'KStkH14',
  UStkAllDay = 'UStkAllDay',
}

export const GetOrderTimeName = (ot: OrderTime) => {
  switch (ot) {
    case OrderTime.KStkAllDay:
      return '종일';
    case OrderTime.KStkFirstHalf:
      return '오전';
    case OrderTime.KStkH11:
      return '11시';
    case OrderTime.KStkH14:
      return '14시';
    case OrderTime.UStkAllDay:
      return '해외';
    default:
      return 'Wrong OrderTIme';
  }
};

export function GetOrdFromOrderTime(ot: OrderTime): number | null {
  switch (ot) {
    case OrderTime.KStkAllDay:
      return 10000;
    case OrderTime.KStkFirstHalf:
      return 10100;
    case OrderTime.KStkH11:
      return 10510;
    case OrderTime.KStkH14:
      return 10520;
    case OrderTime.UStkAllDay:
      return 11000;
    default:
      return null;
  }
}

export const OrderTimeValTxts = Object.keys(OrderTime)
  .filter((v) => Number.isNaN(Number(v)))
  .map((v) => ({ val: v, txt: GetOrderTimeName(v as OrderTime) }));

export enum OrdStState {
  Removed = 'Removed',
  Generated = 'Generated',
  Pending = 'Pending',
  MapNewFail = 'MapNewFail',
  MapModFail = 'MapModFail',
  Waiting = 'Waiting',
  Working = 'Working',
  Done = 'Done',
}

export interface OrdSt {
  Id: number;
  d: string;
  tgtStr: string;
  stId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  sgn: number;
  state: OrdStState;
  stateDisp: string;
  Done: boolean;
  hm0: string;
  hm1: string;
  wei: number;
  exitAll?: boolean;
  note?: string | null;
  errResolved?: boolean;
  nErrs?: number;
  prgsRt: number;
  mSentRt: number;
  prcTy: OrdPrcTy2;
  limitPrc?: number | null;
  pend: boolean;
  ord: number;
  fillPrc: number | null;
  totQty: number | null;
  currPrc: number | null;
  isVI?: boolean;
  slow?: boolean;
  verySlow?: boolean;
  maxVhPrgsDiff?: number | null;
  oqty: number;
  nUnAcpt?: number;
  offsetWei?: number | null;
  errMsgDisp?: string;
  warn?: string;
  info?: string;
  propNinfo?: string;
  prodNm: string | null;
  oppWrkn?: boolean;
  futMaxSprd?: string | null;
  gantt?: string | null;
  modify?: null;
  delete?: null;
  reqPermit?: null;
}

export function os2num(s: OrdStState): number {
  switch (s) {
    case OrdStState.Removed:
      return -10;
    case OrdStState.Generated:
      return 0;
    case OrdStState.Pending:
      return 5;
    case OrdStState.MapNewFail:
      return 7;
    case OrdStState.MapModFail:
      return 8;
    case OrdStState.Waiting:
      return 10;
    case OrdStState.Working:
      return 20;
    case OrdStState.Done:
      return 30;
    default:
      return -100;
  }
}

export const GetOrdStStateColor = (state: OrdStState, pend: boolean) =>
  pend
    ? 'violet'
    : switchExpr(
        state,
        [OrdStState.MapNewFail, 'purple'],
        [OrdStState.MapModFail, 'purple'],
        [OrdStState.Waiting, 'red'],
        [OrdStState.Working, 'blue'],
        [OrdStState.Done, 'green'],
      ) ?? 'black';

export enum DmlActions {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
}

export interface OrdStLog {
  Id: number;
  d: string;
  t: string;
  tHms: string;
  ostId: number;
  act: DmlActions;
  fld: string;
  oldVal: string;
  newVal: string;
  oldValAdj: string;
  newValAdj: string;
  userId: string;
  userNm: string;
}

export interface OrdFxdQty {
  id: number;
  d: string;
  ostId: number;
  vhId: string;
  qty: number;
}

export interface OrdVh {
  Id: number;
  vhId: string;
  orgq: number;
  qty: number;
  osq: number;
  sentq: number;
  smq: number;
  fq: number;
  wq: number;
  cnclq: number;
  rejq: number;
  fillPrc: number | null;
  errMsgDisp?: string;
  info?: string;
  q2o: number;
  acptq: number;
}

export enum OrdProcReqState {
  None = 'None',
  Requested = 'Requested',
  Success = 'Success',
  Fail = 'Fail',
}

export const GetOrdProcReqStateDesc = (ty: OrdProcReqState) => {
  switch (ty) {
    case OrdProcReqState.None:
      return '';
    case OrdProcReqState.Requested:
      return '요청됨';
    case OrdProcReqState.Success:
      return '완료';
    case OrdProcReqState.Fail:
      return '에러';
    default:
      return '';
  }
};

export interface OrdState {
  Id: number;
  emsPend: boolean;
  errMsg: string | null;
  blockProc: boolean;
  blockOper: string | null;
  staledOsts: string | null;
}

export interface OrdProcState {
  Id: number;
  reqStateMsg: string | null;
  state: OrdProcReqState;
  prdCnt: number;
  domWaiting: number;
  ustkWaiting: number;
  ufutWaiting: number;
}

export interface LogWithId {
  Id: number;
  str: string;
}

export interface OrdStPrd {
  Id: number;
  d0: string;
  d1: string;
  tgtStr: string;
  stId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  sgn: number;
  wei: number;
  exitAll: boolean;
  note: string | null;
  stateStr: string;
  fxdQty: number | null;
  t0: string | null;
  t1: string | null;
  prgsRt: number;
  prgsRtToday: number;
  nDays: number;
  nGen: number;
  prodNm: string | null;
}

/*
export enum OrdLogTy {
  SpotGenerated = 'SpotGenerated',
  NotEnoughLoan = 'NotEnoughLoan',
  NoAccount = 'NoAccount',
  NoBroker = 'NoBroker',
  NoPrice = 'NoPrice',
  NegativeQ2o = 'NegativeQ2o',
  SubCntErr = 'SubCntErr',
  SubCntWarn = 'SubCntWarn',
  SystemErr = 'SystemErr',
  NoDMA = 'NoDMA',
  LongPosLimit = 'LongPosLimit',
  CalcOrdNetFail = 'CalcOrdNetFail',
  OrdDirErr = 'OrdDirErr',
  FixAdminReject = 'FixAdminReject',
  Etc = 'Etc',
  ByBrkBuyAndSell = 'ByBrkBuyAndSell',

  YAT_SE_DMA = 'YAT_SE_DMA',
  IndiPrcErr = 'IndiPrcErr',
  IndiErr = 'IndiErr',

  KiwoomErr = 'KiwoomErr',
  OverSellShort = 'OverSellShort',
  KsdFillNoOrdNo = 'KsdFillNoOrdNo',
  KsdFillUnknown = 'KsdFillUnknown',
  KsdFillOver = 'KsdFillOver',
  FillAllocErr = 'FillAllocErr',
  NoHousePos = 'NoHousePos',
  MissedOrdNet = 'MissedOrdNet',
  OrderMappingFail = 'OrderMappingFail',

}

export const GetOrdLogTyName = (ol: OrdLogTy) => {
  switch (ol) {
    case OrdLogTy.SpotGenerated : return '주문생성'
    case OrdLogTy.NotEnoughLoan : return '대차잔고부족'
    case OrdLogTy.NoAccount : return '기본계좌없음'
    case OrdLogTy.NoBroker : return '기본증권사없음'
    case OrdLogTy.NoPrice : return '현재가없음'
    case OrdLogTy.NegativeQ2o : return '주문수량음수'
    case OrdLogTy.SubCntErr : return '양건수량이상'
    case OrdLogTy.SubCntWarn : return '양건수량감안주문'
    case OrdLogTy.SystemErr : return '시스템에러'
    case OrdLogTy.NoDMA : return 'DMA불가계좌'
    case OrdLogTy.LongPosLimit : return 'Long비중4제한'
    case OrdLogTy.CalcOrdNetFail : return '스케줄계산에러'
    case OrdLogTy.OrdDirErr : return '주문방향에러'
    case OrdLogTy.FixAdminReject : return 'FIX거부'
    case OrdLogTy.Etc : return '기타'
    case OrdLogTy.ByBrkBuyAndSell : return '브로커주문양방향'
    case OrdLogTy.YAT_SE_DMA : return '유안타숏셀DMA'
    case OrdLogTy.IndiPrcErr : return 'indi가격확인에러'
    case OrdLogTy.IndiErr : return 'indi조회에러'
    case OrdLogTy.KiwoomErr : return '키움호가에러'
    case OrdLogTy.OverSellShort : return '공매도금지종목'
    case OrdLogTy.KsdFillNoOrdNo : return '예탁원주문번호없음'
    case OrdLogTy.KsdFillUnknown : return '알수없는예탁원체결'
    case OrdLogTy.KsdFillOver : return '예탁원체결수량초과'
    case OrdLogTy.FillAllocErr : return '체결분배오류'
    case OrdLogTy.NoHousePos : return '하우스포지션없음'
    case OrdLogTy.MissedOrdNet : return '주문스케줄누락'
    case OrdLogTy.OrderMappingFail : return '주문매핑 '
    default: return 'Wrong OrderTIme'
  }
}

export interface OrdLog {
  Id: number;
  d: string;
  t: string;
  ty: OrdLogTy;
  vhId: string;
  stId: string;
  brkId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  val:number | null;
  msg:string;
  prodNm: string | null;
}

export interface ordAmtLog {
  Id: number;
  d: string;
  t: string;
  tgt: VhGrpTy;
  byBrk: boolean;
  cnt : number;
  amt : number;

}

*/

export interface OrdSsBanEvt {
  Id: number;
  prodId: string;
  notified: string;
  d0: string;
  d1: string;
  listd: string | null;
  allowed: boolean;
  memo: string | null;
  operId: string;
  prodNm: string | null;
  operNm: string | null;
  info: string | null;
  gent: string | null;
}

export const ordSsBanEvtSchema = z.object({
  prodId: z.string(),
  notified: zodDate,
  d0: zodDate,
  d1: zodDate,
  listd: zodDate,
  allowed: z.coerce.boolean(),
  memo: z.string().nullish(),
  operId: z.string().nullish().readonly(),
  prodNm: z.string().nullish().readonly(),
  operNm: z.string().nullish().readonly(),
  info: z.string().nullish().readonly(),
  gent: z.string().nullish().readonly(),
});

export enum CfgTys {
  None = 'None',
  ShortAsSwap = 'ShortAsSwap',
  LongAsSwap = 'LongAsSwap',
  CoverCashFirst = 'CoverCashFirst',
  BuyCashSellSwap = 'BuyCashSellSwap',
}

export interface OrdVhCfg {
  Id: number;
  d0: string;
  d1: string;
  vhId: string;
  ty: CfgTys;
  inProds?: string | null;
  exProds?: string | null;
  invalid: boolean;
  vh?: Vehicle | null; // Vehicle? vh
}

export const cfgTysSchema = z.nativeEnum(CfgTys);
export const ordVhCfgSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  vhId: z.string(),
  ty: cfgTysSchema,
  inProds: z.string().nullish(),
  exProds: z.string().nullish(),
  invalid: z.coerce.boolean(),
});

export enum BanSide {
  All = 'All', // 매매금지
  Buy = 'Buy', // 매수금지
  Sell = 'Sell', // 매도금지
  LE = 'LE', // 롱진입금지
  LX = 'LX ', // 롱청산금지
  SE = 'SE', // 숏진입금지
  SX = 'SX', // 숏청산금지
}

export enum BanTy {
  일반 = '일반',
  AI딜 = 'AI딜',
}

export interface OrdBanGrp {
  Id: number;
  grp: VhGrpTy;
  vhId?: string;
  stId: string;
  prodId: string;
  side: BanSide;
  memo?: string;
  d0: string;
  d1: string;
  ty: BanTy;
  operId: string;
  prodNm?: string;
  operNm?: string;
}

export const banSideSchema = z.nativeEnum(BanSide);
export const banTySchema = z.nativeEnum(BanTy);

export interface OrdRiskCfg {
  Id: number;
  maxAmtGen: number;
  maxAmt1m: number;
  maxAmt10m: number;
  opgaHitRto: number;
  maxCashRto: number;
}

export interface OrdAcctSpec {
  Id: number;
  vhId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  acctId: string;
  byBrk: boolean;
  stIn?: string;
  prodNm?: string;
}

export const ordAcctSpecSchema = z.object({
  vhId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  ls: lsSchema,
  ex: exSchema,
  stIn: z.string().nullish(),
  acctId: z.string(),
  byBrk: z.coerce.boolean(),
});

export interface OrdBrkAmtTgt {
  Id: number;
  d0: string;
  d1: string;
  tgt: VhGrpTy;
  brkId: string;
  astTy: AstTy;
  acctGrp: AcctGrp;
  vhIn?: string;
  vhEx?: string;
  amtTgt: number;
  amtSent: number;
  amtDone: number;
  ystdDone: number;
  brkGen: number;
  RemainedDays: number;
  TodayTgt: number;
  TodaySent: number;
  ToSendToday: number;
  TodayBrkGen: number;
  ToGenBrkToday: number;
  rto: number;
  currD?: string;
  invalid: boolean;
}

export interface OrdBrkDft {
  Id: number;
  tgt: VhGrpTy;
  brkId: string;
  astTy: AstTy;
  acctGrp: AcctGrp;
  vhIn?: string;
  vhEx?: string;
  rto: number;
}

export interface OrdMap {
  Id: number;
  tgt: VhGrpTy;
  src: VhGrpTy;
  stId: string;
}
