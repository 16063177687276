import { LS, EX } from './Common';
import { BoFillTy } from './BO';
import { Executor } from './Ord';

export enum AlgoType {
  None = 'None',
  Market = 'Market',
  Limit = 'Limit',
  BfClose = 'BfClose',
  AfClose = 'AfClose',
  AfAuc = 'AfAuc',
  TWAP = 'TWAP',
  VWAP = 'VWAP',
  QNT_TWAP = 'QNT_TWAP',
  QNT_VWAP = 'QNT_VWAP',
  QNT_IS = 'QNT_IS',
  QNT_T1 = 'QNT_T1',
  QRAFT_TWAP = 'QRAFT_TWAP',
  QRAFT_VWAP = 'QRAFT_VWAP',
}

export const getAlgoTypeName = (ty: AlgoType) => {
  switch (ty) {
    case AlgoType.Market:
      return '시장가';
    case AlgoType.Limit:
      return '지정가';
    case AlgoType.BfClose:
      return '장전종가';
    case AlgoType.AfClose:
      return '장후종가';
    case AlgoType.AfAuc:
      return '장후단일';
    default:
      return ty.toString();
  }
};

export const workingAlgoTypes = [
  AlgoType.Market,
  AlgoType.Limit,
  AlgoType.BfClose,
  AlgoType.AfClose,
  AlgoType.AfAuc,
  AlgoType.TWAP,
  AlgoType.QRAFT_TWAP,
  AlgoType.QRAFT_VWAP,
];

export interface AlgoOrderPend {
  Id: number;
  d: string;
  executor: Executor;
  stId: string;
  acctId: string;
  prodId: string;
  ls: LS;
  ex: EX;
  algoTy: AlgoType;
  qty2ord: number;
  price: number | null;
  t0: string;
  t1: string | null;
  byBrk: boolean;
  ordMsg: string | null;
  operId: string;
  fee: number | null;
  block: boolean;
  done: boolean;
  prodNm: string | null;
  operNm: string | null;
  sgn: number;
  noArb: boolean | null;
}

export enum AlgoState {
  Generated = 'Generated',
  Allowed = 'Allowed',
  Loaded = 'Loaded',
}

export interface AlgoOrder {
  Id: number;
  mainExec: Executor;
  stId: string;
  algoTy: AlgoType;
  prodId: string;
  isBuy: boolean;
  qty2ord: number;
  price: number | null;
  hms0: string;
  hms1: string | null;
  state: AlgoState;
  prodNm: string | null;
  operNm: string | null;
  vhRepr: string;
  fillTy: BoFillTy;
  OrderQty: number;
  CumQty: number;
  LeavesQty: number;
  AvgPx: number | null;
  Done: boolean;
  partRate: number | null;
  MaxLeavesQty: number | null;
  FillDelayDisp?: string;
  IntvDisp?: string;
  byBrkDisp?: boolean;
  ordMsg?: string | null;
  cnclMsg?: string | null;
  ErrorMsg?: string | null;
  WarnMsg?: string;
  CostTick: number | null;
}

export interface AlgoTag {
  Id: number;
  tag: string;
  hms: string;
  remHms: string | null;
  val: string | null;
  operNm: string | null;
}

export interface AlgoVh {
  Id: number;
  vhId: string;
  qty2ord: number;
  GenQty: number;
  SentQty: number;
  LeavesQty: number;
  CumQty: number;
  Done: boolean;
  fillPrgsRt: number;
}

export interface AlgoAcct {
  Id: number;
  acctId: string;
  ls: LS;
  ex: EX;
  sgn: number;
  GenQty: number;
  SentQty: number;
  LeavesQty: number;
  CumQty: number;
  AvgPx: number;
}

export interface AlgoSlice {
  Id: number;
  prodId: string;
  prodNm: string;
  vhQtysRepr: string | null;
  sliceNo: number;
  schedHms: string;
  ls: LS;
  ex: EX;
  sgn: number;
  acctId: string;
  executor: string;
  byBrk?: boolean;
  ordMsg?: string | null;
  qty2ord: number;
  price: number | null;
  procHms: string | null;
  ClOrdID: string | null;
  OrdStatus: string | null;
  OrderQty: number;
  CumQty: number;
  LeavesQty: number;
  AvgPx: number;
  condFailHms?: string | null;
  infoMsg?: string | null;
  acctMatch: string;
  plcStrgDisp?: string | null;
  doneHms?: string | null;
  DoneElapsed?: string | null;
  DeadlineHms: string | null;
  enforceRsnDisp?: string | null;
  replRsnDisp?: string | null;
  ErrorMsg?: string | null;
  Done: boolean;
  nRepl?: number | null;
  lessQtyRsn?: string | null;
  CostTick: number | null;
}

export enum NMC {
  N = 'N',
  M = 'M',
  C = 'C',
}

export interface AlgoSliceCncl {
  Id: number;
  procHms: string;
  nmc: NMC;
  ClOrdID: string;
  OrigClOrdID: string;
  price: number | null;
  FillQty: number;
  FillPrc: number | null;
  ErrorMsg: string | null;
  ordMsg: string | null;
  replRsn: string | null;
}

export interface AlgoStat {
  Id: number;
  prodId: string;
  prodNm: string | null;
  ls: LS;
  ex: EX;
  sgn: number;
  swap: boolean;
  fq: number;
  fa: number;
}

export interface FixDispMsg {
  Id: number;
  MsgType: string;
  Seq: number;
  STime: string;
  TTime: string | null;
  STimeHms: string;
  TTimeHms: string;
  ClOrdID: string | null;
  OrigClOrdID: string | null;
  OrdStatus: string | null;
  ExecType: string | null;
  OrderQty: number | null;
  Price: number | null;
  EncodedText: string | null;
  LastQty: number | null;
  LastPx: number | null;
  CumQty: number | null;
  AvgPx: number | null;
  LeavesQty: number | null;
}

export interface EmsxRouteMsg {
  Id: number;
  hms: string;
  amount: number | null;
  avg_price: number | null;
  filled: number | null;
  limit_price: number | null;
  last_shares: number | null;
  last_price: number | null;
  notes: string | null;
  order_type: string | null;
  sequence: number | null;
  status: string | null;
  working: number | null;
}
