import React, { useMemo, useState, useEffect } from 'react';
import { Table, Column, SortDirection, RowData } from '@tanstack/react-table';
import _ from 'lodash';
import OutsideHandler from '../ui/OutsideHandler';

const DftBtnStyleMx = 'btn btn-sm btn-outline-secondary ms-2';

export interface IFilterBoxProps<TData extends RowData> {
  table: Table<TData>;
  column: Column<TData, unknown>;
  top: number;
  left: number;
}

export default function FilterBox<TData extends RowData>({
  table,
  column,
  top,
  left,
  close,
}: IFilterBoxProps<TData> & { close: () => void }) {
  const sortedUniqueValues = useMemo(() => {
    const valTxts = column.columnDef.meta?.valTxts;
    const vals = Array.from(column.getFacetedUniqueValues().keys())
      .map((v) => v?.toString() ?? '')
      .map((v: string) =>
        valTxts ? valTxts.find((x) => x.val === v)?.txt ?? '' : v,
      )
      .sort();
    return _.uniq(vals); // getFacetedUniqueValues를 했지만, 다시 매핑하면서 중복 생길수 있어서
  }, [column]);

  const [isSorted, setIsSorted] = useState<false | SortDirection>(
    column.getIsSorted(),
  );
  const [filterValues, setFilterValues] = useState<string[]>(
    Array.from((column.getFilterValue() as Set<string>) ?? []),
  );
  const [filterLike, setFilterLike] = useState<string>('');

  const setSort = (v: false | SortDirection) => () => {
    setIsSorted(v);
    table.toggleAllRowsSelected(false);
  };

  const setFilters =
    (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setFilterValues([value, ...filterValues]);
      } else {
        setFilterValues(filterValues.filter((v) => v !== value));
      }
      // 필터 바뀌면 기존 선택행들 다 리셋해줌. 선택된거 감춰져서 안보이는데 선택 안된걸로 아는거 막게
      table.toggleAllRowsSelected(false);
    };

  useEffect(() => {
    if (isSorted === false) column.clearSorting();
    else column.toggleSorting(isSorted === 'desc'); // multi sort 일단 안함
  }, [column, isSorted]);

  const setSelectAll = () => {
    // 전체선택 -> 전체해제
    if (filterValues.length === sortedUniqueValues.length) {
      // table.toggleAllRowsSelected(false);
      setFilterValues([]);
    } else {
      // 전체선택 -> 전체해제
      // table.toggleAllRowsSelected(true);
      setFilterValues(sortedUniqueValues);
    }
  };

  useEffect(() => {
    column.setFilterValue(
      filterValues.length === 0 ? undefined : new Set<string>(filterValues),
    );
  }, [column, filterValues]);

  return (
    <div className="filterbox" style={{ top: `${top}px`, left: `${left}px` }}>
      <OutsideHandler callback={close}>
        <div className="filterbox-sort">
          <span>Sort : </span>
          &nbsp;
          {[false, 'asc', 'desc'].map((v, k) => (
            <span key={v.toString()}>
              <label htmlFor={`filterSort${k}`}>
                <input
                  type="radio"
                  id={`filterSort${k}`}
                  checked={isSorted === v}
                  onChange={setSort(v as false | SortDirection)}
                />
                {v === false ? 'NONE' : (v as string).toUpperCase()}
              </label>
              &nbsp;&nbsp;&nbsp;
            </span>
          ))}
        </div>
        <input
          type="text"
          name="filterSearch"
          placeholder="Search"
          value={filterLike}
          onChange={(e) => setFilterLike(e.target.value)}
        />
        <div className="filterbox-inside">
          <label htmlFor="filterItemAll" key="All">
            <input
              id="filterItemAll"
              type="checkbox"
              checked={filterValues.length === sortedUniqueValues.length}
              // onChange={() => setFilterValues(sortedUniqueValues)}
              onChange={() => setSelectAll()}
            />
            (Select all)
          </label>

          {sortedUniqueValues
            .filter((v) => !filterLike || v.includes(filterLike))
            .slice(0, 10000) // 임의로 유니크 필터 결과 만개 컷.
            .map((value, k) => (
              <label htmlFor={`filterItem${k}`} key={value}>
                <input
                  id={`filterItem${k}`}
                  type="checkbox"
                  checked={filterValues.findIndex((v) => v === value) >= 0}
                  onChange={setFilters(value)}
                />
                {value}
              </label>
            ))}
        </div>
        <div style={{ margin: '5px' }}>
          <button
            type="button"
            className={DftBtnStyleMx}
            style={{ width: '80px' }}
            onClick={() => close?.()}
          >
            Close
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-sm btn-link"
            style={{ width: '80px' }}
            onClick={() => setFilterValues([])}
          >
            (clear all)
          </button>
          &nbsp;&nbsp;
        </div>
      </OutsideHandler>
    </div>
  );
}
