import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

// TODO YestBizDay, TmrwBizDay navi button

interface Props {
  value: string;
  initDate0?: string | null;
  onChange: (date: string) => void;
  onChangeD0?: (date0: string) => void;
  className?: string;
  name?: string;
  placeholder?: string;
}

export default function DateSelector(props: Props) {
  const {
    value: initDate,
    initDate0,
    onChange,
    onChangeD0,
    className,
    name,
    placeholder,
  } = props;

  const [date_, setDate_] = useState<string>(initDate);
  const [date0_, setDate0_] = useState<string | null | undefined>(initDate0);

  const [date, setDate] = useState<string>(initDate);
  const [date0, setDate0] = useState<string | null | undefined>(initDate0);
  // const { msgBox: m } = useMessageState();

  useEffect(() => {
    setDate(initDate);
  }, [initDate]);

  useEffect(() => {
    setDate0(initDate0);
  }, [initDate0]);

  useEffect(() => {
    if (date0 && date < date0) setDate0(date);
    onChange(date);
  }, [date]);

  useEffect(() => {
    if (date0) onChangeD0?.(date0);
  }, [date0]);

  const updateWhenD0changed = () => {
    if (date0_ && date0_ > date) {
      setDate_(date0_);
      setDate(date0_);
    }
    setDate0(date0_ ?? initDate);
  };

  return (
    <span>
      {initDate0 && (
        <>
          <DatePicker
            wrapperClassName="datepicker"
            dateFormat="yyyy-MM-dd"
            selected={date0 ? new Date(date0) : null}
            name={name ? `${name}0` : 'd0'}
            className={className}
            placeholderText={placeholder}
            onCalendarClose={() => updateWhenD0changed()}
            onChange={(v: Date) => {
              const dstr = v?.toFormatString('yyyy-MM-dd') ?? '';
              setDate0_(dstr);
            }}
            onClickOutside={() => updateWhenD0changed()}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key !== 'Enter') return;
              updateWhenD0changed();
            }}
          />
          ~
        </>
      )}
      <DatePicker
        wrapperClassName="datepicker"
        dateFormat="yyyy-MM-dd"
        selected={date ? new Date(date) : null}
        name={name ?? 'd'}
        className={`${className}${initDate0 ? ' bold' : ''}`}
        placeholderText={placeholder}
        onCalendarClose={() => setDate(date_)}
        onChange={(v: Date) => {
          setDate_(v?.toFormatString('yyyy-MM-dd') ?? '');
        }}
        onClickOutside={() => {
          setDate(date_);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key !== 'Enter') return;
          setDate(date_);
        }}
      />
    </span>
  );
}
