// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr.light {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

hr.narrow {
  margin: 5px 0;
}

.children-mb-1>* {
  margin-bottom: 0.25rem;
}

.children-mb-2>* {
  margin-bottom: 0.5rem;
}

.children-mb-3>* {
  margin-bottom: 1rem;
}

.children-mb-4>* {
  margin-bottom: 1.5rem;
}

.children-mb-5>* {
  margin-bottom: 3rem;
}

.children-ms-1>* {
  margin-left: 0.25rem;
}

.children-ms-2>* {
  margin-left: 0.5rem;
}

.children-ms-3>* {
  margin-left: 1rem;
}

.children-ms-4>* {
  margin-left: 1.5rem;
}

.children-ms-5>* {
  margin-left: 3rem;
}

.children-me-1>* {
  margin-right: 0.25rem;
}

.children-me-2>* {
  margin-right: 0.5rem;
}

.children-me-3>* {
  margin-right: 1rem;
}

.children-me-4>* {
  margin-right: 1.5rem;
}

.children-me-5>* {
  margin-right: 3rem;
}


#globalProcessingMsg {
  color: red;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./../tmslib/src/tmslib.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,SAAS;EACT,0BAA0B;EAC1B,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;;AAGA;EACE,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["hr.light {\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid #ccc;\n  padding: 0;\n}\n\nhr.narrow {\n  margin: 5px 0;\n}\n\n.children-mb-1>* {\n  margin-bottom: 0.25rem;\n}\n\n.children-mb-2>* {\n  margin-bottom: 0.5rem;\n}\n\n.children-mb-3>* {\n  margin-bottom: 1rem;\n}\n\n.children-mb-4>* {\n  margin-bottom: 1.5rem;\n}\n\n.children-mb-5>* {\n  margin-bottom: 3rem;\n}\n\n.children-ms-1>* {\n  margin-left: 0.25rem;\n}\n\n.children-ms-2>* {\n  margin-left: 0.5rem;\n}\n\n.children-ms-3>* {\n  margin-left: 1rem;\n}\n\n.children-ms-4>* {\n  margin-left: 1.5rem;\n}\n\n.children-ms-5>* {\n  margin-left: 3rem;\n}\n\n.children-me-1>* {\n  margin-right: 0.25rem;\n}\n\n.children-me-2>* {\n  margin-right: 0.5rem;\n}\n\n.children-me-3>* {\n  margin-right: 1rem;\n}\n\n.children-me-4>* {\n  margin-right: 1.5rem;\n}\n\n.children-me-5>* {\n  margin-right: 3rem;\n}\n\n\n#globalProcessingMsg {\n  color: red;\n  font-size: large;\n  font-weight: bold;\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
