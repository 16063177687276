import React from 'react';
import { roundToSigFig } from 'tmslib/src/util/utils';
import { OrdSt } from '../../../Tms/Ord';

export default function OstsGantt({ osts }: { osts: OrdSt[] }) {
  if (!osts.length) return null;
  const width = 300;
  const hBtmMrgn = 1;
  const rects = osts
    .filter((v) => v.gantt)
    .map((v) => {
      const t =
        v.gantt === null || v.gantt === undefined ? [] : v.gantt.split('|');
      return {
        Id: v.Id,
        prgsRt: v.prgsRt,
        fillPrc: roundToSigFig(v.fillPrc),
        cls: t[0],
        x: Number(t[1]),
        y: Number(t[2]),
        w: Number(t[3]),
        h: Number(t[4]),
      };
    });
  const height = Math.max(
    10,
    (osts.length ? Math.max(...rects.map((v) => v.y + v.h)) : 0) + hBtmMrgn,
  );
  return (
    <div>
      <svg width={width} height={height}>
        {rects.map((r) => (
          <React.Fragment key={r.Id}>
            <linearGradient id={`grad_${r.Id}`} className={r.cls}>
              <stop offset="0%" stopColor="var(--color-filled)" />
              <stop
                offset={`${Math.round(r.prgsRt)}%`}
                stopColor="var(--color-filled)"
              />
              <stop
                offset={`${Math.round(r.prgsRt)}%`}
                stopColor="var(--color-remained)"
              />
              <stop offset="100%" stopColor="var(--color-remained)" />
            </linearGradient>
            <rect
              x={r.x}
              y={r.y}
              width={r.w}
              height={r.h}
              className={r.cls}
              fill={`url(#grad_${r.Id})`}
              strokeWidth="1"
              rx="1"
              ry="1"
            >
              <title>{`${r.prgsRt}% | ${r.fillPrc}`}</title>
            </rect>
          </React.Fragment>
        ))}
      </svg>
    </div>
  );
}
