import React, { useRef } from 'react';
import _ from 'lodash';
import { Delta, EmitterSource } from 'quill/core';
import EditorCore from './EditorCore';
import OutsideHandler from './OutsideHandler';
import './editor.css';

export default function Editor({
  value: initialValue,
  onChange,
  onBlur,
  style,
  className,
  readOnly,
  wait = 1000,
  infoMsg,
  showInfoMsg,
}: {
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  wait?: number;
  infoMsg?: string;
  showInfoMsg?: boolean;
}) {
  const quillRef = useRef<any>(null);

  // EditorCore가 uncontrolled 라서 setValue 방식은 안됨
  React.useEffect(() => {
    quillRef.current?.clipboard.dangerouslyPasteHTML(initialValue);
  }, [initialValue]);

  const callBlur = () => {
    if (!onBlur) return;
    const html = quillRef.current?.getSemanticHTML();
    onBlur(html);
  };

  const callChangeBase = () => {
    if (!onChange) return;
    const html = quillRef.current?.getSemanticHTML();
    onChange(html);
  };

  const callChange = !wait
    ? callChangeBase
    : _.throttle(callChangeBase, wait, { leading: false });

  return (
    <OutsideHandler callback={() => callBlur()}>
      <EditorCore
        ref={quillRef}
        defaultValue={initialValue}
        style={style}
        className={className}
        onSelectionChange={(range: unknown, oldRange: unknown) => {
          // onBlur 효과
          // https://quilljs.com/docs/api#selection-change
          // https://codepen.io/DmitrySkripkin/pen/eeXpZB
          if (range === null && oldRange !== null) callBlur();
        }}
        onTextChange={(
          delta: Delta,
          oldContent: Delta,
          source: EmitterSource,
        ) => {
          if (source === 'user') callChange();
        }}
        readOnly={readOnly}
      />
      {showInfoMsg && (
        <div
          className="alert alert-slim alert-info like-pre"
          style={{ width: '500px', height: '23px' }}
        >
          {infoMsg}
        </div>
      )}
    </OutsideHandler>
  );
}
