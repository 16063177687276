import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import './NavMenu.css';
import Dropdown from 'tmslib/src/ui/Dropdown';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthDispatch, useAuthState } from './Auth/AuthContext';
import { utilGet, utilPost } from '../tmsutil';
import { Bookmark } from '../AppTypes';

export default function NavMenu() {
  const isProduction = process.env.NODE_ENV === 'production';

  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const menuGroups = _.chain(user?.menus ?? [])
    .groupBy('grp')
    .map((g) => _.first(g))
    .value();

  const { pathname } = useLocation();
  const pathtok = pathname.split('/');
  const grpIdx = isProduction ? 2 : 1;
  const currGrp = pathtok.length > grpIdx && pathtok[grpIdx];
  const showReachLink = user && (user.isSupervisory || user.isAiPe);
  // #region help
  const [showHelpCfg, setShowHelpCfg] = useState<boolean>(); // help설정하는 버튼을 보여줄지말지
  const useHelpPages = [
    '/Back/Master',
    '/Back/Market',
    '/Back/Query',
    '/Back/Closing',

    '/Singa/Back',
    '/next/Back/Master',
    '/next/Back/Market',
    '/next/Back/Query',
    '/next/Singa/Back',
    '/next/Back/Closing',
  ];

  const [showHelp, setShowHelp] = useState<boolean>(info?.showHelp ?? false); // help를 보여줄지 말지 설정 값 토글
  
  const dispatch = useAuthDispatch();

  const toggleShowHelpButton = () => {
    utilPost(m, logger, 'ToggleShowHelp', { url: pathname }, (data) => {
      setShowHelp(data);
      utilGet(m, logger, 'AppInfo', {}, (data_) => {
        dispatch({ type: 'SET_APPINFO', info: data_ });
      });
    });
  };

  useEffect(() => {
    setShowHelpCfg(useHelpPages.includes(pathname));
  }, [pathname]);

  // #endregion

  // #region BOOKMARK
  const [bookmark, setBookmark] = useState<Bookmark | null>(null);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(info?.bookmarks ?? []);

  const navigate = useNavigate();
  useEffect(() => {
    if (bookmark) navigate(bookmark.url);
  }, [bookmark]);

  useEffect(() => setBookmarks(info?.bookmarks ?? []), [info])

  useEffect(() => {
    if (pathname !== bookmark?.url) setBookmark(null);
  }, [pathname]);

  const isBookmarkedPage = bookmarks.some((v) => v.url === pathname);

  const toggleBookmark = () => {
    utilPost(
      m,
      logger,
      'ToggleBookmark',
      { url: pathname, remove: isBookmarkedPage },
      () => {
        utilGet(m, logger, 'AppInfo', {}, (data) => {
          dispatch({ type: 'SET_APPINFO', info: data });
        });
      },
    );
  };
  // #endregion

  const isNotiSupported = () => 'Notification' in window;

  return (
    <header>
      <nav className="navbar navbar-expand navbar-light bg-white border-bottom box-shadow mb-3 ms-2 py-0">
        <div className="d-flex flex-wrap align-items-center">
          <a className="navbar-brand" style={{ width: '50px' }} href="/">
            TMS
          </a>
          <ul className="navbar-nav">
            {menuGroups.map((grp, k) => (
              <li key={grp?.Id ?? k} className="nav-item">
                {/* 기존 웹으로 연결되니 history 무관해짐. NavLink 대신 a 태그써야 클릭시 바로 이동됨 */}
                {isProduction && (
                  <a
                    href={grp ? grp.url : ''}
                    className={`${
                      grp?.grp === currGrp ? 'active ' : ''
                    }nav-link text-dark`}
                  >
                    {grp ? grp.grp : ''}
                  </a>
                )}
                {!isProduction && (
                  <NavLink
                    className={({ isActive }) =>
                      `${isActive ? 'active ' : ''}nav-link text-dark`
                    }
                    to={grp ? grp.rawurl : ''} // 리액트 디버깅 모드에선 react/ 로 시작안해야
                  >
                    {grp ? grp.grp : ''}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
          {showReachLink && (
            <a
              className="btn btn-light btn-sm mx-2"
              href="https://ai.timefolio.co.kr"
              target="_blank"
              rel="noreferrer"
            >
              AI 리치
            </a>
          )}
          <button
            type='button'
            className="ml-2 p-1 btn btn-link"
            style={{
              fontSize: 'large',
              color: isBookmarkedPage ? '#0070ff' : 'gray',
            }}
            onClick={() => toggleBookmark()}
          >
            ★
          </button>
          <Dropdown
            value={bookmark}
            onChange={(v) => setBookmark(v)}
            options={bookmarks}
            optionLabel={v => v.title}
            placeholder="바로가기"
            style={{ borderColor: '#ddd' }}
          />{' '}
          {showHelpCfg && (
            <button
              type='button'
              className="ml-2 p-1 btn btn-link"
              style={{ fontSize: 'large' }}
              onClick={() => toggleShowHelpButton()}
            >
              <span
                className="badge badge-pill badge-success"
                style={{
                  backgroundColor: showHelp === true ? '#0099DD' : 'gray',
                }}
              >
                H
              </span>
            </button>
          )}
          {info && info.uncheckedNotiCnt > 0 && (
            <h5 style={{ margin: '0 5px' }}>
              <a
                className="btn btn-light btn-sm"
                href="/Tools/RqstDocu?cate=Notice"
                target="_blank"
              >
                공지&nbsp;
                <span className="badge bg-warning text-dark">
                  {info.uncheckedNotiCnt}
                </span>
                <img
                  alt=""
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfmBAYGFS2qxW3wAAAArklEQVQY003QIU4DURSF4e81bzJBPFBgK4oj43FViCaFHbAUltENtBJHNwC2tkFCgsNg+kKaYSYZxLTN/Nfc3F+cnBsEE5USjY1ft87VNtHEo0ZG68yfKzM3nqJKYyELaAVffrwro1KWtSCYevBsbBcPBxiZuvfizaWmFz2VubVXXNgPRba01Snc+R6Kz9MWFSPQGdL1gbUkiYrDRElSB9engkeSwioMXnKktvXxDw1SLCeH+OzcAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA0LTA2VDA2OjIxOjM5KzAwOjAwlGmEZgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNC0wNlQwNjoyMTozOSswMDowMOU0PNoAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
                />
              </a>
            </h5>
          )}
          {info?.uncheckedReports && info.uncheckedReports.cnt > 0 && (
            <h5 style={{ margin: '0 5px' }}>
              <a
                className="btn btn-light btn-sm"
                href={`/Front/Research?sub=${info.uncheckedReports.subj}`}
              >
                리서치&nbsp;
                <span className="badge bg-warning text-dark">
                  {info.uncheckedReports.cnt}
                </span>
              </a>
            </h5>
          )}
          {isNotiSupported() && Notification.permission === 'granted' && (
            <span className="badge bg-success mx-2">알림 허용됨</span>
          )}
          {!isNotiSupported() ||
            (Notification.permission === 'denied' && (
              <span className="badge bg-danger mx-2">알림 차단됨</span>
            ))}
          <span className="ms-2">{user?.Name || ''}</span>
          <Link
            className="nav-link text-dark"
            to={isProduction ? '/next/logout' : '/logout'}
          >
            Logout
          </Link>
        </div>
      </nav>
    </header>
  );
}
