import React, { useRef, useEffect } from 'react';



export const signColorSpan = (
  v: number | undefined,
  digits: number,
  args?: {
    style?: React.CSSProperties;
    body?: React.ReactNode;
  },
) => {
  const { style, body } = args ?? {};
  return (
    <span style={{ color: v?.getSignColor(), ...style }}>
      {body ?? v?.toFixedWithComma(digits)}
    </span>
  );
};

export function AlwaysScrollToBottom() {
  const elementRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => elementRef.current?.scrollIntoView());
  return <div ref={elementRef} />;
}

// wwwroot util.js
export function rgb2colorSub(rgb: number) {
  const rgbt = Math.min(Math.max(0, rgb), 255);
  const hexlist = '0123456789ABCDEF';
  return String(hexlist.charAt(Math.floor(rgbt / 16)));
}

export function rgb2color(red: number, green: number, blue: number) {
  const redn = rgb2colorSub(red);
  const greenn = rgb2colorSub(green);
  const bluen = rgb2colorSub(blue);
  return `#${redn}${greenn}${bluen}`;
}
